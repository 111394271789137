import React, { useState } from 'react'
// import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'

function SearchBar() {
  // 開啟搜尋預設
  // eslint-disable-next-line no-unused-vars
  const [useSearch, setUseSearch] = useState(true)

  // 課程
  const [lesson, setLesson] = useState([])

  // 經過搜尋、排序顯示
  // eslint-disable-next-line no-unused-vars
  const [lessonDisplay, setLessonDisplay] = useState([])

  // 是不是在載入中
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true)

  // 輸入框用的文字輸入狀態
  const [search, setSearch] = useState('')

  // 暫時功能停用
  // const [announcement, setAnnouncement] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const handleSearch = async () => {
    setIsLoading(true)

    const response = await fetch(
      `https://w3.ispan.com.tw/api/Classes/search/${search}`,
      {
        method: 'get',
      }
    )
    const data = await response.json()

    // 最後設定到狀態中
    setLesson(data)
    setLessonDisplay(data)

    const newLesson = lesson.filter((v, i) => {
      return v.className.includes(search)
    })
    setLessonDisplay(newLesson)

    // 3秒關閉指示器
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }

  return (
    <>
      {/* <div
        className={
          useSearch ? 'menuLoginItems_content' : 'menuLoginItems_content_search'
        }
        onClick={() => {
          setUseSearch(!useSearch)
        }}
      >
        <i className="fas fa-search"></i>
        <p
          className={
            useSearch ? 'menuLoginItems_txt' : 'menuLoginItems_txt_search'
          }
        >
          搜尋
        </p>
      </div> */}
      <a href="/search">
        <div
          className={
            useSearch
              ? 'menuLoginItems_content'
              : 'menuLoginItems_content_search'
          }
          // onClick={() => setAnnouncement(!announcement)}
        >
          <i className="fas fa-search"></i>
          <p
            className={
              useSearch ? 'menuLoginItems_txt' : 'menuLoginItems_txt_search'
            }
          >
            搜尋
          </p>
        </div>
      </a>
      {/* <div className={useSearch ? 'd-none' : 'd-block'}> */}
      <div className={useSearch ? 'd-none' : 'd-block'}>
        <div className="searchBar-content">
          <div className="searchBar-content_inner">
            <input
              type="search"
              className="searchBar-item"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              placeholder="搜尋課程關鍵字"
            />
            <a href="/search">
              <button
                className="searchBar-button"
                onClick={() => {
                  handleSearch()
                  setUseSearch(!useSearch)
                }}
              >
                <i className="fas fa-search"></i>
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* 提示視窗 */}
      {/* <div className={announcement ? 'd-flex' : 'd-none'} id="contactMessage">
        <div className="message-content">
          <div className="message-info_inner">
            <h4>暫時關閉使用</h4>
            <div className="region-list_content">
              <div className="region-list">
                <p>
                  很抱歉，搜尋功能暫時停用，您若有想詢問課程歡迎來電，(02)
                  6631-6588
                </p>
              </div>
            </div>
          </div>
          <div className="">
            <button
              type=""
              className="region-list_btn"
              onClick={() => setAnnouncement(!announcement)}
            >
              關閉
            </button>
          </div>
        </div>
      </div> */}
      {/* 提示視窗 */}
    </>
  )
}
export default SearchBar
