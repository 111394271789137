import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// 5G+AI跨域智慧天線設計工程師就業養成班詳細內容
function AntennaClass() {
  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      {/* 課程介紹 */}
      <div>
        <div className="pb-4">
          <h6>
            <strong>【課程目標】</strong>
          </h6>

          <ul className="listStyle__ul">
            <li>學員在參與完成本課程的學習後，應該能夠達成以下之目標</li>
            <li>
              【短期】：
              能夠使用Python程式語言處理與5G天線相關之軟硬體整合，完成課堂上的練習與作業，於課程結束後、通過評量，取得結業證書
            </li>
            <li>
              【中期】：
              能夠獨當一面運用程式語言在智慧天線設計與5G的相關應用，並加入AI人工智慧的學科知識
            </li>
            <li>
              【長期】：
              能夠進一步銜接與5G及AI相關的進階學科，讓學員能夠以團隊分工的方式完成期末專題，並將專題成果展示給求才廠商，順利找到好工作
            </li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程大綱 */}
      <div>
        <div className="content__title">
          <h3>課程大綱</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>

        <div className="employmentClassification-list_content">
          <div className="row justify-content-center employmentClassification-list_inner">
            <div className="p-0 col-xxl-10 col-sm-12 d-flex flex-wrap employClassCard-justify">
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>基礎課程</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>5G應用發展實務</li>
                  <li>15G天線設計實務</li>
                  <li>實務電磁學</li>
                  <li>實務微波工程</li>
                  <li>射頻與微波電路應用實務</li>
                  <li>天線設計實作</li>
                  <li>射頻量測技術實務</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>跨域AI課程</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>Python程式設計</li>
                  <li>Python網路爬蟲</li>
                  <li>Python資料處理與分析</li>
                  <li>人工智慧與機器學習應用分析</li>
                  <li>使用Scikit-learn實作機器學習</li>
                  <li>使用pyTorch實作深度學習</li>
                  <li>OpenCV及影像辨識</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>進階課程</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>5G通訊標準與規格介紹</li>
                  <li>5G射頻技術及主動天線設計解析</li>
                  <li>5G毫米波陣列天線設計</li>
                  <li>第五代高速無線通訊之射頻干擾設計與分析</li>
                  <li>天線效能分析實例</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>實作應用及其他</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>天線S-參數及輻射場型量測</li>
                  <li>期中專題製作</li>
                  <li>期末專題製作</li>
                  <li>專題指導</li>
                  <li>LAB練習</li>
                  <li>職場講座</li>
                  <li>專題預演</li>
                  <li>專題發表與媒合/開結訓典禮</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 課程大綱 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">招生對象</div>
          <ul className="classInfoList__styleNone">
            <li>有志於5G+AI天線設計工作者</li>
            <li>有志於5G與其他相關產業工作者</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">預備知識</div>
          <ul className="classInfoList__styleNone">
            <li>基礎電腦軟硬體知識</li>
            <li>基礎網路通訊知識</li>
            <li>基礎Python程式設計知識</li>
          </ul>
        </div>

        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>3D遊戲美術設計人才養成班適合對象</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">熟悉電腦基本操作</h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對3D遊戲美術設計有強烈學習意願及興趣
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        美術或設計相關科系畢業者尤佳
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        男女不拘，但男性需役畢或免役
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        解3D遊戲業界專案設計流程
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        學習3D遊戲美術實務與專業遊戲技術應用，以Unity整合美術與互動設計
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        具備遊戲專題製作能力
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">SOHO接案</li>
                  <li className="longTerm_tabContent_li">獨立製作</li>
                  <li className="longTerm_tabContent_li">出國升學</li>
                  <li className="longTerm_tabContent_li">職場就業</li>
                  <li className="longTerm_tabContent_li">3D遊戲美術設計</li>
                  <li className="longTerm_tabContent_li">3D角色製作</li>
                  <li className="longTerm_tabContent_li">3D場景製作</li>
                  <li className="longTerm_tabContent_li">3D角色動作</li>
                  <li className="longTerm_tabContent_li">遊戲特效設計</li>
                  <li className="longTerm_tabContent_li">3D動畫設計</li>
                </ul>
              </div>
            </Tab> */}
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default AntennaClass
