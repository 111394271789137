import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { OnJobClassTypeInfo } from '../../services/OnJob'

// 課程系統介紹表格內容
function ClassDetailInstructionForSeries(props) {
  const { classesName, classInfo, classTypeContent } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  const [classType, setClassType] = useState('')

  let { id } = useParams() // destructure id  GCPFKCI
  let { url } = useParams() // destructure id  GCPFKCI

  // 將課程內容html裝入classType
  useEffect(() => {
    const data = OnJobClassTypeInfo(id || url)

    data
      .then((classInfo) => {
        return classInfo.text()
      })
      .then((classInfo) => {
        setClassType(classInfo)
      })
  }, [id, url])

  // 卸載時還原載入狀態
  useEffect(() => {
    return () => {
      setClassType()
    }
  }, [setClassType])

  return (
    <>
      {/* 如果沒有建立edm, 並且是有開課的, 就會捲系統資料 */}
      {classType.substring(2, 6) === 'type' ? (
        <div className="seriesClass_insContent_div">
          <nav
            id="controlled-tab-example"
            activeey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tabs defaultActiveKey="content">
              <Tab eventKey="relevantInfo" title="預備知識">
                {classesName === false ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: classTypeContent['prerequisite'], // 沒edm也沒有開課抓type的介紹
                    }}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: classInfo['prerequisite'],
                    }}
                  ></div>
                )}
              </Tab>
              <Tab eventKey="object" title="課程適合對象">
                {classesName === false ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: classTypeContent['audiences'],
                    }}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: classInfo['audiences'],
                    }}
                  ></div>
                )}
              </Tab>
              <Tab eventKey="content" title="課程內容">
                {classesName === false ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: classTypeContent['abstract'],
                    }}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: classInfo['abstract'],
                    }}
                  ></div>
                )}
              </Tab>
              <Tab eventKey="target" title="課程目標">
                {classesName === false ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: classTypeContent['objective'],
                    }}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: classInfo['objective'],
                    }}
                  ></div>
                )}
              </Tab>
            </Tabs>
          </nav>
          <div className="seriesClass_message_div">
            <h6>
              ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
            </h6>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="json-content ojt-content"
            dangerouslySetInnerHTML={{
              __html: classType,
            }}
          ></div>
          <div className="seriesClass_message_div">
            <h6>
              ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
            </h6>
          </div>
        </div>
      )}
    </>
  )
}

export default ClassDetailInstructionForSeries
