import { useState, useEffect } from 'react'
import { Base64 } from 'js-base64'
import Swal from 'sweetalert2'
import LongTermDiscount from '../../services/LongTermDiscount' // 若該學員有報名過養成班, 拿養成班代號Api
import Validate from './Validate'

// 定義SweetAlert2的按鈕　↓↓↓
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    title: 'inquiry-title',
    denyButton: 'denyButton',
  },
  buttonsStyling: false,
})

// 在職班課程報名
const UseTrainingSignup = () => {
  // 預設一開始空字串, value = '', 裝養成班紀錄, api只帶字串過來
  const [longTerm, setLongTerm] = useState('')

  // 單選格, 個人發票代表2, 公司用三聯式是3
  // 欄位字串(發票、抬頭、統編、備註、餐點、優惠身分)
  const [signupInput, setSignupInput] = useState({
    invoiceTypeID: '2',
    taxCode: '',
    invoiceTitle: '',
    isMeatless: 'N', // 葷或素
    discountType: '10', // 優惠身分選擇 => 0, 就業養成班學員 / 1, 二人團報 / 2, 四人團報 / 3, 資策會同仁 / 4, 身心障礙者 / 10, 皆不符合
    // longTerm_classID: longTerm, // 養成班代號
    remark: '',
  })

  // 載入中
  const [isLoading, setIsLoading] = useState(false)

  // 裝customerId
  const [customerId, setCustomerId] = useState('')

  // 裝api養成班字串
  const [longTermText, setLongTermText] = useState('')

  // 無上課養成班提示視窗
  const [alertMessage, setAlertMessage] = useState(false)

  // 錯誤訊息物件
  const [errors, setErrors] = useState({})

  // 表單送出是否正確
  const [submitCorrect, setSubmitCorrect] = useState(false)

  // 處理表格輸入動作
  const handleChange = (e) => {
    const { name, value } = e.target

    setSignupInput({
      ...signupInput,
      [name]: value,
    })
  }

  // 處理清除公司發票欄位
  const handleClearInput = () => {
    setSignupInput({
      ...signupInput,
      taxCode: '',
      invoiceTitle: '',
    })
  }

  // 處理選擇優惠身分的選項, 更動選擇, 團報備註欄位就會被清空
  const handleClearRemarkInput = () => {
    setSignupInput({
      ...signupInput,
      remark: '',
      // longTerm_classID: '',
    })
  }

  // 處理裝 依據會員id 裝上過養成班的代號
  const handleLongTermText = async () => {
    const result = await LongTermDiscount(customerId) //customerId

    setLongTermText(result)

    // 如果有紀錄, 不是拿到空字串
    if (result !== '') {
      setLongTerm(result)

      setSignupInput({
        ...signupInput,
        remark: result,
      })
    }

    // 如果沒有養成班結訓紀錄, 要有視窗提示
    if (result === '') {
      setAlertMessage(true)
    }
  }

  // 取得會員資料
  const handGetInfoData = () => {
    // 取得localStorage的結果
    const data = JSON.parse(localStorage.getItem('SigninMessage'))

    // 如果有會員資料data
    if (data && data.code === 200) {
      const account = Object.values(data)[2]
      const payload = account.split('.')[1]
      const jsonPayload = JSON.parse(Base64.decode(payload))

      setCustomerId(jsonPayload.id)

      // 將data資料設定成新的localStorage => Account ({username: jsonPayload.username / email: jsonPayload.email})
      const setUser = (data) =>
        window.localStorage.setItem('applyMessage', JSON.stringify(data))
      return setUser({
        customerId: jsonPayload.id,
      })
    }
  }

  // 從 localStorage 中取得使用者資訊，有的話就 Parse 這筆資訊
  const getUser = () =>
    window.localStorage.getItem('applyMessage')
      ? JSON.parse(window.localStorage.getItem('applyMessage'))
      : {}

  // 處理登入送出
  const handleSignup = () => {
    setErrors(Validate(signupInput))
    setSubmitCorrect(true)
  }

  // 送出報名, 會將登入會員資料放入, 一進到詳細資訊頁面就會存入localStorage
  useEffect(() => {
    handGetInfoData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // invoiceTypeID 若更動過, 清除公司發票兩個填寫欄位
  useEffect(() => {
    if (signupInput.invoiceTypeID === '2') {
      handleClearInput()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupInput.invoiceTypeID])

  // discountType 若更動過, 清除團報備註欄位
  useEffect(() => {
    if (
      signupInput.discountType === '10' ||
      signupInput.discountType === '0' ||
      signupInput.discountType === '1' ||
      signupInput.discountType === '2' ||
      signupInput.discountType === '3' ||
      signupInput.discountType === '4' ||
      signupInput.discountType === '6'
    ) {
      handleClearRemarkInput()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupInput.discountType])

  // discountType === '0', 捲養成班API
  useEffect(() => {
    if (signupInput.discountType === '0') {
      handleLongTermText()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupInput.discountType])

  return {
    swalWithBootstrapButtons,
    signupInput,
    handleChange,
    handleClearInput,
    isLoading,
    setIsLoading,
    getUser,
    handleClearRemarkInput,
    longTermText,
    alertMessage,
    setAlertMessage,
    longTerm,
    errors,
    setErrors,
    submitCorrect,
    setSubmitCorrect,
    handleSignup,
  }
}

export default UseTrainingSignup
