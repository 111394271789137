// https://w3.ispan.com.tw/
import { apiUrl } from '../config/path'

// 首頁 和 近期說明會
const url = apiUrl + '/api/Briefing'
const urlGetInfoSourceOption = apiUrl + '/api/Attender'

// 首頁, 取得最新3筆說明會資料
// api/Briefing/top3
export function Top3RecentingBriefing() {
  return fetch(url + '/top3').then((data) => data.json())
}

// 未過期所有說明會資料
// api/Briefing/all
export function Briefing() {
  return fetch(url + '/all').then((data) => data.json())
  // return fetch(url).then((data) => data.json())
}

// 取得說明會消息來源
// api/Attender/GetInfoSourceOption
export function GetInfoSourceOption() {
  return fetch(urlGetInfoSourceOption + '/GetInfoSourceOption').then((data) =>
    data.json()
  )
  // return fetch(url).then((data) => data.json())
}
