// 使用套件
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React, { Suspense, lazy, useState, useEffect } from 'react'
import AOS from 'aos' // AOS滾動效果
import 'aos/dist/aos.css' // AOS滾動效果

// 就業養成班 > 課程介紹內頁(靜態詳細介紹, 依課程經理調整內容)
import FullStackDeveloper from './pages/EmploymentClassification/FullStackDeveloper' // 全端工程師就業養成班
import MicrosoftDeveloper from './pages/EmploymentClassification/MicrosoftDeveloper' // 智慧應用微軟C#工程師就業養成班
import AppDeveloper from './pages/EmploymentClassification/AppDeveloper' // iOS App與Mac應用軟體開發養成班
import AIotApplication from './pages/EmploymentClassification/AIotApplication' // AIOT物聯網智慧創新應用就業養成班
import JavaSoftware from './pages/EmploymentClassification/JavaSoftware' // 跨域Java軟體工程師就業養成班
import UIuxInteractive from './pages/EmploymentClassification/UIuxInteractive' // UI/UX創新策略網頁設計就業養成班
import ArtificialIntelligence from './pages/EmploymentClassification/ArtificialIntelligence' // AI 人工智慧創新應用就業養成班
import VR3DGame from './pages/EmploymentClassification/VR3DGame' // 3D 遊戲美術設計就業養成班
import FrontEnd from './pages/EmploymentClassification/FrontEnd' // 前端工程師就業養成班
import CloudTechnology from './pages/EmploymentClassification/CloudTechnology' // 雲端網路智慧科技就業養成班
import EmbeddedSystem from './pages/EmploymentClassification/EmbeddedSystem' // 嵌入式系統與韌體技術就業養成班
import BigDataAnalysis from './pages/EmploymentClassification/BigDataAnalysis' // Big Data 巨量資料分析就業養成班
import UnityDeveloper from './pages/EmploymentClassification/UnityDeveloper' // Unity跨平台VR開發工程師就業養成班
import IT from './pages/EmploymentClassification/IT' // 全方位IT工程師就業養成班
import Antenna from './pages/EmploymentClassification/Antenna' // 前後端整合應用工程師就業養成班
import Phpd from './pages/EmploymentClassification/Phpd' // 創新應用全端網站開發工程師養成班
import AIoTSeries from './pages/EmploymentClassification/AIoTSeries' // AIoT智慧機器人就業養成班
// import Bios from './pages/EmploymentClassification/Bios' // BIOS 韌體設計工程實務養成班
import CloudSystem from './pages/EmploymentClassification/CloudSystem' // 網路與系統工程師就業養成班
import JMSTQAT from './pages/EmploymentClassification/JMSTQAT' // 軟體測試工程師養成訓練班
import JMSMQAT from './pages/EmploymentClassification/JMSMQAT' // 智慧製造軟體測試工程師養成班(工研院新尖兵計劃)
import JPPHPST from './pages/EmploymentClassification/JPPHPST' // PHP後端工程師養成訓練班(職能)
import JWJETPT from './pages/EmploymentClassification/JWJETPT' // 全方位JavaScript工程師就業養成班
// import AnalyseInformation from './pages/EmploymentClassification/AnalyseInformation' // AI跨界行銷資料科學專業班
import LongTerm from './pages/LongTerm/LongTerm' // 就業養成班班級頁面
import OJT from './pages/OJT/OJT' // 轉職就業班班級頁面

// 報名或預約說明會成功
import ApplySuccess from './pages/ApplySuccess'
import ApplyFromMeetingSuccess from './pages/ApplyFromMeetingSuccess'
import ApplyReservations from './pages/ApplyReservations'

// 在職進修班 > 分類 > 課程頁面
import TrainingClasses from './pages/TrainingClass/TrainingClasses' // 在職班課程頁
// 在職進修班, 靜態需求頁面, PMP/使用的相關內容資訊
import TrainingClassesForIndividual from './pages/TrainingClass/TrainingClassesForIndividual' // PMP相關頁面
// 在職進修班, 靜態需求頁面, 主題系列
import TrainingClassesForTheme from './pages/TrainingClass/TrainingClassesForTheme' // 課程主題館
// 在職進修班 > 系列課程 > 課程頁面
import TrainingClassesForSeries from './pages/TrainingClass/TrainingClassesForSeries' // 在職班系列課程頁面
// /GCP/GCPFKCI

// 組合用元件
import Navbar from '../src/components/Navbar/Navbar' // Navbar
import MainContent from '../src/components/MainContent' // 內容包在裡面
import ScrollToTop from '../src/components/ScrollToTop' // 頁面切換時要用捲軸讓頁面回到最上方
// import MultiLevelBreadCrumb from '../src/components/MultiLevelBreadCrumb' // 麵包屑
import ToTopButton from '../src/components/ToTopButton' // 回到最上方
import Footer from '../src/components/Footer' // Footer
import ActivityMessage from '../src/components/ActivityMessage' // 線上課程視窗

// 頁面元件
// 首頁
const Home = lazy(() => import('./pages/Home'))
// 找不到頁面
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'))
// 關於我們
const AboutUs = lazy(() => import('./pages/AboutUs'))
// 個資條款
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
// 就業養成班主頁
const EmploymentClassIndex = lazy(() => import('./pages/EmploymentClassIndex'))
// 在職進修班主頁
const TrainingClassIndex = lazy(() => import('./pages/TrainingClassIndex'))
// 轉職進修班主頁
const Careers = lazy(() => import('./pages/careers'))
// 職業訓練機構專班主頁
const Vocationaltraining = lazy(() => import('./pages/Vocationaltraining'))
// 在職進修班全覽表
const OjtAll = lazy(() => import('./pages/OjtAll'))
// 企業包班
const CorporateTraining = lazy(() => import('./pages/CorporateTraining'))
// 企業會員專案
const Enterprise = lazy(() => import('./pages/Enterprise'))
// 近期說明會
const InformationMeeting = lazy(() => import('./pages/InformationMeeting'))
// 廠商報名徵才
const RecruitmentRegistration = lazy(() =>
  import('./pages/RecruitmentRegistration')
)
// 學員成果
// const StudentOutcomes = lazy(() => import('./pages/StudentOutcomes'))
// 服務據點
const ServicePlace = lazy(() => import('./pages/ServicePlace'))
// 學員須知
const StudentNotice = lazy(() => import('./pages/StudentNotice'))

// 線上課程
// const OnlineClass = lazy(() => import('./pages/OnlineClass'))
// 數位轉型課程頁面
const DigitalTransformation = lazy(() =>
  import('./pages/DigitalTransformation')
)
// 誠徵外聘
const Recruitment = lazy(() => import('./pages/Recruitment'))
// 聯絡我們
// const ContactUs = lazy(() => import('./pages/ContactUs'))
// 註冊
const Register = lazy(() => import('./pages/Register'))
// 會員登入
const Signin = lazy(() => import('./pages/Signin'))
// 忘記密碼
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
// 重發驗證信
const Revalidation = lazy(() => import('./pages/Revalidation'))
// 會員中心
const Member = lazy(() => import('./pages/Member/Member'))
// 會員中心 - 個人資訊
const PersonalInfo = lazy(() => import('./pages/Member/PersonalInfo'))
// 會員中心 - 修改密碼
const ResetPassword = lazy(() => import('./pages/Member/ResetPassword'))
// 會員中心 - 說明會報名記錄
const MeetingRecord = lazy(() => import('./pages/Member/Record'))
// 會員中心 - 課程報名記錄
const ClassRecord = lazy(() => import('./pages/Member/Certification'))
// 在職班 報名課程
const TrainingClassSignup = lazy(() => import('./pages/TrainingClassSignup'))
// 搜尋結果
const SearchResult = lazy(() => import('./pages/SearchResult'))
// 企業場地租借
const Lease = lazy(() => import('./pages/Lease'))
// 就業媒合入口
const Job = lazy(() => import('./pages/Job'))
// 企業徵才入口
const JobRecruitment = lazy(() => import('./pages/JobRecruitment'))
// 企業包班個資同意書
const CorporateClassConsent = lazy(() =>
  import('./pages/Consent/CorporateClassConsent')
)
// 在職進修班個資同意書
const Onjob = lazy(() => import('./pages/Consent/Onjob'))
// 就業養成班個資同意書
const Privacy = lazy(() => import('./pages/Consent/Privacy'))
// 同意轉移會員資料成功頁面
const TransformSuccess = lazy(() =>
  import('./pages/Transform/TransformSuccess')
)
// 不同意轉移會員資料頁面
const TransformDefeat = lazy(() => import('./pages/Transform/TransformDefeat'))
// 台北專區
// const Taipei = lazy(() => import('./pages/Region/Taipei'))
// 台中專區
const Taichung = lazy(() => import('./pages/Region/Taichung'))
// 桃園專區
const Zhongli = lazy(() => import('./pages/Region/Zhongli'))
// 台南高雄專區
const SouthTaiwan = lazy(() => import('./pages/Region/SouthTaiwan'))
// 失業職訓補助頁面
const BusinessSubsidy = lazy(() => import('./pages/BusinessSubsidy'))
// 小人提補助頁面
const OnJobSubsidy = lazy(() => import('./pages/OnJobSubsidy'))
// 考試中心頁面
const TestCenter = lazy(() => import('./pages/TestCenter'))

// 在職進修班 > 課程分類
// 人工智慧
const AI = lazy(() => import('./pages/TrainingClassification/AI'))
// 資料庫 / 大數據
const Database = lazy(() => import('./pages/TrainingClassification/Database'))
// 軟體工程
const Software = lazy(() => import('./pages/TrainingClassification/Software'))
// 網路通訊
const Network = lazy(() => import('./pages/TrainingClassification/Network'))
// 雲端技術
const Cloud = lazy(() => import('./pages/TrainingClassification/Cloud'))
// 智慧綠能
const GreenEnergy = lazy(() =>
  import('./pages/TrainingClassification/GreenEnergy')
)
// 資安管理
const InformationSecurity = lazy(() =>
  import('./pages/TrainingClassification/InformationSecurity')
)
// 專案管理
const Manage = lazy(() => import('./pages/TrainingClassification/Manage'))
// 創新設計
const Design = lazy(() => import('./pages/TrainingClassification/Design'))
// 數位行銷
const DigitalMarketing = lazy(() =>
  import('./pages/TrainingClassification/DigitalMarketing')
)
// 數據分析
const DataAnalyze = lazy(() =>
  import('./pages/TrainingClassification/DataAnalyze')
)
// 人資管理
const HR = lazy(() => import('./pages/TrainingClassification/HR'))
// 辦公室應用
const Office = lazy(() => import('./pages/TrainingClassification/Office'))
// 行銷技能
const Marketing = lazy(() => import('./pages/TrainingClassification/Marketing'))
// 程式設計
const Programming = lazy(() =>
  import('./pages/TrainingClassification/Programming')
)
// 元宇宙
const Metaverse = lazy(() => import('./pages/TrainingClassification/Metaverse'))
// ESG永續學堂
const ESG = lazy(() => import('./pages/TrainingClassification/ESG'))
// 嵌入式
const Embedded = lazy(() => import('./pages/TrainingClassification/Embedded'))

// GCP課程講解
const GCP = lazy(() => import('./pages/IndividualNeeds/GCP'))
// Google Cloud 核心基礎架構課程
const GoogleCloud = lazy(() => import('./pages/TechSpan/GoogleCloud'))
// Google Cloud Platform Big Data & Machine Learning Fundamentals
const GoogleCloudSecond = lazy(() =>
  import('./pages/TechSpan/GoogleCloudSecond')
)
// 研討會第一場
const SeminarFirst = lazy(() => import('./pages/Electronic/SeminarFirst'))
// 研討會第二三場
const SeminarTwoThree = lazy(() => import('./pages/Electronic/SeminarTwoThree'))
// 研討會第四場
const SeminarFourth = lazy(() => import('./pages/Electronic/SeminarFourth'))
// 研討會第五場體驗場
const SeminarFifth = lazy(() => import('./pages/Electronic/SeminarFifth'))
// 研討會第五場講座場
const SeminarSixed = lazy(() => import('./pages/Electronic/SeminarSixed'))
// 活動主頁
const LectureActivities = lazy(() => import('./pages/LectureActivities'))

// 學員心得
const ExperienceOne = lazy(() => import('./pages/Experience/ExperienceOne'))
const ExperienceTwo = lazy(() => import('./pages/Experience/ExperienceTwo'))
const ExperienceThree = lazy(() => import('./pages/Experience/ExperienceThree'))
const ExperienceFour = lazy(() => import('./pages/Experience/ExperienceFour'))
const ExperienceFive = lazy(() => import('./pages/Experience/ExperienceFive'))
const ExperienceSix = lazy(() => import('./pages/Experience/ExperienceSix'))
const ExperienceSeven = lazy(() => import('./pages/Experience/ExperienceSeven'))
const ExperienceEight = lazy(() => import('./pages/Experience/ExperienceEight'))
const ExperienceNine = lazy(() => import('./pages/Experience/ExperienceNine'))
const ExperienceTen = lazy(() => import('./pages/Experience/ExperienceTen'))
const ExperienceEleven = lazy(() =>
  import('./pages/Experience/ExperienceEleven')
)
const ExperienceTwelve = lazy(() =>
  import('./pages/Experience/ExperienceTwelve')
)
const ExperienceThirteen = lazy(() =>
  import('./pages/Experience/ExperienceThirteen')
)
const ExperienceFourteen = lazy(() =>
  import('./pages/Experience/ExperienceFourteen')
)
const ExperienceFifteen = lazy(() =>
  import('./pages/Experience/ExperienceFifteen')
)
const ExperienceSixteen = lazy(() =>
  import('./pages/Experience/ExperienceSixteen')
)
const ExperienceSeventeen = lazy(() =>
  import('./pages/Experience/ExperienceSeventeen')
)
const ExperienceEighteen = lazy(() =>
  import('./pages/Experience/ExperienceEighteen')
)
const ExperienceNineteen = lazy(() =>
  import('./pages/Experience/ExperienceNineteen')
)
const ExperienceTwenty = lazy(() =>
  import('./pages/Experience/ExperienceTwenty')
)
const ExperienceTwentyOne = lazy(() =>
  import('./pages/Experience/ExperienceTwentyOne')
)
const ExperienceTwentyTwo = lazy(() =>
  import('./pages/Experience/ExperienceTwentyTwo')
)
const ExperienceTwentyThree = lazy(() =>
  import('./pages/Experience/ExperienceTwentyThree')
)
const ExperienceTwentyFour = lazy(() =>
  import('./pages/Experience/ExperienceTwentyFour')
)
const ExperienceTwentyFive = lazy(() =>
  import('./pages/Experience/ExperienceTwentyFive')
)
const ExperienceTwentySix = lazy(() =>
  import('./pages/Experience/ExperienceTwentySix')
)

// 最新活動 - 初次尋職青年穩定就業計畫
const JobAllowance = lazy(() => import('./pages/EventPage/JobAllowance'))
// 最新活動 - 勞動部疫後改善缺工擴大就業方案
const EmploymentExpansion = lazy(() =>
  import('./pages/EventPage/EmploymentExpansion')
)
// 最新活動 - 青年就業旗艦計畫
const JobOpportunity = lazy(() => import('./pages/EventPage/JobOpportunity'))
// 最新活動 - 投資青年就業方案 第2期
const JobInvestment = lazy(() => import('./pages/EventPage/JobInvestment'))

// 最新消息
const News = lazy(() => import('./pages/News'))
// 最新消息-公司成立
const NewsOne = lazy(() => import('./pages/News/NewsOne'))
// 最新消息-微軟班特價
const NewsTwo = lazy(() => import('./pages/News/NewsTwo'))
// 最新消息
const NewsThree = lazy(() => import('./pages/News/NewsThree'))
// 最新消息
const NewsFour = lazy(() => import('./pages/News/NewsFour'))
// 最新消息
const NewsFive = lazy(() => import('./pages/News/NewsFive'))
// 最新消息
const NewsSix = lazy(() => import('./pages/News/NewsSix'))
// 最新消息
const NewsSeven = lazy(() => import('./pages/News/NewsSeven'))
// 最新消息
const NewsEight = lazy(() => import('./pages/News/NewsEight'))
// 最新消息
const NewsNine = lazy(() => import('./pages/News/NewsNine'))
// 最新消息
const NewsTen = lazy(() => import('./pages/News/NewsTen'))
// 最新消息
const NewsEleven = lazy(() => import('./pages/News/NewsEleven'))
// 最新消息
const NewsTwelve = lazy(() => import('./pages/News/NewsTwelve'))
// 最新消息
const NewsThirteen = lazy(() => import('./pages/News/NewsThirteen'))
// 最新消息
const NewsFourteen = lazy(() => import('./pages/News/NewsFourteen'))
// 最新消息
const NewsFifteen = lazy(() => import('./pages/News/NewsFifteen'))
// 最新消息
const NewsSixteen = lazy(() => import('./pages/News/NewsSixteen'))
// 最新消息
const NewsSeventeen = lazy(() => import('./pages/News/NewsSeventeen'))
// 最新消息
const NewsEighteen = lazy(() => import('./pages/News/NewsEighteen'))
// 最新消息
const NewsNineteen = lazy(() => import('./pages/News/NewsNineteen'))
// 最新消息
const NewsTwenty = lazy(() => import('./pages/News/NewsTwenty'))
// 最新消息
const NewsTwentyOne = lazy(() => import('./pages/News/NewsTwentyOne'))
// 最新消息
const NewsTwentyTwo = lazy(() => import('./pages/News/NewsTwentyTwo'))
// 最新消息
const NewsTwentyThree = lazy(() => import('./pages/News/NewsTwentyThree'))
// 最新消息
const NewsTwentyFour = lazy(() => import('./pages/News/NewsTwentyFour'))
// 最新消息
const NewsTwentyFive = lazy(() => import('./pages/News/NewsTwentyFive'))
// 最新消息
const NewsTwentySix = lazy(() => import('./pages/News/NewsTwentySix'))
// 最新消息
const NewsTwentySeven = lazy(() => import('./pages/News/NewsTwentySeven'))
// 最新消息
const NewsTwentyEight = lazy(() => import('./pages/News/NewsTwentyEight'))
// 最新消息
const NewsTwentyNine = lazy(() => import('./pages/News/NewsTwentyNine'))
// 最新消息
const NewsThirty = lazy(() => import('./pages/News/NewsThirty'))
// 最新消息
const NewsThirtyOne = lazy(() => import('./pages/News/NewsThirtyOne'))
// 最新消息
const NewsThirtyTwo = lazy(() => import('./pages/News/NewsThirtyTwo'))
// 最新消息
const NewsThirtyThree = lazy(() => import('./pages/News/NewsThirtyThree'))
// 最新消息
const NewsThirtyFour = lazy(() => import('./pages/News/NewsThirtyFour'))
// 最新消息
const NewsThirtyFive = lazy(() => import('./pages/News/NewsThirtyFive'))
// 最新消息
const NewsThirtySix = lazy(() => import('./pages/News/NewsThirtySix'))
// 最新消息
const NewsThirtySeven = lazy(() => import('./pages/News/NewsThirtySeven'))
// 最新消息
const NewsThirtyEight = lazy(() => import('./pages/News/NewsThirtyEight'))
// 最新消息
const NewsThirtyNine = lazy(() => import('./pages/News/NewsThirtyNine'))
// 最新消息
const NewsForty = lazy(() => import('./pages/News/NewsForty'))
// 最新消息
const NewsFortyOne = lazy(() => import('./pages/News/NewsFortyOne'))
// 最新消息
const NewsFortyTwo = lazy(() => import('./pages/News/NewsFortyTwo'))
// 最新消息
const NewsFortyThree = lazy(() => import('./pages/News/NewsFortyThree'))
// 最新消息
const NewsFortyFour = lazy(() => import('./pages/News/NewsFortyFour'))
// 最新消息
const NewsFortyFive = lazy(() => import('./pages/News/NewsFortyFive'))
const NewsFortySix = lazy(() => import('./pages/News/NewsFortySix'))
const NewsFortySeven = lazy(() => import('./pages/News/NewsFortySeven'))
const NewsFortyEight = lazy(() => import('./pages/News/NewsFortyEight'))

//王孝弘測試用 - 金流相關
const CheckOut = lazy(() => import('./pages/CashFlow/CheckOut'))

function App(props) {
  // AOS滾動效果
  AOS.init()

  const { lessonDisplay, setLessonDisplay, isLoading, submitResult } = props
  //  麵包屑判斷 (true在左上 false由各頁面自訂, 需要改動者再引入)
  const [breadCrumbBool, setBreadCrumbBool] = useState(true)

  const [dynamicBreadcrumbName, setDynamicBreadcrumbName] = useState('')

  // 登入狀態(未登入false, 登入true)
  const [signinBool, setSigninBool] = useState(false)

  // 顯示最上方學生須知區塊
  const [display, setDisplay] = useState(true)

  // 養成班切換分頁
  // eslint-disable-next-line no-unused-vars
  const [optionTab, setOptionTab] = useState(1)

  // 養成班切換課程類別
  // eslint-disable-next-line no-unused-vars
  const [optionTypeTab, setOptionTypeTab] = useState(1)

  // 在職班切換分頁
  // eslint-disable-next-line no-unused-vars
  const [optionSetTab, setOptionSetTab] = useState(1)

  // eslint-disable-next-line no-unused-vars
  const [EmploymentClasses, setEmploymentClasses] = useState([])

  // AOS滾動效果
  useEffect(() => {
    AOS.init({
      duration: 1000,
    })
  }, [])

  return (
    <Router>
      <>
        <Navbar
          signinBool={signinBool}
          setSigninBool={setSigninBool}
          display={display}
          setDisplay={setDisplay}
          lessonDisplay={lessonDisplay}
          setLessonDisplay={setLessonDisplay}
          setOptionTab={setOptionTab}
          setOptionTypeTab={setOptionTypeTab}
        />
        <MainContent display={display} setDisplay={setDisplay}>
          <ScrollToTop>
            <Suspense
              fallback={
                <div
                  style={{
                    minHeight: '100vh',
                    display: 'grid',
                    placeContent: 'center',
                  }}
                >
                  <div className="spinner-wrapper">
                    <span className="loading" data-name="Loading">
                      Loading
                    </span>
                  </div>
                </div>
              }
            >
              <Switch>
                {/* 首頁 */}
                <Route exact path="/">
                  <Home setOptionTab={setOptionTab} />
                </Route>
                {/* 關於我們 */}
                <Route exact path="/aboutus">
                  <AboutUs
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 企業場地租借 */}
                <Route exact path="/lease">
                  <Lease
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 就業媒合入口 */}
                <Route exact path="/job">
                  <Job
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 企業徵才入口 */}
                <Route exact path="/jobRecruitment">
                  <JobRecruitment
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 個資條款 */}
                <Route exact path="/privacypolicy">
                  <PrivacyPolicy />
                </Route>
                {/* 同意轉移會員資料成功頁面 */}
                <Route exact path="/agreeResult">
                  <TransformSuccess />
                </Route>
                {/* 不同意轉移會員資料頁面 */}
                <Route exact path="/defeatResult">
                  <TransformDefeat />
                </Route>
                {/* 就業養成班主頁 */}
                <Route exact path="/longterm">
                  <EmploymentClassIndex
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionTab={optionTab}
                    setOptionTab={setOptionTab}
                    optionTypeTab={optionTypeTab}
                    setOptionTypeTab={setOptionTypeTab}
                  />
                </Route>
                {/* 在職進修班主頁 */}
                <Route exact path="/OJT">
                  <TrainingClassIndex
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 轉職進修班主頁 */}
                <Route exact path="/careers">
                  <Careers
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 職業訓練機構專班主頁 */}
                <Route exact path="/vocationaltraining">
                  <Vocationaltraining
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班全覽表 */}
                <Route exact path="/OJT/All">
                  <OjtAll
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 企業包班 */}
                <Route exact path="/corporate-training">
                  <CorporateTraining
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 企業會員專案 */}
                <Route exact path="/enterprise">
                  <Enterprise
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 近期說明會 */}
                <Route exact path="/informationmeeting">
                  <InformationMeeting
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    signinBool={signinBool}
                  />
                </Route>
                {/* 報名說明會 */}
                {/* <Route path="/applyMeeting/:id">
                  <ApplyMeeting />
                </Route> */}
                {/*依課程*/}
                <Route path="/recruitment">
                  <RecruitmentRegistration />
                </Route>
                {/* 學員成果 */}
                {/* <Route exact path="/studentoutcomes">
                  <StudentOutcomes />
                </Route> */}
                {/* 服務據點 */}
                <Route exact path="/serviceplace">
                  <ServicePlace
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 最新消息 */}
                <Route exact path="/news">
                  <News />
                </Route>
                {/* 線上課程專區 */}
                {/* <Route exact path="/online">
                  <OnlineClass signinBool={signinBool} />
                </Route> */}
                {/* 數位轉型課程頁面 */}
                <Route exact path="/DT">
                  <DigitalTransformation
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 誠徵外聘講師頁面 */}
                <Route exact path="/weneedyou">
                  <Recruitment />
                </Route>
                {/* 學員須知 */}
                <Route exact path="/studentnotice">
                  <StudentNotice />
                </Route>
                {/* 聯絡我們 */}
                {/* <Route exact path="/contact">
                  <ContactUs />
                </Route> */}
                {/* 註冊 */}
                <Route exact path="/register">
                  <Register />
                </Route>
                {/* 會員登入 */}
                <Route exact path="/signin">
                  <Signin
                    signinBool={signinBool}
                    setSigninBool={setSigninBool}
                  />
                </Route>
                {/* 忘記密碼 */}
                <Route exact path="/forgotpassword">
                  <ForgotPassword />
                </Route>
                {/* 重發驗證信 */}
                <Route exact path="/resend">
                  <Revalidation />
                </Route>
                {/* 會員中心 */}
                <Route path="/member">
                  <Member
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 會員中心 - 個人資訊 */}
                <Route path="/member/personalinfo">
                  <PersonalInfo />
                </Route>
                {/* 會員中心 - 修改密碼 */}
                <Route path="/member/resetpassword">
                  <ResetPassword />
                </Route>
                {/* 會員中心 - 報名記錄 */}
                <Route path="/member/record">
                  <MeetingRecord />
                </Route>
                {/* 會員中心 - 結訓證書下載 */}
                <Route path="/member/certification">
                  <ClassRecord />
                </Route>
                {/* 就業養成班 > 課程介紹內頁(靜態詳細介紹, 依課程經理調整內容) */}
                {/* 全端工程師就業養成班 */}
                <Route exact path="/longterm/JMFUENT">
                  <FullStackDeveloper
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 智慧應用微軟C#工程師就業養成班 */}
                <Route exact path="/longterm/JMMSITT">
                  <MicrosoftDeveloper
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* iOS App與Mac應用軟體開發養成班 */}
                <Route exact path="/longterm/JEMMPDT">
                  <AppDeveloper
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 跨域Java軟體工程師就業養成班 */}
                <Route exact path="/longterm/JJEEITT">
                  <JavaSoftware
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* UI/UX創新策略網頁設計就業養成班 */}
                <Route exact path="/longterm/JWUIUXT">
                  <UIuxInteractive
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* AIoT智慧機器人就業養成班 */}
                <Route exact path="/longterm/JJAIENT">
                  <ArtificialIntelligence
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* AIOT物聯網智慧創新應用就業養成班 */}
                <Route exact path="/longterm/JEAIOTT">
                  <AIotApplication
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* AIoT 系列就業養成班 */}
                <Route exact path="/longterm/JEAIMLT">
                  <AIoTSeries
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/*BIOS 韌體設計工程養成班 */}
                {/* <Route exact path="/longterm/JEBIOST">
                  <Bios
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route> */}
                {/* VR AI繪圖與3D美術人才養成班就業養成班 */}
                <Route exact path="/longterm/JAMTDCT">
                  <VR3DGame
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 前端工程師就業養成班 */}
                <Route exact path="/longterm/JPMFEET">
                  <FrontEnd
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 雲端網路智慧科技就業養成班 */}
                <Route exact path="/longterm/JNSESET">
                  <CloudTechnology
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 嵌入式系統與韌體技術就業養成班 */}
                <Route exact path="/longterm/JEMEMET">
                  <EmbeddedSystem
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* Big Data 巨量資料分析就業養成班 */}
                <Route exact path="/longterm/JJBDSET">
                  <BigDataAnalysis
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* Unity跨平台VR開發工程師就業養成班 */}
                <Route exact path="/longterm/JPUMVRT">
                  <UnityDeveloper
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 全方位IT工程師就業養成班 */}
                <Route exact path="/longterm/JWITENT">
                  <IT
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 5G+AI跨域智慧天線設計工程師就業養成班 */}
                <Route exact path="/longterm/JN5GAIT">
                  <Antenna
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 創新應用全端網站開發工程師養成班(台中) */}
                <Route exact path="/longterm/JPPHPDT">
                  <Phpd
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 網路與系統工程師就業養成班 */}
                <Route exact path="/longterm/JNNWSET">
                  <CloudSystem
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 軟體測試工程師養成訓練班 */}
                <Route exact path="/longterm/JMSTQAT">
                  <JMSTQAT
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 智慧製造軟體測試工程師養成班(工研院新尖兵計劃) */}
                <Route exact path="/longterm/JMSMQAT">
                  <JMSMQAT
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* PHP後端工程師養成訓練班(職能) */}
                <Route exact path="/longterm/JPPHPST">
                  <JPPHPST
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 全方位JavaScript工程師就業養成班 */}
                <Route exact path="/longterm/JWJETPT">
                  <JWJETPT
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* AI跨界行銷資料科學專業班 */}
                {/* <Route exact path="/longterm/JPDSMKT">
                  <AnalyseInformation
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route> */}
                {/* 報名說明會成功 */}
                <Route path="/longterm/signupsuccess">
                  <ApplySuccess signinBool={signinBool} />
                </Route>
                {/* 報名說明會成功, 從近期說明會來的 */}
                <Route path="/longterm/signupsuccessed">
                  <ApplyFromMeetingSuccess signinBool={signinBool} />
                </Route>
                {/* 預約說明會成功 */}
                <Route path="/longterm/reservationsuccess">
                  <ApplyReservations signinBool={signinBool} />
                </Route>
                {/* 台北專區 */}
                {/* <Route exact path="/taipei">
                  <Taipei
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    signinBool={signinBool}
                  />
                </Route> */}
                {/* 台中專區 */}
                <Route exact path="/taichung">
                  <Taichung
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    signinBool={signinBool}
                  />
                </Route>
                {/* 桃園專區 */}
                <Route exact path="/zhongli">
                  <Zhongli
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    signinBool={signinBool}
                  />
                </Route>
                {/* 台南高雄專區 */}
                <Route exact path="/kaohsiung">
                  <SouthTaiwan
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    signinBool={signinBool}
                  />
                </Route>
                {/* 考試中心 */}
                <Route exact path="/testcenter">
                  <TestCenter
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionTypeTab={optionTypeTab}
                    setOptionTypeTab={setOptionTypeTab}
                  />
                </Route>
                {/* 在職班 報名課程 */}
                <Route path="/trainingClassSignup">
                  <TrainingClassSignup
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    submitResult={submitResult}
                  />
                </Route>
                {/* 在職進修班 > 課程分類 */}
                {/* 人工智慧 */}
                <Route exact path="/OJT/AI">
                  <AI
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 資料庫 / 大數據 */}
                <Route exact path="/OJT/Database">
                  <Database
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 軟體工程 */}
                <Route exact path="/OJT/Software">
                  <Software
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 網路通訊 */}
                <Route exact path="/OJT/Network">
                  <Network
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 雲端技術 */}
                <Route exact path="/OJT/Cloud">
                  <Cloud
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 智慧綠能 */}
                <Route exact path="/OJT/GreenEnergy">
                  <GreenEnergy
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 資安管理 */}
                <Route exact path="/OJT/InformationSecurity">
                  <InformationSecurity
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 專案管理 */}
                <Route exact path="/OJT/manage">
                  <Manage
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 創新設計 */}
                <Route exact path="/OJT/Design">
                  <Design
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 數位行銷 */}
                <Route exact path="/OJT/DigitalMarketing">
                  <DigitalMarketing
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 數據分析 */}
                <Route exact path="/OJT/DataAnalyze">
                  <DataAnalyze
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 人資管理 */}
                <Route exact path="/OJT/HR">
                  <HR
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 辦公室應用 */}
                <Route exact path="/OJT/Office">
                  <Office
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 行銷技能 */}
                <Route exact path="/OJT/Marketing">
                  <Marketing
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 程式設計 */}
                <Route exact path="/OJT/Programming">
                  <Programming
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 元宇宙 */}
                <Route exact path="/OJT/Metaverse">
                  <Metaverse
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* ESG永續學堂 */}
                <Route exact path="/OJT/ESG">
                  <ESG
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 嵌入式 */}
                <Route exact path="/OJT/Embedded">
                  <Embedded
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    optionSetTab={optionSetTab}
                    setOptionSetTab={setOptionSetTab}
                  />
                </Route>
                {/* 軟體開發/程式設計/智慧綠能課程主題館 */}
                <Route path="/ITA">
                  <TrainingClassesForTheme />
                </Route>
                {/* 人工智慧和大數據課程主題館	 */}
                <Route path="/AIT">
                  <TrainingClassesForTheme />
                </Route>
                {/* 資訊安全課程主題館 */}
                <Route path="/IST">
                  <TrainingClassesForTheme />
                </Route>
                {/* ESG永續學堂主題館	 */}
                <Route path="/ESG">
                  <TrainingClassesForTheme />
                </Route>
                {/* 搜尋結果 */}
                <Route exact path="/search">
                  <SearchResult
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    isLoading={isLoading}
                    lessonDisplay={lessonDisplay}
                    setLessonDisplay={setLessonDisplay}
                  />
                </Route>
                {/* 研討會 */}
                <Route exact path="/Seminar/20221101">
                  <SeminarFirst />
                </Route>
                <Route exact path="/Seminar">
                  <SeminarTwoThree />
                </Route>
                <Route exact path="/Seminar/20230908">
                  <SeminarFourth />
                </Route>
                <Route exact path="/Seminar/20230918">
                  <SeminarFifth />
                </Route>
                <Route exact path="/Seminar/20230920">
                  <SeminarSixed />
                </Route>
                <Route exact path="/Activities">
                  <LectureActivities
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職班系列課程 > GCP講解 */}
                <Route path="/GCP/regispanqwiklab">
                  <GCP />
                </Route>
                {/* TechSpan雲端工作坊-Google Cloud 核心基礎架構課程 */}
                <Route exact path="/GoogleCloud">
                  <GoogleCloud
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    signinBool={signinBool}
                  />
                </Route>
                {/* TechSpan雲端工作坊-Google Cloud Platform Big Data & Machine Learning Fundamentals */}
                <Route exact path="/GoogleCloud230525">
                  <GoogleCloudSecond
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    signinBool={signinBool}
                  />
                </Route>
                {/* 勞動部勞動力發展署北基宜花金馬分署補助訊息 */}
                <Route exact path="/business-subsidy">
                  <BusinessSubsidy
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 勞動部勞動力發展署 小人提 */}
                <Route exact path="/onjobsubsidy">
                  <OnJobSubsidy
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 全端班118 */}
                <Route exact path="/2100360">
                  <ExperienceOne
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 全端班118 */}
                <Route exact path="/2100364">
                  <ExperienceTwo
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1501048">
                  <ExperienceThree
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1501043">
                  <ExperienceFour
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1501047">
                  <ExperienceFive
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1501041">
                  <ExperienceSix
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1501028">
                  <ExperienceSeven
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1501040">
                  <ExperienceEight
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1600366">
                  <ExperienceNine
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1600381">
                  <ExperienceTen
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1600369">
                  <ExperienceEleven
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1600382">
                  <ExperienceTwelve
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, APP班 */}
                <Route exact path="/1600000">
                  <ExperienceThirteen
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 全端班123 */}
                <Route exact path="/0260012">
                  <ExperienceFourteen
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 全端班123 */}
                <Route exact path="/0260007">
                  <ExperienceFifteen
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, Java班171 */}
                <Route exact path="/6190007">
                  <ExperienceSixteen
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 前端班 */}
                <Route exact path="/2250008">
                  <ExperienceTwentySix
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1501026">
                  <ExperienceSeventeen
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1501035">
                  <ExperienceEighteen
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1501027">
                  <ExperienceNineteen
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1501044">
                  <ExperienceTwenty
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1501033">
                  <ExperienceTwentyOne
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1601337">
                  <ExperienceTwentyTwo
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1700718">
                  <ExperienceTwentyThree
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1601331">
                  <ExperienceTwentyFour
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 養成班心得分享, 數據班 */}
                <Route exact path="/1601315">
                  <ExperienceTwentyFive
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 最新活動, 初次尋職青年穩定就業計畫 */}
                <Route exact path="/eventpage/job_allowance">
                  <JobAllowance
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 最新活動, 勞動部疫後改善缺工擴大就業方案 */}
                <Route exact path="/eventpage/employment_expansion">
                  <EmploymentExpansion
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 最新活動, 青年就業旗艦計畫 */}
                <Route exact path="/eventpage/job_opportunity">
                  <JobOpportunity
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 最新活動, 投資青年就業方案 第2期 */}
                <Route exact path="/eventpage/job_investment">
                  <JobInvestment
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 最新消息, 1220先寫死 優惠訊息連結 */}
                <Route exact path="/news/2021-1220">
                  <NewsThree />
                </Route>
                {/* 最新消息, 1103先寫死 優惠訊息連結 */}
                <Route exact path="/news/2021-1103">
                  <NewsOne />
                </Route>
                {/* 最新消息, 1104先寫死 公司情報連結 */}
                <Route exact path="/news/2021-0901">
                  <NewsTwo />
                </Route>
                {/* 最新消息, 0425先寫死 公司情報連結 */}
                <Route exact path="/news/2022-0425_01">
                  <NewsFour />
                </Route>
                {/* 最新消息, 0425先寫死 公司情報連結 */}
                <Route exact path="/news/2022-0425_02">
                  <NewsFive />
                </Route>
                {/* 最新消息, 0425先寫死 公司情報連結 */}
                <Route exact path="/news/2022-0425_03">
                  <NewsSix />
                </Route>
                {/* 最新消息, 0425先寫死 公司情報連結 */}
                <Route exact path="/news/2022-0425_04">
                  <NewsSeven />
                </Route>
                {/* 最新消息, 1011先寫死 公司情報連結 */}
                <Route exact path="/news/2022-1104">
                  <NewsEight />
                </Route>
                {/* 最新消息, 0817先寫死 公司情報連結 */}
                <Route exact path="/news/2022-0817">
                  <NewsNine />
                </Route>
                {/* 最新消息, 0201 優惠訊息 */}
                <Route exact path="/news/2023-0201">
                  <NewsTen />
                </Route>
                {/* 最新消息, 0207 口試試場公告 */}
                <Route exact path="/news/2023-0207">
                  <NewsEleven />
                </Route>
                {/* 最新消息, 0209 錄訓公告 */}
                <Route exact path="/news/2023-0209">
                  <NewsTwelve />
                </Route>
                {/* 最新消息, 0317 口試試場公告 */}
                <Route exact path="/news/2023-0317">
                  <NewsThirteen />
                </Route>
                {/* 最新消息, 0317  */}
                <Route exact path="/news/2023-0317_01">
                  <NewsFourteen />
                </Route>
                {/* 最新消息, 0317  */}
                <Route exact path="/news/2023-0317_02">
                  <NewsFifteen />
                </Route>
                {/* 最新消息, 0320  */}
                <Route exact path="/news/2023-0320">
                  <NewsSixteen />
                </Route>
                {/* 最新消息, 0321  */}
                <Route exact path="/news/2023-0321">
                  <NewsSeventeen />
                </Route>
                {/* 最新消息, 0410  */}
                <Route exact path="/news/2023-0410">
                  <NewsEighteen />
                </Route>
                {/* 最新消息, 0412  */}
                <Route exact path="/news/2023-0412">
                  <NewsNineteen />
                </Route>
                {/* 最新消息, 0417  */}
                <Route exact path="/news/2023-0417">
                  <NewsTwenty />
                </Route>
                {/* 最新消息, 0418  */}
                <Route exact path="/news/2023-0418">
                  <NewsTwentyOne />
                </Route>
                {/* 最新消息, 0419  */}
                <Route exact path="/news/2023-0419">
                  <NewsTwentyTwo />
                </Route>
                {/* 最新消息, 0421  */}
                <Route exact path="/news/2023-0421">
                  <NewsTwentyThree />
                </Route>
                {/* 最新消息, 0425 優惠訊息 */}
                <Route exact path="/news/2023-0425">
                  <NewsTwentyFour />
                </Route>
                {/* 最新消息, 0515 就業博覽會 */}
                <Route exact path="/news/2023-0515">
                  <NewsTwentyFive />
                </Route>
                {/* 最新消息, 0516  */}
                <Route exact path="/news/2023-0516">
                  <NewsTwentySix />
                </Route>
                {/* 最新消息, 0517  */}
                <Route exact path="/news/2023-0517">
                  <NewsTwentySeven />
                </Route>
                {/* 最新消息, 0522  */}
                <Route exact path="/news/2023-0522">
                  <NewsTwentyEight />
                </Route>
                {/* 最新消息, 0522  */}
                <Route exact path="/news/2023-0524">
                  <NewsTwentyNine />
                </Route>
                {/* 最新消息, 0613  */}
                <Route exact path="/news/2023-0613">
                  <NewsThirty />
                </Route>
                {/* 最新消息, 0616  */}
                <Route exact path="/news/2023-0616">
                  <NewsThirtyOne />
                </Route>
                {/* 最新消息, 0726  */}
                <Route exact path="/news/2023-0726">
                  <NewsThirtyTwo />
                </Route>
                {/* 最新消息, 0727  */}
                <Route exact path="/news/2023-0727">
                  <NewsThirtyThree />
                </Route>
                {/* 最新消息, 0802  */}
                <Route exact path="/news/2023-0802">
                  <NewsThirtyFour />
                </Route>
                {/* 最新消息, 0824  */}
                <Route exact path="/news/2023-0824">
                  <NewsThirtyFive />
                </Route>
                {/* 最新消息, 0903  */}
                <Route exact path="/news/2023-0903">
                  <NewsThirtySix />
                </Route>
                {/* 最新消息, 0914  */}
                <Route exact path="/news/2023-0914">
                  <NewsThirtySeven />
                </Route>
                {/* 最新消息, 1128  */}
                <Route exact path="/news/2023-1128">
                  <NewsThirtyEight />
                </Route>
                {/* 最新消息, 240118  */}
                <Route exact path="/news/2024-0118">
                  <NewsThirtyNine />
                </Route>
                {/* 最新消息, 240118_01  */}
                <Route exact path="/news/2024-0118_01">
                  <NewsForty />
                </Route>
                {/* 最新消息, 240418  */}
                <Route exact path="/news/2024-0418">
                  <NewsFortyOne />
                </Route>
                {/* 最新消息, 240502  */}
                <Route exact path="/news/2024-0502">
                  <NewsFortyTwo />
                </Route>
                {/* 最新消息, 240513  */}
                <Route exact path="/news/2024-0513">
                  <NewsFortyThree />
                </Route>
                {/* 最新消息, 240614  */}
                <Route exact path="/news/2024-0614">
                  <NewsFortyFour />
                </Route>
                {/* 最新消息, 240614  */}
                <Route exact path="/news/2024-0702">
                  <NewsFortyFive />
                </Route>
                {/* 最新消息, 240723  */}
                <Route exact path="/news/2024-0723">
                  <NewsFortySix />
                </Route>
                {/* 最新消息, 241018  */}
                <Route exact path="/news/2024-1018">
                  <NewsFortySeven />
                </Route>
                {/* 最新消息, 241030  */}
                <Route exact path="/news/2024-1030">
                  <NewsFortyEight />
                </Route>
                {/* 金流 */}
                <Route exact path="/cashflow/checkout">
                  <CheckOut
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班個資同意書 */}
                <Route path="/short/:id">
                  <Onjob />
                </Route>
                {/* 企業包班個資同意書 */}
                <Route path="/corporate/:id">
                  <CorporateClassConsent />
                </Route>
                {/* 就業養成班個資同意書 */}
                <Route path="/long/:id">
                  <Privacy />
                </Route>
                {/* 養成班班級頁面 */}
                <Route exact path="/longterm/:id">
                  <LongTerm
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    dynamicBreadcrumbName={dynamicBreadcrumbName}
                    setDynamicBreadcrumbName={setDynamicBreadcrumbName}
                  />
                </Route>
                {/* 轉職班級頁面 */}
                <Route exact path="/job/:id">
                  <OJT
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                    dynamicBreadcrumbName={dynamicBreadcrumbName}
                    setDynamicBreadcrumbName={setDynamicBreadcrumbName}
                  />
                </Route>
                {/* 在職進修班 > 人工智慧 > 課程*/}
                <Route path="/OJT/AI/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 資料庫/大數據 > 課程*/}
                <Route path="/OJT/Database/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 軟體工程 > 課程*/}
                <Route path="/OJT/Software/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 網路通訊 > 課程*/}
                <Route path="/OJT/Network/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 雲端技術 > 課程*/}
                <Route path="/OJT/Cloud/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 智慧綠能 > 課程*/}
                <Route path="/OJT/GreenEnergy/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 資安管理 > 課程*/}
                <Route path="/OJT/InformationSecurity/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 專案管理 > 課程*/}
                <Route path="/OJT/manage/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 創新設計 > 課程*/}
                <Route path="/OJT/Design/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 數位行銷 > 課程*/}
                <Route path="/OJT/DigitalMarketing/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 數據分析 > 課程*/}
                <Route path="/OJT/DataAnalyze/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 人資管理 > 課程*/}
                <Route path="/OJT/HR/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 辦公室應用 > 課程*/}
                <Route path="/OJT/Office/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 行銷技能 > 課程*/}
                <Route path="/OJT/Marketing/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 程式設計 > 課程*/}
                <Route path="/OJT/Programming/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 元宇宙 > 課程*/}
                <Route path="/OJT/Metaverse/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > ESG永續學堂 > 課程*/}
                <Route path="/OJT/ESG/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 在職進修班 > 嵌入式 > 課程*/}
                <Route path="/OJT/Embedded/:id">
                  <TrainingClasses
                    signinBool={signinBool}
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* PMP 相關連結 */}
                <Route path="/PMP/:id">
                  <TrainingClassesForIndividual />
                </Route>
                {/* 在職班系列課程 > 課程 */}
                <Route path="/GCP/:id">
                  <TrainingClassesForSeries />
                </Route>
                {/* 首頁 > 在職班課程 */}
                <Route exact path="/:id">
                  <TrainingClasses
                    breadCrumbBool={breadCrumbBool}
                    setBreadCrumbBool={setBreadCrumbBool}
                  />
                </Route>
                {/* 404找不到頁面 */}
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </Suspense>
          </ScrollToTop>
        </MainContent>
        <Footer />
        <ActivityMessage />
        <ToTopButton />
      </>
    </Router>
  )
}

export default App
