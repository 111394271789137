// https://w3.ispan.com.tw/
import { apiUrl } from '../config/path'

// 首頁 和 養成班

const url = apiUrl + '/api/Classes'
const url1 = apiUrl + '/api/ClassType'

// 養成班課程頁面, 根據班別代號取得開課班級
// api/Classes/long/JPMFEET
export async function LongTermClasses(id) {
  // console.log(id)
  return await fetch(url + '/long/' + id).then((data) => data.json())
}

// 養成班課程頁面, 根據班別代號取得班別ClassType資料, "未使用"的養成班系統介紹資料
// api/ClassType/JPMFEET
export async function LongTermTypeInfo(id) {
  return await fetch(url1 + '/' + id).then((data) => data.json())
}

// 養成班課程頁面, 取得養成班開課班級的 <標籤>
// api/Classes/tag/JAMTDCT
export async function LongTermClassTag(id) {
  return await fetch(url + '/tag/' + id).then((data) => data.json())
}

// 養成班班別分類頁面, 取得所有使用中養成班的班別資料 ("未使用")
// api/ClassType
export async function LongTermClassType() {
  return await fetch(url1).then((data) => data.json())
}

// 養成班班別分類頁面, 取得所有使用中養成班的班別資料 (依照開課日期排序)
// api/ClassType/v1
export async function LongTermClassTypeV1() {
  return await fetch(url1 + '/v1').then((data) => data.json())
}

// 養成班班別分類頁面, 取得所有使用中養成班的班別 <標籤> (報名資訊, 班別tag)
// api/ClassType/info
export async function LongTermClassTypeInfo() {
  return await fetch(url1 + '/info').then((data) => data.json())
}

// 搜尋頁面, 根據課程關鍵字搜尋課程資料
// api/Classes/search/雲端
export async function ClassSearch(keyword) {
  return await fetch(`${url}/search/${keyword}`).then((data) => data)
}

// 搜尋頁面, 根據課程關鍵字搜尋課程資料, 22年2/9版
// api/Classes/v1/search/雲端
export async function ClassSearchV1(keyword) {
  return await fetch(`${url}/v1/search/${keyword}`).then((data) => data)
}

// 搜尋頁面、各區專頁、養成班地區分類, 根據課程地點篩選課程資料
// api/Classes/address/台中
export async function ClassFilter(city) {
  return await fetch(`${url}/address/${city}`).then((data) => data.json())
}

// 各區專頁、養成班地區分類, 根據課程地點篩選課程資料, 22年2/9新版
// api/Classes/v1/address/台中
export async function ClassFilterV1(city) {
  return await fetch(`${url}/v1/address/${city}`).then((data) => data.json())
}
