// https://w3.ispan.com.tw/
import { apiUrl } from '../config/path'

// 首頁 和 養成班

const url = apiUrl + '/api/Classes'

// 轉職進修課程頁面, 根據班別代號取得開課班級
// https://w3.ispan.com.tw/api/Classes/OJTLong
export async function OJTClasses() {
  // console.log(id)
  return await fetch(url + '/OJTLong/').then((data) => data.json())
}

// 轉職進修課程頁面, 取得班級開課班級的 <標籤>
// https://w3.ispan.com.tw/api/Classes/OJTTag
export async function OJTClassesTag() {
  return await fetch(url + '/OJTTag/').then((data) => data.json())
}
