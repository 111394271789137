import React, { useState, useEffect } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import { Link } from 'react-router-dom'
// import Dropdown from './Dropdown'
import DropdownOjt from './DropdownOjt'
import DropdownAboutUs from './DropdownAboutUs'
import DropdownLongTerm from './DropdownLongTerm'
import DropdownMateService from './DropdownMateService'
// import DropdownShare from './DropdownShare'
import DropdownEnterprise from './DropdownEnterprise'
import DropdownActivity from './DropdownActivity'
import SearchBar from './SearchBar'
// import AnyMessageButton from '../Home/AnyMessageButton'
import UseNavbar from './UseNavbar'
// import ClassmateNotice from '../ClassmateNotice'

// Navbar
function Navbar(props) {
  // 登入Bool, 設定狀態 / 顯示最上方學生須知區塊
  // const { signinBool, setSigninBool, display, setDisplay } = props
  const {
    signinBool,
    setSigninBool,
    // display,
    lessonDisplay,
    setLessonDisplay,
    setOptionTab,
    setOptionTypeTab,
  } = props

  const {
    dropdownAboutUs,
    dropdownOjt,
    dropdownLongTerm,
    dropdownMateService,
    dropdownShare,
    dropdownEnterprise,
    dropdownActivity,
    switchAboutUsCss,
    aboutUsOpen,
    switchLongTermCss,
    LongTermOpen,
    switchCssOjt,
    openOjt,
    switchMateServiceCss,
    mateServiceOpen,
    // switchShareCss,
    // shareOpen,
    switchEnterpriseCss,
    EnterpriseOpen,
    switchActivityCss,
    ActivityOpen,
    handleMouseEnterAboutUs,
    handleMouseLeaveAboutUs,
    handleMouseEnterLongterm,
    handleMouseLeaveLongterm,
    onMouseEnterOjt,
    onMouseLeaveOjt,
    handleMouseEnterMateService,
    handleMouseLeaveMateService,
    // handleMouseEnterShare,
    // handleMouseLeaveShare,
    handleMouseEnterEnterprise,
    handleMouseLeaveEnterprise,
    handleMouseEnterActivity,
    handleMouseLeaveActivity,
    handleAboutUsOpen,
    handleLongTermOpen,
    handleOpenOjt,
    handleMateServiceOpen,
    // handleShareOpen,
    handleEnterpriseOpen,
    handleActivityOpen,
  } = UseNavbar()

  // 漢堡預設樣子, 點擊切換為叉叉
  const [bars, setBars] = useState(true)

  // header狀態
  const [isFixed, setIsFixed] = useState(true)

  // logo狀態
  // eslint-disable-next-line no-unused-vars
  const [logoStatus, setLogoStatus] = useState(false)

  // 預設下載視窗為不顯示
  const [download, setDownload] = useState(false)

  // 滾動畫面, header固定至上方
  const handleScroll = () => {
    if (window.scrollY < 80) {
      setIsFixed(false)
      setLogoStatus(false)
    } else {
      setIsFixed(true)
      setLogoStatus(true)
    }
  }

  const handleIsAccount = () => {
    const data = JSON.parse(localStorage.getItem('SigninMessage'))
    if (data && data.code === 200) {
      setSigninBool(true)
    } else {
      setSigninBool(false)
    }
  }

  const handleLogout = () => {
    const data = JSON.parse(localStorage.getItem('SigninMessage'))
    if (data && data.code === 200) {
      setSigninBool(false)
      JSON.parse(localStorage.removeItem('SigninMessage'))
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    handleIsAccount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signinBool])

  // 登出後將Account localStorage移出
  useEffect(() => {
    const Account = JSON.parse(localStorage.getItem('Account'))
    if (!signinBool && Account) {
      localStorage.removeItem('Account')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 登出後將applyMessage localStorage移出
  useEffect(() => {
    const applyMessage = JSON.parse(localStorage.getItem('applyMessage'))
    if (!signinBool && applyMessage) {
      localStorage.removeItem('applyMessage')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={dropdownOjt ? 'dropdownBcc' : ''}></div>
      <div className={dropdownLongTerm ? 'dropdownBcc' : ''}></div>
      <div className={dropdownEnterprise ? 'dropdownBcc' : ''}></div>
      <div className={dropdownActivity ? 'dropdownBcc' : ''}></div>
      <div className={dropdownAboutUs ? 'dropdownBcc' : ''}></div>
      <div className={dropdownMateService ? 'dropdownBcc' : ''}></div>
      <div className={dropdownShare ? 'dropdownBcc' : ''}></div>

      <div
        className={`NavbarItems_content ${
          isFixed && 'container-fluid p-0 NavbarItems'
        }`}
      >
        {/* <ClassmateNotice display={display} setDisplay={setDisplay} />黃色區塊公告訊息 */}
        <div className="NavbarItems-content_inner">
          {/* 1001想更改的樣式 */}
          {/* <div className="navbar-logo_content">
            <a href="/" className="navbar-logo_inner">
              <img
                className="navbar-logo"
                src="/img/Logoslogan.png"
                alt="iSpan資展國際"
              />
            </a>
          </div>
          <a href="/" className="navbar-logo_smBlock">
            <img
              className="navbar-logo"
              src="/img/Logoslogan_sm.png"
              alt="iSpan資展國際"
            />
          </a> */}
          {/* 1001想更改的樣式 */}
          <a href="/" className="">
            <img
              className="navbar-logo"
              src="/img/Logoslogan_sm.png"
              alt="iSpan資展國際"
            />
          </a>
          <div
            className={bars ? 'nav-menu_content' : 'nav-menu_content active'}
          >
            <div className="nav-menu_inner">
              <ul className="nav-menu">
                <li
                  className="hoverAppear nav-menu_li"
                  onMouseEnter={handleMouseEnterAboutUs}
                  onMouseLeave={handleMouseLeaveAboutUs}
                  onClick={() => handleAboutUsOpen()}
                >
                  <a
                    href="/aboutus"
                    className={
                      switchAboutUsCss ? 'nav-links' : 'nav-links is-active'
                    }
                  >
                    <span
                      className="nav-links_ch nav_links_span"
                      data-hover="關於我們"
                    >
                      關於我們
                    </span>
                    {/* <span className="nav-links_en">{item.subTitle}</span> */}
                  </a>
                  {dropdownAboutUs && <DropdownAboutUs />}
                </li>
                <Collapse in={aboutUsOpen}>
                  <div
                    id="example-collapse-text"
                    className="nav-menu_trainingClassification"
                  >
                    <div className="trainingClassification-menu_content">
                      <a
                        href="/aboutus"
                        className="trainingClassification-menu_a"
                      >
                        品牌背景
                      </a>
                      <a
                        href="/serviceplace"
                        className="trainingClassification-menu_a"
                      >
                        服務據點
                      </a>
                      <a href="/news" className="trainingClassification-menu_a">
                        消息公告
                      </a>
                    </div>
                  </div>
                </Collapse>

                <li
                  className="hoverAppear nav-menu_li"
                  onMouseEnter={handleMouseEnterLongterm}
                  onMouseLeave={handleMouseLeaveLongterm}
                  onClick={() => handleLongTermOpen()}
                >
                  {/* <a href="/employmentclassindex" className="nav-links">
                    <span className="nav-links_ch"> */}
                  <a
                    href="/longterm"
                    className={
                      switchLongTermCss ? 'nav-links' : 'nav-links is-active'
                    }
                  >
                    <span
                      className="nav-links_ch nav_links_span"
                      data-hover="就業養成"
                    >
                      就業養成
                    </span>
                    {/* <span className="nav-links_en">Vocational Training</span> */}
                  </a>
                  {dropdownLongTerm && (
                    <DropdownLongTerm
                      setOptionTab={setOptionTab}
                      setOptionTypeTab={setOptionTypeTab}
                    />
                  )}
                </li>

                <Collapse in={LongTermOpen}>
                  <div
                    id="example-collapse-text"
                    className="nav-menu_trainingClassification"
                  >
                    <div className="trainingClassification-menu_content">
                      {/* <a
                        href="/longterm"
                        className="trainingClassification-menu_a"
                        onClick={() => {
                          setOptionTab(4)
                        }}
                      >
                        課程探索
                      </a> */}
                      <a
                        href="/longterm"
                        className="trainingClassification-menu_a"
                      >
                        課程一覽
                      </a>
                      <a
                        href="/informationmeeting"
                        className="trainingClassification-menu_a"
                      >
                        說明會一覽
                      </a>
                      <a href="/job" className="trainingClassification-menu_a">
                        就業媒合
                      </a>
                      <a
                        href="/careers"
                        className="trainingClassification-menu_a"
                      >
                        轉職進修
                      </a>
                      {/* <a href="/" className="trainingClassification-menu_a">
                        政府補助
                      </a> */}
                    </div>
                  </div>
                </Collapse>

                <li
                  className="hoverAppear nav-menu_li"
                  onMouseEnter={onMouseEnterOjt}
                  onMouseLeave={onMouseLeaveOjt}
                  onClick={() => handleOpenOjt()}
                >
                  <a
                    href="/OJT"
                    className={
                      switchCssOjt ? 'nav-links' : 'nav-links is-active'
                    }
                  >
                    <span
                      className="nav-links_ch nav_links_span"
                      data-hover="在職培訓"
                    >
                      在職培訓
                    </span>

                    {/* <p className="nav-links_en">On Job Training</p> */}
                  </a>

                  {dropdownOjt && <DropdownOjt />}
                </li>

                <Collapse in={openOjt}>
                  <div
                    id="example-collapse-text"
                    className="nav-menu_trainingClassification"
                  >
                    <div className="trainingClassification-menu_content">
                      <a
                        href="/OJT/All"
                        className="trainingClassification-menu_a"
                      >
                        課程總覽
                      </a>
                      <a href="/OJT" className="trainingClassification-menu_a">
                        分類探索
                      </a>
                      <a
                        href="/corporate-training"
                        className="trainingClassification-menu_a"
                      >
                        企業包班
                      </a>
                      <a href="/DT" className="trainingClassification-menu_a">
                        數位人才培訓
                      </a>
                      <a
                        href="/onjobsubsidy"
                        className="trainingClassification-menu_a"
                      >
                        小型人力提升計畫
                      </a>
                      <a
                        href="/careers"
                        className="trainingClassification-menu_a"
                      >
                        轉職進修
                      </a>
                      <a
                        href="/vocationaltraining"
                        className="trainingClassification-menu_a"
                      >
                        職業訓練機構專班
                      </a>
                    </div>
                  </div>
                </Collapse>

                {/* <Collapse in={open}>
                  <div
                    id="example-collapse-text"
                    className="nav-menu_trainingClassification"
                  >
                    <div className="trainingClassification-menu_content">
                      <a href="/OJT" className="trainingClassification-menu_a">
                        在職培訓分類
                      </a>
                      <a
                        href="/OJT/AI"
                        className="trainingClassification-menu_a"
                      >
                        人工智慧
                      </a>
                      <a
                        href="/OJT/Database"
                        className="trainingClassification-menu_a"
                      >
                        資料庫 / 大數據
                      </a>
                      <a
                        href="/OJT/Software"
                        className="trainingClassification-menu_a"
                      >
                        軟體工程
                      </a>
                      <a
                        href="/OJT/Network"
                        className="trainingClassification-menu_a"
                      >
                        網路通訊
                      </a>
                      <a
                        href="/OJT/Cloud"
                        className="trainingClassification-menu_a"
                      >
                        雲端技術
                      </a>
                      <a
                        href="/OJT/GreenEnergy"
                        className="trainingClassification-menu_a"
                      >
                        智慧綠能
                      </a>
                      <a
                        href="/OJT/InformationSecurity"
                        className="trainingClassification-menu_a"
                      >
                        資安管理
                      </a>
                      <a
                        href="/OJT/manage"
                        className="trainingClassification-menu_a"
                      >
                        專案管理
                      </a>
                      <a
                        href="/OJT/Design"
                        className="trainingClassification-menu_a"
                      >
                        創新設計
                      </a>
                      <a
                        href="/OJT/DigitalMarketing"
                        className="trainingClassification-menu_a"
                      >
                        數位行銷
                      </a>
                      <a
                        href="/OJT/DataAnalyze"
                        className="trainingClassification-menu_a"
                      >
                        數據分析
                      </a>
                      <a
                        href="/OJT/HR"
                        className="trainingClassification-menu_a"
                      >
                        人資管理
                      </a>
                      <a
                        href="/OJT/Office"
                        className="trainingClassification-menu_a"
                      >
                        辦公室應用
                      </a>
                      <a
                        href="/OJT/Marketing"
                        className="trainingClassification-menu_a"
                      >
                        行銷技能
                      </a>
                      <a
                        href="/OJT/Programming"
                        className="trainingClassification-menu_a"
                      >
                        程式設計
                      </a>
                      <a
                        href="/OJT/Metaverse"
                        className="trainingClassification-menu_a"
                      >
                        元宇宙
                      </a>
                      <a
                        href="/OJT/ESG"
                        className="trainingClassification-menu_a"
                      >
                        ESG永續學堂
                      </a>
                    </div>
                  </div>
                </Collapse> */}

                <li
                  className="hoverAppear nav-menu_li"
                  onMouseEnter={handleMouseEnterMateService}
                  onMouseLeave={handleMouseLeaveMateService}
                  onClick={() => handleMateServiceOpen()}
                >
                  {/* <a href="/employmentclassindex" className="nav-links">
                    <span className="nav-links_ch"> */}
                  <a
                    href="/studentnotice"
                    className={
                      switchMateServiceCss ? 'nav-links' : 'nav-links is-active'
                    }
                  >
                    <span
                      className="nav-links_ch nav_links_span"
                      data-hover="學員服務"
                    >
                      學員服務
                    </span>
                    {/* <span className="nav-links_en">Vocational Training</span> */}
                  </a>
                  {dropdownMateService && (
                    <DropdownMateService setOptionTypeTab={setOptionTypeTab} />
                  )}
                </li>

                <Collapse in={mateServiceOpen}>
                  <div
                    id="example-collapse-text"
                    className="nav-menu_trainingClassification"
                  >
                    <div className="trainingClassification-menu_content">
                      {/* <a href="/" className="trainingClassification-menu_a">
                        線上平台
                      </a> */}
                      <a
                        href="/studentnotice"
                        className="trainingClassification-menu_a"
                      >
                        學員須知
                      </a>
                      <a
                        href="/testcenter"
                        className="trainingClassification-menu_a"
                      >
                        考試中心
                      </a>
                      <a
                        href="/eventpage/job_allowance"
                        className="trainingClassification-menu_a"
                      >
                        初次尋職青年穩定就業計畫
                      </a>
                      <a
                        href="/eventpage/employment_expansion"
                        className="trainingClassification-menu_a"
                      >
                        勞動部疫後改善缺工擴大就業方案
                      </a>
                      <a
                        href="/eventpage/job_opportunity"
                        className="trainingClassification-menu_a"
                      >
                        青年就業旗艦計畫
                      </a>
                      <a
                        href="/eventpage/job_investment"
                        className="trainingClassification-menu_a"
                      >
                        投資青年就業方案 第2期
                      </a>
                      {/* <a
                        href="/testcenter"
                        className="trainingClassification-menu_a"
                        onClick={() => {
                          setOptionTypeTab(2)
                        }}
                      >
                        Kryterion考試中心
                      </a>
                      <a
                        href="/testcenter"
                        className="trainingClassification-menu_a"
                        onClick={() => {
                          setOptionTypeTab(3)
                        }}
                      >
                        VUE授權中心
                      </a> */}
                    </div>
                  </div>
                </Collapse>

                {/* <li
                  className="hoverAppear nav-menu_li"
                  onMouseEnter={handleMouseEnterShare}
                  onMouseLeave={handleMouseLeaveShare}
                  onClick={() => handleShareOpen()}
                >
                  <a href="/employmentclassindex" className="nav-links">
                    <span className="nav-links_ch">
                  <a
                    href="/"
                    className={
                      switchShareCss ? 'nav-links' : 'nav-links is-active'
                    }
                  >
                    <span
                      className="nav-links_ch nav_links_span"
                      data-hover="見證分享"
                    >
                      見證分享
                    </span>
                    <span className="nav-links_en">Vocational Training</span>
                  </a>
                  {dropdownShare && <DropdownShare />}
                </li> */}

                {/* <Collapse in={shareOpen}>
                  <div
                    id="example-collapse-text"
                    className="nav-menu_trainingClassification"
                  >
                    <div className="trainingClassification-menu_content">
                      <a href="/" className="trainingClassification-menu_a">
                        就業心得分享
                      </a>
                      <a href="/" className="trainingClassification-menu_a">
                        作品牆
                      </a>
                      <a href="/" className="trainingClassification-menu_a">
                        新聞專欄
                      </a>
                    </div>
                  </div>
                </Collapse> */}

                <li
                  className="hoverAppear nav-menu_li"
                  onMouseEnter={handleMouseEnterEnterprise}
                  onMouseLeave={handleMouseLeaveEnterprise}
                  onClick={() => handleEnterpriseOpen()}
                >
                  <a
                    href="/enterprise"
                    className={
                      switchEnterpriseCss ? 'nav-links' : 'nav-links is-active'
                    }
                  >
                    <span
                      className="nav-links_ch nav_links_span"
                      data-hover="企業服務"
                    >
                      企業服務
                    </span>

                    {/* <p className="nav-links_en">On Job Training</p> */}
                  </a>

                  {dropdownEnterprise && <DropdownEnterprise />}
                </li>
                <Collapse in={EnterpriseOpen}>
                  <div
                    id="example-collapse-text"
                    className="nav-menu_trainingClassification"
                  >
                    <div className="trainingClassification-menu_content">
                      {/* <a href="/" className="trainingClassification-menu_a">
                        企業服務
                      </a> */}
                      <a
                        href="/enterprise"
                        className="trainingClassification-menu_a"
                      >
                        會員專案
                      </a>
                      <a
                        href="/corporate-training"
                        className="trainingClassification-menu_a"
                      >
                        企業包班
                      </a>
                      <a
                        href="/lease"
                        className="trainingClassification-menu_a"
                      >
                        場地租借
                      </a>
                      <a
                        href="/jobRecruitment"
                        className="trainingClassification-menu_a"
                      >
                        企業徵才
                      </a>
                      {/* <a href="/DT" className="trainingClassification-menu_a">
                        數位人才培訓
                      </a> */}
                    </div>
                  </div>
                </Collapse>

                <li
                  className="hoverAppear nav-menu_li"
                  onMouseEnter={handleMouseEnterActivity}
                  onMouseLeave={handleMouseLeaveActivity}
                  onClick={() => handleActivityOpen()}
                >
                  <span
                    className={
                      switchActivityCss ? 'nav-links' : 'nav-links is-active'
                    }
                  >
                    <span
                      className="nav-links_ch nav_links_span"
                      data-hover="最新活動"
                    >
                      最新活動
                    </span>

                    {/* <p className="nav-links_en">On Job Training</p> */}
                  </span>

                  {dropdownActivity && <DropdownActivity />}
                </li>
                <Collapse in={ActivityOpen}>
                  <div
                    id="example-collapse-text"
                    className="nav-menu_trainingClassification"
                  >
                    <div className="trainingClassification-menu_content">
                      <a
                        href="/Activities"
                        className="trainingClassification-menu_a"
                      >
                        活動專區
                      </a>
                    </div>
                  </div>
                </Collapse>
                {/* 
                <li className="nav-content_inner_1">
                  <div
                    className="nav-content_download"
                    onClick={() => setDownload(!download)}
                  >
                    <i className="fas fa-book-open"></i>
                    <h6 className="nav-links_ch">課程內容</h6>
                  </div>
                </li> */}
                <li className="nav-content_inner_2">
                  <div className="nav-content_member">
                    {!signinBool && (
                      <a href="/register" className="footer-icon_a">
                        <div className="nav-items_range">
                          <i className="fas fa-user-plus" /> <br />
                          <p className="menuLoginItems_txt">註冊</p>
                        </div>
                      </a>
                    )}
                    {signinBool && (
                      <a href="/member/personalinfo" className="footer-icon_a">
                        <div className="nav-items_range">
                          <i className="fas fa-user" /> <br />
                          <p className="menuLoginItems_txt">會員</p>
                        </div>
                      </a>
                    )}
                    <div className="nav-verticalLine"></div>
                    {!signinBool && (
                      <a href="/signin" className="footer-icon_a">
                        <div className="nav-items_range">
                          <i className="fas fa-sign-in-alt" /> <br />
                          <p className="menuLoginItems_txt">登入</p>
                        </div>
                      </a>
                    )}
                    {signinBool && (
                      <a
                        href="/signin"
                        className="footer-icon_a"
                        onClick={() => {
                          handleLogout()
                        }}
                      >
                        <div className="nav-items_range">
                          <i className="fas fa-sign-out-alt" /> <br />
                          <p className="menuLoginItems_txt">登出</p>
                        </div>
                      </a>
                    )}
                  </div>
                </li>
                <li className="nav-content_inner_1">
                  <div className="nav-content_link">
                    <a
                      href="https://www.facebook.com/IIIDEITaipei"
                      className="nav-content_link_item"
                    >
                      <h6>
                        <img
                          src="/img/facebookIcon.png"
                          alt="iSpan資展國際臉書Facebook"
                        />
                        Facebook
                      </h6>
                    </a>
                    <a href="/" className="nav-content_link_item">
                      <h6>
                        <img
                          src="/img/youTubeIcon.png"
                          alt="iSpan資展國際YouTube"
                        />
                        YouTube
                      </h6>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="menuLoginItems">
            <SearchBar
              lessonDisplay={lessonDisplay}
              setLessonDisplay={setLessonDisplay}
            />

            {/* 依照登入登出顯示 */}
            {!signinBool && (
              <a href="/register">
                <div className="menuLoginItems_content menuLoginCube">
                  <i className="fas fa-user-plus" /> <br />
                  <p className="menuLoginItems_txt">註冊</p>
                </div>
              </a>
            )}
            {signinBool && (
              <a href="/member/personalinfo">
                <div className="menuLoginItems_content menuLoginCube">
                  <i className="fas fa-user"></i> <br />
                  <p className="menuLoginItems_txt">會員</p>
                </div>
              </a>
            )}
            {!signinBool && (
              <a href="/signin">
                <div className="menuLoginItems_content menuLoginCube">
                  <i className="fas fa-sign-in-alt" /> <br />
                  <p className="menuLoginItems_txt">登入</p>
                </div>
              </a>
            )}
            {signinBool && (
              <a
                href="/signin"
                onClick={() => {
                  handleLogout()
                }}
              >
                <div className="menuLoginItems_content menuLoginCube">
                  <i className="fas fa-sign-out-alt"></i> <br />
                  <p className="menuLoginItems_txt">登出</p>
                </div>
              </a>
            )}
            {/* 依照登入登出顯示 */}
          </div>
          <div
            className="menu-icon_content"
            onClick={() => {
              setBars(!bars)
            }}
          >
            <div className={bars ? 'menu-icon' : 'menu-icon menu-active'}>
              {/* {bars ? 'menu-iconHamburger' : 'fas fa-times'} */}
              <span className="menu-icon_1"></span>
              <span className="menu-icon_2"></span>
              <span className="menu-icon_3"></span>
            </div>
            <p className="menuLoginItems_txt">選單</p>
          </div>
        </div>
      </div>
      {/* <AnyMessageButton display={display} /> */}

      {/* 課程內容下載 */}
      <div className={download ? 'd-block' : 'd-none'} id="download">
        <div className="download-content">
          <div className="message-content">
            <button
              type="button"
              className="customClose ml-auto"
              aria-label="Close"
              onClick={() => setDownload(!download)}
            >
              <div className="close-border">
                <i
                  className="fas fa-times message-close"
                  aria-hidden="true"
                ></i>
              </div>
            </button>
            <div className="message-content_img">
              <img src="/img/download.jpg" alt="" />
              <div className="message-content_head">
                <div className="message-head_txt">
                  <h5>2022年度課程</h5>
                  <h3>
                    <span className="main-picture_en">課程內容總覽</span>
                    {/* <span className="main-picture_ch message-txt_en">
                      Course Overview
                    </span> */}
                  </h3>
                </div>
              </div>
            </div>
            <div className="message-content_inner">
              <h6>
                您可以直接選擇就業養成班或在職進修班課程總覽下載pdf，歡迎下載瀏覽
                !
              </h6>
              <div className="message-content_txt">
                <Link
                  to="/pdf/iSpan資展國際【2022年就業養成班】開課時間一覽表.pdf"
                  target="_blank"
                  download
                >
                  <div className="message-content_button">
                    <div className="lessonButon-content_txt">
                      <h6>
                        就業養成班<i className="fas fa-download"></i>
                      </h6>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/pdf/iSpan資展國際【2022年在職進修班】課程一覽表.pdf"
                  target="_blank"
                  download
                >
                  <div className="message-content_button m-0">
                    <div className="lessonButon-content_txt">
                      <h6>
                        在職進修班<i className="fas fa-download"></i>
                      </h6>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 課程內容下載 */}
    </>
  )
}

export default Navbar
