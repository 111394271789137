import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// PHP後端工程師養成訓練班詳細內容
function JPPHPSTClass(props) {
  const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      <div className="classContent__figure">
        <img src="/img/employment/php_head.jpg" alt="" />
      </div>

      <div className="txtC__div text-justify">
        <p>
          PHP是一種在伺服器端執行的嵌入HTML文檔的腳本語言，語言的風
          格類似於C語言；和ASP、JAVA一樣，是一門常用於Web編程的語
          言，它能運行在Windows、Linux等絕大多數作業系統環境中，常與開
          源免費的Web服務Apache和資料庫MySQL配合使用於Linux平台
          上（簡稱LAMP），具有最高的性價比，號稱「Web架構黃金組合」。
        </p>
        <br />
        <p>
          目前市場上對於PHP人才的需求非常高，尤其是在網路開發、電商平
          台、金融科技等領域，PHP人才的薪資也相對較高。目前國內PHP相
          關職缺全年度任何時間皆超過上百筆以上(例如：113年4月查詢104
          人力銀行約有7804筆、1111人力銀行169筆)。
        </p>
        <br />
        <p>
          在台灣，初階PHP軟體工程師起薪大約在新台幣3萬至3.5萬元之間
          ，而一位有經驗的PHP開發人員若是也略懂C#、Java或Python等
          程式語言的話，則其平均月薪會落在5萬元至8萬元之間。當然，月
          薪這個數字也會因著公司、地區和實際需求而有所不同。隨著大數據和人工智慧等新興技術的崛起，對於具備AI相關技能之PHP人才需求也將跟隨趨勢而起。
        </p>
      </div>

      {/* 課程介紹 */}
      <div>
        <div className="pb-4">
          <h6>
            <strong>【課程目標】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              培養學員具備PHP
              Laravel框架的使用能力，以期更好地組織和管理程式碼，並提升開發之效率
            </li>
            <li>
              培養學員能夠閱讀 PHP
              官方文件，透過豐富的教程和範例，可以幫助學員更深入地瞭解 PHP
              語言特性和用法
            </li>
            <li>
              透過製作期末專題來學習 PHP，讓學員熟悉 PHP
              的應用和開發流程，並提高實戰經驗
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <h6>
            <strong>【就業展望】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              PHP
              是一種廣泛使用的網頁開發語言，因此在網路相關產業中擁有很好的就業展望。許多公司都需要
              PHP 開發人員來維護和開發網站和應用程式
            </li>
            <li>
              Web開發人員、軟體工程師、資料庫開發人員、使用PHP
              進行自動化系統管理任務、開發電子商務網站、社交媒體網站、內容管理系統等等
            </li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure employmentClassification-img_only">
          <img
            src="/img/employment/syllabus/JPPHPST_class.jpg"
            alt="PHP後端工程師養成訓練班"
          />
        </div>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>

        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>PHP後端工程師養成訓練班適合對象</h4>
                <div className="longTermTabC__div">
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        具基本之網頁及程式撰寫經驗或概念者
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        有興趣使用PHP與MySQL製作網頁者
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        本課程以最受中小企業青睞的開放原始碼，免費程式語言PHP及資料庫MySQL為學習標的，且包含最新Php框架技術-Laravel,並結合瀏覽器必備互動程式語言JavaScript，學員不僅可節省軟體開發成本，更進一步具熱門網頁開發技術，增加自己在就業市場的競爭優勢
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">Web開發人員</li>
                  <li className="longTerm_tabContent_li">軟體工程師</li>
                  <li className="longTerm_tabContent_li">
                    使用PHP 進行自動化系統管理任務
                  </li>
                  <li className="longTerm_tabContent_li">開發電子商務網站</li>
                  <li className="longTerm_tabContent_li">社交媒體網站</li>
                  <li className="longTerm_tabContent_li">內容管理系統</li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default JPPHPSTClass
