import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// 軟體測試工程師養成訓練班, 20221201新增
function JMSTQATClass() {
  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      {/* <div className="classContent__figure">
        <img src="/img/employment/AI_head.png" alt="" />
      </div> */}
      <div className="txtC__div text-justify mt-0">
        <div className="employmentClassification-instruction_p">
          <p>
            軟體開發的品質絕對是軟體公司要能永續經營的重要基石，因此追求品質的軟體開發工程師，總是會透過單元測試(Unit
            Test)來驗證程式的邏輯是否正確，也有些公司採用了TDD (Test-Driven
            Development)
            測試驅動開發的方法，希望可以提升程式品質，這些當然都很好，但這只是個開始，為了保證軟體交付的品質，我們還有諸如整合測試(Integration
            testing)、系統測試(System testing)、端對端測試(End to End
            testing)、壓力測試(Stress
            testing)等等的測試計畫需要進行，因此軟體公司需要有QA
            軟體測試工程師(Quality Assurance
            Engineer)的團隊，目前國內軟體測試相關職缺全年度任何時間皆超過1000筆以上(如111年七月於104人力銀行約1500筆、111人力銀行
            2400筆)，顯示軟體測試工作已逐步為國內業界所認可接受，且歐美相關研發機構亦已開始要求國內業者須有通過認證之軟體測試工程師參與軟體開發，軟體測試是國內要將軟體產業提升至國際水準的必要手段與途徑。
          </p>
          <br />
          <p>
            從事軟體測試相關工作，我們建議最好具備了軟體開發的能力，不僅有利於測試專業的學習，更可強化就業職能及職涯發展的開拓，因此特別開設了軟體測試工程師養成訓練班，從軟體開發的技術教起，訓練軟體開發的能力，另外透過軟體測試技術的學習，讓學員可以學會具有交付品質保證軟體的思維。
          </p>
        </div>
      </div>

      {/* 課程介紹 */}
      {/* <div>
    <div className="content__title">
              <h3>課程介紹</h3>
              <div className="line__div--light">
                <div className="lease-content_line"></div>
              </div>
            </div>
        <div className="pb-4">
          <div className="">
            【課程目標】
          </div>
          <ul className="listStyle__ul">
            <li>
              本訓練目標為使學員能習得人工智慧的相關知識、Java與Python程式設計開發、資料分析處理、機器學習與深度學習的專業知識與技能，並具小組分工從事人工智慧系統設計與實作之能力，使學員於通過考核、取得結訓證書後能順利就業。
            </li>
            <li>
              1）培養學員能夠運用JavaScript、Node.js、Java
              Web等網站前後端整合開發技術，在Raspberry Pi
              IoT平台進行AI機器學習以及OpenCV影像辨識應用，展現出AIoT(AI人工智慧+IoT物聯網)的創新應用
            </li>
            <li>
              2）培養學員能夠運用Python程式語言，進行資料分析、影像辨識、機器學習與深度學習等創新應用
            </li>
            <li>
              3）培養學員能夠運用TensorFlow(含Keras)深度學習框架來建構深度學習的模型
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="">
            【班級特色】
          </div>
          <ul className="listStyle__ul">
            <li>
              從零開始！
              本班從Python程式設計、Java程式設計、JavaScript語法入門開始，一路引領您實作到AI相關的應用、機器學習專案的開發
            </li>
            <li>
              強調實作！ 透過Node.js、JavaEE進行網站前後端的整合；藉由Raspberry
              Pi(樹莓派)實驗板，讓您得以自行動手完成您想要的AI創新應用
            </li>
            <li>
              專題導向！
              透過專題小組的團隊合作以及大、小專題的歷練，引導您完成期末專題並上台進行成果發表
            </li>
          </ul>
        </div>
      </div> */}
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure employmentClassification-img_only">
          <img
            src="/img/employment/syllabus/JMSTQAT_class.png"
            alt="軟體測試工程師養成訓練班課表"
          />
        </div>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">招生簡章</div>
          <ul className="classInfoList__styleNone">
            <li>
              <Link
                to="/pdf/資展國際軟體測試工程師養成訓練班招生簡章.pdf"
                className="orange"
                target="_blank"
                download
              >
                簡章下載<i className="fas fa-download"></i>
              </Link>
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>軟體開發</li>
            <li>網頁開發</li>
            <li>軟體測試等相關工程師</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              主要安排於星期一到星期五週間白天，但因部分課程配合業師的時間，部份課程有可能會安排於『夜間時段』與『假日時段』來進行授課
            </li>
          </ul>
        </div>
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>軟體測試工程師養成訓練班適合對象</h4>
                <div className="longTermTabC__div">
                  {/* <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure> */}
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        有就業意願之失業者均可參訓，無特殊條件
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        需具有電腦操作基本操作能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        有耐力與毅力，願意接受本班考驗
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備軟體測試知識、理論與實務軟體測試技能
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備網站應用程式開發、資料庫應用等前後端基礎應用與整合開發能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        輔導考取軟體測試相關國際專業認證
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">軟體開發</li>
                  <li className="longTerm_tabContent_li">網頁開發</li>
                  <li className="longTerm_tabContent_li">
                    軟體測試等相關工程師
                  </li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>

      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default JMSTQATClass
