import React from 'react'
import { useHistory } from 'react-router-dom'

// 報名說明會完成訊息通知, 從近期說明會列表來的
function ApplyFromMeetingSuccess() {
  const history = useHistory()

  return (
    <>
      {/* 報名說明會主圖 ↓↓↓　*/}
      <div className="container-fluid p-0">
        <div className="col p-0">
          <figure className="main-picture_img">
            <img src="/img/pageBanner/apply_head.jpg" alt="" />
          </figure>
          <div className="banner__titleC">
            <div className="bannerTitle__white">
              <h1 className="main-picture_en">Signup</h1>
              <h1 className="main-picture_ch">報名說明會</h1>
            </div>
          </div>
        </div>
      </div>
      {/* 報名說明會主圖 ↑↑↑　*/}

      {/* 報名說明會訊息通知 */}
      <div className="success-message_content">
        <svg width="120" height="120">
          <circle
            fill="none"
            stroke="#2DBD2A"
            strokeWidth="8"
            cx="-20"
            cy="60"
            r="50"
            className="circle"
            transform="rotate(-90 20 20)"
          />
          <polyline
            fill="none"
            stroke="#2DBD2A"
            strokeWidth="9"
            points="35, 60, 56, 80 86, 46"
            strokeLinejoin="round"
            className="tick"
          />
        </svg>
        <h6>親愛的會員您好</h6>
        <p>感謝您報名說明會，報名已提交成功</p>
        <p>我們會將說明會詳細資訊寄至您會員註冊之電子信箱</p>
        <p>您也可以在會員中心查詢您的報名紀錄，謝謝 !</p>
        <button
          className="employmentClassification-content_button customMarginTop45"
          onClick={() => {
            history.push('/informationmeeting')
          }}
        >
          回上一頁
        </button>
      </div>
    </>
  )
}

export default ApplyFromMeetingSuccess
