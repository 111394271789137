import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { OnJobClassTypeInfo } from '../../services/OnJob'

// 在職班課程 > PMP "相關頁面"
function TrainingClassesForIndividual() {
  const [classType, setClassType] = useState('')

  let { id } = useParams() // destructure id  pmpexam

  // 將課程內容html裝入classType
  useEffect(() => {
    const data = OnJobClassTypeInfo(id)

    data
      .then((classInfo) => {
        return classInfo.text()
      })
      .then((classInfo) => {
        setClassType(classInfo)
      })
  }, [id])

  // 卸載時還原載入狀態
  useEffect(() => {
    return () => {
      setClassType()
    }
  }, [setClassType])

  return (
    <>
      <div className="container-fluid p-0 ojt-page">
        {/* ↓↓↓　*/}
        <div className="job-content_form">
          <div className="job-form_bc ojt_individualPageBcc_div">
            <div className="custom-container">
              <div className="job-form_title white">
                {(() => {
                  switch (id) {
                    case 'pmpexam':
                      return <h2>PMP®國際專案管理師考試資訊</h2>
                    case 'PMPipass':
                      return <h2>PMP®考試線上教材</h2>

                    default:
                      return null
                  }
                })()}
                <h6>如欲瞭解更多，歡迎洽詢資展國際窗口</h6>
              </div>
            </div>
          </div>
          {/* 內容 */}
          <div className="custom-container">
            <div className="enterprise-content_m">
              <div className="job-form_content">
                <div className="job-form_inner digitalTrans_inner_div">
                  <div
                    className="json-content ojt_individualPage_div position-relative"
                    dangerouslySetInnerHTML={{
                      __html: classType,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {/* 內容 */}
        </div>
        {/* ↑↑↑　*/}
      </div>
    </>
  )
}
export default TrainingClassesForIndividual
