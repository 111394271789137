import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'
// import { Link } from 'react-router-dom'
import { Link } from 'react-scroll'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

// 智慧應用微軟C#就業養成班詳細內容, 20230901修改
function MicrosoftDeveloperClass(props) {
  const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  // 課程內容資訊打開與隱藏狀態
  const [switchCss1, setSwitchCss1] = useState(false)
  const [switchCss2, setSwitchCss2] = useState(false)
  const [switchCss3, setSwitchCss3] = useState(false)
  const [switchCss4, setSwitchCss4] = useState(false)
  const [switchCss5, setSwitchCss5] = useState(false)
  const [switchCss6, setSwitchCss6] = useState(false)
  const [switchCss7, setSwitchCss7] = useState(false)
  const [switchCss8, setSwitchCss8] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [open5, setOpen5] = useState(false)
  const [open6, setOpen6] = useState(false)
  const [open7, setOpen7] = useState(false)
  const [open8, setOpen8] = useState(false)

  // 展開詳細內容狀態
  const handleOpen1 = () => {
    setSwitchCss1(!switchCss1)
    setOpen1(!open1)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  const handleOpen2 = () => {
    setSwitchCss2(!switchCss2)
    setOpen2(!open2)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  const handleOpen3 = () => {
    setSwitchCss3(!switchCss3)
    setOpen3(!open3)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  const handleOpen4 = () => {
    setSwitchCss4(!switchCss4)
    setOpen4(!open4)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  const handleOpen5 = () => {
    setSwitchCss5(!switchCss5)
    setOpen5(!open5)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }
  const handleOpen6 = () => {
    setSwitchCss6(!switchCss6)
    setOpen6(!open6)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }
  const handleOpen7 = () => {
    setSwitchCss7(!switchCss7)
    setOpen7(!open7)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss8(false)
    setOpen8(false)
  }
  const handleOpen8 = () => {
    setSwitchCss8(!switchCss8)
    setOpen8(!open8)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
  }

  return (
    <>
      <div className="classContent__figure">
        <img src="/img/employment/C_head.png" alt="" />
      </div>

      <div className="txtC__div text-justify">
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          微軟.NET程式開發人員，目前職場最熱
        </h4>
        <p className="employmentClassification-instruction_p">
          據104人力銀行資料顯示，台灣整體人才需求出現從硬體轉到軟硬體通吃的明顯挪移現象，硬體廠商近來積極招募軟體人才，以期推出軟體硬體整合的解決方案，使得軟體人才的需求量不減反增，其中尤以微軟的
          .NET
          開發人才的短缺，特別顯著，從本班結訓學員以超快、超高和超多的就業率可得到充分的驗證。
        </p>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          微軟Visual Studio 神器，搶先學習 .NET 應用程式開發
        </h4>
        <p className="employmentClassification-instruction_p">
          使用 Visual Studio
          開發跨平台應用程式，包括建置網路應用程式、行動應用程式、傳統型.NET應用程式、以及新式智慧AI應用程式。這是一個相當巨大的商機，更是想成為程式設計師的您難得的機會與舞台！
        </p>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          養成式教育訓練，全方位技術深耕
        </h4>
        <div className="employmentClassification-instruction_p">
          <p>
            資展國際的《智慧應用微軟C#工程師就業養成班》，一直都是業界微軟程式設計師培育的重要搖籃，面對這樣的機會與挑戰，當然責無旁貸，務求讓程式設計師掌握此新商機所必備的技能。
          </p>
          <br />
          <p>
            本班課程內容豐富，從C# 程式語言，ADO.NET 與 LINQ
            等資料庫存取技術，RESTful Web 服務及一直到 ASP.NET及 ASP.NET Core
            MVC
            網站開發，並加強了前端網頁開發技術，從了解最新HTML5的相關運用、Bootstrap
            響應式網頁設計到JavaScript函式庫
            jQuery及Ajax整合應用的學習。本班課程也擴充導入趨勢智慧應用，如Microsoft
            Azure 雲端網站建置與開發、BOT
            機器人客服、Azure認知服務與機器學習應用、商業智慧Power
            BI等。同時也學習微軟XAML Islands、WPF MVVM
            用戶端應用程式及跨平台手機App開發。全班課程設計，符合業界最常使用技術，涵蓋了所有重要且最新的
            .NET
            平台技術，為您一步步打下紮實基礎。每梯次結訓時廠商踴躍搶人的畫面，就是我們訓練成果的最好證明！
          </p>
        </div>

        {/* 學生心得 */}
        <div className="knowledge_content knowledge-c">
          <h5>學生心得與專題</h5>
          <div className="knowledge-content">
            <div className="knowledge-content_list">
              <ul className="classLink-content_ul knowledge-content_ul">
                <li>
                  <a
                    href="https://www.youtube.com/watch?v=KA5AhA3flNU"
                    className="knowledge-content_a"
                  >
                    122第一組 社群交友平台
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/watch?v=7ENyAAewAAE"
                    className="knowledge-content_a"
                  >
                    122第二組 小農是好農
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/watch?v=wk5us0IgHco"
                    className="knowledge-content_a"
                  >
                    122第三組 與治療師的距離
                  </a>
                </li>
              </ul>
            </div>
            <div className="knowledge-content_list">
              <ul className="classLink-content_ul knowledge-content_ul">
                <li>
                  <a
                    href="https://www.youtube.com/watch?v=sZDyqalfHB0"
                    className="knowledge-content_a"
                  >
                    122第四組 FANCY好感衣著購物網
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.iiiedu.org.tw/msit-stu-001/"
                    className="knowledge-content_a"
                  >
                    濬杰-從數學系跳到軟體工程師
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* 學生心得 */}
      </div>

      {/* 課程介紹 */}
      <div>
        <div className="pb-4">
          <h6>
            <strong>【課程目標】</strong>
          </h6>
          <p className="mt-4">您希望修練 .NET 「一身真功夫」嗎</p>
          <br />
          <p>
            Microsoft
            是目前市佔率最高的軟體領導廠商，本班是職訓市場唯一最有經驗、且針對Microsoft軟體開發技術所設計的就業養成課程，擁有MicroSoft軟體開發技術，就具備"獨家功夫"的競爭優勢
          </p>
          <ul className="listStyle__ul">
            <li>架構網路消費平台軟體 - 拍賣、購物中心、網路開店、網路行銷</li>
            <li>建立網路社群部落格軟體 - 家族、交友、郵件信箱、即時通訊</li>
            <li>
              建置超酷娛樂多媒體音效平台 -
              相簿、音樂、電影訂位、旅遊、星座算命、遊戲
            </li>
            <li>
              開發網路資訊服務平台 -
              地圖、線上考試、理財、股市、書店、3C家電商城
            </li>
            <li>
              桌上型應用程式軟體開發 - 商用文書處理軟體、企業軟體-
              財務，客戶關係管理，供應商管理、數位軟體 - 媒體播放器
              Player、訊息管理 - 郵件信箱，個人信息系統
            </li>
            <li>
              新式智慧AI應用程式 / 行動裝置設計 - (如Pocket
              PC和智慧型手機)應用程式開發與手機遊戲設計
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              C#樂章 .NET舞台
              <span>
                1.
                微軟是目前市佔率最高的軟體領導廠商，本班是職訓市場唯一最有經驗、且針對微軟開發技術所設計的就業養成課程
              </span>
              <span>
                2. 本課程採用在Microsoft.NET開發平台技術中最熱門的Visual C#
                語言為課程核心
              </span>
            </li>
            <li>
              全方位學習 與時俱進的教學內容
              <span>1. 從傳統的.NET應用程式到最新WPF MVVM的完整學習</span>
              <span>
                2. 從傳統網頁的開發，Ajax網頁整合開發到ASP.NET Core MVC
                跨平台網站開發
              </span>
            </li>
            <li>
              企業專題合作 縮短訓用落差
              <span>
                1.
                結合企業/公司之實務專案需求，提供學員作為專題實作演練之素材，並由參加學員以小組團隊合作方式，磨練學員所學技能與強化產業應用能力
              </span>
            </li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure">
          <img
            src={
              longTermClass
                ? `/img/employment/syllabus/C_JMMSITT_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '台北':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Taipei_C2E2302'
                            : 'Taipei'
                        }`
                      case '桃園':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Zhongli_C2E2302'
                            : 'Zhongli'
                        }`
                      case '台中':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Taichung_C2E2302'
                            : 'Taichung'
                        }`
                      case '台南':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Tainan_C2E2302'
                            : 'Tainan'
                        }`
                      case '高雄':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'kaohsiung_C2E2302'
                            : 'kaohsiung'
                        }`
                      default:
                        return ''
                    }
                  })()}.png?240621`
                : '/img/employment/syllabus/C_JMMSITT_Taipei.png?240621'
            }
            alt=""
          />
        </div>
        <p className="red pt-3 mb-3">※課程內容以地區班級為準。</p>

        {/* 展開詳細介紹 ↓↓↓　*/}
        <div className="mt-4 pb-5">
          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen1()}
              aria-controls="example-collapse-text"
              aria-expanded={open1}
              className={
                switchCss1
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              關聯式資料庫
            </button>
          </div>
          <Collapse in={open1}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  <p>
                    1. 微軟 SQL Server Transact-SQL 基礎查詢語法 :<br />
                    ─ 關連式資料庫導論：
                    <br />
                    SQL語言介紹、索引建立與使用、新增、刪除、與修改、資料分組與統計運算、多重表格查詢、子查詢處理方式
                  </p>
                  <br />
                  <p>
                    2. 微軟 SQL Server 資料庫實作
                    <br />─ SQL Server資料庫設計實作、建立資料庫與資料庫檔案 、
                    資料型別介紹以及建立資料表 建立以及調校索引、
                    使用條件約束(Constraints)與觸發器(Triggers)來實作資料的技術、
                    資料庫函數的實作技術、 交易與鎖定的管理完整性、 使用XML
                    、View的實作技術、 資料庫預儲程序的實作技術
                  </p>
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen2()}
              aria-controls="example-collapse-text"
              aria-expanded={open2}
              className={
                switchCss2
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              .NET 核心技術&nbsp;
              <br className="brNoneXxs" />
              .NET 資料存取
            </button>
          </div>
          <Collapse in={open2}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  <p>
                    1. C# 物件導向程式語言
                    <br />
                    ─ C# (C Sharp) 是微軟為.NET
                    Framework量身訂做的程式語言，C#擁有C/C++的強大功能以及Visual
                    Basic簡易使用的特性
                    <br />─ C# 是快速建立高效能的企業和 Web
                    應用程式最有效的工具。整合的 Visual Database Tools 和
                    快速應用程式開發 (RAD)環境，可有效提高軟體開發的生產力
                  </p>
                  <br />
                  <p>
                    2. C# .NET 應用程式 與 .NET Framework 核心開發
                    <br />
                    ─ C# 視窗程式設計是使用者介面（user
                    interface）技術的展示層（presentation
                    layer）開發框架，廣泛用於 Windows平台的介面開發
                    <br />
                    ─ .NET Framework 架構的核心是提供與 .NET Framework
                    中的基本應用程式開發工作有關的資訊之連結
                    <br />─ 學習XAML Islands、WPF MVVM
                    用戶端應用程式及跨平台手機App開發
                  </p>
                  <br />
                  <p>
                    3. ADO.NET資料庫程式設計
                    <br />─ ADO.NET 是將資料存取服務公開至 .NET
                    程式設計人員的類別集合，提供關聯式、XML
                    及應用程式資料的存取。ADO.NET
                    支援各種應用程式開發需要及中介層商務物件
                  </p>
                  <br />
                  <p>
                    4. LINQ整合查詢語言
                    <br />─ Language-Integrated Query (LINQ) 是 Visual Studio
                    2010 中的一組功能，可將強大的查詢功能擴充至 C# 和 Visual
                    Basic 的語言語法。LINQ
                    引進了標準、易於學習的查詢與更新資料模式，以及可擴充以支援任何可能之資料存放區種類的技術
                  </p>
                  <br />
                  <p>
                    5. .NET元件開發
                    <br />─ 建立發展以元件為基礎的 三層 (N層)
                    分散式應用程式開發，徹底了解物件導向 - 繼承、
                    封裝、多型、介面、事件和委派
                  </p>
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen3()}
              aria-controls="example-collapse-text"
              aria-expanded={open3}
              className={
                switchCss3
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              網頁開發技術
            </button>
          </div>
          <Collapse in={open3}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  <p>
                    1. HTML5/CSS3 <br />─
                    目前最熱門的網頁開發技術，除了用來製作網頁內容外，還能透過網頁來繪圖、聽音樂以及看影片，也提供豐富的API讓開發人員可以開發出多元的網頁應用系統
                  </p>
                  <br />
                  <p>
                    2. JavaScript <br />─
                    製作動態網頁主要的程式語言，也是製作Ajax及HTML5網頁的主要技術
                  </p>
                  <br />
                  <p>
                    3. jQuery ajax <br />─
                    近幾年來最熱門的JavaScript函式庫，搭配jQuery
                    UI及Plug-in，可以快速的開發出，讓人眼睛為之一亮的網頁效果.
                    可輕易地完成AJAX網頁的開發，提供使用者流暢的網頁操作經驗
                  </p>
                  <br />
                  <p>
                    4. ASP.NET Core MVC <br />─
                    MVC是一種物件導向的設計模式，ASP.NET
                    MVC就是透過這樣的設計模式來開發良好結構的網頁應用程式
                  </p>
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen4()}
              aria-controls="example-collapse-text"
              aria-expanded={open4}
              className={
                switchCss4
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              智慧應用
            </button>
          </div>
          <Collapse in={open4}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  1. 根據市場的需求本班課程導入智慧應用: 如Microsoft Azure
                  雲端網站建置與開發、BOT
                  機器人客服、Azure認知服務與機器學習應用、商業智慧Power BI等。
                  <br />─ Windows Azure 是開放雲端平台且深具彈性，您可用以跨
                  Microsoft
                  管理的全球性資料中心網路，快速建置、部署及管理應用程式。
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header lesson-header-last">
            <button
              onClick={() => handleOpen5()}
              aria-controls="example-collapse-text"
              aria-expanded={open5}
              className={
                switchCss5
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              企業專題製作
            </button>
          </div>
          <Collapse in={open5}>
            <div id="example-collapse-text" className="lesson-body-last">
              <div className="lesson-content">
                <h6>
                  1.
                  結合企業/公司之實務專案需求，提供學員作為專題實作演練之素材，並由參加學員以小組團隊合作方式，結合上課所學技術，希冀達成縮短訓用落差，磨練學員所學技能與強化產業應用能力
                </h6>
              </div>
            </div>
          </Collapse>
        </div>
        {/* 展開詳細介紹 ↑↑↑　*/}
      </div>
      {/* 課程內容 */}

      {/* 心得分享 */}
      <div>
        <div className="content__title">
          <h3>心得分享</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>

        {/* 展開詳細介紹 ↓↓↓　*/}
        <div className="mt-4 pb-5">
          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen6()}
              aria-controls="example-collapse-text"
              aria-expanded={open6}
              className={
                switchCss6
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              林○謙 – 淡江大學 – 法國語文系
            </button>
          </div>
          <Collapse in={open6}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  <p>
                    推薦理由 :<br />
                    該同學學習力積極，有問題除了詢問上課老師外，更擅長課後延伸學習，原本同學就有前端相關基礎，來報名C#班級後更加強了後端技術，面對同學的技術詢問更不吝嗇與他分享，教別人的同時，也成為更有實力的人。
                  </p>
                  <br />
                  <p>
                    學習心得 :
                    <br />
                    目前在資訊公司擔任.NET
                    工程師，工作內容以全端開發為主，需要學習angular 和 C# .Net
                    的技術以及SQL的應用。投入職場後發現，會根據每家公司的不同。需要用上不同的技術。在課程中，我認為是建立基礎為主。實務應用跟課程學習一定會有差距。可以靠著自學補足缺少的技術。在參加課程之前，我已經自學近一年的時間，所以學習上不會遇到太多問題。我認為上課期間除了聽課外還要搭配自學，比較能夠適應整體的課程安排，也有時間製作自己的side
                    project。在後續求職中更加順利。
                  </p>
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen7()}
              aria-controls="example-collapse-text"
              aria-expanded={open7}
              className={
                switchCss7
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              陳○蘋 – 國立體育大學 – 體育推廣學系
            </button>
          </div>
          <Collapse in={open7}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  <p>
                    推薦理由 :
                    <br />
                    該同學因為過去未曾學習過程式撰寫、也未接觸資訊相關的工作，所以前來進行系統性學習。雖課堂學習上成效有限，但在提升資訊素養並與過往的專案管理能力融會貫通後，而且符合目前部門所需的背景經歷，足以推薦。
                  </p>
                  <br />
                  <p>
                    學習心得 :
                    <br />
                    目前於金融業數位金融部門PM一職，工作內容為專案管理，工作範疇包含優化對外平台、內部系統；官網CMS前端語法調整與權限管理；接收內部部門需求並作為資訊部同仁與乙方廠商洽談需求的橋樑，以促使專案順利運作。新工作薪資優渥，轉換職場領域後的獎金及福利優於過往，而在資展國際培養的資訊素養亦能夠讓我在目前的職場上有所發揮，未來也預計在現有的基礎上加強UI/UX/前端等技能，提高自身在數位金融領域的不可取代性。
                  </p>
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen8()}
              aria-controls="example-collapse-text"
              aria-expanded={open8}
              className={
                switchCss8
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              陳○瑋 – 師範大學 – 社會教育學系
            </button>
          </div>
          <Collapse in={open8}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  <p>
                    推薦理由 :<br />
                    該同學學習力極強，非常願意支援同學做不出來的功能，並加以教學，常常擔任整組甚至是跨組別的技術長，在最後專題實作方面更是把許多功能完善；對於履歷製作也更願意與其他同學分享製作方向，是班上大家認為的領導人，同時也支援很多班上的其他同學。
                  </p>
                  <br />
                  <p>
                    學習心得 :<br />
                    目前擔任後端工程師，薪資較轉職前增長將近50%，主要開發 Web
                    API ，工作內容也會接觸到資料庫管理 /
                    雲端服務管理；在資展國際參訓期間學習到的程式技能，著實為工作打下了良好基礎；而製作專題期間，所培養的問題分析與解決思維，亦能在工作上需要學習新技術時，發揮關鍵作用。
                  </p>
                </h6>
              </div>
            </div>
          </Collapse>
        </div>
        {/* 展開詳細介紹 ↑↑↑　*/}
      </div>
      {/* 心得分享 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">招生簡章</div>
          <ul className="classInfoList__styleNone">
            <li>
              <a
                href="/pdf/資展國際_智慧應用微軟C工程師就業養成班_招訓簡章.pdf"
                className="orange"
                target="_blank"
                download
              >
                簡章下載<i className="fas fa-download"></i>
              </a>
            </li>
          </ul>
        </div> */}
        <div className="pb-4">
          <div className="subT__div">招生對象</div>
          <ul className="classInfoList__styleNone">
            <li>
              對網路建置、維護與管理、網際網路應用程式開發，資料庫規劃設計等有興趣者
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">學習工具</div>
          <ul className="classInfoList__styleNone">
            <li>
              使用Microsoft .NET跨平台、跨裝置、跨語言解決方案
              (上課使用的所有軟體，皆提供免費的試用版)
            </li>
            <li>作業系統: Microsoft Windows 10</li>
            <li>開發工具: Microsoft Visual Studio</li>
            <li>開發平台: Microsoft .NET Framework / .NET Core平台</li>
            <li>開發語言: C# 程式語言</li>
            <li>開發架構: 元件、服務導向、雲端運算為基礎架構</li>
            <li>資料庫: Microsoft SQL Server</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>.NET 全方位工程師</li>
            <li>企業網站前後端應用程式建置與開發</li>
            <li>BI 商業智慧分析師</li>
            <li>認知服務整合網站工程師</li>
            <li>銷售點資訊管理系統應用開發</li>
            <li>資料存取及報表統計資料分析應用程式開發</li>
            <li>跨平台行動裝置應用開發</li>
            <li>MES工程師</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>3D遊戲美術設計人才養成班適合對象</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">熟悉電腦基本操作</h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對3D遊戲美術設計有強烈學習意願及興趣
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        美術或設計相關科系畢業者尤佳
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        男女不拘，但男性需役畢或免役
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        解3D遊戲業界專案設計流程
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        學習3D遊戲美術實務與專業遊戲技術應用，以Unity整合美術與互動設計
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        具備遊戲專題製作能力
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">SOHO接案</li>
                  <li className="longTerm_tabContent_li">獨立製作</li>
                  <li className="longTerm_tabContent_li">出國升學</li>
                  <li className="longTerm_tabContent_li">職場就業</li>
                  <li className="longTerm_tabContent_li">3D遊戲美術設計</li>
                  <li className="longTerm_tabContent_li">3D角色製作</li>
                  <li className="longTerm_tabContent_li">3D場景製作</li>
                  <li className="longTerm_tabContent_li">3D角色動作</li>
                  <li className="longTerm_tabContent_li">遊戲特效設計</li>
                  <li className="longTerm_tabContent_li">3D動畫設計</li>
                </ul>
              </div>
            </Tab> */}
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default MicrosoftDeveloperClass
