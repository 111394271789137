import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// python轉職班詳細內容
function PWOJTPT() {
  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      {/* <div className="classContent__figure">
        <img src="/img/employment/AI_head.png" alt="" />
      </div> */}
      <div className="txtC__div text-justify mt-0">
        <div className="employmentClassification-instruction_p">
          <p>
            AI 就是未來！藉由雲端運算(Cloud Computing)、邊緣運算(Edge Computing)
            這些強大的算力，並經由具備高速率、低延遲、大連結等特性的5G
            系統將這些大大小小的物聯網(IoT)裝置串聯在一起，物換星移下，我們已一步又一步地邁向前所未見的AI
            桃花源、美麗新世界。AI
            的璀璨光輝無遠弗屆，智慧生活將永無止盡。唯有搞懂人類的行為，才能滿足人們實際的需求；善用數位科技，實事求是，才能解決普羅大眾生活上的問題與痛點；AI
            生活化、生活AI
            化，才能將咱們國家的國力得以大幅提升、人民的福祉得以大幅躍進。
          </p>
          <br />
          <p>是的，毋庸置疑，『AI 就是你、我生活的未來！』</p>
        </div>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          由淺入深、技術扎根
        </h4>
        <p className="employmentClassification-instruction_p">
          根據 TIOBE
          程式語言的排行榜，自從人工智慧的應用興起，Python已經盤據最熱門的程式語言一段時間了，對於初學者來說它有簡單易學的特性，想要進入軟體開發相關的領域從
          Python
          入門比較不會有挫折感，Python的應用也非常的全面，從測試自動化、遊戲開發、網頁開發、資料分析、區塊鍊到人工智慧等，都可以透過Python找到解決方案。因此本課程主要就是以
          Python 為核心，學習 Python 的相關知識與應用，從基礎的 Python
          語言教起，學習網頁開發的相關應用技術，再循序漸進的學習資料分析、機器學習、深度學習等引導學員進入人工智慧的奇幻旅程。專題的部分會結合網頁及AI應用，開發出能夠自我學習、推理、解決問題的應用系統，並透過網頁讓使用者可以使用。
          <br />
          學員只要透過本班精心規劃的課程按部就班地學習，再經由期末專題的實作加以整合，以此來證明自己的技術能力，如果能夠再結合原有的專長，一定能夠創造個人獨特的專業價值，打造夢寐以求的未來。
        </p>
      </div>

      {/* 課程介紹 */}
      <div>
        <div className="pb-4">
          <h6>
            <strong>【課程目標】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              本訓練目標為以 Python
              為主訓練扎實的程式能力，學習網頁開發應用的相關理論基礎與實作能力，進而學習人工智慧的相關知識。
            </li>
            <li>
              1）培養學員能夠運用前端 HTML、CSS 及 JavaScript技術，結合後端
              Python Web Framework 的框架，製作出網頁相關的應用程式。
            </li>
            <li>
              2）培養學員能夠運用資料分析、影像辨識、機器學習與深度學習等創新應用
            </li>
            <li>
              3）培養學員能夠運用TensorFlow(含Keras)深度學習框架來建構深度學習的模型
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              從零開始！
              本班從Python程式設計、JavaScript語法入門開始，一路引領您實作到AI相關的應用、機器學習專案的開發
            </li>
            <li>
              專題導向！
              透過專題小組的團隊合作以及大、小專題的歷練，引導您完成期末專題並上台進行成果發表
            </li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure pb-3">
          <img src="/img/careers/python_PWOJTPT_Taipei.png?240112" alt="" />
        </div>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              周間晚上
              (線上或實體)，六日一天上課(實體7小時)，實際情況將會因配合講師安排為主
            </li>
          </ul>
        </div>
      </div>
      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>適合對象</h4>
                <div className="longTermTabC__div">
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對於網站開發有興趣者
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對於運用Python語言進行資料分析、機器學習、深度學習等技術應用有興趣者
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對於網站前後端整合開發應用、資料科學等工作職務嚮往者
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        製作出網頁相關的應用程式
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        運用資料分析、影像辨識、機器學習與深度學習等創新應用
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        運用TensorFlow(含Keras)深度學習框架來建構深度學習的模型
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">
                    Python 應用開發工程師
                  </li>
                  <li className="longTerm_tabContent_li">網站應用開發工程師</li>
                  <li className="longTerm_tabContent_li">
                    Python AI應用工程師
                  </li>
                  <li className="longTerm_tabContent_li">
                    Python 機器學習工程師
                  </li>
                  <li className="longTerm_tabContent_li">Python 資料分析師</li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default PWOJTPT
