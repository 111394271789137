import React from 'react'
import UseApplyMeeting from './UseApplymeeting'

// 確認報名說明會視窗
function AttenderMessage(props) {
  const {
    attenderCheck,
    setAttenderCheck,
    sendAttenderSubmit,
    setSendAttenderSubmit,
    error,
  } = props

  const { source, setSource, sourceOption } = UseApplyMeeting()

  return (
    <>
      {/* 提示視窗 */}
      <div className="discountMessage-content">
        <div className="message-content">
          <button
            type="button"
            className="customClose ml-auto"
            aria-label="Close"
            onClick={() => setAttenderCheck(!attenderCheck)}
          >
            <div className="close-border">
              <i className="fas fa-times message-close" aria-hidden="true"></i>
            </div>
          </button>
          <div className="discountMessage-inner_div">
            <h4>確定要送出報名嗎 ?</h4>
            <figure>
              <img src="/img/inquireAlert.png" alt="" />
            </figure>
            <h6 className="red">
              送出後即報名完成，您將會收到系統寄出的報名成功信件。
            </h6>
            <br />
            <p>
              您是如何得知本課程的 ?&nbsp;
              {error && <span className="red">{error}</span>}
            </p>
            <div>
              {sourceOption.map((item, i) => {
                return (
                  <>
                    <div key={i} className="checkboxC__div">
                      <input
                        type="checkbox"
                        value={item.value}
                        defaultChecked={false}
                        onChange={(e) => {
                          const key = e.target.value
                          setSource({ ...source, [key]: !source[key] })
                        }}
                        id={item.text}
                      />
                      <h6 className="pl-3">
                        <label htmlFor={item.text}>{item.text}</label>
                      </h6>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div className="swal2-actions">
            <button
              type="button"
              className="swal2-confirm"
              onClick={() => setSendAttenderSubmit(!sendAttenderSubmit)}
            >
              送出
            </button>
            <button
              type="button"
              className="swal2-cancel"
              onClick={() => setAttenderCheck(!attenderCheck)}
            >
              取消
            </button>
          </div>
        </div>
      </div>
      {/* 提示視窗 */}
    </>
  )
}

export default AttenderMessage
