import { useState } from 'react'

const UseNavbar = () => {
  // dropdown(關於我們)預設為不顯示, hover出現
  const [dropdownAboutUs, setDropdownAboutUs] = useState(false)

  // dropdown(在職班)預設為不顯示, hover出現
  const [dropdownOjt, setDropDownOjt] = useState(false)

  // dropdown(養成班)預設為不顯示, hover出現
  const [dropdownLongTerm, setDropdownLongTerm] = useState(false)

  // dropdown(學員服務)預設為不顯示, hover出現
  const [dropdownMateService, setDropdownMateService] = useState(false)

  // dropdown(見證分享)預設為不顯示, hover出現
  const [dropdownShare, setDropdownShare] = useState(false)

  // dropdown(企業服務)預設為不顯示, hover出現
  const [dropdownEnterprise, setDropdownEnterprise] = useState(false)

  // dropdown(最新活動)預設為不顯示, hover出現
  const [dropdownActivity, setDropdownActivity] = useState(false)

  // 關於我們漢堡模式展開預設
  const [switchAboutUsCss, setSwitchAboutUsCss] = useState(false)
  const [aboutUsOpen, setAboutUsOpen] = useState(false)

  // 就業養成班漢堡模式展開預設
  const [switchLongTermCss, setSwitchLongTermCss] = useState(false)
  const [LongTermOpen, setLongTermOpen] = useState(false)

  // 在職進修班漢堡模式展開預設
  const [switchCssOjt, setSwitchCssOjt] = useState(false)
  const [openOjt, setOpenOjt] = useState(false)

  // 學員服務漢堡模式展開預設
  const [switchMateServiceCss, setSwitchMateServiceCss] = useState(false)
  const [mateServiceOpen, setMateServiceOpen] = useState(false)

  // 見證分享漢堡模式展開預設
  const [switchShareCss, setSwitchShareCss] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)

  // 企業服務漢堡模式展開預設
  const [switchEnterpriseCss, setSwitchEnterpriseCss] = useState(false)
  const [EnterpriseOpen, setEnterpriseOpen] = useState(false)

  // 最新活動漢堡模式展開預設
  const [switchActivityCss, setSwitchActivityCss] = useState(false)
  const [ActivityOpen, setActivityOpen] = useState(false)

  // 游標滑入關於我們, 出現dropdown
  const handleMouseEnterAboutUs = () => {
    if (window.innerWidth < 992) {
      setDropdownAboutUs(false)
    } else {
      setDropdownAboutUs(true)
    }
  }

  // 游標離開關於我們, dropdown消失
  const handleMouseLeaveAboutUs = () => {
    if (window.innerWidth < 992) {
      setDropdownAboutUs(false)
    } else {
      setDropdownAboutUs(false)
    }
  }

  // 游標滑入就業養成班, 出現dropdown
  const handleMouseEnterLongterm = () => {
    if (window.innerWidth < 992) {
      setDropdownLongTerm(false)
    } else {
      setDropdownLongTerm(true)
    }
  }

  // 游標離開就業養成班, dropdown消失
  const handleMouseLeaveLongterm = () => {
    if (window.innerWidth < 992) {
      setDropdownLongTerm(false)
    } else {
      setDropdownLongTerm(false)
    }
  }

  // 游標滑入在職進修班, 出現dropdown
  const onMouseEnterOjt = () => {
    if (window.innerWidth < 992) {
      setDropDownOjt(false)
    } else {
      setDropDownOjt(true)
    }
  }
  // 游標離開在職進修班, dropdown消失
  const onMouseLeaveOjt = () => {
    if (window.innerWidth < 992) {
      setDropDownOjt(false)
    } else {
      setDropDownOjt(false)
    }
  }

  // 游標滑入學員服務, 出現dropdown
  const handleMouseEnterMateService = () => {
    if (window.innerWidth < 992) {
      setDropdownMateService(false)
    } else {
      setDropdownMateService(true)
    }
  }

  // 游標離開學員服務, dropdown消失
  const handleMouseLeaveMateService = () => {
    if (window.innerWidth < 992) {
      setDropdownMateService(false)
    } else {
      setDropdownMateService(false)
    }
  }

  // 游標滑入見證分享, 出現dropdown
  const handleMouseEnterShare = () => {
    if (window.innerWidth < 992) {
      setDropdownShare(false)
    } else {
      setDropdownShare(true)
    }
  }

  // 游標離開見證分享, dropdown消失
  const handleMouseLeaveShare = () => {
    if (window.innerWidth < 992) {
      setDropdownShare(false)
    } else {
      setDropdownShare(false)
    }
  }

  // 游標滑入企業服務, 出現dropdown
  const handleMouseEnterEnterprise = () => {
    if (window.innerWidth < 992) {
      setDropdownEnterprise(false)
    } else {
      setDropdownEnterprise(true)
    }
  }

  // 游標離開企業服務, dropdown消失
  const handleMouseLeaveEnterprise = () => {
    if (window.innerWidth < 992) {
      setDropdownEnterprise(false)
    } else {
      setDropdownEnterprise(false)
    }
  }

  // 游標滑入最新活動, 出現dropdown
  const handleMouseEnterActivity = () => {
    if (window.innerWidth < 992) {
      setDropdownActivity(false)
    } else {
      setDropdownActivity(true)
    }
  }

  // 游標離開最新活動, dropdown消失
  const handleMouseLeaveActivity = () => {
    if (window.innerWidth < 992) {
      setDropdownActivity(false)
    } else {
      setDropdownActivity(false)
    }
  }

  // <992 關於我們展開
  const handleAboutUsOpen = () => {
    setSwitchAboutUsCss(!switchAboutUsCss)
    setAboutUsOpen(!aboutUsOpen)
  }

  // <992 就業養成班展開
  const handleLongTermOpen = () => {
    setSwitchLongTermCss(!switchLongTermCss)
    setLongTermOpen(!LongTermOpen)
  }

  // <992 在職進修班展開
  const handleOpenOjt = () => {
    setSwitchCssOjt(!switchCssOjt)
    setOpenOjt(!openOjt)
  }

  // <992 學員服務展開
  const handleMateServiceOpen = () => {
    setSwitchMateServiceCss(!switchMateServiceCss)
    setMateServiceOpen(!mateServiceOpen)
  }
  // <992 見證分享展開
  const handleShareOpen = () => {
    setSwitchShareCss(!switchShareCss)
    setShareOpen(!shareOpen)
  }

  // <992 企業服務展開
  const handleEnterpriseOpen = () => {
    setSwitchEnterpriseCss(!switchEnterpriseCss)
    setEnterpriseOpen(!EnterpriseOpen)
  }

  // <992 最新活動展開
  const handleActivityOpen = () => {
    setSwitchActivityCss(!switchActivityCss)
    setActivityOpen(!ActivityOpen)
  }

  return {
    dropdownAboutUs,
    dropdownOjt,
    dropdownLongTerm,
    dropdownMateService,
    dropdownShare,
    dropdownEnterprise,
    dropdownActivity,
    switchAboutUsCss,
    aboutUsOpen,
    switchLongTermCss,
    LongTermOpen,
    switchCssOjt,
    openOjt,
    switchMateServiceCss,
    mateServiceOpen,
    switchShareCss,
    shareOpen,
    switchEnterpriseCss,
    EnterpriseOpen,
    switchActivityCss,
    ActivityOpen,
    handleMouseEnterAboutUs,
    handleMouseLeaveAboutUs,
    handleMouseEnterLongterm,
    handleMouseLeaveLongterm,
    onMouseEnterOjt,
    onMouseLeaveOjt,
    handleMouseEnterMateService,
    handleMouseLeaveMateService,
    handleMouseEnterShare,
    handleMouseLeaveShare,
    handleMouseEnterEnterprise,
    handleMouseLeaveEnterprise,
    handleMouseEnterActivity,
    handleMouseLeaveActivity,
    handleAboutUsOpen,
    handleLongTermOpen,
    handleOpenOjt,
    handleMateServiceOpen,
    handleShareOpen,
    handleEnterpriseOpen,
    handleActivityOpen,
  }
}

export default UseNavbar
