import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// 前端轉職班班詳細內容
function PWOJTFT() {
  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  // 課程內容資訊打開與隱藏狀態
  const [switchCss1, setSwitchCss1] = useState(false)
  const [switchCss2, setSwitchCss2] = useState(false)
  const [switchCss3, setSwitchCss3] = useState(false)
  const [switchCss4, setSwitchCss4] = useState(false)

  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)

  // 展開詳細內容狀態
  const handleOpen1 = () => {
    setSwitchCss1(!switchCss1)
    setOpen1(!open1)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
  }

  const handleOpen2 = () => {
    setSwitchCss2(!switchCss2)
    setOpen2(!open2)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
  }

  const handleOpen3 = () => {
    setSwitchCss3(!switchCss3)
    setOpen3(!open3)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss4(false)
    setOpen4(false)
  }

  const handleOpen4 = () => {
    setSwitchCss4(!switchCss4)
    setOpen4(!open4)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
  }

  return (
    <>
      {/* 課程介紹 */}
      <div>
        {/* <div className="classContent__figure">
          <img src="/img/employment/Front-End Developer_head02.png" alt="" />
        </div> */}
        {/* <div className="pb-4">
          <div>
            【課程目標】
          </div>
          <ul className="listStyle__ul">
            <li>只要學員吸收良好，就能達到就業所需職能養成</li>
            <li>能獨立完成設計個人網站以及與小組協同完成電商網站</li>
            <li>能夠掌握網站從UX規劃到網站製作，以及前端與後端技術開發流程</li>
          </ul>
        </div> */}
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>業界講師授課，扎實的前端訓練，不參雜非相關技術學習</li>
            <li>
              能夠學習到網頁視覺排版、UX網站規劃設計能力、JavaScript前端開發能力
            </li>
            <li>最後學習專題作品完成度百分百，就業沒煩惱</li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure">
          <img src="/img/careers/FE_PWOJTFT_Taipei.png?240112" alt="" />
        </div>

        {/* 展開詳細介紹 ↓↓↓　*/}
        <div className="mt-4 pb-5">
          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen2()}
              aria-controls="example-collapse-text"
              aria-expanded={open2}
              className={
                switchCss2
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              HTML 與 CSS 網頁技術
            </button>
          </div>
          <Collapse in={open2}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  從基礎的 HMTL 語意標籤規劃，到 CSS Layout 撰寫，或是使用 CSS
                  Framework 技術，讓你擁有自己手寫與使用框架撰寫 RWD
                  網頁的全能力，複雜網頁切版也能完成
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header lesson-header-last">
            <button
              onClick={() => handleOpen3()}
              aria-controls="example-collapse-text"
              aria-expanded={open3}
              className={
                switchCss3
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              JavaScript 技術
            </button>
          </div>
          <Collapse in={open3}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  課程會由 JavaScript 語法基礎開始，透過 Ajax 串接
                  API，後續並會直接教導 JS Framework
                  操作與使用，專題製作更以框架開發為主，能真正落實學習後的實作應用
                </h6>
              </div>
            </div>
          </Collapse>
        </div>
        {/* 展開詳細介紹 ↑↑↑　*/}
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>UI/UX規劃</li>
            <li>網站規劃設計</li>
            <li>前端工程師</li>
            <li>網頁工程師</li>
            <li>UX規劃師</li>
          </ul>
        </div> */}
        <div className="pb-4">
          <div className="subT__div">結訓學員就業產業資訊</div>
          <ul className="classInfoList__styleNone">
            <li>鴻海富士康 - UI/UX(簡XX), 前端工程師(陳XX)</li>
            <li>ASUS華碩 - Senior Web Engineer(孔xx)</li>
            <li>廣達電腦 - UI/UX Designer(江xx), Web Engineer (胡xx)</li>
            <li>泰金寶集團 視覺設計UIUX(簡xx, 洪xx)</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              周間晚上
              (線上或實體)，六日一天上課(實體7小時)，實際情況將會因配合講師安排為主
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>適合對象</h4>
                <div className="longTermTabC__div">
                  {/* <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure> */}
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        想要加值程式能力的平面設計師
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        想要轉職成為前端工程師者
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對於網頁開發技術有興趣者
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        只要學員吸收良好，就能達到就業所需職能養成
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        能獨立完成設計個人網站以及與小組協同完成電商網站
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        能夠掌握網站從UX規劃到網站製作，以及前端與後端API串接技術
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">UI/UX規劃</li>
                  <li className="longTerm_tabContent_li">網站規劃設計</li>
                  <li className="longTerm_tabContent_li">前端工程師</li>
                  <li className="longTerm_tabContent_li">網頁工程師</li>
                  <li className="longTerm_tabContent_li">UX規劃師</li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default PWOJTFT
