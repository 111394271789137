import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import { OnJobClassTypeInfo } from '../../services/OnJob'

// 在職班課程內容介紹
function ClassDetailInstruction(props) {
  const { classesName, classTypeName, classInfo, classTypeContent } = props
  // 展開詳細內容狀態
  const [closed, setClosed] = useState(true)

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  const [classType, setClassType] = useState('')

  // const videoRef = useRef(null)

  let { id } = useParams() // destructure id  MSA411

  // 將課程內容html裝入classType
  useEffect(() => {
    const data = OnJobClassTypeInfo(id)

    data
      .then((classInfo) => {
        return classInfo.text()
      })
      .then((classInfo) => {
        setClassType(classInfo)
      })
  }, [id])

  // 卸載時還原載入狀態
  useEffect(() => {
    return () => {
      setClassType()
    }
  }, [setClassType])

  // useEffect(() => {
  //   const video = document.querySelector('video')
  //   console.log(video)

  //   if (video) {
  //     video.play() // 自动播放视频
  //     video.muted = false // 开启声音
  //   }
  // }, [])

  return (
    <>
      <div className="content__div--mt">
        <div className="bc__div--light">
          <div className="custom-container">
            <div className="bcBlockInner__div" id="classIntroduce">
              <h3 className="black">課程介紹</h3>
              <div className="lesson-ditailed_inner">
                <div
                  className={
                    closed
                      ? 'lesson-ditailed_header onJob-instruction_content is-opened'
                      : 'lesson-ditailed_header onJob-instruction_content'
                  }
                >
                  <div
                    className="lesson-header_content"
                    onClick={() => setClosed(!closed)}
                  >
                    <div className="lesson-header_a">
                      <div className="lesson-header_title">
                        {/* 如果有開課, 就會帶出第一門課的課名, 若沒有就會抓取classType課名 */}
                        {/* Google Cloud、網路行銷規劃師這兩門課規則和別門課不一樣 */}
                        {classesName
                          ? classesName ===
                            '網路行銷規劃師-網路市場分析與數位行銷規劃'
                            ? '網路行銷規劃師三部曲'
                            : classesName ===
                              'Google Cloud基礎知識之核心基礎架構'
                            ? 'Google Cloud 原廠系列課程'
                            : classesName ===
                              '使用Google Kubernetes Engine做架構設計'
                            ? 'Google Cloud 原廠系列課程'
                            : classesName ===
                              '使用Google Compute Engine做架構設計'
                            ? 'Google Cloud 原廠系列課程'
                            : classesName ===
                              '使用Google Cloud做架構設計：設計與過程'
                            ? 'Google Cloud 原廠系列課程'
                            : classesName ===
                              '使用Google Cloud Platform做資料工程'
                            ? 'Google Cloud 原廠系列課程'
                            : classesName === '使用 Google Cloud 從數據到洞察'
                            ? 'Google Cloud 原廠系列課程'
                            : classesName
                          : classTypeName ===
                            '網路行銷規劃師-網路商圈市場需求分析及網站動線規劃策略'
                          ? '網路行銷規劃師三部曲'
                          : classTypeName ===
                            '使用Google Cloud做架構設計：設計與過程'
                          ? 'Google Cloud 原廠系列課程'
                          : classTypeName}
                      </div>
                      <div className="expandIcon__div--circle">
                        <span className="expandIcon__span--allow"></span>
                        <span className="expandIcon__span--line"></span>
                        <span className="expandIcon__span--line"></span>
                      </div>
                    </div>
                  </div>
                  {/* 如果沒有建立edm, 並且是有開課的, 就會捲系統資料 */}
                  {classType.substring(2, 6) === 'type' ? (
                    <div
                      className={
                        closed
                          ? 'classContent__container'
                          : 'classContent__container__off'
                      }
                    >
                      <div className="classContent__padding">
                        <nav
                          id="controlled-tab-example"
                          activeey={key}
                          onSelect={(k) => setKey(k)}
                        >
                          <Tabs defaultActiveKey="content">
                            <Tab
                              eventKey="relevantInfo"
                              title="預備知識"
                              // dangerouslySetInnerHTML={createMarkup(classType.note)}
                            >
                              {classesName === false ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    // __html: classInfo['prerequisite'],
                                    __html: classTypeContent['prerequisite'], // 沒edm也沒有開課抓type的介紹
                                  }}
                                ></div>
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: classInfo['prerequisite'],
                                    // __html: classTypeContent['prerequisite'], // 沒edm也沒有開課抓type的介紹
                                  }}
                                ></div>
                              )}
                            </Tab>
                            <Tab
                              eventKey="object"
                              title="課程適合對象"
                              // dangerouslySetInnerHTML={createMarkup(classType.audience)}
                            >
                              {classesName === false ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    // __html: classInfo['audiences'],
                                    __html: classTypeContent['audiences'],
                                  }}
                                ></div>
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: classInfo['audiences'],
                                    // __html: classTypeContent['audiences'],
                                  }}
                                ></div>
                              )}
                            </Tab>
                            <Tab
                              eventKey="content"
                              title="課程內容"
                              // dangerouslySetInnerHTML={createMarkup(classType.abstract)}
                            >
                              {classesName === false ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    // __html: classInfo['abstract'],
                                    __html: classTypeContent['abstract'],
                                  }}
                                ></div>
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: classInfo['abstract'],
                                    // __html: classTypeContent['abstract'],
                                  }}
                                ></div>
                              )}
                            </Tab>
                            <Tab
                              eventKey="target"
                              title="課程目標"
                              // dangerouslySetInnerHTML={createMarkup(classType.objective)}
                            >
                              {classesName === false ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    // __html: classInfo['objective'],
                                    __html: classTypeContent['objective'],
                                  }}
                                ></div>
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: classInfo['objective'],
                                    // __html: classTypeContent['objective'],
                                  }}
                                ></div>
                              )}
                            </Tab>
                          </Tabs>
                        </nav>
                        <div className="red customMarginBottom50">
                          <h6>
                            ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
                          </h6>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        closed
                          ? 'classContent__container'
                          : 'classContent__container__off'
                      }
                    >
                      <div className="classContent__padding">
                        <div
                          className="json-content ojt-content"
                          dangerouslySetInnerHTML={{
                            __html: classType,
                          }}
                        ></div>
                        <div className="red customMarginBottom50">
                          {/* <h6>
                            ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
                          </h6> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClassDetailInstruction
