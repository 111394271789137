import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// 誠徵外聘講師視窗
function ActivityMessage() {
  // 預設活動視窗為顯示
  const [display, setDisplay] = useState(true)

  return (
    <>
      <div
        className={display ? 'activity-content' : 'd-none'}
        onClick={() => setDisplay(!display)}
      >
        <div className="activity-content_inner">
          <button
            type="button"
            className="customClose ml-auto activity-close_tr"
            aria-label="Close"
            onClick={() => setDisplay(!display)}
          >
            <div className="close-border activity-btn">
              <i className="fas fa-times activity-close" aria-hidden="true"></i>
            </div>
          </button>
          <Link to="/weneedyou">
            <div className="activity-content_img">
              <img
                src="/img/recruitment_bccSM.png"
                alt="資展國際誠徵外聘講師、正職導師"
                className="activity-content_img_lg"
              />
              <img
                src="/img/recruitment_bccSM.png"
                alt="資展國際誠徵外聘講師、正職導師"
                className="activity-content_img_sm"
              />
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default ActivityMessage
