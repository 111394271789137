import React from 'react'
import { Link } from 'react-router-dom'

// 課程探索的細項分類頁面
function LongTermExploreItems(props) {
  const { handleClick, setOptionTab, setOptionTypeTab } = props
  // 課程探索選項預設是消失狀態, 點擊每個分類之後到達分頁呈現消失
  // const [click, setClick] = useState(false)

  return (
    <>
      <div className="dropdown_subClassification_div">
        <div className="dropdown_subClassificationContent_div">
          <div className="classificationTheme h0">
            <div className="dropdown_subClassificationTitle_div">
              <h3>課程探索</h3>
            </div>
          </div>
          <div className="classificationTheme classificationTheme_subContent">
            <ul>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/longterm"
                  onClick={() => {
                    handleClick()
                    setOptionTab(3)
                    setOptionTypeTab(1)
                  }}
                >
                  人工智慧分類
                </Link>
              </li>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/longterm"
                  onClick={() => {
                    handleClick()
                    setOptionTab(3)
                    setOptionTypeTab(2)
                  }}
                >
                  雲端技術分類
                </Link>
              </li>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/longterm"
                  onClick={() => {
                    handleClick()
                    setOptionTab(3)
                    setOptionTypeTab(3)
                  }}
                >
                  程式設計分類
                </Link>
              </li>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/longterm"
                  onClick={() => {
                    handleClick()
                    setOptionTab(3)
                    setOptionTypeTab(4)
                  }}
                >
                  數位內容分類
                </Link>
              </li>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/longterm"
                  onClick={() => {
                    handleClick()
                    setOptionTab(3)
                    setOptionTypeTab(5)
                  }}
                >
                  軟韌體系統分類
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default LongTermExploreItems
