// get 該學員上過的養成班代號
import { apiUrl } from '../config/path'

const url = apiUrl + '/api/NewOrders/'

const LongTermDiscount = async (customerId) => {
  const longTermUrl = url + `LongTermClass?CustomerID=${customerId}`
  return await fetch(longTermUrl).then((data) => data.text())
}

export default LongTermDiscount
