import React, { useState, useEffect } from 'react'
import { Redirect, useParams, useLocation } from 'react-router-dom'
import moment from 'moment' // 格式化日期
import currency from 'currency.js' // 貨幣轉換
import { OnJobSingleClasses } from '../../services/OnJob'
import UseTrainingSignup from '../TrainingSignup/UseTrainingSignup'

// 在職班開班課程List
function TrainingClassesListForSeries() {
  const { swalWithBootstrapButtons, getUser } = UseTrainingSignup()

  // 沒登入轉址
  // eslint-disable-next-line no-unused-vars
  const [redirectTo, setRedirectTo] = useState('')

  // 裝得到的開課班級資料
  const [classes, setClasses] = useState([])

  // 裝替換的網址代號
  const [replaceUrl, setReplaceUrl] = useState('')

  // 貨幣轉換
  const NTD = (value) => currency(value, { precision: 0, symbol: 'NT$' })

  let { id } = useParams() // destructure id  PMP
  let { url } = useParams() // destructure id  PMP

  // 處理轉換id對應的縮寫代號
  const handleUrl = () => {
    switch (id || url) {
      case 'GCPFKCI':
        return setReplaceUrl('MSA502')
      case 'GCPAGKE':
        return setReplaceUrl('MSA503')
      case 'GCPAGCE':
        return setReplaceUrl('MSA504')
      case 'GCPADP':
        return setReplaceUrl('MSA488')
      case 'GCPDE':
        return setReplaceUrl('MSA489')
      case 'GCPFDI':
        return setReplaceUrl('MSA490')
      default:
        return id
    }
  }

  // 處理接開課的api
  // api/Classes/short/MSA411
  const handleClasses = () => {
    if (replaceUrl !== '') {
      const data = OnJobSingleClasses(replaceUrl)
      data.then((classes) => {
        setClasses(classes)
      })
    }
  }

  // 當前路由
  const ifo = useLocation()

  // 點擊立即報名
  const handleSignupClass = (classID, startDate, endDate, tuitionDiscount) => {
    // e.preventDefault()
    const data = JSON.parse(localStorage.getItem('SigninMessage'))

    // 有登入就進入報名流程
    if (data && data.code === 200) {
      setRedirectTo('trainingClassSignup')
      localStorage.setItem(
        'TrainingSignup',
        JSON.stringify({
          classID: classID,
          customerID: getUser().customerId,
          startDate: startDate,
          endDate: endDate,
          tuitionDiscount: tuitionDiscount,
          fromUrl: ifo.pathname,
        })
      )
    } else {
      swalWithBootstrapButtons
        .fire({
          title: '尚未登入',
          html: '<figure><img src="/img/questionAlert.png" alt="" /></figure>請先登入會員，才能報名在職班課程。',
          padding: '60px',
          showConfirmButton: true,
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: '登入',
          cancelButtonText: '取消',
          denyButtonText: '',
          backdrop: `rgba(0,0,0,.6)`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            setRedirectTo('signin')
          }
          // 暫存課程代號
          localStorage.setItem(
            'TrainingTemplate',
            JSON.stringify({
              class: id || url,
            })
          )
        })
    }
  }

  // 執行id代號轉換
  useEffect(() => {
    handleUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, url])

  // 拿開課資料
  useEffect(() => {
    handleClasses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replaceUrl])

  // 卸載時還原載入狀態
  useEffect(() => {
    return () => {
      setClasses()
    }
  }, [setClasses])

  return (
    <>
      {/* 不同的情況下改變轉址 */}
      {redirectTo === 'signin' && <Redirect to="/signin" />}
      {redirectTo === 'trainingClassSignup' && (
        <Redirect to="/trainingClassSignup" />
      )}
      <div className="seriesClass_classesContent_div">
        <div className="seriesClass_classes_div">
          {classes.length === 0 ? (
            <div className="seriesClass_noClass_div">尚無開課</div>
          ) : (
            <>
              {classes.map((v, i) => {
                return (
                  <div className="seriesClass_class_div">
                    <div className="seriesClass_classInfo_div">
                      <p className="seriesClass_classDirection_p">上課期間</p>
                      <p className="seriesClass_classExplain_p">
                        {moment(v.startDate).format('YYYY/MM/DD') +
                          '-' +
                          moment(v.endDate).format('YYYY/MM/DD')}
                        {/* 2021/12/16-2021/12/17 */}
                      </p>
                      <p className="seriesClass_classExplain_p">
                        {v.date} ({v.timesType}){/* 四、五 (白天) */}
                      </p>
                    </div>

                    <div className="seriesClass_classMark_div">
                      <span>總時數 {v.length} hr</span>
                      {/* <span>總時數 16 hr</span> */}
                    </div>

                    {v.address.includes('線上') ? (
                      <div className="seriesClass_classMark_div">
                        <span>線上直播</span>
                      </div>
                    ) : (
                      <div className="seriesClass_classMark_div">
                        <span>實體課程</span>
                      </div>
                    )}
                    {/* <div className="seriesClass_classMark_div">
              <span>線上直播</span>
            </div> */}

                    <div className="seriesClass_classInfo_div">
                      <p className="seriesClass_classDirection_p">上課地點</p>
                      <p className="seriesClass_classExplain_p">{v.address}</p>
                      {/* <p className="seriesClass_classExplain_p">
                台北市復興南路一段390號2樓
              </p> */}
                      <a
                        href="https://goo.gl/maps/Nk2ns8TT7s6WpqQ98"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="seriesClass_map_div">
                          <span>Map</span>
                        </div>
                      </a>
                    </div>

                    <div className="seriesClass_priceContent_div">
                      <div>
                        <p className="seriesClass_classDirection_p">原價</p>
                        <h5>{NTD(v.tuition).format()}</h5>
                      </div>
                      <div className="orange">
                        <p className="seriesClass_classDirection_p">優惠價</p>
                        <h5>{NTD(v.tuitionDiscount).format()}</h5>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="seriesClass_button"
                      onClick={() =>
                        handleSignupClass(
                          v.classID,
                          v.startDate,
                          v.endDate,
                          v.tuitionDiscount
                        )
                      }
                    >
                      立即報名
                    </button>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default TrainingClassesListForSeries
