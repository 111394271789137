export const debug = true

// 測試開發/營運網址
export const devUrl = 'http://localhost:3000'

// production
// export const apiUrl = 'https://192.168.20.122/iSpan'
// export const apiUrl = 'https://www.ispan.com.tw/iSpan'
// export const apiUrlTest = 'https://www.ispan.com.tw:442'
export const apiUrl = 'https://w3.ispan.com.tw'

// development
//export const apiUrl = 'https://localhost:44376'
//export const apiUrl = 'http://localhost:8080'

const currentUrl = window.location.href.slice(-11)

// 尚未處理就業養成班分類 > 班種 麵包屑0419
export const pathnameList = [
  // 就業養成班
  '/longterm',
  // 就業養成班 - 依班別探索
  '/longterm/classtype',
  // 就業養成班 - 依地區探索
  '/longterm/regiontype',
  // 就業養成班 - 補助計畫課程
  '/longterm/subsidyclass',
  // 就業養成班 - 所有養成班
  '/longterm/alllongterm',
  // 就業養成班 => 前端工程師就業養成班
  '/longterm/JPMFEET',
  // 就業養成班 => 智慧應用微軟C#工程師就業養成班
  '/longterm/JMMSITT',
  // 就業養成班 => 跨域Java軟體工程師就業養成班
  '/longterm/JJEEITT',
  // 就業養成班 => 全端工程師就業養成班
  '/longterm/JMFUENT',
  // 就業養成班 => 雲端網路與資安工程師就業養成班
  '/longterm/JNSESET',
  // 就業養成班 => Big Data 巨量資料分析就業養成班
  '/longterm/JJBDSET',
  // 就業養成班 => AI繪圖與3D美術人才養成班
  '/longterm/JAMTDCT',
  // 就業養成班 => iOS App與Mac應用軟體開發養成班
  '/longterm/JEMMPDT',
  // 就業養成班 => AIoT智慧機器人就業養成班
  '/longterm/JJAIENT',
  // 就業養成班 => AI 與深度學習應用工程師就業養成班
  '/longterm/JEAIOTT',
  // 就業養成班 => AIoT智慧機器人就業養成班
  '/longterm/JEAIMLT',
  // 就業養成班 => 嵌入式系統與韌體技術就業養成班
  '/longterm/JEMEMET',
  // 就業養成班 => UI/UX創新策略網頁設計就業養成班
  '/longterm/JWUIUXT',
  // 就業養成班 => Unity跨平臺遊戲開發工程師養成班
  '/longterm/JPUMVRT',
  // 就業養成班 => 全方位IT工程師就業養成班
  '/longterm/JWITENT',
  // 就業養成班 => 前後端整合應用工程師
  '/longterm/JPFBEET',
  // 就業養成班 => 5G+AI跨域智慧天線設計工程師就業養成班
  '/longterm/JN5GAIT',
  // 就業養成班 => AI雲端web應用系統開發實作精修班
  '/longterm/PMAIAPT',
  // 就業養成班 => 創新應用全端網站開發工程師養成班
  '/longterm/JPPHPDT',
  // 就業養成班 => BIOS 韌體設計工程實務養成班
  '/longterm/JEBIOST',
  // 就業養成班 => 網路與系統工程師就業養成班
  '/longterm/JNNWSET',
  // 就業養成班 => 軟體測試工程師養成訓練班
  '/longterm/JMSTQAT',
  // 就業養成班 => 智慧製造軟體測試工程師養成班(工研院新尖兵計劃)
  '/longterm/JMSMQAT',
  // 就業養成班 => 韌體工程師專業班
  '/longterm/JEFWSET',
  // 就業養成班 => AI跨界行銷資料科學專業班
  '/longterm/JPDSMKT',
  // 就業養成班 => PHP後端工程師養成訓練班
  '/longterm/JPPHPST',
  // 就業養成班 => 全方位JavaScript工程師就業養成班
  '/longterm/JWJETPT',
  // 就業養成班 => 班級
  `/longterm/${currentUrl}`,
  // 轉職進修班 => 班級
  `/job/${currentUrl}`,
  // 在職班 報名課程
  '/trainingClassSignup',
  // 在職進修班
  '/OJT',
  // 在職進修班 => 人工智慧
  '/OJT/AI',
  // 在職進修班 => 資料庫 / 大數據
  '/OJT/Database',
  // 在職進修班 => 軟體工程
  '/OJT/Software',
  // 在職進修班 => 網路通訊
  '/OJT/Network',
  // 在職進修班 => 雲端技術
  '/OJT/Cloud',
  // 在職進修班 => 智慧綠能
  '/OJT/GreenEnergy',
  // 在職進修班 => 資安管理
  '/OJT/InformationSecurity',
  // 在職進修班 => 專案管理
  '/OJT/manage',
  // 在職進修班 => 創新設計
  '/OJT/Design',
  // 在職進修班 => 數位行銷
  '/OJT/DigitalMarketing',
  // 在職進修班 => 數據分析
  '/OJT/DataAnalyze',
  // 在職進修班 => 人資管理
  '/OJT/HR',
  // 在職進修班 => 辦公室應用
  '/OJT/Office',
  // 在職進修班 => 行銷技能
  '/OJT/Marketing',
  // 在職進修班 => 程式設計
  '/OJT/Programming',
  // 在職進修班 => 元宇宙
  '/OJT/Metaverse',
  // 在職進修班 => ESG永續學堂
  '/OJT/ESG',
  // 在職進修班 => 嵌入式
  '/OJT/Embedded',
  // 在職進修班 => 在職班課程一覽
  '/OJT/All',
  // 數位轉型專區
  '/DT',
  // 企業包班
  '/corporate-training',
  // 企業會員專案
  '/enterprise',
  // 近期說明會
  '/informationmeeting',
  // 學員成果
  '/studentoutcomes',
  // 服務據點
  '/serviceplace',
  // 註冊
  '/register',
  // 登入
  '/signin',
  // 忘記密碼
  '/forgotPassword',
  // 會員中心
  '/member',
  // 會員中心 - 個人資訊
  '/member/personalinfo',
  // 會員中心 - 修改密碼
  '/member/resetpassword',
  // 會員中心 - 報名記錄
  '/member/record',
  // 會員中心 - 證書下載
  '/member/certification',
  // 關於資展國際
  '/aboutus',
  // 場地租借
  '/lease',
  // 就業媒合
  '/job',
  // 台北專區
  '/taipei',
  // 台中專區
  '/taichung',
  // 桃園專區
  '/zhongli',
  // 台南高雄專區
  '/kaohsiung',
  // 搜尋課程
  '/search',
  // 小人提補助
  '/onjobsubsidy',
  // TechSpan雲端工作坊
  // '/TechSpan/GoogleCloud',
  '/GoogleCloud',
  // 活動專區
  '/Activities',
  // 考試中心
  '/testcenter',
  // 企業徵才
  '/jobRecruitment',
  // 見證分享/就業心得分享
  '/2100360',
  // 見證分享/就業心得分享
  '/2100364',
  // 見證分享/就業心得分享
  '/1501048',
  // 見證分享/就業心得分享
  '/1501043',
  // 見證分享/就業心得分享
  '/1501047',
  // 見證分享/就業心得分享
  '/1501041',
  // 見證分享/就業心得分享
  '/1501028',
  // 見證分享/就業心得分享
  '/1501040',
  // 見證分享/就業心得分享
  '/1600366',
  // 見證分享/就業心得分享
  '/1600381',
  // 見證分享/就業心得分享
  '/1600369',
  // 見證分享/就業心得分享
  '/1600382',
  // 見證分享/就業心得分享
  '/1600000',
  // 見證分享/就業心得分享
  '/0260012',
  // 見證分享/就業心得分享
  '/0260007',
  // 見證分享/就業心得分享
  '/6190007',
  // 見證分享/就業心得分享
  '/1501026',
  // 見證分享/就業心得分享
  '/1501035',
  // 見證分享/就業心得分享
  '/1501027',
  // 見證分享/就業心得分享
  '/1501044',
  // 見證分享/就業心得分享
  '/1501033',
  // 見證分享/就業心得分享
  '/1601337',
  // 見證分享/就業心得分享
  '/1700718',
  // 見證分享/就業心得分享
  '/1601331',
  // 見證分享/就業心得分享
  '/1601315',
  // 見證分享/就業心得分享
  '/2250008',
  // 最新活動/初次尋職青年穩定就業計畫
  '/eventpage/job_allowance',
  // 最新活動/勞動部疫後改善缺工擴大就業方案
  '/eventpage/employment_expansion',
  // 最新活動/青年就業旗艦計畫
  '/eventpage/job_opportunity',
  // 最新活動/投資青年就業方案 第2期
  '/eventpage/job_investment',
  // 轉職進修班
  '/careers',
  // 職業訓練機構專班
  '/vocationaltraining',
]

export const pathnameTextList = [
  // 就業養成班
  '/就業養成班',
  // 養成班的分頁 (不需要麵包屑)
  '/就業養成班',
  '/就業養成班',
  '/就業養成班',
  '/就業養成班',
  '/就業養成班/前端工程師就業養成班',
  '/就業養成班/智慧應用微軟C#工程師就業養成班',
  '/就業養成班/跨域Java軟體工程師就業養成班',
  '/就業養成班/全端工程師就業養成班',
  '/就業養成班/雲端網路與資安工程師就業養成班',
  '/就業養成班/Big Data 巨量資料分析就業養成班',
  '/就業養成班/AI繪圖與3D美術人才養成班',
  '/就業養成班/iOS App與Mac應用軟體開發養成班',
  '/就業養成班/AIoT智慧機器人就業養成班',
  '/就業養成班/AI 與深度學習應用工程師就業養成班',
  '/就業養成班/AIoT智慧機器人就業養成班',
  '/就業養成班/嵌入式系統與韌體技術就業養成班',
  '/就業養成班/UI/UX創新策略網頁設計就業養成班',
  // '/就業養成班/Unity跨平臺遊戲開發工程師就業養成班',
  '/就業養成班/Unity互動程式開發工程師養成班',
  '/就業養成班/全方位IT工程師就業養成班',
  '/就業養成班/前後端整合應用工程師',
  '/就業養成班/5G+AI跨域智慧天線設計工程師就業養成班',
  '/就業養成班/AI雲端web應用系統開發實作精修班',
  '/就業養成班/創新應用全端網站開發工程師養成班',
  '/就業養成班/BIOS 韌體設計工程實務養成班',
  '/就業養成班/網路與系統工程師就業養成班',
  '/就業養成班/軟體測試工程師養成訓練班',
  '/就業養成班/智慧製造軟體測試工程師養成班(工研院新尖兵計劃)',
  '/就業養成班/韌體工程師專業班',
  '/就業養成班/AI跨界行銷資料科學專業班',
  '/就業養成班/PHP後端工程師養成訓練班',
  '/就業養成班/全方位JavaScript工程師就業養成班',
  '/就業養成班/',
  '/轉職進修班/',
  // 在職進修班
  '/課程報名',
  '/在職進修班',
  '/在職進修班/人工智慧',
  '/在職進修班/資料庫 • 大數據',
  '/在職進修班/軟體工程',
  '/在職進修班/網路通訊',
  '/在職進修班/雲端技術',
  '/在職進修班/智慧綠能',
  '/在職進修班/資安管理',
  '/在職進修班/專案管理',
  '/在職進修班/創新設計',
  '/在職進修班/數位行銷',
  '/在職進修班/數據分析',
  '/在職進修班/人資管理',
  '/在職進修班/辦公室應用',
  '/在職進修班/行銷技能',
  '/在職進修班/程式設計',
  '/在職進修班/元宇宙',
  '/在職進修班/ESG永續學堂',
  '/在職進修班/嵌入式',
  '/在職進修班/在職班課程總覽',
  '/數位轉型專區',
  // 企業包班
  '/企業包班',
  // 企業會員專案
  '/企業會員專案',
  // 課程說明會
  '/課程說明會',
  // 學員成果
  '/學員成果',
  // 服務據點
  '/服務據點',
  // 註冊
  '/註冊',
  // 登入
  '/登入',
  // 忘記密碼
  '/忘記密碼',
  // 會員中心
  '/會員中心',
  // 會員中心 - 個人資訊
  '/會員中心/個人資訊',
  // 會員中心 - 修改密碼
  '/會員中心/修改密碼',
  // 會員中心 - 報名記錄
  '/會員中心/報名記錄',
  // 會員中心 - 證書下載
  '/會員中心/證書下載',
  // 關於資展國際
  '/關於資展國際',
  // 場地租借
  '/場地租借',
  // 就業媒合
  '/就業媒合',
  // 台北專區
  '/台北專區',
  // 台中專區
  '/台中專區',
  // 桃園專區
  '/桃園專區',
  // 台南高雄專區
  '/台南 高雄專區',
  // 搜尋課程
  '/搜尋課程',
  // 小人提補助
  '/小型人力提升計畫',
  // TechSpan雲端工作坊
  // '/TechSpan雲端工作坊/GoogleCloud核心基礎架構課程',
  '/GoogleCloud核心基礎架構課程',
  // 活動專區
  '/活動專區',
  // 考試中心
  '/考試中心',
  // 企業徵才
  '/企業徵才',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 見證分享/就業心得分享
  '/就業心得分享',
  // 最新消息/初次尋職青年穩定就業計畫
  '/最新消息/初次尋職青年穩定就業計畫',
  // 最新消息/勞動部疫後改善缺工擴大就業方案
  '/最新消息/勞動部疫後改善缺工擴大就業方案',
  // 最新消息/青年就業旗艦計畫
  '/最新消息/青年就業旗艦計畫',
  // 最新消息/投資青年就業方案 第2期
  '/最新消息/投資青年就業方案 第2期',
  // 轉職進修班
  '/轉職進修班',
  // 職業訓練機構專班
  '/職業訓練機構專班',
]
