import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// 回到最上方Button
const ToTopButton = () => {
  // 一開始顯示為不出現ToTopButton
  const [showScroll, setShowScroll] = useState(false)

  // 畫面位置決定出現或不出現Button
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  // 點擊觸發function, 畫面回到最上方
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  window.addEventListener('scroll', checkScrollTop)

  return (
    <>
      <Link
        className="toToButton"
        to="#"
        onClick={scrollTop}
        style={{ display: showScroll ? 'flex' : 'none' }}
      >
        <div className="toToButton-content">
          <p>Top</p>
        </div>
      </Link>
    </>
  )
}

export default ToTopButton
