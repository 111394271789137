import { apiUrl } from '../config/path'
// 就業養成班大分類

const url = apiUrl + '/api/Employee/'

export async function Maintainer(name) {
  //console.log(url + name)
  return await fetch(url + name).then((data) => data.json())
}
export async function Mentor(name) {
  // console.log(url + name)
  const data = url + name
  // console.log(data)
  if (data === 'https://w3.ispan.com.tw/api/Employee/false') {
    return
  } else {
    return await fetch(url + name).then((data) => data.json())
  }
}
