import React, { useState, useEffect } from 'react'
import { useParams, Redirect, useLocation } from 'react-router-dom'
// import LinkScroll from '../../components/EmploymentClass/LinkScroll'
import MetaDecorator from '../../components/Util/MetaDecorator'

import { OnJobSingleClasses, OnJobTypeSingleInfo } from '../../services/OnJob' // 縮寫對應的班級代號 api
import TrainingContactInfoForSeries from '../../components/TrainingSeriesClass/TrainingContactInfoForSeries' // 報名資訊
import TrainingClassesListForSeries from '../../components/TrainingSeriesClass/TrainingClassesListForSeries' // 課程開班List
import ClassDetailInstructionForSeries from '../../components/TrainingSeriesClass/ClassDetailInstructionForSeries' // 課程介紹EMD內容
import ClassmateQuestionForSeries from '../../components/TrainingSeriesClass/ClassmateQuestionForSeries' // 常見問題

// 在職進修班分類 > 系列課程 > 課程
// /GCP/GCPFKCI => 應對/GCP/:id, 副總的系列課程, 兩種網址
function TrainingClassesForSeries() {
  // 會員
  // const { signinBool, breadCrumbBool, setBreadCrumbBool } = props

  // 裝開課列表
  // eslint-disable-next-line no-unused-vars
  const [classes, setClasses] = useState([])

  // 裝課程基底資訊
  // eslint-disable-next-line no-unused-vars
  const [classType, setClassType] = useState({})

  // 裝課程經理陣列
  const [classMentor, setClassMentor] = useState([])

  // 裝教務承辦陣列
  const [classMaintainer, setClassMaintainer] = useState([])

  // 裝課程經理陣列 (classType)
  const [mentor, setMentor] = useState('')

  // 裝教務承辦陣列 (classType)
  const [maintainer, setMaintainer] = useState('')

  // 裝替換的網址代號
  const [replaceUrl, setReplaceUrl] = useState('')

  // 轉址
  // eslint-disable-next-line no-unused-vars
  const [redirectTo, setRedirectTo] = useState('')

  let { id } = useParams() // destructure id GCPFKCI

  const url = 'https://www.ispan.com.tw' + useLocation().pathname

  // 處理轉換id對應的縮寫代號
  const handleUrl = () => {
    switch (id) {
      case 'GCPFKCI':
        return setReplaceUrl('MSA502')
      case 'GCPAGKE':
        return setReplaceUrl('MSA503')
      case 'GCPAGCE':
        return setReplaceUrl('MSA504')
      case 'GCPADP':
        return setReplaceUrl('MSA488')
      case 'GCPDE':
        return setReplaceUrl('MSA489')
      case 'GCPFDI':
        return setReplaceUrl('MSA490')
      default:
        return id
    }
  }

  // 處理拿到開課班級, 取得出班級的課程經理
  const handleMentor = () => {
    if (classes.length !== 0) {
      // 開課陣列不=0, 抓到課程經理陣列
      const newMentor = classes.map((v) => {
        return v.classMentor
      })
      setClassMentor(newMentor)
    } else {
      // 抓最新那筆課程經理
      const mentor = classType.classMentor
      setMentor(mentor)
    }
  }

  // 處理拿到開課班級, 取得出班級的教務承辦
  const handleMaintainer = () => {
    if (classes.length !== 0) {
      // 開課陣列不=0, 抓到教務承辦陣列
      const maintainer = classes.map((v) => {
        return v.maintainer
      })
      setClassMaintainer(maintainer)
    } else {
      // 抓最新那筆教務承辦
      const maintainer = classType.maintainer
      setMaintainer(maintainer)
    }
  }

  // 沒有開課也要能夠看到課程介紹, 捲課程代號帶出的資料
  // api/ClassType/single/MSA502
  const handleClassType = async () => {
    const datas = await OnJobTypeSingleInfo(replaceUrl)

    setClassType(datas)
  }

  // 處理接開課的api
  // api/Classes/short/MSA411
  const handleClasses = () => {
    const data = OnJobSingleClasses(replaceUrl)

    data.then((courses) => {
      if (courses.length !== 0) {
        setClasses(courses)
      }
    })
  }

  // 執行id代號轉換
  useEffect(() => {
    handleUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  // 執行找開課列表
  useEffect(() => {
    // replaceUrl是轉換id, 若不是空字串了才執行用班級代號找開課列表
    if (replaceUrl !== '') {
      handleClasses()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replaceUrl])

  useEffect(() => {
    if (replaceUrl !== '') {
      handleClassType()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replaceUrl])

  useEffect(() => {
    handleMentor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes, classType])

  useEffect(() => {
    handleMaintainer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes, classType])

  return (
    <>
      <MetaDecorator
        title={`${
          classes.length !== 0 ? classes[0].className : classType.classTypeName
        } | 
        iSpan資展國際`}
        keywords={(() => {
          switch (id) {
            case 'GCPFKCI':
              return 'Google Cloud，Google Cloud Platform'
            case 'GCPAGKE':
              return 'Google Cloud，Kubernetes Engine，Google Cloud Platform'
            case 'GCPAGCE':
              return 'Google Cloud，Compute Engine，Google Cloud Platform'
            case 'GCPADP':
              return 'Google Cloud，Google Cloud Platform，架構設計'
            case 'GCPFDI':
              return 'Google Cloud，Google Cloud Platform，數據洞察'
            case 'GCPDE':
              return 'Google Cloud，Google Cloud Platform，資料工程'
            default:
              return ''
          }
        })()}
        description={(() => {
          switch (id) {
            case 'GCPFKCI':
              return '學習使用Google Cloud Platform，了解並比較Google Cloud 中許多可用的計算和存儲服務，學會重要的資源和原則管理工具。'
            case 'GCPAGKE':
              return '學習在 Google Kubernetes 上部署和管理容器化應用引擎 (GKE)。 使用 Google Cloud 上的工具與來操作 GKE的部署。包括pods、containers、部署和服務等，您還將學習如何部署實用的解決方案，包括安全和訪問管理、資源管理和資源監控。'
            case 'GCPAGCE':
              return '熟悉 Google Cloud 的彈性的基礎架構和平台服務，特別是在 Compute Engine上。包括基礎設施組件，如網路、系統和應用服務。部署實用的解決方案，包括：混合網路、客戶提供的加密密鑰、安全和存取管理、配額和計費，以及資源監控。'
            case 'GCPADP':
              return '課程結合講座、設計活動和動手實作，展示在 Google Cloud 上使用的設計模式來構建高度可靠及高效的解決方案，並運行高可用且經濟高效的部署。本課程是為已完成「使用Google Compute Engine 架構」或「使用 Google Kubernetes Engine 架構」課程的學員所創建。'
            case 'GCPFDI':
              return '使用Google Cloud 的無伺服器、可高度擴充且高效的雲端數據儲存大規模數據中獲取洞察見解的方法。本課程結合講座、範例和動手實作來教您 BigQuery 的基礎知識，包括如何創建數據轉換流程、構建 BI 儀表板、擷取新數據集和大規模設計架構。'
            case 'GCPDE':
              return '透過實作體驗在Google Cloud設計與建置資料處理系統。講師將結合講座、範例和動手實作，展示如何設計數據處理系統、建構端對端的數據流程、分析數據資料和進行機器學習。本課程涵蓋結構化、非結構化和串流數據。'
            default:
              return ''
          }
        })()}
        url={url}
      ></MetaDecorator>
      {/* 不同的情況下改變轉址 */}
      {redirectTo === 'OJT' && <Redirect to="/OJT" />}

      {/* 在職班課程主圖 ↓↓↓　*/}
      <div className="container-fluid p-0">
        <div className="col p-0">
          <figure className="main-picture_img">
            {(() => {
              switch (id) {
                case 'GCPFKCI':
                  return (
                    <img
                      src="/img/onJobBanner/GCP.png"
                      alt="iSpan資展國際 | 在職進修班"
                    />
                  )
                case 'GCPAGKE':
                  return (
                    <img
                      src="/img/onJobBanner/GCP.png"
                      alt="iSpan資展國際 | 在職進修班"
                    />
                  )
                case 'GCPAGCE':
                  return (
                    <img
                      src="/img/onJobBanner/GCP.png"
                      alt="iSpan資展國際 | 在職進修班"
                    />
                  )
                case 'GCPADP':
                  return (
                    <img
                      src="/img/onJobBanner/GCP.png"
                      alt="iSpan資展國際 | 在職進修班"
                    />
                  )
                case 'GCPDE':
                  return (
                    <img
                      src="/img/onJobBanner/GCP.png"
                      alt="iSpan資展國際 | 在職進修班"
                    />
                  )
                case 'GCPFDI':
                  return (
                    <img
                      src="/img/onJobBanner/GCP.png"
                      alt="iSpan資展國際 | 在職進修班"
                    />
                  )

                default:
                  return null
              }
            })()}
          </figure>
          <div className="banner__titleC">
            <div className="bannerTitle__white">
              {(() => {
                switch (id) {
                  case 'GCPFKCI':
                    return (
                      <h1 className="seriesClass_title_h1">
                        Google Cloud基礎知識之核心基礎架構
                      </h1>
                    )
                  case 'GCPAGKE':
                    return (
                      <h1 className="seriesClass_title_h1">
                        使用Google Kubernetes Engine做架構設計
                      </h1>
                    )
                  case 'GCPAGCE':
                    return (
                      <h1 className="seriesClass_title_h1">
                        使用Google Compute Engine做架構設計
                      </h1>
                    )
                  case 'GCPADP':
                    return (
                      <h1 className="seriesClass_title_h1">
                        使用Google Cloud做架構設計：設計與過程
                      </h1>
                    )
                  case 'GCPDE':
                    return (
                      <h1 className="seriesClass_title_h1">
                        使用Google Cloud Platform做資料工程
                      </h1>
                    )
                  case 'GCPFDI':
                    return (
                      <h1 className="seriesClass_title_h1">
                        使用Google Cloud 從數據到洞察
                      </h1>
                    )

                  default:
                    return null
                }
              })()}
            </div>
          </div>
        </div>
      </div>
      {/* 在職班課程主圖 ↑↑↑　*/}

      {/* 報名說明文字, 課程資訊表格 ↓↓↓　*/}
      <div className="container-fluid p-0 ojt-page">
        <div id="ojt-page">
          {/* <LinkScroll /> */}

          <div className="custom-container">
            <div className="seriesClass_content_div">
              {/* 課程介紹、報名資訊、常見問題 ↓↓↓ */}
              <div className="seriesClass_leftContent_div">
                <div>
                  <div>
                    <div className="seriesClass_titleTLine_div"></div>
                    <h4 className="seriesClass_title_h4">課程介紹</h4>
                  </div>
                  <div>
                    <ClassDetailInstructionForSeries
                      classesName={classes.length !== 0 && classes[0].className}
                      classInfo={classes.length !== 0 && classes[0]}
                      classTypeContent={classType}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <div className="seriesClass_titleTLine_div"></div>
                    <h4 className="seriesClass_title_h4">報名資訊</h4>
                  </div>
                  <div>
                    <TrainingContactInfoForSeries
                      classesMentor={classMentor}
                      mentor={classMentor.length === 0 && mentor}
                      classMaintainers={classMaintainer}
                      maintainers={classMaintainer.length === 0 && maintainer}
                    />
                  </div>
                </div>

                <div className="seriesClass_rightContentMd_div">
                  <div className="seriesClass_rightContent_div">
                    <div>
                      <TrainingClassesListForSeries replaceUrl={replaceUrl} />
                    </div>
                    <div></div>
                  </div>
                </div>

                <div>
                  <div>
                    <div className="seriesClass_titleTLine_div"></div>
                    <h4 className="seriesClass_title_h4">常見問題</h4>
                  </div>
                  <div>
                    <ClassmateQuestionForSeries />
                  </div>
                </div>
              </div>
              {/* 開課資訊、相關課程 ↓↓↓ */}
              <div className="seriesClass_rightContentL_div">
                <div className="seriesClass_rightContent_div">
                  <TrainingClassesListForSeries />
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TrainingClassesForSeries
