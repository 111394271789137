import React from 'react'
import { Helmet } from 'react-helmet'

// SEO meta
const MetaDecorator = ({
  title = '測試標題',
  keywords = '測試關鍵字',
  description = '測試content',
  url,
  children,
}) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{title}</title>
    <meta name="keywords" content={keywords} />
    <meta name="description" content={description} />
    {children}
    {url ? <link rel="canonical" href={url} /> : ''}
  </Helmet>
)

export default MetaDecorator
