import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-router-dom'

// 智慧製造軟體測試工程師養成班(工研院新尖兵計劃), 20240704新增
function JMSMQATClass() {
  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      <div className="classContent__figure">
        <img src="/img/employment/STQA_head.png" alt="" />
      </div>
      <div className="txtC__div text-justify">
        <div className="employmentClassification-instruction_p">
          <p>
            智慧製造與自動化的應用不僅改變了製造業的生產方式，同時也影響軟體開發的流程和要求，隨著智慧製造的崛起，軟體測試領域也正在迅速演變。在這樣的趨勢下，對於軟體測試工程師來說，掌握智慧製造相關技術至關重要。
          </p>
          <br />
          <p>
            本課程涵蓋的核心技術包括將從智慧製造產業會運用到微軟相關技術開始，從C#
            程式語言，ADO.NET 與 LINQ 等資料庫存取技術，一直到 ASP.NET及 ASP.NET
            Core MVC 網站開發，並加強了前端網頁開發技術，Typescript Angular
            前端開發框架整合應用的學習，並了解軟體測試流程，提高測試效率和品質。課程核心涵蓋軟體開發的技術，進而學習軟體測試實作技能的相關技術，讓學員成為具有軟體測試思維與測試實作技能的軟體開發工程師，並將介紹智慧製造技術，並指導學員如何整合這些工具到軟體測試的各個階段。
          </p>
        </div>
      </div>

      {/* 課程介紹 */}
      <div>
        <div className="content__title">
          <h3>課程特色及優勢</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <ul className="listStyle__ul">
            <li>
              C # 樂章 .NET 舞台 ~
              零基礎學習。微軟是目前市佔率最高的軟體領導廠商，本班是職訓市場唯一最有經驗、且針對微軟開發技術所設計的就業養成課程。讓學員從完全不具程式設計基礎開始，逐步引導學習企業最新最熱門的就業技術。
            </li>
            <li>
              軟體程式開發之訓練著重在微軟.NET解決方案，除了 C#
              .NET核心技術外，為提升學員競爭力，課程設計也包含了ADO.NET、LINQ、Entity
              Framework、ASP.NET Core Web API
              應用程式、網頁前端開發JavaScript、Ajax、Angular
              前端框架，其目的是希望對業界採用的微軟解決方案及具指標的領導技術，培養具即戰力人才。
            </li>
            <li>
              軟體測試之訓練課程，包含撰寫測試計畫書(Test Plan)、測試案例(Test
              Case)、測試問題追蹤以及測試報告(Test
              Report)，也包括使用各種測試工具，以進行單元測試(Unit
              Test)、整合測試(Integration testing)、系統測試(System
              testing)、端對端測試(End to End testing)、壓力測試(Stress
              testing)等。
            </li>
            <li>
              學員結訓後，提供就業輔導。歷屆結訓時微軟.NET相關班級廠商踴躍參與的畫面以及高就業率。
            </li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">招生簡章</div>
          <ul className="classInfoList__styleNone">
            <li>
              <Link
                to="/pdf/智慧製造軟體測試工程師養成班(工研院新尖兵計劃)招生簡章.pdf"
                className="orange"
                target="_blank"
                download
              >
                簡章下載<i className="fas fa-download"></i>
              </Link>
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              主要安排於星期一到星期五週間白天，但因部分課程配合業師的時間，部份課程有可能會安排於『夜間時段』與『假日時段』來進行授課
            </li>
          </ul>
        </div>
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>智慧製造軟體測試工程師養成班適合對象</h4>
                <div className="longTermTabC__div">
                  {/* <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure> */}
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        有志進入軟體開發與軟體測試及應用領域者
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">學歷高中/職(含)以上</h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        開訓當日應為年滿15歲至29歲之本國籍失業或待業青年。訓練期間不得為在職勞工、自營作業者、公司或行（商）號負責人。可申請勞動部勞動力發展署「產業新尖兵計畫」補助
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備軟體測試知識、理論與實務軟體測試技能
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備網站應用程式開發、資料庫應用等前後端基礎應用與整合開發能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養智慧製造相關領域的知識和技能
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">智慧製造工程師</li>
                  <li className="longTerm_tabContent_li">智慧機械開發工程師</li>
                  <li className="longTerm_tabContent_li">軟體開發工程師</li>
                  <li className="longTerm_tabContent_li">
                    網頁前後端開發工程師
                  </li>
                  <li className="longTerm_tabContent_li">軟體測試工程師</li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>

      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default JMSMQATClass
