import React from 'react'
import { Link } from 'react-router-dom'

// 1001把Link關掉
// Footer
function Footer() {
  return (
    <>
      <footer>
        <div className="footer-content">
          <img src="/img/footer.jpg" alt="footer背景圖片" />
          <section>
            {/* <div className="contact-content_inner">
              <div className="contact_content">
                <i className="far fa-envelope contact-envelope"></i>
                <h3>Contact</h3>
                <p>聯繫我們</p>
              </div>
              <div className="qa_content">
                <i className="far fa-file-alt contact-envelope"></i>
                <h3>Q & A</h3>
                <p>任何問題與解答</p>
              </div>
            </div> */}

            <a href="mailto:iservice@ispan.com.tw?subject=iSpan資展國際&nbsp;-&nbsp;顧客意見">
              <div className="contact-content_inner">
                <div className="contact_content">
                  {/* <p className="contact-txt_en">Contact Us</p> */}
                  <p className="contact-txt_ch">有任何問題請聯繫我們</p>
                  <i className="far fa-envelope contact-envelope"></i>
                </div>
              </div>
            </a>
          </section>
          <div className="container-fluid p-0 footer-bcc">
            <div className="footer-container footer-container_inner">
              <div className="footer-infoIcon">
                <div>
                  <div className="footer-logo">
                    <img src="/img/footerLOGO.png" alt="iSpan資展國際" />
                  </div>
                  <div className="footer-text footer-text_distance">
                    <h6 className="d-block lesson-content_h6">
                      Tel : (02) 6631-6588(台北窗口)
                    </h6>
                    {/* <h6 className="d-block pb-2 lesson-content_h6">
                      Fax : (02)6631-6598
                    </h6> */}
                    <h6 className="lesson-content_h6">
                      地址 : 台北市復興南路一段390號2樓 <br />
                      (大安捷運站4號、6號出口)
                    </h6>
                  </div>
                </div>
                <div className="footer-line_mdBlock"></div>
                <div className="footer-icon">
                  <div className="footer-icon_a">
                    <a href="https://www.facebook.com/ispanTaipei">
                      <img
                        src="/img/facebookIcon.png"
                        alt="iSpan資展國際臉書Facebook
                      "
                      />
                    </a>
                  </div>
                  <div className="footer-verticalLine_mdBlock"></div>
                  <div className="footer-icon_a">
                    <a href="https://www.youtube.com/channel/UCObZbQQVeVvznp9TIPkqIPA">
                      <img
                        src="/img/youTubeIcon.png"
                        alt="iSpan資展國際YouTube"
                      />
                    </a>
                  </div>
                </div>
                <div className="footer-line_mdBlock"></div>
              </div>

              <div className="footer-text_content">
                <div className="footer-text">
                  <h6 className="footerTxt__h6">
                    <span>課程項目</span>
                  </h6>

                  <Link to="/longterm">
                    <h6 className="footerTxt__h6">
                      <span>就業養成</span>
                    </h6>
                  </Link>
                  <Link to="/OJT">
                    <h6 className="footerTxt__h6">
                      <span>在職培訓</span>
                    </h6>
                  </Link>
                </div>
                <div className="footer-text">
                  <h6 className="footerTxt__h6">
                    <span>服務內容</span>
                  </h6>

                  <Link to="/lease">
                    <h6 className="footerTxt__h6">
                      <span>場地租借</span>
                    </h6>
                  </Link>
                  <Link to="/enterprise">
                    <h6 className="footerTxt__h6">
                      <span>企業會員</span>
                    </h6>
                  </Link>
                  <Link to="/job">
                    <h6 className="footerTxt__h6">
                      <span>廠商徵才入口</span>
                    </h6>
                  </Link>
                  <a href="mailto:iservice@ispan.com.tw?subject=iSpan資展國際 - 異業洽談">
                    <h6 className="footerTxt__h6">
                      <span>異業洽談</span>
                    </h6>
                  </a>
                </div>
                <div className="footer-text">
                  <h6 className="footerTxt__h6">
                    <span>資展國際</span>
                  </h6>

                  <Link to="/aboutus">
                    <h6 className="footerTxt__h6">
                      <span>關於我們</span>
                    </h6>
                  </Link>
                  <Link to="/news">
                    <h6 className="footerTxt__h6">
                      <span>消息公告</span>
                    </h6>
                  </Link>
                  <a href="mailto:iservice@ispan.com.tw?subject=iSpan資展國際 - 顧客意見">
                    <h6 className="footerTxt__h6">
                      <span>聯繫我們</span>
                    </h6>
                  </a>
                  <a href="https://rainbowfun.ispan.com.tw/">
                    <h6 className="d-block d-sm-inline footerTxt__h6">
                      <span>彩虹牧場 (國高中夏令營)</span>
                    </h6>
                  </a>
                  <a href="https://www.iiiedu.org.tw/">
                    <h6 className="d-block d-sm-inline footer-text_sub1_p">
                      <span>資策會數位教育研究所</span>
                    </h6>
                  </a>
                </div>
              </div>

              <div className="footer-line"></div>
              <div className="footer-text__lastContent">
                <div className="footer-text">
                  <Link to="/privacypolicy">
                    <h6 className="footer-text_sub1_p">
                      <span>服務條款及隱私權保護政策</span>
                    </h6>
                  </Link>
                </div>
              </div>

              <div className="footer-text__lastContent">
                <div className="footer-text trademark">
                  <h6 className="lesson-content_h6">
                    資展國際股份有限公司 © All Rights Reserved.
                  </h6>
                  <h6 className="lesson-content_h6">
                    資展國際股份有限公司附設台北市私立資展國際電腦技藝短期補習班
                  </h6>
                  <h6 className="lesson-content_h6">
                    資展國際股份有限公司附設臺北職業訓練中心
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
