import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// Java就業養成班詳細內容, 20230901修改
function JavaSoftwareClass(props) {
  const { longTermClass } = props
  console.log(longTermClass)

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      <div className="classContent__figure">
        <img src="/img/employment/Java_head.png" alt="" />
      </div>

      <div className="txtC__div text-justify">
        <div className="employmentClassification-instruction_p">
          <p>
            您是否畢業後尋尋覓覓，一直沒找到合適的工作？在萎靡不振的就業市場中，究竟何去何從？
          </p>
          <br />
          <p>
            不是您能力不足，只是沒找到正確的管道幫助自己而已。請您來參加我們的課程說明會，
            讓業界的前輩們幫您開創自己的一片蔚藍大海，遨遊到一個您從未想過的新天地！
          </p>
        </div>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          您給我們兩個小時，我們給您一個機會！歡迎參加說明會！
        </h4>
        <p className="employmentClassification-instruction_p">
          在這個不景氣的年代，您知道有許多的關鍵(Mission-Critical)系統，都是使用Java技術完成、大量使用
          {longTermClass && longTermClass.address.substring(0, 2) === '台北' ? '' : 'Struts、'}
          SpringFramework、Hibernate這些應用程式框架嗎？您知道程式設計師不能只會寫程式而已，系統管理、網頁開發、資料庫設計，都已經變成程式設計師的外掛了嗎？您知道有越來越多的企業，都在評估行動應用與雲端運算的機會與可能性，提供客戶手機介面、或是把各種服務放在雲端嗎？
        </p>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          不怕您學，就怕您不學
        </h4>
        <p className="employmentClassification-instruction_p">
          我們會從最基礎的Java語法開始教起，一直到業界目前
          {longTermClass && longTermClass.address.substring(0, 2) === '台北'
            ? ''
            : '最常使用的Servlet/JSP、JSTL等動態網頁開發技術，以及業界目前'}
          最熱門的Hibernate與
          {longTermClass && longTermClass.address.substring(0, 2) === '台北'
            ? 'Spring boot'
            : 'SpringFramework'}
          等應用程式框架，我們有超過兩百小時的Java課程，搭配資展國際講師精心調製的教材內容，即使您是個程式設計領域的新手，只要您肯學，願意投入時間與心力，成為有水準的Java程式設計師絕對不是一件難事！
        </p>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          符合用人需求，增加就業籌碼
        </h4>
        <p className="employmentClassification-instruction_p">
          以現在的職場工作而言，有哪一個程式設計師可以完全不碰資料庫？有哪一個程式設計師可以完全不碰網頁製作？所以囉，整個課程的設計，雖然是以Java為主軸，但是我們的終極目標，是要培養一個能夠參與業界專案開發的優秀程式設計師！所以，不管是基本的作業系統管理、網頁開發、資料庫設計、軟體工程等，這一切的一切，都是一個程式設計師該會的基本馬步功夫！
        </p>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          立足雲端運算，提早卡位
        </h4>
        <p className="employmentClassification-instruction_p">
          雲端運算不只是這兩年全世界IT產業的趨勢，政府也已經將雲端運算列為政策執行的重點，程式設計師當然也不能夠不知道這股IT潮流。我們的課程當中，也加入了雲端運算的介紹，以及Java與雲端運算的整合方式，讓您到企業工作之後，可以協助企業，不管是公司內部營運管理系統，或是公司外部入口網站，都可以逐步地搬移到雲端之上！
        </p>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          提供就業輔導，工作免煩惱
        </h4>
        <p className="employmentClassification-instruction_p">
          在您認真學習，取得優秀成績，做出夠水準的專題之後，空有一身好本領，可是找不到知人善任的伯樂老闆，怎麼辦？沒關係，資策會有專門的人員幫您進行就業輔導。您可以專心於學習之中，由我們為您打點相關的就業輔導事宜。
        </p>
      </div>

      {/* 課程介紹 */}
      <div>
        {/* <div className="pb-4">
          <h6><strong>【課程目標】</strong></h6>
          <ul className="listStyle__ul">
            <li>培養學員具備轉換跑道、從事IT相關行業的工作能力</li>
            <li>培養學員具備企業網站規劃、建置、開發的能力</li>
            <li>培養學員具備資料庫規劃、建置、開發的能力</li>
            <li>培養學員具備網際企業應用、與雲端應用的程式開發能力</li>
          </ul>
        </div> */}
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              本班課程設計以業界迫切需求的Java技術為主，強調由淺入深，實務操作，目的在培養有能力獨當一面的Java跨平台應用程式設計師
            </li>
            <li>
              從Java
              {longTermClass && longTermClass.address.substring(0, 2) === '台北'
                ? '程式語言'
                : '/JSP/Servlet/Java EE 等程式設計'}
              ，到Hibernate、Spring、Spring MVC 應用程式框架以及 SQL
              Server資料庫的設計與應用等，俾使學員橫跨程式設計、網頁開發、企業級資料庫運用等電腦主流技術
            </li>
            <li>
              依據政府軟體核心關鍵技術人才培訓計劃之精神，因應政府推廣行動應用與雲端運算的政策方針，為企業培訓Java程式設計人才，以軟體搭硬體，為台灣再造另一個經濟奇蹟
            </li>
            <li>
              從完全不具程式設計基礎開始，逐步將學員引導到現今企業最熱門的行動平台雲端運算平台，讓學員具備跨平台的Java程式設計基本功夫
            </li>
            <li>學員結訓後，由本中心提供就業輔導</li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure pb-3">
          <img
            src={
              longTermClass
                ? `/img/employment/syllabus/Java_JJEEITT_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '台北':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Taipei_C2E2302'
                            : 'Taipei'
                        }`
                      case '桃園':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Zhongli_C2E2302'
                            : 'Zhongli'
                        }`
                      case '台中':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Taichung_C2E2302'
                            : 'Taichung'
                        }`
                      case '台南':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Tainan_C2E2302'
                            : 'Tainan'
                        }`
                      case '高雄':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'kaohsiung_C2E2302'
                            : 'kaohsiung'
                        }`
                      default:
                        return ''
                    }
                  })()}.png?240807`
                : '/img/employment/syllabus/Java_default.png'
            }
            alt=""
          />
        </div>
        <p className="red mb-5">※課程內容以地區班級為準。</p>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">招生對象</div>
          <ul className="classInfoList__styleNone">
            <li>經教育部核准立案之國內外公私立大專院校畢業青年</li>
            <li>
              對於網際網路應用、企業應用、與資料庫設計管理等程式設計領域有興趣
            </li>
            <li>具備基本英文閱讀能力</li>
            <li>有耐力與毅力，願意接受本班考驗</li>
          </ul>
        </div> */}
        <div className="pb-4">
          <div className="subT__div">學習工具</div>
          <ul className="classInfoList__styleNone">
            <li>切合業界潮流，商業軟體與自由軟體並重</li>
            <li>作業系統：Microsoft Windows</li>
            <li>開發工具：Eclipse</li>
            <li>應用程式伺服器：Tomcat</li>
            <li>資料庫：Microsoft SQL Server</li>
            <li>Java應用程式框架：Spring、Hibernate、Spring MVC</li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>Java跨平台應用程式設計師</li>
            <li>Java動態網頁程式設計師</li>
            <li>ISP網站程式設計師</li>
            <li>Microsoft SQL Server資料庫程式設計師</li>
            <li>企業內部應用系統開發人員</li>
            <li>雲端運算程式程式設計師</li>
            <li>Outsourcing專案委外程式設計師</li>
          </ul>
        </div> */}
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>
      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>跨域Java軟體工程師就業養成班適合對象</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        經教育部核准立案之國內外公私立大專院校畢業青年
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對於網際網路應用、企業應用、與資料庫設計管理等程式設計領域有興趣
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        具備基本英文閱讀能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        有耐力與毅力，願意接受本班考驗
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備轉換跑道、從事IT相關行業的工作能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備企業網站規劃、建置、開發的能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備資料庫規劃、建置、開發的能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備網際企業應用、與雲端應用的程式開發能力
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">
                    Java跨平台應用程式設計師
                  </li>
                  <li className="longTerm_tabContent_li">
                    Java動態網頁程式設計師
                  </li>
                  <li className="longTerm_tabContent_li">ISP網站程式設計師</li>
                  <li className="longTerm_tabContent_li">
                    Microsoft SQL Server資料庫程式設計師
                  </li>
                  <li className="longTerm_tabContent_li">
                    企業內部應用系統開發人員
                  </li>
                  <li className="longTerm_tabContent_li">
                    雲端運算程式程式設計師
                  </li>
                  <li className="longTerm_tabContent_li">
                    Outsourcing專案委外程式設計師
                  </li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>

      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default JavaSoftwareClass
