import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// 全方位JavaScript工程師就業養成班詳細內容
function JWJETPTClass(props) {
  const { longTermClass } = props
  console.log(longTermClass)

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      <div className="txtC__div text-justify">
        <p>
          JavaScript
          是當今最流行的程式語言之一，無論是前端網頁開發、後端伺服器建置，還是完整的全端應用程式開發，JavaScript
          都是不可或缺的技術。
        </p>
        <br />
        <p>
          本課程專為希望從零開始學習 JavaScript
          並進一步發展職業生涯的初學者設計，也適合希望提升技能、轉職或進入高需求
          IT 行業的現職開發人員，您將深入學習從前端框架 Vue.js 到後端開發
          Node.js，再到 DevOps
          部署、測試及版本控制的全方位技能。無論您目前的技術水平如何，這門課程都將帶您從基礎知識起步，一步步邁向專業水平，最終為您打開JavaScript工程師的職業大門。
        </p>
      </div>

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure pb-3">
          <img
            src={
              longTermClass
                ? `/img/employment/syllabus/JavaScript_JWJETPT_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '台北':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Taipei_C2E2302'
                            : 'Taipei'
                        }`
                      default:
                        return ''
                    }
                  })()}.png?240906`
                : '/img/employment/syllabus/JavaScript_JWJETPT_Taipei.png?240906'
            }
            alt=""
          />
        </div>
        <p className="red mb-5">※課程內容以地區班級為準。</p>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>全方位JavaScript工程師就業養成班適合對象</h4>
                <div className="longTermTabC__div">
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        想要轉換跑道，進入資訊科技領域相關工作者
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        想要培養跨域能力，加強職場競爭力者
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        藉由漸進的基礎訓練與觀念培養，讓不曾接觸過程式開發的的學員，能夠藉由講師的帶領，同學間的合作，掌握程式開發完整的能力，並從不斷的練習中累積經驗與作品，藉由專題製作體驗職場工作流程與溝通技巧，讓學員於學習結束後能夠找到資訊領域相關工作。
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">前端開發工程師</li>
                  <li className="longTerm_tabContent_li">後端開發工程師</li>
                  <li className="longTerm_tabContent_li">全端開發工程師</li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default JWJETPTClass
