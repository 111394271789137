import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// AI就業養成班詳細內容 (20230901沒有開班了)
function ArtificialIntelligenceClass() {
  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      <div className="classContent__figure">
        <img src="/img/employment/AI_head.png" alt="" />
      </div>

      <div className="txtC__div text-justify">
        <div className="employmentClassification-instruction_p">
          <p>
            隨著5G、物聯網(IoT)、大數據(Big
            Data)、雲端運算等新興技術快速的發展，
            人工智慧(AI)的應用再度成為全世界各行各業矚目的焦點！
          </p>
          <br />
          <p>
            在這個以人工智慧為顯學的時代中，我們該如何運用大量的資料，透過電腦視覺、機器學習與深度學習等相關技術，創造出一個個有趣的整合應用與服務，以協助企業進行智慧化的升級，已是這一波數位轉型浪潮下不可或缺的戰略思考。
          </p>
          <br />
          <p>
            那麼，在浩瀚的ICT技術當中，應該要具備哪些技能與素養，才能夠成為一名優秀的AI整合應用開發工程師呢？
            不是您能力不足，只是沒找到正確的管道幫助自己而已。請您來參加我們的課程說明會，
            讓業界的前輩們幫您開創自己的一片蔚藍大海，遨遊到一個您從未想過的新天地！
          </p>
        </div>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          由淺入深、技術扎根
        </h4>
        <p className="employmentClassification-instruction_p">
          能夠運用網站前後端整合開發技術，展現人工智慧認知服務的創新應用。
          <br />
          能夠運用Python程式語言，進行資料分析、影像辨識、機器學習與深度學習等創新應用。
          <br />
          能夠運用TensorFlow、Keras、PyTorch等深度學習框架來建構深度學習的模型。
          <br />
        </p>
      </div>

      {/* 課程介紹 */}
      <div>
        <div className="pb-4">
          <h6>
            <strong>【課程目標】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              培養學員能夠運用網站前後端整合開發技術，展現人工智慧認知服務的創新應用。
            </li>
            <li>
              培養學員能夠運用Python程式語言，進行資料分析、影像辨識、機器學習與深度學習等創新應用。
            </li>
            <li>
              培養學員能夠運用TensorFlow、Keras、PyTorch等深度學習框架來建構深度學習的模型。
            </li>
            <li>
              培養學員具備網際企業應用、行動應用、與雲端應用的程式開發能力
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>首重實作！從Node.js網站前後端整合開發到機器學習專案的開發</li>
            <li>
              從零開始！本班從Python
              程式設計、JavaScript語法入門開始，一路引領您實作到AI相關的應用
            </li>
            <li>
              專題導向！我們透過大、小專題的歷練，引導您完成期末的專題成果的輸出並上台進行發表
            </li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure employmentClassification-img_only">
          <img src="/img/employment/syllabus/AI_class.png" alt="" />
        </div>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">招生對象</div>
          <ul className="classInfoList__styleNone">
            <li>對於運用JavaScript語言進行網站開發有興趣者</li>
            <li>
              對於運用Python語言進行資料分析、機器學習、深度學習等技術應用有興趣者
            </li>
            <li>對於人工智慧應用及其服務整合應用有高度興趣者</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>Python 機器學習工程師</li>
            <li>Python 資料分析師</li>
            <li>影像辨識應用工程師</li>
            <li>網站應用開發工程師</li>
            <li>企業內部應用系統開發人員</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>3D遊戲美術設計人才養成班適合對象</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">熟悉電腦基本操作</h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對3D遊戲美術設計有強烈學習意願及興趣
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        美術或設計相關科系畢業者尤佳
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        男女不拘，但男性需役畢或免役
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        解3D遊戲業界專案設計流程
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        學習3D遊戲美術實務與專業遊戲技術應用，以Unity整合美術與互動設計
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        具備遊戲專題製作能力
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">SOHO接案</li>
                  <li className="longTerm_tabContent_li">獨立製作</li>
                  <li className="longTerm_tabContent_li">出國升學</li>
                  <li className="longTerm_tabContent_li">職場就業</li>
                  <li className="longTerm_tabContent_li">3D遊戲美術設計</li>
                  <li className="longTerm_tabContent_li">3D角色製作</li>
                  <li className="longTerm_tabContent_li">3D場景製作</li>
                  <li className="longTerm_tabContent_li">3D角色動作</li>
                  <li className="longTerm_tabContent_li">遊戲特效設計</li>
                  <li className="longTerm_tabContent_li">3D動畫設計</li>
                </ul>
              </div>
            </Tab> */}
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default ArtificialIntelligenceClass
