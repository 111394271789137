import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// Unity就業養成班詳細內容, 20230901修改
function UnityDeveloperClass(props) {
  const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      <div className="Unity_iframe">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/ucalG1dsvL4?si=RuuBCotizaBk_T0N"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="txtC__div text-justify">
        <div className="employmentClassification-instruction_p">
          <p>
            「元宇宙Metaverse」由「meta(超越)」與「universe(宇宙)」兩個字所組成，意旨一個跟現實世界平行的虛擬世界，一個未來持久化和去中心化的線上三維虛擬環境，擁有一套完整的社會和經濟體系。人們在虛擬世界中社交、學習、玩樂，藉由虛實整合、數位遊戲、AR/VR型態等各種互動形式，享受沉浸式體驗。{' '}
          </p>
          <br />
          <p>
            本課程使用Unity引擎為核心，進行互動程式開發，將實體世界的物件轉換成3D的運算機制，利用Unity跨平台的特性，製作AR/VR/XR型態的互動程式或3D虛擬遊戲。課程從C#程式設計、Unity引擎與元宇宙數學打下基礎，延伸至聯網程式設計、人工智慧與互動程式設計，以及即時3D呈像程式設計等進階課程，再導入3D虛擬模型應用，並解析AR/VR/XR目前發展現況與趨勢。課程為專業資深業界講師以業界案例實作為教學導向，最後以teamwork團隊製作互動程式專題整合所學。
          </p>
        </div>
        {/* <div className="employmentClassification-instruction_p">
          <p>
            「元宇宙」（Metaverse）是近期最火熱的關鍵字，由「meta(超越)」與「universe(宇宙)」兩個字所組成，意旨一個跟現實世界平行的虛擬世界，一個未來持久化和去中心化的線上三維虛擬環境，擁有一套完整的社會和經濟體系。這個集體虛擬共享空間，打破了虛擬世界、真實世界與網際網路的藩籬，更瞄準了8千億美元「元宇宙」商機。數位遊戲就是元宇宙的重要主軸架構之一，將所有實體世界的物件轉換成3D的運算機制，人們在虛擬世界中社交、學習、玩樂，更可藉由人機交互的硬體與周邊支援，如穿戴式裝置等，享受沉浸式體驗。
          </p>
          <br />
          <p>
            2021全球遊戲市場產生1803億美元收入，成長超乎預期，全球疫情導致的封城措施更使得玩家參與度和收入都大幅增長！2024年將上看2188億美元，持續往上攀升，熱度不減。
          </p>
          <br />
          <p>
            Unity3D佔全球遊戲引擎50％的市場，居全球首位，並且可以快速跨多個平台開發，包括：手機遊戲(iPhone/Android)、PC遊戲(Windows/Mac/Linux)、網頁遊戲(各種主流瀏覽器)、遊戲機專用遊戲(Switch/Xbox/PS5)，也能製作AR/VR/MR互動程式或遊戲。遊戲平台板塊逐漸移向行動遊戲，App
            Store與Google Play的Top
            1000手遊中，71%由Unity製作，包括騰訊的《王者榮耀》、米哈遊的《原神》、任天堂的《Pokémon
            GO》。本課程專門培育Unity遊戲開發工程師，邀請業界師資授課，納入遊戲數學與遊戲AI課程，歡迎喜愛遊戲的你，跨入虛實整合開發的世界，插旗未來的元宇宙市場！
          </p>
        </div> */}
      </div>
      {/* 課程介紹 */}
      <div>
        {/* <div className="pb-4">
          <h6><strong>【課程目標】</strong></h6>
          <ul className="listStyle__ul">
            <li>學習Unity 3D遊戲設計各項功能與實務進階應用</li>
            <li>學習遊戲實務開發設計流程</li>
            <li>達到就業所需職能養成</li>
          </ul>
        </div> */}
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>業界資深講師授課，獨家傳授業界實務應用與技巧</li>
            <li>
              非單一工具軟體教學，而是以遊戲專案開發的流程結構為骨架，各項專業技術為內容，完整且全面性的教學導向，達到業界實際職能需求
            </li>
            <li>最後以team work的專題作品呈現，以團隊合作方式實際整合所學</li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="">
            【產業資訊】
          </div>
          <ul className="listStyle__ul">
            <li>
              <a
                href="https://hk.saowen.com/a/862250902605c02f9b2dad8dc7cca7f982671b02cedecfc87b863db192bb43f8"
                target="_blank"
                rel="noreferrer"
              >
                Unity XR負責人：2018將是VR開始騰飛的一年
              </a>
            </li>
            <li>
              <a
                href="https://trampuncle.com/ar-curate/"
                target="_blank"
                rel="noreferrer"
              >
                蘇富比國際房地產公司推出可視化家俱之AR應用《Curate》
              </a>
            </li>
            <li>
              <a
                href="https://unity3d.com/machine-learning"
                target="_blank"
                rel="noreferrer"
              >
                Unity Machine Learning
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=kbnzUs2eZ-M"
                target="_blank"
                rel="noreferrer"
              >
                日本由Unity製作的遊戲
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure">
          <img
            src={
              longTermClass && longTermClass.taxCode !== ''
                ? `/img/employment/syllabus/Unity_JPUMVRT_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '台北':
                        return 'Taipei'
                      default:
                        return ''
                    }
                  })()}_C2E2302.png?240710`
                : '/img/employment/syllabus/Unity_JPUMVRT_Taipei.png?240710'
            }
            alt=""
          />
        </div>
        <p className="red pt-3">※課程內容以地區班級為準。</p>
        <div className="employmentClassification-list_content">
          <div className="row justify-content-center employmentClassification-list_inner">
            <div className="p-0 col-xxl-10 col-sm-12 d-flex flex-wrap employClassCard-justify">
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>C#程式設計基礎</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>物件導向程式設計基本概念</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  {/* <h5>C#遊戲程式設計</h5> */}
                  <h5>C#程式設計進階</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>資料結構與演算法</li>
                  {/* <li>C#進階與遊戲</li> */}
                  <li>C#進階與互動程式設計</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  {/* <h5>遊戲介面設計</h5> */}
                  <h5>互動介面設計</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>操作介面設計實務</li>
                  <li>群組系統設計實務</li>
                  <li>人機介面測試方法</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>Unity入門</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>操作環境介面、專案管理設定</li>
                  <li>物件編輯、場景系統、光源設定</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>Unity進階</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>程式操控物件函式</li>
                  <li>互動函式、物理函式</li>
                  <li>第一人稱、第三人稱操控範例</li>
                  <li>讀取物件與場景</li>
                  <li>物件與資源管理</li>
                  <li>Render path & Shader</li>
                  <li>Post-Processing</li>
                  {/* <li>3D 模型應用</li>
                  <li>程式腳本設計</li>
                  <li>遊戲發佈設定</li> */}
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  {/* <h5>3dsMax遊戲應用實務</h5> */}
                  <h5>3D虛擬模型應用</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  {/* <li>建模操作</li> */}
                  <li>3D虛擬建模建置操作</li>
                  <li>材質與貼圖</li>
                  <li>3ds MAX與Unity整合應用</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  {/* <h5>遊戲製作流程與管理</h5> */}
                  <h5>程式專案製作流程與管理</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>制定開發團隊組織與流程</li>
                  {/* <li>遊戲開發與營運工具應用</li> */}
                  <li>程式專案開發與營運工具應用</li>
                  {/* <li>遊戲製作流程與時程規劃</li> */}
                  <li>程式專案製作流程與時程規劃</li>
                  <li>資料庫建立與管理、運用</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>Git流程開發</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>Git的運作原理</li>
                  <li>如何在團隊開發中使⽤Git-Flow</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>系統分析</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>UML、物件導向原則</li>
                  <li>Database正規化</li>
                  <li>系統開發範例</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  {/* <h5>遊戲數學</h5> */}
                  <h5>元宇宙數學</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>虛擬空間力場模擬</li>
                  <li>虛擬空間理解與碰撞偵測</li>
                  <li>3D座標系統、三角函數</li>
                  <li>矩陣與向量運算、直線與平面</li>
                  {/* <li>3D座標系統、三角函數、矩陣與向量運算</li> */}
                  {/* <li>直線與平面、碰撞偵測</li> */}
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  {/* <h5>遊戲人工智慧</h5> */}
                  <h5>人工智慧與互動程式設計</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>
                    搜尋演算法(Search Algorithms)、路徑搜尋 (Path Finding)
                  </li>
                  <li>
                    生物操控行為模擬 (Steering Behaviors)、群體運動 (Group
                    Steering)
                  </li>
                  <li>有限狀態機 (Finite State Machine)</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  {/* <h5>Unity多人遊戲程式設計</h5> */}
                  <h5>聯網程式設計</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>多人網路遊戲概論</li>
                  <li>客⼾端/伺服器端遊戲開發</li>
                  <li>實作聊天室程式(多人連線與交談)</li>
                  <li>實作聊天室Client程式與Unity整合</li>
                  <li>Photon Fusion介紹</li>
                  <li>使用Photon+Unity實作多人連線程式</li>
                </ul>
              </div>

              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  {/* <h5>XR概論</h5> */}
                  <h5>AR/VR/XR概論</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>元宇宙趨勢發展</li>
                  <li>VR概論與平台發展</li>
                  <li>VR with Cardboard</li>
                  <li>AR現況與應用</li>
                  <li>MR現況與應用</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>職場講座</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>講解撰寫履歷自傳與面試技巧</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>即時3D呈像程式設計</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>
                    Shader程式設計基礎 (Introduction to GPU & Shader
                    Programming)
                  </li>
                  <li>即時3D渲染流程 (Realtime 3D Rendering Pipeline)</li>
                  <li>Phong 光影反射數學模型 (Phong Reflection Model)</li>
                  <li>Phong 光影著色法 (Phong Shading)</li>
                  <li>法向量貼圖(Normal Map)</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>專題指導/製作</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>專題指導：老師指導、解決問題、review專題進度</li>
                  <li>專題製作：同學自行製作、討論、整合</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>專題預演/發表</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>簡報重點與技巧演練</li>
                  <li>專題正式發表</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  {/* <h5>Lab上機練習</h5> */}
                  <h5>術科演練及程式作業撰寫</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>
                    穿插在正課之間的上機練習時間，讓同學自我練習與調適學習節奏
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">招生簡章</div>
          <ul className="classInfoList__styleNone">
            <li>
              <a
                href="/pdf/資展國際_Unity跨平台遊戲開發工程師養成班_招訓簡章.pdf"
                className="orange"
                target="_blank"
                download
              >
                簡章下載<i className="fas fa-download"></i>
              </a>
            </li>
          </ul>
        </div> */}
        {/* <div className="pb-4">
          <div className="subT__div">招生對象</div>
          <ul className="classInfoList__styleNone">
            <li>男女不拘，但男性需役畢或免役</li>
            <li>
              有志投入學習遊戲開發者，需有邏輯或數學能力，資工科系或相關程式學習及接觸經驗者佳
            </li>
          </ul>
        </div> */}
        <div className="pb-4">
          <div className="subT__div">預備知識</div>
          <ul className="classInfoList__styleNone">
            <li>具備基本程式設計基礎較佳</li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>遊戲開發工程師</li>
            <li>C#網路程式開發</li>
            <li>Unity工程師</li>
            <li>網路遊戲程式設計</li>
            <li>AR/VR程式開發工程師</li>
            <li>技術美術(TA)</li>
            <li>軟體工程師</li>
            <li>遊戲規劃設計師</li>
          </ul>
        </div> */}
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                {/* <h4>Unity跨平台遊戲開發工程師養成班適合對象</h4> */}
                <h4>Unity互動程式開發工程師養成班適合對象</h4>
                <div className="longTermTabC__div">
                  {/* <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure> */}
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        男女不拘，但男性需役畢或免役
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        有志投入學習遊戲開發者，需有邏輯或數學能力，資工科系或相關程式學習及接觸經驗者佳
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員開發以Unity引擎為主軸的互動程式，包括開發流程管理、各項互動功能與進階應用
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員達到就業所需職能養成，從事元宇宙互動程式設計相關產業
                      </h5>
                    </div>
                  </div>
                  {/* <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        學習Unity 3D遊戲設計各項功能與實務進階應用
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        學習遊戲實務開發設計流程
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        達到就業所需職能養成
                      </h5>
                    </div>
                  </div> */}
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">遊戲開發工程師</li>
                  <li className="longTerm_tabContent_li">C#網路程式開發</li>
                  <li className="longTerm_tabContent_li">Unity工程師</li>
                  <li className="longTerm_tabContent_li">網路遊戲程式設計</li>
                  <li className="longTerm_tabContent_li">
                    AR/VR程式開發工程師
                  </li>
                  <li className="longTerm_tabContent_li">技術美術(TA)</li>
                  <li className="longTerm_tabContent_li">軟體工程師</li>
                  <li className="longTerm_tabContent_li">遊戲規劃設計師</li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default UnityDeveloperClass
