import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// UIUX就業養成班詳細內容, 20230901修改
function UIuxInteractiveClass(props) {
  const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      {/* 課程介紹 */}
      <div>
        <div className="classContent__figure mb-4">
          <img src="/img/employment/UIUX_banner.jpg" alt="" />
        </div>

        {/* <div className="pb-4">
          <h6><strong>【課程目標】</strong></h6>
          <ul className="listStyle__ul">
            <li>達到就業所需職能養成</li>
            <li>能獨立完成設計個人網站</li>
            <li>完成電子商務網站規劃設計與製作</li>
          </ul>
        </div> */}
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>業界講師授課，扎實的基礎學習，以及coding的能力題製作</li>
            <li>
              能夠學習到包含網頁視覺能力、UI/UX規劃設計能力、前端工程師實力
            </li>
            <li>最後學習專題作品完成度百分百</li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure">
          <img
            src={
              longTermClass && longTermClass.taxCode !== ''
                ? `/img/employment/syllabus/UIUX_JWUIUXT_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '台北':
                        return 'Taipei'
                      case '桃園':
                        return 'Zhongli'
                      case '台中':
                        return 'Taichung'
                      case '台南':
                        return 'Tainan'
                      case '高雄':
                        return 'kaohsiung'
                      default:
                        return ''
                    }
                  })()}_C2E2302.png?240426`
                : '/img/employment/syllabus/UIUX_JWUIUXT_Taipei.png?240426'
            }
            alt=""
          />
        </div>
        <p className="red pt-3">※課程內容以地區班級為準。</p>

        <div className="employmentClassification-list_content">
          <div className="row justify-content-center employmentClassification-list_inner">
            <div className="p-0 col-xxl-10 col-sm-12 d-flex flex-wrap employClassCard-justify">
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>視覺影像處理與 UI 設計</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>Photoshop視覺影像處理</li>
                  <li>Photoshop 網頁排版實作</li>
                  <li>Illustrator繪圖與UI設計</li>
                  <li>網頁配色與基礎設計概論</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>UI/UX工具(Figma)</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>工具環境設定與操作</li>
                  <li>各種狀置規格說明</li>
                  <li>UI 介面設計實作</li>
                  <li>UX 規劃流程圖製作</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>網頁設計入門</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>HTML基礎概念</li>
                  <li>HTML5 語意標籤</li>
                  <li>Visual Studio Code 開發工具</li>
                  <li>網頁切版與隔線系統使用介紹</li>
                  <li>CSS3基礎</li>
                  <li>CSS3 @media Rule 使用介紹</li>
                  <li>Parallax捲動頁面</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>前端網頁動態技術</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>CSS3網頁動態效果</li>
                  <li>Bootstrap與Flex 排版</li>
                  <li>JavaScript網頁動態效果</li>
                  <li>指導完成個人RWD網站作品</li>
                  <li>個人作品發表</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>網站規劃與行銷</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>網站定位分析、網站架構規劃</li>
                  <li>分組討論與規劃</li>
                  <li>網站視覺規劃、與網路行銷</li>
                  <li>網站媒體行銷規劃與宣傳</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>UI/UX實務流程</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>UI/UX流程說明</li>
                  <li>使用者行為、介面行為、系統行為差異</li>
                  <li>用戶體驗地圖(User Experience Map)</li>
                  <li>Functional Map</li>
                  <li>Wireframe</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>網頁前端框架 Vue.js開發</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>Vue.js 基本概念說明</li>
                  <li>使用 Vue CLI 建立 Vue開發環境</li>
                  <li>Vue 指令（Directive）</li>
                  <li>Vue 事件處理</li>
                  <li>Vue 元件(組件)(Component)</li>
                  <li>Vue Router(路由)</li>
                  <li>元件間的資料管理 - Pinia</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>專題實務整合</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>
                    依UI/UX實務流程課程研究與調查的使用者目標族群及需求並訂定網站功能架構與視覺風格，搭配網頁切版技術開發響應式網頁
                  </li>
                  <li>網站企劃書及網站簡報</li>
                  <li>專題觀摩及發表</li>
                  <li>專題指導</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>就業輔導</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>指導完成履歷及作品集</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>網頁視覺設計師</li>
            <li>UI/UX設計規劃</li>
            <li>網站行銷企劃</li>
            <li>網站規劃設計</li>
            <li>前端工程師</li>
            <li>網頁工程師</li>
            <li>UI設計師</li>
            <li>UX規劃師</li>
          </ul>
        </div> */}
        <div className="pb-4">
          <div className="subT__div">結訓學員就業產業資訊</div>
          <ul className="classInfoList__styleNone">
            <li>大陸Tencent騰訊 - UI/UX設計(李XX)</li>
            <li>大陸Autodesk - Software Engineer(蘇xx)</li>
            <li>
              鴻海富士康 - UI/UX(簡XX), 前端工程師(陳XX), 網頁視覺設計(楊XX)
            </li>
            <li>ASUS華碩 - Senior Web Engineer(孔xx), Web Engineer(鐘xx)</li>
            <li>廣達電腦 - UI/UX Designer(江xx), Web Engineer (胡xx)</li>
            <li>泰金寶集團 視覺設計(楊xx, 艾xx), UIUX(簡xx, 洪xx)</li>
            <li>技嘉科技 - Web Designer(廖xx, 歐xx)</li>
            <li>玉山銀行 - Web Designer(郭xx, 林xx)</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>UI/UX創新策略網頁設計就業養成班適合對象</h4>
                <div className="longTermTabC__div">
                  {/* <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure> */}
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        經教育部核准立案之國內外公私立大專院校畢業青年
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對於網頁畫面呈現等程式設計領域有興趣
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        具備基本英文閱讀能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        有耐力與毅力，願意接受本班考驗
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        達到就業所需職能養成
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        能獨立完成設計個人網站
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        完成電子商務網站規劃設計與製作
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">網頁視覺設計師</li>
                  <li className="longTerm_tabContent_li">UI/UX設計規劃</li>
                  <li className="longTerm_tabContent_li">網站行銷企劃</li>
                  <li className="longTerm_tabContent_li">網站規劃設計</li>
                  <li className="longTerm_tabContent_li">前端工程師</li>
                  <li className="longTerm_tabContent_li">網頁工程師</li>
                  <li className="longTerm_tabContent_li">UI設計師</li>
                  <li className="longTerm_tabContent_li">UX規劃師</li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default UIuxInteractiveClass
