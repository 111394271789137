import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// AI 就業養成班詳細內容, 20230901修改
function AIoTSeriesClass(props) {
  const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      {/* <div className="classContent__figure">
        <img src="/img/employment/AI_head.png" alt="" />
      </div> */}
      <div className="txtC__div text-justify mt-0">
        <div className="employmentClassification-instruction_p">
          <p>
            AI 就是未來！藉由雲端運算(Cloud Computing)、邊緣運算(Edge Computing)
            這些強大的算力，並經由具備高速率、低延遲、大連結等特性的5G
            系統將這些大大小小的物聯網(IoT)裝置串聯在一起，物換星移下，我們已一步又一步地邁向前所未見的AI
            桃花源、美麗新世界。AI
            的璀璨光輝無遠弗屆，智慧生活將永無止盡。唯有搞懂人類的行為，才能滿足人們實際的需求；善用數位科技，實事求是，才能解決普羅大眾生活上的問題與痛點；AI
            生活化、生活AI
            化，才能將咱們國家的國力得以大幅提升、人民的福祉得以大幅躍進。
          </p>
          <br />
          <p>是的，毋庸置疑，『AI 就是你、我生活的未來！』</p>
        </div>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          由淺入深、技術扎根
        </h4>
        <p className="employmentClassification-instruction_p">
          本班課程內容是以紮實的Python與Java兩大熱門的程式語言開發技術打底，循序漸進邁入資料分析、機器學習、深度學習等奇幻之旅程，並佐以RaspberryPi(樹莓派)IoT平台進行
          影像辨識等應用，之後透過Node.js、JavaEE進行網站前、後端技術的整合與串接，以此實作出人工智慧創新應用的專案成果出來。
          <br />
          透過本班精心規劃的課程學習，將可以習得多種神經網路與運算方法，然後經由期末專題的實作加以整合，如此可以提高學習的樂趣，完成技術目的，並順利進入就業市場。
        </p>
      </div>

      {/* 課程介紹 */}
      <div>
        <div className="pb-4">
          <h6>
            <strong>【課程目標】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              本訓練目標為使學員能習得人工智慧的相關知識、Java與Python程式設計開發、資料分析處理、機器學習與深度學習的專業知識與技能，並具小組分工從事人工智慧系統設計與實作之能力，使學員於通過考核、取得結訓證書後能順利就業。
            </li>
            <li>
              1）培養學員能夠運用JavaScript、Node.js、Java
              Web等網站前後端整合開發技術，在Raspberry Pi
              IoT平台進行AI機器學習以及OpenCV影像辨識應用，展現出AIoT(AI人工智慧+IoT物聯網)的創新應用
            </li>
            <li>
              2）培養學員能夠運用Python程式語言，進行資料分析、影像辨識、機器學習與深度學習等創新應用
            </li>
            <li>
              3）培養學員能夠運用TensorFlow(含Keras)深度學習框架來建構深度學習的模型
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              從零開始！
              本班從Python程式設計、Java程式設計、JavaScript語法入門開始，一路引領您實作到AI相關的應用、機器學習專案的開發
            </li>
            <li>
              強調實作！ 透過Node.js、JavaEE進行網站前後端的整合；藉由Raspberry
              Pi(樹莓派)實驗板，讓您得以自行動手完成您想要的AI創新應用
            </li>
            <li>
              專題導向！
              透過專題小組的團隊合作以及大、小專題的歷練，引導您完成期末專題並上台進行成果發表
            </li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure pb-3">
          <img
            src={
              longTermClass && longTermClass.taxCode !== ''
                ? `/img/employment/syllabus/AI_JEAIMLT_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '台北':
                        return 'Taipei'
                      default:
                        return ''
                    }
                  })()}_C2E2302.png?241017`
                : '/img/employment/syllabus/AI_JEAIMLT_Taipei.png?241017'
            }
            alt="AIoT智慧機器人就業養成班"
          />
        </div>
        <p className="red mb-5">※課程內容以地區班級為準。</p>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">招生對象</div>
          <ul className="classInfoList__styleNone">
            <li>
              對於運用JavaScript語言、Node.js、Java Web技術進行網站開發有興趣者
            </li>
            <li>
              對於運用Python語言進行資料分析、機器學習、深度學習等技術應用有興趣者
            </li>
            <li>
              對於運用Raspberry
              Pi(樹莓派)、OpenCV影像辨識以及IoT平台智慧裝置應用整合有興趣者
            </li>
            <li>
              對於AIoT(AI人工智慧+IoT物聯網)應用有興趣、有志成為創客Maker一族者
            </li>
            <li>
              對於物聯網智慧創新應用、網站前後端整合開發應用、資料科學等工作職務嚮往者
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>IoT物聯網工程師</li>
            <li>Python 機器學習工程師</li>
            <li>Python 資料分析師</li>
            <li>AI機器學習智慧裝置應用工程師</li>
            <li>Node.js 網站應用開發工程師</li>
            <li>OpenCV影像辨識應用工程師</li>
            <li>Java 網站應用開發工程師</li>
            <li>Python 應用開發工程師</li>
            <li>網站應用開發工程師</li>
            <li>企業內部應用系統開發人員</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              主要安排於星期一到星期五週間白天，但因部分課程配合業師的時間，部份課程有可能會安排於『夜間時段』與『假日時段』來進行授課
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">備註</div>
          <ul className="classInfoList__styleNone">
            <li>
              1）加贈價值約NT$7,000元的Raspberry
              Pi(樹莓派)實驗版乙套，詳情請參加免費的說明會
            </li>
            <li>
              2）請注意！本班可能會有少部分的課程為『假日時段』與『夜間時段』來進行授課
            </li>
            <li>
              3）請注意！必須本人親自前來聆聽說明會的介紹後，才可以依報名順位繳費來上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>
      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>3D遊戲美術設計人才養成班適合對象</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">熟悉電腦基本操作</h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對3D遊戲美術設計有強烈學習意願及興趣
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        美術或設計相關科系畢業者尤佳
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        男女不拘，但男性需役畢或免役
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        解3D遊戲業界專案設計流程
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        學習3D遊戲美術實務與專業遊戲技術應用，以Unity整合美術與互動設計
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        具備遊戲專題製作能力
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">SOHO接案</li>
                  <li className="longTerm_tabContent_li">獨立製作</li>
                  <li className="longTerm_tabContent_li">出國升學</li>
                  <li className="longTerm_tabContent_li">職場就業</li>
                  <li className="longTerm_tabContent_li">3D遊戲美術設計</li>
                  <li className="longTerm_tabContent_li">3D角色製作</li>
                  <li className="longTerm_tabContent_li">3D場景製作</li>
                  <li className="longTerm_tabContent_li">3D角色動作</li>
                  <li className="longTerm_tabContent_li">遊戲特效設計</li>
                  <li className="longTerm_tabContent_li">3D動畫設計</li>
                </ul>
              </div>
            </Tab> */}
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default AIoTSeriesClass
