import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { OnJobClassTypeInfo } from '../../services/OnJob'

// 在職班課程 > 彤彤的課程, "主題系列" 頁面
function TrainingClassesForTheme() {
  const [classType, setClassType] = useState('')

  let url = useLocation()

  const path = url.pathname.split('/')[1]

  // 將課程內容html裝入classType
  useEffect(() => {
    const data = OnJobClassTypeInfo(path)

    data
      .then((classInfo) => {
        return classInfo.text()
      })
      .then((classInfo) => {
        setClassType(classInfo)
      })
  }, [path])

  // 卸載時還原載入狀態
  useEffect(() => {
    return () => {
      setClassType()
    }
  }, [setClassType])

  return (
    <>
      <div className="container-fluid p-0 ojt-page">
        {/* ↓↓↓　*/}
        <div className="job-content_form">
          <div className="job-form_bc ojt_individualPageBcc_div">
            <div className="custom-container">
              <div className="job-form_title white">
                {(() => {
                  switch (path) {
                    case 'ITA':
                      return <h2>軟體開發/程式設計/智慧綠能課程主題館</h2>
                    case 'AIT':
                      return <h2>人工智慧和大數據課程主題館</h2>
                    case 'IST':
                      return <h2>資訊安全課程主題館</h2>
                    case 'ESG':
                      return <h2>ESG永續學堂主題館</h2>

                    default:
                      return null
                  }
                })()}
              </div>
            </div>
          </div>
          {/* 內容 */}
          <div className="custom-container">
            <div className="enterprise-content_m">
              <div className="job-form_content">
                <div className="job-form_inner digitalTrans_inner_div">
                  <div
                    className="json-content ojt_individualPage_div ojt_themePage_div position-relative"
                    dangerouslySetInnerHTML={{
                      __html: classType,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {/* 內容 */}
        </div>
        {/* ↑↑↑　*/}
      </div>
    </>
  )
}
export default TrainingClassesForTheme
