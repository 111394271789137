import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// AI跨界行銷資料科學專業班詳細內容, 20230904新增
function AnalyseInformationClass(props) {
  const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  // 課程內容資訊打開與隱藏狀態
  const [switchCss1, setSwitchCss1] = useState(false)
  const [switchCss2, setSwitchCss2] = useState(false)
  const [switchCss3, setSwitchCss3] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)

  // 展開詳細內容狀態
  const handleOpen1 = () => {
    setSwitchCss1(!switchCss1)
    setOpen1(!open1)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
  }

  const handleOpen2 = () => {
    setSwitchCss2(!switchCss2)
    setOpen2(!open2)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss3(false)
    setOpen3(false)
  }

  const handleOpen3 = () => {
    setSwitchCss3(!switchCss3)
    setOpen3(!open3)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
  }

  return (
    <>
      <div className="txtC__div text-justify mt-0">
        <p>
          MarTech（行銷科技）是指結合行銷與技術的領域，旨在應用數位科技和資料分析來增強行銷活動的效果和效率。這個詞匯是由「行銷」（Marketing）和「科技」（Technology）兩個詞組成的合成詞。
        </p>
        <br />
        <p>
          在數位化時代，市場營銷的方式已經發生了革命性的變化。傳統的廣告手法不再足夠，而隨著網際網路、社交媒體和移動技術的崛起，行銷科技的重要性不斷增加。MarTech的主要目標是通過運用各種技術工具和數據分析來實現更有針對性的行銷活動，並更好地理解和滿足目標客戶的需求。
        </p>
        <br />
        <p>MarTech涵蓋了各種不同的技術和解決方案，其中包括：</p>
        <p>
          1.
          數據分析工具：用於收集、整理和分析客戶數據，從中提取有價值的見解，幫助企業更好地了解目標客戶的喜好和行為。
        </p>
        <p>
          2.
          客戶關係管理系統（CRM）：用於有效地管理客戶資料，跟進客戶互動，提供更加個性化的服務和推廣活動。
        </p>
        <p>
          3.
          個性化行銷工具：利用客戶資料和行為模式來提供定制化的行銷內容，使其更符合客戶的興趣和需求。
        </p>
        <p>
          4.
          社交媒體行銷：利用社交媒體平台來與潛在客戶和現有客戶進行互動，推廣產品和服務。
        </p>
        <p>
          5.
          數位廣告技術：包括搜尋引擎優化（SEO）、搜尋引擎行銷（SEM）、網站廣告和應用程式廣告等數位廣告手段。
        </p>
        <p>
          6.
          自動化行銷：使用自動化工具和流程來自動執行行銷活動，提高效率並節省時間和人力。
        </p>
        <p>
          7.
          數據管理平台：用於整合和管理來自不同數據來源的客戶數據，以實現全面的數據分析和行銷策略。
        </p>
        <br />
        <p>
          MarTech的應用範圍非常廣泛，從中小型企業到大型跨國企業都在採用這些技術來改進行銷策略並提升業務成績。然而，MarTech的快速發展也帶來了一些挑戰，如數據隱私和安全性問題，以及技術整合的複雜性。因此，企業在應用MarTech時需要仔細考慮相關風險並選擇合適的解決方案。
        </p>
        <br />
        <p>
          總的來說，MarTech是現代行銷不可或缺的一部分，它幫助企業更加精準地了解客戶、提供個性化體驗並在競爭激烈的市場中取得優勢。隨著科技的不斷進步，MarTech領域也會持續演進，為行銷人員帶來更多創新和挑戰。
        </p>
      </div>

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure pb-3">
          <img
            src={
              longTermClass && longTermClass.taxCode !== ''
                ? `/img/employment/syllabus/Analyse_JPDSMKT_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '台北':
                        return 'Taipei'
                      default:
                        return ''
                    }
                  })()}_C2E2302.png?231005`
                : '/img/employment/syllabus/Analyse_JPDSMKT_Taipei.png?231005'
            }
            alt=""
          />
        </div>
        <p className="red mb-5">※課程內容以地區班級為準。</p>

        {/* 展開詳細介紹 ↓↓↓　*/}
        <div className="mt-4 pb-5">
          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen1()}
              aria-controls="example-collapse-text"
              aria-expanded={open1}
              className={
                switchCss1
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              資料分析與視覺化
            </button>
          </div>
          <Collapse in={open1}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  <p>
                    1. 基礎Python程式設計：
                    <br />
                    在本課程中，學生將學習Python程式設計的基本概念、語法和技巧。學生將編寫Python程式來處理數據，進行數值計算和編寫簡單的演算法。這套語言的易讀性和豐富的第三方程式庫使其成為資料科學和分析領域的首選工具。
                  </p>
                  <br />
                  <p>
                    2. 網路爬蟲實務：
                    <br />
                    這門課程將教授學生如何使用Python來建立網路爬蟲，以從網站上抓取數據。學生將學會分析網站的結構，提取目標數據，以供後續進行數據清洗和整理。這對於資料分析是必不可少的，因為它提供了收集實際數據的能力。
                  </p>
                  <br />
                  <p>
                    3. 基礎資料處理：
                    <br />
                    這個課程將教授學生處理各種類型數據的技能，包括結構化和非結構化數據。學生將學會使用Python等工具來處理缺失值、重複值和異常值，並進行數據轉換和標準化，以便進行後續分析。
                  </p>
                  <br />
                  <p>
                    4. MySQL資料庫實務應用：
                    <br />
                    這門課程將介紹學生MySQL資料庫的基本概念和操作。學生將學會建立和管理資料庫，設計資料表，進行查詢和聯接操作。資料庫技能是實現大型資料集管理和有效查詢的基礎。
                  </p>
                  <br />
                  <p>
                    5. Tableau圖表工具：
                    <br />
                    本課程將教授學生使用Tableau進行資料視覺化的技能。學生將學會使用Tableau建立各種圖表和視覺化説故事板，以呈現分析結果和發現數據中的模式和趨勢。Tableau是一個流行且強大的資料視覺化工具，使得將行銷相關數據轉換為易於理解的視覺展示變得輕而易舉。
                  </p>
                  <br />
                  <p>
                    「資料分析與視覺化」課程將使學生從頭開始掌握處理數據的技能，包括數據收集、清理、處理、分析和視覺化。學生將具備運用Python和MySQL等工具進行資料處理和分析的能力，同時學會使用Tableau進行數據視覺化和溝通分析結果。這樣的綜合能力將使學生在行銷相關領域取得競爭優勢，並為企業或研究機構提供有價值的數據洞察力。
                  </p>
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen2()}
              aria-controls="example-collapse-text"
              aria-expanded={open2}
              className={
                switchCss2
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              人工智慧應用
            </button>
          </div>
          <Collapse in={open2}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  <p>
                    1. 基礎機器學習簡介與應用：
                    <br />
                    機器學習是人工智慧的重要支柱，它使機器能夠透過經驗學習，進行模式識別和決策。在這個部分，學員將學習基本機器學習概念，如監督式學習、非監督式學習和強化學習等。同時，他們也將探討機器學習在現實生活中的廣泛應用，例如自然語言處理、影像辨識、推薦系統等。
                  </p>
                  <br />
                  <p>
                    2. 文字探勘與ChatGPT：
                    <br />
                    文字探勘是一個重要的自然語言處理技術，它將人類語言轉換為結構化的數據，使得計算機能夠處理和分析。在這個部分，學員將學習如何使用文本處理、詞向量表示和情感分析等技術，以及如何應用於文本分類、情緒分析和資訊擷取等實際應用場景。而ChatGPT是一種強大的語言模型，它基於GPT-3.5架構，能夠理解和生成人類語言。在這個部分，學員將學習如何使用ChatGPT進行自然對話，包括如何構建對話環境、調整模型輸出、處理回饋等。同時，他們也將了解ChatGPT在客服、虛擬助手和創意寫作等領域的實際應用。
                  </p>
                  <br />
                  <p>
                    「人工智慧應用」此類課程的目標是培養學員在人工智慧領域的應用能力，使他們能夠將所學知識轉化為實際解決方案。通過這門課程，學員將掌握基礎機器學習技術、文字探勘技巧和ChatGPT的應用能力，並且能夠在行銷、客服、自然語言處理等領域中運用這些技術，推動企業的轉型與創新。
                  </p>
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen3()}
              aria-controls="example-collapse-text"
              aria-expanded={open3}
              className={
                switchCss3
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              GA數據應用
            </button>
          </div>
          <Collapse in={open3}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  <p>
                    1. Google Analytics 4（GA4）：
                    <br />
                    這是一個功能豐富的網站分析工具，可以幫助學員追蹤網站流量、使用者互動和轉換率等關鍵指標。學員將學習建立GA4帳戶、設定跟蹤代碼，並深入了解事件追蹤和使用者行為分析，以優化網站體驗和業務目標。
                  </p>
                  <br />
                  <p>
                    2. Google Tag Manager（GTM）與Google Trends：
                    <br />
                    GTM是一個強大的標籤管理工具，可讓用戶輕鬆添加、編輯和管理網站上的跟蹤代碼和像素。課程將包括如何在GTM中設定和管理標籤、觸發器和變數，以實現更靈活的跟蹤和數據收集，同時減少對開發人員的依賴。Google
                    Trends則是一個用於探索全球搜索趨勢的工具。學員將學習如何使用Google
                    Trends來分析關鍵字的熱門程度和趨勢，從而指導市場營銷策略、SEO優化和內容創作。
                  </p>
                  <br />
                  <p>
                    通過結合GA4、GTM與Google
                    Trends，本系列「GA數據應用」課程將使學員能夠透過GA為例，深入理解網站數據、優化用戶體驗，並應用趨勢分析來指導行銷決策。無論是初學者還是有經驗的從業人士，都能從中受益，將所學知識轉化為實際成果。
                  </p>
                </h6>
              </div>
            </div>
          </Collapse>
        </div>
        {/* 展開詳細介紹 ↑↑↑　*/}
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天為主，另因配合企業講師之時間安排，部份課程會於週末假日或平日晚上上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      課務承辦人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>AI跨界行銷資料科學專業班適合對象</h4>
                <div className="longTermTabC__div">
                  {/* <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure> */}
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">大專(以上)畢業青年</h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        基本不限，歡迎各領域的有志者加入。若有就讀過行銷相關科系或曾有行銷相關工作經驗尤佳
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對將「行銷」與「資料」這兩件事情的結合感興趣，願意投入時間心力，且具備高度學習意願
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  {/* <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure> */}
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備行銷科技與相關工作技能
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員習得資料分析與視覺化的核心技能
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員學習人工智慧及文字探勘等技術
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員操作Google Analytics
                        4並將其延伸到各種行銷場合的經驗
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        透過實際的專題整合以證明學員能有處理行銷數據的能力並應用至跨產業的情境
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">行銷資料分析師</li>
                  <li className="longTerm_tabContent_li">資料視覺化工程師</li>
                  <li className="longTerm_tabContent_li">行銷企劃專員</li>
                  <li className="longTerm_tabContent_li">品牌行銷專員</li>
                  <li className="longTerm_tabContent_li">行銷專案經理</li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default AnalyseInformationClass
