import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'

function Question() {
  const [switchCss1, setSwitchCss1] = useState(false)
  const [open1, setOpen1] = useState(false)
  // 展開詳細內容狀態
  const handleOpen1 = () => {
    setSwitchCss1(!switchCss1)
    setOpen1(!open1)
  }
  return (
    <>
      <div className="custom-container">
        <div className="bcBlockInner__div pt-0">
          <h3 className="black">問與答</h3>
          <figure>
            <img
              className="deskImg__img w-100"
              alt=""
              src="/img/question.png"
            />
            <img
              className="mobileImg__img w-100"
              alt=""
              src="/img/question_mobile.png"
            />
          </figure>
          <span className="lesson-content_detail">
            <div className="classmate-content_margin">
              <div className="ojt-q_content">
                <button
                  onClick={() => handleOpen1()}
                  aria-controls="example-collapse-text"
                  aria-expanded={open1}
                  className={
                    switchCss1
                      ? 'classmate-content_button opened'
                      : 'classmate-content_button closed'
                  }
                >
                  <span>Q. </span>這個班級有沒有補助呢?
                </button>
              </div>
              <Collapse in={open1}>
                <div
                  id="example-collapse-text"
                  className="classmate-content__body"
                >
                  <div className="ojt-a_content">
                    <h6>
                      <p>
                        <span>A. </span>
                        您可以到台灣就業通查詢此班級是否有符合補助計畫
                        <br />
                        <a
                          href="https://course.taiwanjobs.gov.tw/course/search-training"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://course.taiwanjobs.gov.tw/course/search-training
                        </a>
                      </p>
                    </h6>
                  </div>
                </div>
              </Collapse>
            </div>
          </span>
        </div>
      </div>
    </>
  )
}

export default Question
