import React from 'react'
// import { DropdownLongtermItems } from './DropdownLongtermItems'
import { Link } from 'react-router-dom'
import { EmploymentClassList } from '../EmploymentClass/EmploymentClassList'

// 就業養成班一覽分類
function LongTermItems(props) {
  const { handleClick } = props
  // 就業養成班選項預設是消失狀態, 點擊每個分類之後到達分頁呈現消失
  // const [click, setClick] = useState(false)

  // 點擊課程就移除localStorage
  const handleRemoveRegion = () => {
    if (JSON.parse(window.localStorage.getItem('longTermFromRegion'))) {
      localStorage.removeItem('longTermFromRegion')

      window.scrollTo(0, 0)
    }
  }

  return (
    <>
      <div className="dropdown_subClassification_div">
        <div className="dropdown_subClassificationContent_div">
          <div className="classificationTheme">
            <div className="dropdown_subClassificationTitle_div">
              <h3>課程一覽</h3>
            </div>
          </div>
          <div className="classificationTheme classificationTheme_subContent">
            <ul>
              {EmploymentClassList.map((v, i) => {
                return (
                  <li key={i} className="longTerm-content_li">
                    <Link
                      className="longTerm-content_link"
                      to={v.path}
                      onClick={handleClick}
                      onMouseDown={handleRemoveRegion}
                    >
                      {v.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default LongTermItems
