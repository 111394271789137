import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'

// 在職班學員常見問題
function ClassmateQuestion(props) {
  const { classTypeName } = props

  const [switchCss1, setSwitchCss1] = useState(false)
  const [switchCss2, setSwitchCss2] = useState(false)
  const [switchCss3, setSwitchCss3] = useState(false)
  const [switchCss4, setSwitchCss4] = useState(false)
  const [switchCss5, setSwitchCss5] = useState(false)
  const [switchCss6, setSwitchCss6] = useState(false)
  const [switchCss7, setSwitchCss7] = useState(false)
  const [switchCss8, setSwitchCss8] = useState(false)
  const [switchCss9, setSwitchCss9] = useState(false)

  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [open5, setOpen5] = useState(false)
  const [open6, setOpen6] = useState(false)
  const [open7, setOpen7] = useState(false)
  const [open8, setOpen8] = useState(false)
  const [open9, setOpen9] = useState(false)

  // 展開詳細內容狀態
  const handleOpen1 = () => {
    setSwitchCss1(!switchCss1)
    setOpen1(!open1)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen2 = () => {
    setSwitchCss2(!switchCss2)
    setOpen2(!open2)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen3 = () => {
    setSwitchCss3(!switchCss3)
    setOpen3(!open3)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen4 = () => {
    setSwitchCss4(!switchCss4)
    setOpen4(!open4)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen5 = () => {
    setSwitchCss5(!switchCss5)
    setOpen5(!open5)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen6 = () => {
    setSwitchCss6(!switchCss6)
    setOpen6(!open6)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen7 = () => {
    setSwitchCss7(!switchCss7)
    setOpen7(!open7)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen8 = () => {
    setSwitchCss8(!switchCss8)
    setOpen8(!open8)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen9 = () => {
    setSwitchCss9(!switchCss9)
    setOpen9(!open9)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  return (
    <>
      <div className="custom-container" id="question">
        <div className="onJob-content_m">
          <div className="bcBlockInner__div">
            <h3 className="black">常見問題</h3>
            <span className="lesson-content_detail">
              <div className="classmate-content_margin">
                <div className="ojt-q_content">
                  <button
                    onClick={() => handleOpen1()}
                    aria-controls="example-collapse-text"
                    aria-expanded={open1}
                    className={
                      switchCss1
                        ? 'classmate-content_button opened'
                        : 'classmate-content_button closed'
                    }
                  >
                    <span>Q. </span>如何知道該課程確定開課?
                  </button>
                </div>
                <Collapse in={open1}>
                  <div
                    id="example-collapse-text"
                    className="classmate-content__body"
                  >
                    <div className="ojt-a_content">
                      <h6>
                        <p>
                          <span>A. </span>
                          至少需達10人才予開課，本公司最晚於上課前一週通知是否開課。
                          <br />
                          ※主辦單位得視報名情況而保留實際開課日期的變更權利
                        </p>
                      </h6>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="classmate-content_margin">
                <div className="ojt-q_content">
                  <button
                    onClick={() => handleOpen2()}
                    aria-controls="example-collapse-text"
                    aria-expanded={open2}
                    className={
                      switchCss2
                        ? 'classmate-content_button opened'
                        : 'classmate-content_button closed'
                    }
                  >
                    <span>Q. </span>上課地點在哪裡?
                  </button>
                </div>
                <Collapse in={open2}>
                  <div
                    id="example-collapse-text"
                    className="classmate-content__body"
                  >
                    <div className="ojt-a_content">
                      <h6>
                        <p>
                          <span>A. </span>
                          資展國際股份有限公司
                          <br />
                          台北 : 台北市復興南路一段390號2、3樓
                          <br />
                          桃園 : 桃園市中壢區新生路二段421號 (聖德基督學院)
                          <br />
                          台中 : 台中市南屯區公益路二段51號18樓
                          <br />
                          高雄 : 高雄市中正四路211號8樓之1
                          <br />
                          ※上課地點與教室之確認，以上課通知函為主
                        </p>
                      </h6>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="classmate-content_margin">
                <div className="ojt-q_content">
                  <button
                    onClick={() => handleOpen3()}
                    aria-controls="example-collapse-text"
                    aria-expanded={open3}
                    className={
                      switchCss3
                        ? 'classmate-content_button opened'
                        : 'classmate-content_button closed'
                    }
                  >
                    <span>Q. </span>要如何報名課程?
                  </button>
                </div>
                <Collapse in={open3}>
                  <div
                    id="example-collapse-text"
                    className="classmate-content__body"
                  >
                    <div className="ojt-a_content">
                      <h6>
                        <p>
                          <span>A. </span>
                          本課程採線上報名，請至課程頁面報名。
                          <br />
                          ※客服專線 : (02) 6631-6588、(02) 6631-6591
                        </p>
                      </h6>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="classmate-content_margin">
                <div className="ojt-q_content">
                  <button
                    onClick={() => handleOpen4()}
                    aria-controls="example-collapse-text"
                    aria-expanded={open4}
                    className={
                      switchCss4
                        ? 'classmate-content_button opened'
                        : 'classmate-content_button closed'
                    }
                  >
                    <span>Q. </span>什麼時候要繳課程費用?
                  </button>
                </div>
                <Collapse in={open4}>
                  <div
                    id="example-collapse-text"
                    className="classmate-content__body"
                  >
                    <div className="ojt-a_content">
                      <h6>
                        <p>
                          <span>A. </span>
                          報名課程之學員將於開課前7天內收到e-mail通知，確認課程是否開課，確認開課才需繳費。
                          <br />
                          ※政府計畫學費補助方案班級，則悉依計畫規定辦理
                        </p>
                      </h6>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="classmate-content_margin">
                <div className="ojt-q_content">
                  <button
                    onClick={() => handleOpen5()}
                    aria-controls="example-collapse-text"
                    aria-expanded={open5}
                    className={
                      switchCss5
                        ? 'classmate-content_button opened'
                        : 'classmate-content_button closed'
                    }
                  >
                    <span>Q. </span>有什麼繳費方式?
                  </button>
                </div>
                <Collapse in={open5}>
                  <div
                    id="example-collapse-text"
                    className="classmate-content__body"
                  >
                    <div className="ojt-a_content">
                      <h6>
                        <p>
                          <span>A. </span>
                          匯款方式可選擇信用卡、ATM匯款。
                          <br />- 以信用卡支付者 :
                          確定開班後會寄發訂單連結，請於指定繳費期限內完成線上刷卡
                          {/* 請務必將正確信用卡資料填寫於信用卡授權書並回傳 */}
                          <br />
                          - 以ATM匯款支付者 :
                          確定開班後會寄發繳費帳號，請於指定繳費期限內完成繳費
                          <br />
                          ※課程若未如期開班，費用將全額退還
                        </p>
                      </h6>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="classmate-content_margin">
                <div className="ojt-q_content">
                  <button
                    onClick={() => handleOpen6()}
                    aria-controls="example-collapse-text"
                    aria-expanded={open6}
                    className={
                      switchCss6
                        ? 'classmate-content_button opened'
                        : 'classmate-content_button closed'
                    }
                  >
                    <span>Q. </span>報名課程有哪些優惠方案嗎?
                  </button>
                </div>

                <Collapse in={open6}>
                  <div
                    id="example-collapse-text"
                    className="classmate-content__body"
                  >
                    <div className="ojt-a_content">
                      <h6>
                        {classTypeName ===
                          '成為職業調色師的必備心法 - 調色師進階實務' ||
                        classTypeName ===
                          '揭開後製調光和色彩學的面紗 - 調色師入門認知' ? (
                          <p>
                            <span>A. </span>
                            <br />
                            1) 本班限時限額特別優惠，無其他額外優惠
                          </p>
                        ) : (
                          <p>
                            <span>A. </span>
                            <br />
                            1) 團報優惠 :
                            2-3人團報可打95折、4人(以上)團報可打9折優惠
                            (團報優惠與早鳥優惠可一併使用)
                            <br />
                            2) 每門課程會有早鳥報名優惠期限
                            <br />
                            ※線上課程報名每個帳號僅提供一位學員報名，若為團報學員，每位皆必須完成線上報名，並於其他金額說明欄位註記「與XXX一起團報」，以利事後核對名單
                            <br />
                            ※政府計畫學費補助方案班級，則悉依計畫規定辦理
                          </p>
                        )}
                      </h6>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="classmate-content_margin">
                <div className="ojt-q_content">
                  <button
                    onClick={() => handleOpen7()}
                    aria-controls="example-collapse-text"
                    aria-expanded={open7}
                    className={
                      switchCss7
                        ? 'classmate-content_button opened'
                        : 'classmate-content_button closed'
                    }
                  >
                    <span>Q. </span>報名確認與取消的相關問題
                  </button>
                </div>
                <Collapse in={open7}>
                  <div
                    id="example-collapse-text"
                    className="classmate-content__body"
                  >
                    <div className="ojt-a_content">
                      <h6>
                        <p>
                          <span>A. </span>
                          <br />
                          1)
                          已完成報名與繳費之學員，課程主辦單位將於開課三天前以E-mail方式寄發上課通知函；若課程因故取消或延期，亦將以E-mail方式通知，如未收到任何通知
                          ，敬請來電確認。
                          <br />
                          2)
                          已完成繳費之學員如欲取消報名，請於實際上課日前以信件通知業務承辦人，主辦單位將退還90%
                          課程費用。
                          <br />
                          3)
                          學員於培訓期間如因個人因素無法繼續參與課程，將依課程退費規定辦理之︰上課未逾總時數三分之一，欲辦理退費，可退還所有費用二分之一；上課逾總
                          時數三分之一，則不退費。
                          <br />
                          4) 課程執行單位保留是否接受報名之權利。
                          <br />
                          5)
                          如遇不可抗拒之因素，課程主辦單位保留修訂課程日期及取消課程的權利
                          。
                          <br />
                          6) 政府計畫學費補助方案班級，則悉依計畫規定辦理。
                        </p>
                      </h6>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="classmate-content_margin">
                <div className="ojt-q_content">
                  <button
                    onClick={() => handleOpen8()}
                    aria-controls="example-collapse-text"
                    aria-expanded={open8}
                    className={
                      switchCss8
                        ? 'classmate-content_button opened'
                        : 'classmate-content_button closed'
                    }
                  >
                    <span>Q. </span>上完課程會有結業證明嗎?
                  </button>
                </div>
                <Collapse in={open8}>
                  <div
                    id="example-collapse-text"
                    className="classmate-content__body"
                  >
                    <div className="ojt-a_content">
                      <h6>
                        <p>
                          <span>A. </span>
                          依資展國際股份有限公司ISO品質系統之「教育訓練服務程序」規定，本課程為短期班，參訓學員缺課未超過總時數五分之一者，結業時由本公司核發結業證書。
                        </p>
                      </h6>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="classmate-content_margin">
                <div className="ojt-q_content">
                  <button
                    onClick={() => handleOpen9()}
                    aria-controls="example-collapse-text"
                    aria-expanded={open9}
                    className={
                      switchCss9
                        ? 'classmate-content_button opened'
                        : 'classmate-content_button closed'
                    }
                  >
                    <span>Q. </span>上課期間是否有提供餐點?
                  </button>
                </div>
                <Collapse in={open9}>
                  <div
                    id="example-collapse-text"
                    className="classmate-content__body"
                  >
                    <div className="ojt-a_content">
                      <h6>
                        <p>
                          <span>A. </span>
                          本課程上課期間，每天都會提供午餐，學員不需自行準備或額外付費。
                        </p>
                      </h6>
                    </div>
                  </div>
                </Collapse>
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClassmateQuestion
