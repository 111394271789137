import React, { useState, useEffect } from 'react'
import { Redirect, useLocation, Link } from 'react-router-dom'
import currency from 'currency.js' // 貨幣轉換
import moment from 'moment' // 格式化日期
import UseApplyMeeting from './UseApplymeeting'
import AttenderMessage from './AttenderMessage'
import BookerMessage from './BookerMessage'
import { LongTermClasses, LongTermClassTag } from '../../services/LongTerm' // 處理根據班種取得開課班級列表, api

// 課程資訊列表 (班別各地區開班)
function ClassInfoList(props) {
  // 會員
  const { signinBool } = props

  const {
    swalWithBootstrapButtons,
    submitApplyResult,
    submitReservationResult,
    getUser,
    attenderCheck,
    setAttenderCheck,
    bookerCheck,
    setBookerCheck,
    handleSubmitApply,
    handleSubmitBooker,
    setPackageData,
    handleSetInfoSourceId,
    // setIsLoading,
    isLoading,
    sendAttenderSubmit,
    setSendAttenderSubmit,
    sendBookerSubmit,
    setSendBookerSubmit,
    error,
    setError,
  } = UseApplyMeeting()

  // 星期幾
  const weeks = ['日', '一', '二', '三', '四', '五', '六']

  // 沒登入轉址
  // eslint-disable-next-line no-unused-vars
  const [redirectTo, setRedirectTo] = useState('')

  // 開課資訊列表
  const [longTermClasses, setLongTermClasses] = useState([])
  // console.log(longTermClasses)

  // 班級tag標籤
  // eslint-disable-next-line no-unused-vars
  const [longTermClassTag, setLongTermClassTag] = useState([])
  // console.log(longTermClassTag)

  // 包裝新的組合陣列用的 (開課+標籤)
  const [newLongTermClasses, setNewLongTermClasses] = useState([])
  // console.log(newLongTermClasses)

  // Loading
  // eslint-disable-next-line no-unused-vars
  const [getData, setGetData] = useState(false)

  const NTD = (value) => currency(value, { precision: 0, symbol: 'NT$' })
  const location = useLocation()

  // console.log(location.pathname) //  /JMMSITT
  // console.log(location.pathname.substr(1, 7)) // 去掉 / 只剩 JMMSITT
  const classTypeId = location.pathname.substr(10, 7)
  // console.log(classTypeId)

  // 點擊課程就記錄課程地點在localStorage
  // const handleRecordFromRegion = (address) => {
  //   setIsLoading(true)
  //   localStorage.setItem(
  //     'longTermFromRegion',
  //     JSON.stringify({
  //       region: address,
  //     })
  //   )
  //   // 2秒關閉指示器
  //   setTimeout(() => {
  //     window.location.reload(false)
  //     window.scrollTo(0, 0)
  //     setIsLoading(false)

  //     // console.log('time')
  //   }, 2000)
  //   // console.log(JSON.parse(window.localStorage.getItem('longTermFromRegion')))
  // }

  // 報名或預約說明會
  const handleApply = (e, id) => {
    e.preventDefault()

    if (signinBool) {
      // 有登入，接下來就判對是否已經安排好了說明會

      // dataset.briefing => data-briefing 判定
      if (e.target.dataset.briefing === 'yes') {
        // 有說明會就將briefingID傳到Server(報名說明會)
        // console.log('不對')
        let jsonData = {}

        // 出現視窗
        setAttenderCheck(true)
        setBookerCheck(false)

        // 打包要送出的json
        jsonData = {
          customerId: getUser().customerId, // 從localStorage取出
          briefingID: id,
          Flag: 0,
          // InfoSource: infoSourceId,
        }
        // 裝起來
        setPackageData(jsonData)
        // 處理勾選消息來源
        handleSetInfoSourceId()

        // 回傳datas的結果
        // {attenderID: 4, customerId: 14, briefingID: "93e2a769-a584-4042-acd2-1f645ba11dfb", flag: 0, createdDate: "2021-08-20T15:30:56.1030293+08:00"}
      } else if (e.target.dataset.briefing === 'no') {
        let jsonData = {}

        // 出現視窗
        setBookerCheck(true)
        setAttenderCheck(false)
        // 沒有說明會就將classID傳到Server(專人通知)

        // 打包要送出的json
        jsonData = {
          customerId: getUser().customerId, // 從localStorage取出
          classId: id,
        }
        // 裝起來
        setPackageData(jsonData)

        // 處理勾選消息來源
        handleSetInfoSourceId()

        // 回傳datas的結果
        // {bookerId: 5, customerId: 14, classId: "JMMSITT2106", createdDate: "2021-08-20T15:31:01.8151251+08:00"}
      }
    } else {
      swalWithBootstrapButtons
        .fire({
          title: '尚未登入',
          html: '<figure><img src="/img/questionAlert.png" alt="" /></figure>請先登入會員，才能報名說明會。',
          padding: '60px',
          showConfirmButton: true,
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: '登入',
          cancelButtonText: '取消',
          denyButtonText: '',
          backdrop: `rgba(0,0,0,.6)`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            // console.log('滾去登入吧')
            setRedirectTo('signin')

            // 暫存課程代號
            localStorage.setItem(
              'EmploymentSignup',
              JSON.stringify({ class: classTypeId })
            )
          }
        })
    }
  }

  // 產生亂數, 網址使用
  const handleSuccessSide = (length) => {
    let result = ''
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return result
  }

  const successSide = handleSuccessSide(10)
  // console.log(successSide)

  // 取得課程資料
  const handleGetClassTag = async () => {
    // 狀態更新(true:傳送中, false:傳送完成)
    setGetData(true)
    const datas = await LongTermClassTag(classTypeId)
    setLongTermClassTag(datas)
    setGetData(false)
    // console.log(datas)
  }

  // 處理兩個json組合
  const handleText = async () => {
    // 取的課程開課資訊
    const datas = await LongTermClasses(classTypeId)
    setLongTermClasses(datas)

    // 取得開課tag
    const dataTag = await LongTermClassTag(classTypeId)
    setLongTermClassTag(dataTag)

    // 取出tag陣列的classTag
    const mixClass = [...dataTag.map((v, i) => v.classTag)]

    // console.log('Tag陣列 : ', mixClass)

    // 將兩個陣列組合
    datas.forEach(function (v, i, a) {
      a[i] = { v, classTagName: mixClass[i] }
    })

    // console.log('測試組合 : ', datas)

    // 將新的陣列包裝成newLongTermClasses
    setNewLongTermClasses(datas)
  }
  console.log('組合 : ', newLongTermClasses)

  useEffect(() => {
    handleText()
    // handleGetClassInfo()
    handleGetClassTag()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classTypeId])

  // 報名
  useEffect(() => {
    const checkBoxId = JSON.parse(localStorage.getItem('checkedId'))

    if (checkBoxId.InfoSource.length !== 0) {
      handleSubmitApply()
    } else {
      setError('請至少勾選一個選項')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAttenderSubmit])

  // 預約
  useEffect(() => {
    const checkBoxId = JSON.parse(localStorage.getItem('checkedId'))

    if (checkBoxId.InfoSource.length !== 0) {
      handleSubmitBooker()
    } else {
      setError('請至少勾選一個選項')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendBookerSubmit])

  // 送出中
  // const spinner = (
  //   <>
  //     <div className="spinner-wrapper">
  //       <div className=""></div>
  //       <span className="loading" data-name="Loading">
  //         Loading
  //       </span>
  //     </div>
  //   </>
  // )

  return (
    <>
      {/* 不同的情況下改變轉址 */}
      {redirectTo === 'signin' && <Redirect to="/signin" />}
      {/* 有說明會且報名成功 */}
      {submitApplyResult === true && (
        <Redirect to={`/longterm/signupsuccess/${successSide}`} />
      )}
      {/* 沒有說明會但預約成功 */}
      {submitReservationResult === true && (
        <Redirect to={`/longterm/reservationsuccess/${successSide}`} />
      )}

      <div className={isLoading ? 'spinner-wrapper_content' : 'd-none'}>
        <div className="spinner-wrapper mt-0">
          <span className="loading" data-name="Loading">
            Loading
          </span>
        </div>
      </div>

      <div className="custom-container" id="classList">
        <div className="bcBlockInner__div">
          <h3 className="black">開課資訊</h3>
          <div className="mt-3">
            {longTermClasses.length === 0 ? (
              <div className="classListC__div text-center blue">尚無課程</div>
            ) : (
              <div className="classCardListC__div">
                {newLongTermClasses.map((item, i) => {
                  return (
                    <>
                      <div className="classCardC__div" key={i}>
                        <div className="classCardInner__div p-0">
                          <Link
                            to={`/longterm/${item.v.aClass.classID}`}
                            title="點擊圖片查看該班級內容"
                            onClick={() => {
                              // handleRecordFromRegion(
                              //   item.v.aClass.address.substring(0, 2)
                              // )
                            }}
                          >
                            <figure className="classCard__figure">
                              <img
                                src="/img/employmentIndex/classCardBc.png"
                                alt=""
                              />
                              <div className="classCardImg__div">
                                <div className="classCardImgTxt__div--name">
                                  <span>
                                    {item.v.aClass.className.replace(
                                      /(就業養成班|養成班)/g,
                                      ''
                                    )}
                                  </span>
                                </div>
                                <div className="classCardImgTxt__div--location">
                                  {item.v.aClass.address.substring(0, 2)}開課
                                </div>
                                {/* <div className="classCardImgTxt__div--price">
                                  {item.v.aClass.classID === 'JEMEMET2202' ? (
                                    ''
                                  ) : (
                                    <>
                                      <span className="priceTxt--orange pr-2">
                                        {item.v.aClass.classID === 'JJEEITT2310'
                                          ? '計劃核定價'
                                          : '優惠價 '}
                                        {NTD(
                                          item.v.aClass.tuitionDiscount
                                        ).format()}
                                      </span>
                                      <span className="priceTxt--original">
                                        {NTD(item.v.aClass.tuition).format()}
                                      </span>
                                    </>
                                  )}
                                </div> */}
                                <div className="classCardImgTxt__div">
                                  {/* {item.v.aClass.address.substring(0, 2)}
                                    開課 */}
                                  {(() => {
                                    switch (item.v.aClass.classID) {
                                      case 'JNSESET2403':
                                        return <p>113年10月中旬開課</p>
                                      case 'JPUMVRT2402':
                                        return <p>113年11月上旬開課</p>
                                      case 'JEMEMET2402':
                                        return <p>113年12月下旬開課</p>
                                      default:
                                        return (
                                          <p>
                                            {moment(
                                              item.v.aClass.startDate
                                            ).format('YYYY/MM/DD') +
                                              '-' +
                                              moment(
                                                item.v.aClass.endDate
                                              ).format('YYYY/MM/DD')}
                                          </p>
                                        )
                                    }
                                  })()}
                                  {/* <p>
                                      {moment(item.v.aClass.startDate).format(
                                        'YYYY/MM/DD'
                                      ) +
                                        '-' +
                                        moment(item.v.aClass.endDate).format(
                                          'YYYY/MM/DD'
                                        )}
                                    </p> */}
                                  <span>
                                    {item.v.aClass.companyName !== '' &&
                                    item.v.aClass.taxCode !== ''
                                      ? item.v.aClass.classID ===
                                          'JMMSITT2405' ||
                                        item.v.aClass.classID === 'JNSESET2402'
                                        ? '學員自行負擔費用'
                                        : '計劃核定價'
                                      : '優惠價 '}
                                    {NTD(
                                      item.v.aClass.tuitionDiscount
                                    ).format()}
                                  </span>
                                  <span className="priceTxt--original">
                                    {NTD(item.v.aClass.tuition).format()}
                                  </span>
                                </div>
                              </div>
                            </figure>
                          </Link>
                          <div className="classCardTxt__div">
                            <div className="cardInfoMeetC__div">
                              <p className="cardInfoMeetC__p">
                                <strong>
                                  {item.v.classBriefing &&
                                  item.v.classBriefing.flag === true ? (
                                    moment(
                                      item.v.classBriefing.briefingDate
                                    ).format('MM/DD') +
                                    '(' +
                                    weeks[
                                      moment(
                                        item.v.classBriefing.briefingDate
                                      ).day()
                                    ] +
                                    ')'
                                  ) : (
                                    <></>
                                  )}
                                </strong>
                                <span>
                                  {item.v.classBriefing === null ||
                                  item.v.classBriefing.flag === false ? (
                                    ''
                                  ) : (
                                    <>{item.v.classBriefing.briefingTime}</>
                                  )}
                                  {item.v.classBriefing === null ||
                                  item.v.classBriefing.flag === false
                                    ? ''
                                    : item.v.classBriefing.briefingArea.includes(
                                        '線上'
                                      )
                                    ? '線上說明會'
                                    : '實體說明會'}
                                </span>
                              </p>
                            </div>
                            <div className="classCardBtnC__div">
                              {(() => {
                                switch (item.v.aClass.flag) {
                                  case '1':
                                    return (
                                      <>
                                        {item.v.classBriefing &&
                                        item.v.classBriefing.maxAttender ===
                                          1000 ? (
                                          <div className="customViewMoreButton customViewMoreButton--disable w-100">
                                            說明會報名已額滿<span></span>
                                          </div>
                                        ) : item.v.aClass.classID ===
                                          'JMSMQAT2401' ? (
                                          <a
                                            href="https://docs.google.com/forms/d/e/1FAIpQLSf4UCbSwNpJlI_6lB5IQnGWG34I6igeUA6qGOXtoq3wEkUHiA/viewform"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <div className="customViewMoreButton w-100">
                                              前往報名說明會
                                            </div>
                                          </a>
                                        ) : (
                                          <div
                                            className="customViewMoreButton w-100"
                                            data-briefing={
                                              item.v.classBriefing !== null &&
                                              item.v.classBriefing.flag === true
                                                ? 'yes'
                                                : 'no'
                                            }
                                            onClick={(e) =>
                                              handleApply(
                                                e,
                                                item.v.classBriefing !== null &&
                                                  item.v.classBriefing.flag ===
                                                    true
                                                  ? item.v.classBriefing
                                                      .briefingID
                                                  : item.v.aClass.classID
                                              )
                                            }
                                          >
                                            {item.v.classBriefing !== null &&
                                            item.v.classBriefing.flag === true
                                              ? '報名說明會'
                                              : '搶先預約說明會'}
                                            <span></span>
                                          </div>
                                        )}
                                      </>
                                    )
                                  case '12':
                                    return (
                                      <div className="customViewMoreButton customViewMoreButton--disable w-100">
                                        班級報名已額滿<span></span>
                                      </div>
                                    )
                                  case '13':
                                    return (
                                      <div className="customViewMoreButton customViewMoreButton--disable w-100">
                                        額滿請電洽<span></span>
                                      </div>
                                    )
                                  default:
                                    return null
                                }
                              })()}
                            </div>
                            <div className="cardDivideLine my-3"></div>
                            <div>
                              {/* 第一個tag */}
                              {item.classTagName === null ? (
                                ''
                              ) : (
                                <>
                                  {item.classTagName.classTagName === null ? (
                                    ''
                                  ) : (
                                    <span
                                      className={
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[0]
                                          ? 'tagTxt__span'
                                          : 'd-none'
                                      }
                                    >
                                      &nbsp;◉&nbsp;
                                      {
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[0]
                                      }
                                    </span>
                                  )}
                                </>
                              )}
                              {/* 第二個tag */}
                              {item.classTagName === null ? (
                                ''
                              ) : (
                                <>
                                  {item.classTagName.classTagName === null ? (
                                    ''
                                  ) : (
                                    <span
                                      className={
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[1]
                                          ? 'tagTxt__span'
                                          : 'd-none'
                                      }
                                    >
                                      &nbsp;◉&nbsp;
                                      {
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[1]
                                      }
                                    </span>
                                  )}
                                </>
                              )}
                              {/* 第三個tag */}
                              {item.classTagName === null ? (
                                ''
                              ) : (
                                <>
                                  {item.classTagName.classTagName === null ? (
                                    ''
                                  ) : (
                                    <span
                                      className={
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[2]
                                          ? 'tagTxt__span'
                                          : 'd-none'
                                      }
                                    >
                                      &nbsp;◉&nbsp;
                                      {
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[2]
                                      }
                                    </span>
                                  )}
                                </>
                              )}
                              {/* 第四個tag */}
                              {item.classTagName === null ? (
                                ''
                              ) : (
                                <>
                                  {item.classTagName.classTagName === null ? (
                                    ''
                                  ) : (
                                    <span
                                      className={
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[3]
                                          ? 'tagTxt__span'
                                          : 'd-none'
                                      }
                                    >
                                      &nbsp;◉&nbsp;
                                      {
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[3]
                                      }
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="classCardListC__div" key={i}>
                        <div>
                          <div className="cardInfoMeetC__div">
                            <p className="cardInfoMeetC__p">
                              <span>
                                {item.v.classBriefing &&
                                item.v.classBriefing.flag === true
                                  ? moment(
                                      item.v.classBriefing.briefingDate
                                    ).format('YYYY') + '年'
                                  : ''}
                              </span>
                              <strong>
                                {item.v.classBriefing &&
                                item.v.classBriefing.flag === true ? (
                                  moment(
                                    item.v.classBriefing.briefingDate
                                  ).format('MM/DD') +
                                  '(' +
                                  weeks[
                                    moment(
                                      item.v.classBriefing.briefingDate
                                    ).day()
                                  ] +
                                  ')'
                                ) : (
                                  <></>
                                )}
                              </strong>
                              <span>
                                {item.v.classBriefing === null ||
                                item.v.classBriefing.flag === false ? (
                                  ''
                                ) : (
                                  <>{item.v.classBriefing.briefingTime}</>
                                )}
                                {item.v.classBriefing === null ||
                                item.v.classBriefing.flag === false
                                  ? ''
                                  : item.v.classBriefing.briefingArea.includes(
                                      '線上'
                                    )
                                  ? '線上說明會'
                                  : '實體說明會'}
                              </span>
                            </p>
                          </div>
                          <div className="classCardBtnC__div">
                            {(() => {
                              switch (item.v.aClass.flag) {
                                case '1':
                                  return (
                                    <>
                                      {item.v.classBriefing &&
                                      item.v.classBriefing.maxAttender ===
                                        1000 ? (
                                        <div className="customViewMoreButton customViewMoreButton--disable w-100">
                                          說明會報名已額滿<span></span>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            item.v.aClass.classID ===
                                              'JEFWSET2301' &&
                                            item.v.classBriefing === null
                                              ? 'd-none'
                                              : 'customViewMoreButton w-100'
                                          }
                                          data-briefing={
                                            item.v.classBriefing !== null &&
                                            item.v.classBriefing.flag === true
                                              ? 'yes'
                                              : 'no'
                                          }
                                          onClick={(e) =>
                                            handleApply(
                                              e,
                                              item.v.classBriefing !== null &&
                                                item.v.classBriefing.flag ===
                                                  true
                                                ? item.v.classBriefing
                                                    .briefingID
                                                : item.v.aClass.classID
                                            )
                                          }
                                        >
                                          {item.v.classBriefing !== null &&
                                          item.v.classBriefing.flag === true
                                            ? '報名說明會'
                                            : '說明會時間未確定👉🏼搶先預約說明會'}
                                          <span></span>
                                        </div>
                                      )}
                                    </>
                                  )
                                case '12':
                                  return (
                                    <div className="customViewMoreButton customViewMoreButton--disable w-100">
                                      班級報名已額滿<span></span>
                                    </div>
                                  )
                                case '13':
                                  return (
                                    <div className="customViewMoreButton customViewMoreButton--disable w-100">
                                      額滿請電洽<span></span>
                                    </div>
                                  )
                                default:
                                  return null
                              }
                            })()}
                          </div>
                          <Link
                            to={`/longterm/${item.v.aClass.classID.substring(
                              0,
                              7
                            )}`}
                            title="點擊查看該地區課程"
                            onClick={() => {
                              handleRecordFromRegion(
                                item.v.aClass.address.substring(0, 2)
                              )
                            }}
                          >
                            <figure className="classCard__figure">
                              <img
                                src="/img/employmentIndex/classCardBc.png"
                                alt=""
                              />
                              <div className="classCardImg__div">
                                <div className="classCardImgTxt__div">
                                  <p>總時數 {item.v.aClass.length} hr</p>
                                  <p>
                                    上課期間
                                    {moment(item.v.aClass.startDate).format(
                                      'YYYY/MM/DD'
                                    ) +
                                      '-' +
                                      moment(item.v.aClass.endDate).format(
                                        'YYYY/MM/DD'
                                      )}
                                  </p>
                                  <span>
                                    {item.v.aClass.address.substring(0, 2)}
                                    開課
                                  </span>
                                </div>
                                <div className="classCardImgTxt__div--price">
                                  {item.v.aClass.classID === 'JEMEMET2202' ? (
                                    ''
                                  ) : (
                                    <>
                                      <span className="priceTxt--orange pr-2">
                                        {item.v.aClass.classID === 'JJEEITT2310'
                                          ? '計劃核定價'
                                          : '優惠價 '}
                                        {NTD(
                                          item.v.aClass.tuitionDiscount
                                        ).format()}
                                      </span>
                                      <span className="priceTxt--original">
                                        {NTD(item.v.aClass.tuition).format()}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </figure>
                          </Link>
                          <div>
                          第一個tag
                            {item.classTagName === null ? (
                              ''
                            ) : (
                              <>
                                {item.classTagName.classTagName === null ? (
                                  ''
                                ) : (
                                  <span
                                    className={
                                      item.classTagName.classTagName.split(
                                        ','
                                      )[0]
                                        ? 'tagTxt__span'
                                        : 'd-none'
                                    }
                                  >
                                    &nbsp;◉&nbsp;
                                    {
                                      item.classTagName.classTagName.split(
                                        ','
                                      )[0]
                                    }
                                  </span>
                                )}
                              </>
                            )}
                           第二個tag
                            {item.classTagName === null ? (
                              ''
                            ) : (
                              <>
                                {item.classTagName.classTagName === null ? (
                                  ''
                                ) : (
                                  <span
                                    className={
                                      item.classTagName.classTagName.split(
                                        ','
                                      )[1]
                                        ? 'tagTxt__span'
                                        : 'd-none'
                                    }
                                  >
                                    &nbsp;◉&nbsp;
                                    {
                                      item.classTagName.classTagName.split(
                                        ','
                                      )[1]
                                    }
                                  </span>
                                )}
                              </>
                            )}
                      第三個tag 
                            {item.classTagName === null ? (
                              ''
                            ) : (
                              <>
                                {item.classTagName.classTagName === null ? (
                                  ''
                                ) : (
                                  <span
                                    className={
                                      item.classTagName.classTagName.split(
                                        ','
                                      )[2]
                                        ? 'tagTxt__span'
                                        : 'd-none'
                                    }
                                  >
                                    &nbsp;◉&nbsp;
                                    {
                                      item.classTagName.classTagName.split(
                                        ','
                                      )[2]
                                    }
                                  </span>
                                )}
                              </>
                            )}
                         第四個tag
                            {item.classTagName === null ? (
                              ''
                            ) : (
                              <>
                                {item.classTagName.classTagName === null ? (
                                  ''
                                ) : (
                                  <span
                                    className={
                                      item.classTagName.classTagName.split(
                                        ','
                                      )[3]
                                        ? 'tagTxt__span'
                                        : 'd-none'
                                    }
                                  >
                                    &nbsp;◉&nbsp;
                                    {
                                      item.classTagName.classTagName.split(
                                        ','
                                      )[3]
                                    }
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div> */}
                    </>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* 確認送出報名視窗 */}
      <div className={attenderCheck ? 'd-flex' : 'd-none'} id="discountMessage">
        <AttenderMessage
          attenderCheck={attenderCheck}
          setAttenderCheck={setAttenderCheck}
          sendAttenderSubmit={sendAttenderSubmit}
          setSendAttenderSubmit={setSendAttenderSubmit}
          error={error}
        />
      </div>
      {/* 確認送出報名視窗 */}

      {/* 確認送出預約視窗 */}
      <div className={bookerCheck ? 'd-flex' : 'd-none'} id="discountMessage">
        <BookerMessage
          bookerCheck={bookerCheck}
          setBookerCheck={setBookerCheck}
          sendBookerSubmit={sendBookerSubmit}
          setSendBookerSubmit={setSendBookerSubmit}
          error={error}
        />
      </div>
      {/* 確認送出預約視窗 */}
    </>
  )
}

export default ClassInfoList
