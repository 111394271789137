import React, { useEffect, useState } from 'react'
import MultiLevelBreadCrumb from '../../components/MultiLevelBreadCrumb'
import UseLongTerm from '../../components/LongTerm/UseLongTerm'
import moment from 'moment' // 格式化日期

function OJT(props) {
  const {
    breadCrumbBool,
    setBreadCrumbBool,
    signinBool,
    dynamicBreadcrumbName,
    setDynamicBreadcrumbName,
  } = props

  const {
    applyInfoHtml,
    description,
    ClassInfo,
    ContactStaff,
    isLoading,
    setIsLoading,
    PWOJTFT,
    PWOJTPT,
    PWOJTJT,
    PWOJTMT,
  } = UseLongTerm()

  // 展開詳細內容狀態
  const [closed, setClosed] = useState(true)

  const [longTermClass, setLongTermClass] = useState([])
  console.log(longTermClass)
  const handleLongTermClass = (newClass) => {
    if (newClass.length !== 0) {
      // 在這裡處理接收到的數據
      setLongTermClass(newClass)
    }
  }
  // console.log(longTermClass)

  useEffect(() => {
    // 設置 isLoading 為 true，表示加载中
    setIsLoading(true)

    // 使用 setTimeout 在 2 秒後將 isLoading 設置為 false，表示加载完成
    const timeoutId = setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    // 在组件卸載時清除定時器，以防止内存泄漏
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 掛載時改動麵包屑位置
  useEffect(() => {
    setBreadCrumbBool(false)
  })

  // 卸載時還原麵包屑位置
  useEffect(() => {
    return () => {
      setBreadCrumbBool(true)
    }
  })

  useEffect(() => {
    if (longTermClass.length !== 0 && setDynamicBreadcrumbName) {
      setDynamicBreadcrumbName(longTermClass[0].v.aClass.className)
    }
  }, [longTermClass, setDynamicBreadcrumbName])

  return (
    <>
      <div className={isLoading ? 'spinner-wrapper_content' : 'd-none'}>
        <div className="spinner-wrapper mt-0">
          <span className="loading" data-name="Loading">
            Loading
          </span>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="col p-0 position-relative">
          <div className="banner__container">
            <figure className="main-picture_img">
              <img src="/img/employment/longTermBanner.jpg" alt="" />
            </figure>
            <div className="banner__titleC">
              <div className="custom-container">
                <div className="bannerTitle__white text-left">
                  <h1>
                    {longTermClass.length !== 0 &&
                      longTermClass[0].v.aClass.className.replace(
                        /(就業養成班|養成班)/g,
                        ''
                      )}
                  </h1>
                  <h6>
                    轉職進修班 &nbsp;|&nbsp;
                    {description}
                  </h6>
                  <p>
                    開課時間 :&nbsp;
                    {moment(
                      longTermClass.length !== 0 &&
                        longTermClass[0].v.aClass.startDate
                    ).format('YYYY/MM/DD') +
                      '-' +
                      moment(
                        longTermClass.length !== 0 &&
                          longTermClass[0].v.aClass.endDate
                      ).format('YYYY/MM/DD')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 麵包屑 ↓↓↓　*/}
      <div className="container-fluid p-0">
        <div className="breadcrumbs-content greyBcc">
          {!breadCrumbBool && dynamicBreadcrumbName !== '' && (
            <MultiLevelBreadCrumb
              dynamicBreadcrumbName={
                dynamicBreadcrumbName !== '' && dynamicBreadcrumbName
              }
            />
          )}
        </div>
      </div>
      {/* 麵包屑 ↑↑↑　*/}

      <div className="bc__div--light">
        <div className="custom-container">
          <div>
            <div className="bcBlockInner__div">
              <h3 className="titleC__h3">
                <span className="black">報名課程資訊</span>
              </h3>
              <div className="bc__div--white">
                <div>
                  <div
                    className="json-content"
                    dangerouslySetInnerHTML={{ __html: [applyInfoHtml] }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="bcBlockInner__div pt-0">
              <h3 className="titleC__h3">
                <span className="black">課程介紹</span>
              </h3>
              <div className="lesson-ditailed_inner">
                <div
                  className={
                    closed
                      ? 'lesson-ditailed_header is-opened'
                      : 'lesson-ditailed_header'
                  }
                >
                  <div
                    className="lesson-header_content"
                    onClick={() => setClosed(!closed)}
                  >
                    <div className="lesson-header_a">
                      <div className="lesson-header_title">
                        {longTermClass.length !== 0 &&
                          longTermClass[0].v.aClass.className}
                      </div>
                      <div className="expandIcon__div--circle">
                        <span className="expandIcon__span--allow"></span>
                        <span className="expandIcon__span--line"></span>
                        <span className="expandIcon__span--line"></span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      closed
                        ? 'classContent__container'
                        : 'classContent__container__off'
                    }
                  >
                    <div className="classContent__padding">
                      {(() => {
                        switch (
                          longTermClass.length !== 0 &&
                          longTermClass[0].v.aClass.classTypeID
                        ) {
                          case 'PWOJTFT':
                            return (
                              <PWOJTFT
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'PWOJTPT':
                            return (
                              <PWOJTPT
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'PWOJTJT':
                            return (
                              <PWOJTJT
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'PWOJTMT':
                            return (
                              <PWOJTMT
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          default:
                            return null
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <ClassInfo
              signinBool={signinBool}
              LongTermClassInfo={handleLongTermClass}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="custom-container" id="contactCourseWide">
          <ContactStaff
            maintainer={
              longTermClass.length !== 0 && longTermClass[0].v.aClass.maintainer
            }
          />
        </div>
      </div>
    </>
  )
}

export default OJT
