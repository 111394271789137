import React, { useState } from 'react'
import { Link } from 'react-scroll'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

// APP就業養成班詳細內容, 20230901修改
function AppDeveloperClass(props) {
  const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      <div className="classContent__figure">
        <img src="/img/employment/APP_head.png?20231128" alt="" />
      </div>

      <div className="txtC__div text-justify">
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          為何您應該接受 iOS App 開發者訓練
        </h4>
        <p>
          全世界持續進行越來越數位化的改變，全球的資訊使用者使用資訊所透過的媒介
          ，很大部分是仰賴隨手可得和我們形影不離的智慧型手機。
        </p>
        <p>
          而執智慧型手機界牛耳的
          iPhone，不但引領風潮，更是廣大消費者，甚而是企業用戶
          都喜愛的簡易使用者體驗的實現。
        </p>
        <p>
          學習成為一名 iPhone的iOS作業系統上運行的 App
          應用程式的開發者，可以為您帶來許多好處。
        </p>
        <p className="mb-3">
          下面是一些讓您想要接受 iOS App 開發者訓練的理由：
        </p>
        <p className="mb-3">
          <b>1. 全球的廣大市場</b> iPhone 的全球智慧型手機的滲透率，iOS
          應用程式的需求也在企業界，變成不可或缺的一環。
        </p>
        <p className="mb-3">
          既有企業運用iOS 應用程式，讓組織成員隨時隨地處理組織內部的事務:
          比如案件提交簽核。 讓企業員工對外能更方便提供顧客及時的服務:
          比如客訴處理流程的掌控。
        </p>
        <p className="mb-3">
          新興企業有了iOS
          應用程式的協助得以創建破壞性的新型商業模式，以小博大，以新抗舊。
          我們確實親眼目睹，有時也親身體驗這些新興行業所帶來的服務:
          比如多元駕駛，全民食物外送。
        </p>
        <p className="mb-3">
          無論是在社交、保健、遊戲、教育各個領域，iOS
          應用程式開發者都有契機能創造出具有影響力的應用程式。
        </p>
        <p className="mb-3">
          <b>2. 優渥的薪資待遇</b> 根據報告，iOS
          進階開發者的平均年薪遠高於許多其他職業。 但您需要先能以 iOS
          的入門開發者進入這個領域，才算領到門票能在這程式開發的殿堂去向上爬昇。
        </p>
        <p className="mb-3">
          <b>3. 持續的成長與學習</b>
          成為應用程式的開發者後，您會發現新的技術持續的推陳出新。
          這意味者充滿挑戰，反觀意味者充滿者機會。 不斷的觀察與學習去掌握 Apple
          不斷推出新的硬體和軟體，您會成長茁壯，廣開您的視野，豐富您的人生體驗。
          <br />
          iOS
          開發者要善用您的創新和實踐力來解決問題，您會有充分機會創造出能夠改變世界的應用程式。
        </p>
        <p className="employmentClassification-instruction_p">
          總體來說，成為一名 iOS
          入門應用開發者不僅可以預期未來優渥的薪資，還可以擁有持續成長的機會，不致故步自封，讓您跟上世界的腳步。
          所以，何不嘗試接受 iOS App 入門開發者訓練，開啟您的新職業生涯呢？
        </p>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          iPhone狂潮席捲全球
        </h4>
        <p className="employmentClassification-instruction_p ">
          iPhone掀起的智慧型手機熱潮，已讓成千上萬的台灣人加入蘋果的粉絲大家族，其中最讓人著迷的地方在於App
          Store上的應用程式，目前數量已超過180萬套軟體，下載次數更已破1400億次！擁有一支已在全世界售出10億台的iPhone智慧型手機，即可免費或付費取得這些軟體，手機不再只局限於原廠所提供的功能，反而像是通往另一個世界的大門，每天都有不同的新奇軟體可以使用！而且原廠對有侵犯隱私更是盡心盡力，比如近一年逾20萬的上架被原廠因有侵犯隱私疑慮而遭拒絕。App
          的使用者可以有別於別的行動平台, 能更放心地去使用這些上架的App。
        </p>
        {/* 學生心得 */}
        <div className="knowledge_content knowledge-c employmentClassification-instruction_p">
          <h5>學生心得分享</h5>
          <div className="knowledge-content">
            <div className="knowledge-content_list">
              <ul className="classLink-content_ul knowledge-content_ul">
                <li>
                  <a href="/1600000" className="knowledge-content_a">
                    Gordon Feng
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* 學生心得 */}

        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          學生證言
        </h4>
        <p className="employmentClassification-instruction_mb">
          「能夠自己做出自己心裡面想做的遊戲和App是快樂的。通過資策會那幾個月的訓練，真的可以把我從出版界轉換成可以寫程式的開發者..」
          …魏巍
        </p>
        <div className="APP_iframe">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/_Wgrc1_A7js"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <p className="employmentClassification-instruction_p">
          創造智慧型手機新世代的兩大功臣為Apple與各地的開發者。Apple提供App
          Store開放平台，而全球各地的軟體開發者投入iPhone應用軟體開發的行列，才讓iPhone的世界永遠多彩多姿，新奇無比。而這些iPhone開發者也締造了許多致富傳奇：史丹福的助理教授Ge
          Wang，因為開發iPhone上的陶笛軟體，不到一個月就有40萬次的下載量，賺進了近台幣八九百萬的收入。Steve
          Demeter，一位30歲出頭的工程師，利用工作之餘的時間撰寫了一套方塊遊戲軟體，兩個月內賺進約台幣七百五十萬。
          <br />
          由於Apple提供完善的平台以及分帳規則，軟體開發者終於可以直接透過撰寫軟體獲利，而無須僅是為特定公司撰寫軟體領取月薪，或是大費周章地將自己的軟體推銷出去。加入智慧型手機應用軟體開發，不僅是個人技術的養成，更有機會成為下一個iPhone致富傳奇的主角。
        </p>
        {/* 學員作品 */}
        <div>
          <div className="content__title">
            <h3>學生實作上架作品</h3>
            <div className="line__div--light">
              <div className="lease-content_line"></div>
            </div>
          </div>
          <div className="game-content_work">
            <div className="game-work_content">
              <div className="game-work_img">
                <img src="/img/employment/APP_work_01.png" alt="" />
              </div>
              <div className="game-work_img">
                <img src="/img/employment/APP_work_02.png" alt="" />
              </div>
              <div className="game-work_img">
                <img src="/img/employment/APP_work_03.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* 學員作品 */}
        {/* 學員作品 */}
        <div>
          <div className="content__title">
            <h3>專題作品發表</h3>
            <div className="line__div--light">
              <div className="lease-content_line"></div>
            </div>
          </div>
          <div className="APP_iframe">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Ckd6JYCxU5M?si=0kXiUA3Q2ipeoCCE"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        {/* 學員作品 */}
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          雲端服務結合必為趨勢
        </h4>
        <p className="employmentClassification-instruction_p">
          雲端服務已成為資訊領域中的最熱門應用，未來行動應用軟體將從封閉的軟體內容，逐步轉化為雲端服務應用軟體，提供使用者即時的多元化豐富資訊，並能具備高互動之社交功能。未來所有的網站服務，必須能適應於各種平台上瀏覽，而各式應用程式將扮演好Client端的角色，幫助使用者以最便捷的方式，提供人性化的界面來進行存取。
        </p>
        <p className="employmentClassification-instruction_p">
          本課程為資展國際股份有限公司所規劃，課程設計以業界迫切需求的Apple IOS
          APP開發解決方案為主軸，訓練學員藉由撰寫Swift程式、使用 MVC
          架構來開發前端行動App應用程式、再學習內建裝置等相關課程的模組訓練、運用網路服務API去連結關聯式資料庫系統邁向前後端模組整合的全面訓練，並藉由大量術科演練、程式作業撰寫，以及期末專題製作、實戰演練開發出企業級APP應用系統，培養具動手實作且可獨當一面的行動開發人才。
        </p>
        <p className="meetingList-content_title blue">
          iOS
          App與Mac應用軟體開發養成班，帶領同學一探行動開發的世界，並結合雲端服務，成為IT界最夯的程式設計師！
        </p>
      </div>

      {/* 課程介紹 */}
      {/* <div>
        
        <div className="pb-4">
          <h6><strong>【課程目標】</strong></h6>
          <ul className="listStyle__ul">
            <li>培養學員具轉換跑道，從事電腦程式設計等相關行業之能力</li>
            <li>培養專長於iPhone平台的行動應用軟體開發程式設計師</li>
            <li>
              培養行動應用雲端服務人才，具備規劃與實作行動應用雲端服務的能力
            </li>
          </ul>
        </div>
      </div> */}
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure pb-3">
          <img
            src={
              longTermClass && longTermClass.taxCode !== ''
                ? `/img/employment/syllabus/APP_JEMMPDT_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '台北':
                        return 'Taipei'
                      default:
                        return ''
                    }
                  })()}_C2E2302.png?240702`
                : '/img/employment/syllabus/APP_JEMMPDT_Taipei.png?240702'
            }
            alt=""
          />
        </div>
        <p className="red mb-5">※課程內容以地區班級為準。</p>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">招生簡章</div>
          <ul className="classInfoList__styleNone">
            <li>
              <a
                href="/pdf/資展國際_APP Developer 行動開發工程師養成班_招訓簡章.pdf"
                className="orange"
                target="_blank"
                download
              >
                簡章下載<i className="fas fa-download"></i>
              </a>
            </li>
          </ul>
        </div> */}
        {/* <div className="pb-4">
          <div className="subT__div">招訓資格</div>
          <ul className="classInfoList__styleNone">
            <li>
              1）年齡：年滿15歲以上且具工作意願之失業、待業青年(參考廠商錄取標準建議滿18歲)。
            </li>
            <li>2）性別：不限。</li>
            <li>
              3）招生對象：會中、英文輸入，有網際網路的使用經驗，有志於從事iPhone
              App應用程式設計，並且可全心投入學習，積極建立就業所需之專業知識者。
            </li>
          </ul>
        </div> */}
        {/* <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>
              iOS APP設計師、Full
              Stack工程師、UI/UX設計師、資料存取分析應用工程師
            </li>
          </ul>
        </div> */}
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">訓練方式</div>
          <ul className="classInfoList__styleNone">
            <li>
              1）本班以就業為導向、學員學習全方位的iOS
              App之開發，此乃因應世界邁向人人幾乎都擁有智慧型手機的趨勢，企業會選擇透過在手機上運作的應用程式將資訊服務提供給客戶或是讓企業員工以即時的方式來跟資訊系統無縫互動。學員可由此開創個人職涯新面向。
            </li>
            <li>
              2）
              課程由淺入深、循序漸進，理論與實作並重、務實紮根技術，並於課程進行中塑造群組學習與團隊合作的場域與機制，培育學員有效提升學習技巧與學習效率，以及溝通協調、問體解決與團隊合作的職場軟性技能。
            </li>
            <li>
              3）
              強調實機操作，培養學員動手實作之能力，以大量的程式撰寫，厚植軟體開發經驗。
            </li>
            <li>
              4）
              階段成果考核，並從中調整與補償學習落差或不足之處，以學習成長建立學員程式開發的自信。
            </li>
            <li>
              5）
              透過業師(或是企業)出題，學員解題，縮小產學落差，創造實務環境，讓學員進入職場後，能儘量減少磨合期，甚至能無縫接軌企業場域，以實務情境，精進學員潛力與經驗價值。
            </li>
            <li>
              6）
              透過期末專題成果發表與面試，邀請徵才廠商前來參與盛會，不但能訓練文書、簡報與表達能力，更能與廠商零距離接觸，展現學習成效與實作能力，增加就業媒合的成功率。
            </li>
            <li>
              7）提供履歷表撰寫指導、職場倫理講座與學員面試模擬指導，全方位提升學員就業職能。
            </li>
          </ul>
        </div>
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>iOS App與Mac應用軟體開發養成班適合對象</h4>
                <div className="longTermTabC__div">
                  {/* <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure> */}
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        會中、英文輸入，有網際網路的使用經驗，有志於從事iPhone
                        App應用程式設計，並且可全心投入學習，積極建立就業所需之專業知識者
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具轉換跑道，從事電腦程式設計等相關行業之能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養專長於iPhone平台的行動應用軟體開發程式設計師
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養行動應用雲端服務人才，具備規劃與實作行動應用雲端服務的能力
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">iOS APP設計師</li>
                  <li className="longTerm_tabContent_li">Full Stack工程師</li>
                  <li className="longTerm_tabContent_li">UI/UX設計師</li>
                  <li className="longTerm_tabContent_li">
                    資料存取分析應用工程師
                  </li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default AppDeveloperClass
