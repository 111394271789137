import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import EventItems from './EventItems'

// 最新活動Dropdown
function DropdownActivity() {
  // 最新活動選項預設是消失狀態, 點擊每個分類之後到達分頁呈現消失
  const [click, setClick] = useState(false)

  // 免費活動預設為不顯示, hover出現
  const [eventDisplay, setEventDisplay] = useState(false)

  const handleMouseLeave = () => {
    setEventDisplay(false)
  }

  // hover免費活動
  const handleMouseLeaveEvent = () => {
    if (eventDisplay) {
      setEventDisplay(true)
    } else {
      setEventDisplay(true)
    }
  }

  // 切換狀態
  const handleClick = () => setClick(!click)

  return (
    <>
      <ul
        onClick={handleClick}
        className={
          click ? 'customDropdown-menu clicked' : 'customDropdown-menu'
        }
        onMouseLeave={handleMouseLeave}
      >
        <li className="classificationTheme">
          <div className="dropdown-content_inner">
            <h2 className="titleC__h3">
              <div className="dropdown-title">
                <span className="section-content_en">最新活動</span>
              </div>
              <span className="lesson-content_ch d-block mt-3">Activity</span>
              {/* 要不要做一個總覽課程頁面? */}
            </h2>
          </div>
        </li>
        <li className="classificationTheme">
          <div className="dropdown_primaryClassification_div">
            <ul>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeave}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/Activities"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  活動專區
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeaveEvent}
              >
                <span
                  className="dropdown_primaryClassification_a"
                  // to="/"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  免費活動
                </span>
              </li>
            </ul>
          </div>
          {eventDisplay && <EventItems handleClick={handleClick} />}
        </li>
      </ul>
    </>
  )
}

export default DropdownActivity
