import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// 全端工程師轉職精修班詳細內容
function PWOJTMT(props) {
  const { longTermClass } = props
  console.log(longTermClass)

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      {/* <div className="classContent__figure">
        <img src="/img/employment/Full-Stack_head.png" alt="" />
      </div> */}

      <div className="txtC__div text-justify">
        <p>
          在網頁應用為主的開發架構中，以工作來區分可以分成前端開發工程師、後端開發工程師以及全端開發工程師，不同類型的工程師當然會要求不同的技術能力，當您熟悉了前端開發工程師所擁有的技術後，就會聽到有人跟你說可以多學一些後端技術，來增加自己的能力，若是您熟悉的是後端開發技術，也會有人跟你說前端技術當紅，多學一些總是好的，因此總是殊途同歸的走向全端開發工程師的工作方向，既然如此為何不一開始就以全端開發工程師的工作為主要發展與技術學習的方向呢？
        </p>
        <br />
        <p>
          我們並不是要在短短的三四個月的時間訓練出神級的開發工程師，相反的我們會以訓練紮實的Junior
          Programmer初階程式開發工程師為目標來設計課程，以前端主要的程式語言JavaScript為核心，學習相關熱門的函式庫jQuery及框架Vue.js，後端主要以微軟的C#程式為主，學習ASP.NET
          MVC及Restful API的開發方式，當然資料庫運用是不能少的。
        </p>
      </div>

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure pb-3 text-center">
          <img
            src="/img/careers/Full_PWOJTMT_Taipei.png"
            alt=""
            className="game-img"
          />
        </div>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              周間晚上
              (線上或實體)，六日一天上課(實體7小時)，實際情況將會因配合講師安排為主
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>全端工程師轉職精修班適合對象</h4>
                <div className="longTermTabC__div">
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對軟體工程師等工作職務嚮往者
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        想利用工作之餘加強自身能力提升競爭力者
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        想多學一個技能培養跨域能力者
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具轉換跑道，從事軟體開發相關工作之能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員瞭解後端 MVC 框架的開發能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備前端 Vue.js 框架的開發能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備前後端分離開發架構之能力
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">前端開發工程師</li>
                  <li className="longTerm_tabContent_li">後端開發工程師</li>
                  <li className="longTerm_tabContent_li">全端開發工程師</li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default PWOJTMT
