import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { OJTIndexList } from '../TrainingClass/OJTIndexList'

// 在職進修班一覽分類
function OjtItems(props) {
  const { handleClick } = props

  // 分類一覽預設為顯示
  const [typeDisplay, setTypeDisplay] = useState(true)

  // 地區探索預設為顯示
  // eslint-disable-next-line no-unused-vars
  const [areaDisplay, setAreaDisplay] = useState(false)

  // const handleMouseHoverArea = () => {
  //   setAreaDisplay(true)
  //   setTypeDisplay(false)
  // }

  const handleMouseHoverType = () => {
    setTypeDisplay(true)
    setAreaDisplay(false)
  }

  return (
    <>
      <div className="dropdown_subClassification_div">
        <ul className="dropdown_subClassificationContent_div">
          <div className="classificationTheme">
            <div
              className="dropdown_subClassificationTitle_div"
              onMouseEnter={handleMouseHoverType}
            >
              <h3>分類一覽</h3>
            </div>
            {/* <div
              className="dropdown_subClassificationTitle_div"
              onMouseEnter={handleMouseHoverArea}
            >
              <h3>地區探索</h3>
            </div> */}
          </div>

          <div className="classificationTheme classificationTheme_subContent">
            {typeDisplay ? (
              <ul>
                {OJTIndexList.map((v, i) => {
                  return (
                    <li key={i} className="longTerm-content_li">
                      <Link
                        className="longTerm-content_link"
                        to={v.path}
                        onClick={handleClick}
                      >
                        {v.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <ul>
                <li className="longTerm-content_li">
                  <Link
                    className="longTerm-content_link"
                    to=""
                    onClick={handleClick}
                  >
                    台北地區
                  </Link>
                </li>
                <li className="longTerm-content_li">
                  <Link
                    className="longTerm-content_link"
                    to=""
                    onClick={handleClick}
                  >
                    台中地區
                  </Link>
                </li>
                <li className="longTerm-content_li">
                  <Link
                    className="longTerm-content_link"
                    to=""
                    onClick={handleClick}
                  >
                    高雄地區
                  </Link>
                </li>
                <li className="longTerm-content_li">
                  <Link
                    className="longTerm-content_link"
                    to=""
                    onClick={handleClick}
                  >
                    線上開課
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </ul>
      </div>
    </>
  )
}

export default OjtItems
