import React from 'react'
import { Link } from 'react-router-dom'

// 考試中心 - 考試中心的細項分類頁面
function TestCenterItems(props) {
  const { handleClick, setOptionTypeTab } = props

  return (
    <>
      <div className="dropdown_subClassification_div">
        <div className="dropdown_subClassificationContent_div">
          <div className="classificationTheme h0">
            <div className="dropdown_subClassificationTitle_div">
              <h3>考試中心介紹</h3>
            </div>
          </div>
          <div className="classificationTheme classificationTheme_subContent">
            <ul>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/testcenter"
                  onClick={() => {
                    handleClick()
                    setOptionTypeTab(1)
                  }}
                >
                  資展國際考試中心
                </Link>
              </li>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/testcenter"
                  onClick={() => {
                    handleClick()
                    setOptionTypeTab(2)
                  }}
                >
                  Kryterion考試中心
                </Link>
              </li>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/testcenter"
                  onClick={() => {
                    handleClick()
                    setOptionTypeTab(3)
                  }}
                >
                  Pearson VUE考試中心
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestCenterItems
