// 在職進修班's首頁分類List

export const OJTIndexList = [
  {
    title: '人工智慧',
    subTitle: '提供學員快速體驗人工智慧關鍵技術',
    path: '/OJT/AI',
    classOne: '人工智慧TensorFlow上手實作班',
    classTwo: 'Python機器學習',
    classThree: '人工智慧深度強化學習',
    linkOne: '/OJT/onjob/MSA348',
    linkTwo: '/OJT/onjob/MSA313',
    linkThree: '/OJT/onjob/MSA411',
  },
  {
    title: '資料庫 / 大數據',
    path: '/OJT/Database',
  },
  {
    title: '軟體工程',
    path: '/OJT/Software',
  },
  {
    title: '網路通訊',
    path: '/OJT/Network',
  },
  {
    title: '雲端技術',
    path: '/OJT/Cloud',
  },
  {
    title: '智慧綠能',
    path: '/OJT/GreenEnergy',
  },
  {
    title: '資安管理',
    path: '/OJT/InformationSecurity',
  },
  {
    title: '專案管理',
    path: '/OJT/manage',
  },
  {
    title: '創新設計',
    path: '/OJT/Design',
  },
  {
    title: '數位行銷',
    path: '/OJT/DigitalMarketing',
  },
  {
    title: '數據分析',
    path: '/OJT/DataAnalyze',
  },
  {
    title: '人資管理',
    path: '/OJT/HR',
  },
  {
    title: '辦公室應用',
    path: '/OJT/Office',
  },
  {
    title: '行銷技能',
    path: '/OJT/Marketing',
  },
  {
    title: '程式設計',
    path: '/OJT/Programming',
  },
  {
    title: '元宇宙',
    path: '/OJT/Metaverse',
  },
  {
    title: 'ESG永續學堂',
    path: '/OJT/ESG',
  },
  {
    title: '嵌入式',
    path: '/OJT/Embedded',
  },
]
