// https://w3.ispan.com.tw/
import { apiUrl } from '../config/path'

// 在職進修班

const url = apiUrl + '/api/OnJob/'
const url1 = apiUrl + '/api/Classes'
const url2 = apiUrl + '/api/ClassType'

// 在職班首頁, 大分類
// api/OnJob/categories
export async function OnJobCatalog() {
  return await fetch(url + 'categories').then((data) => data.json())
}

// 在職班分類課程頁面, 在職班每個分類的課程card, "未使用(舊版)"
// id 是分類代號
// api/OnJob/1
// export async function TagCourses(id) {
//   return await fetch(url + id).then((data) => data)
// }

// 在職班分類課程頁面, 在職班每個分類的課程card, 23年4/11新版
// id 是分類代號
// api/OnJob/v3/1
export async function TagCoursesNew(id) {
  return await fetch(url + 'v3/' + id).then((data) => data)
}

// 在職班課程頁面, 根據班級代號前6碼取得單門課 開課資料, 23年4/11新版
// api/Classes/v2/short/ML2
export async function OnJobSingleClassesInfo(id) {
  return await fetch(url1 + '/v2/short/' + id).then((data) => data.json())
}

// 在職班課程頁面, 根據班級代號前6碼取得單門課 開課資料, 用在系列裡的課程
// api/Classes/short/MSA411
export async function OnJobSingleClasses(id) {
  return await fetch(url1 + '/short/' + id).then((data) => data.json())
}

// 在職班課程頁面, 根據課程縮寫取得單門課 開課資料, 1/12新的縮寫對應的開課課程代號, 23年4/11新版
// api/ClassType/v3/single/ML2
export async function OnJobTypeClass(id) {
  return await fetch(url2 + '/v3/single/' + id)
    .then((data) => data.json())
    .catch((err) => {
      // 沒有在職班課程就轉址到在職班分類首頁
      window.location.replace('/OJT')
      // console.log('有錯誤', err)
    })
}

// 在職班課程頁面, 根據班級代號前6碼取得單門課 在職班系統介紹資料, 用在系列裡的課程
// api/ClassType/single/MSA411
export async function OnJobTypeSingleInfo(id) {
  return await fetch(url2 + '/single/' + id).then((data) => data.json())
}

// 在職班課程頁面, 根據班級代號"對應的縮寫"取得課程edm內容
// api/Edm/MMP  {FileName}
export async function OnJobClassTypeInfo(id) {
  return await fetch(apiUrl + '/api/Edm/' + id).then((data) => data)
}
