import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Mentor } from '../../services/Employee' // 傳入課程經理名稱 api

// 在職班報名課程資訊
function TrainingContactInfoForSeries(props) {
  const { classesMentor, classMaintainers, mentor, maintainers } = props

  const [mentorArray, setMentorArray] = useState([])

  // 處理用課程經理名字帶出課程經理聯繫資訊, 且將其裝成陣列
  const handleMentor = () => {
    // 課程經理 + 教務承辦
    const margeClassMentor = classesMentor.concat(classMaintainers)
    // console.log('合併開課陣列的經理和教務', margeClassMentor)

    // 篩選掉名字相同的
    const filterName = margeClassMentor.filter((v, i, a) => {
      return a.indexOf(v) === i
    })
    const classesMentorArray = []
    // eslint-disable-next-line no-unused-vars
    const mentorInfo = filterName.map(async (v) => {
      const item = await Mentor(v)

      // console.log('不重複的聯絡人資料', item)
      // 開課的課程經理和教務資料裝成陣列
      classesMentorArray.push(item)
      // 台中不要出現王先生的聯絡方式, 因為Toms還是掛王先生是課程經理
      const filterTaichungContact = classesMentorArray.filter((v) => {
        return v.name !== '王先生'
      })
      // console.log('排除王先生', filterTaichungContact)
      // console.log('聯絡人資料', classesMentorArray)
      // 不要把不該秀的資訊顯示出來, 所以先做篩選
      const listItems = filterTaichungContact.map((elem) => ({
        address: elem.address,
        name: elem.name,
        phone: elem.phone,
        email: elem.email,
        department: elem.department,
        cardTitle: elem.cardTitle,
      }))
      // console.log('listItems', listItems)
      // 還沒有帶完資料就顯示畫面, 因此先將其存在sessionStorage

      sessionStorage.setItem('mentorArray', JSON.stringify(listItems))

      const mentorArraySet = JSON.parse(sessionStorage.getItem('mentorArray'))
      if (mentorArraySet) {
        setMentorArray(mentorArraySet)
      }
    })
  }

  let { id } = useParams()

  // 拿到課程聯絡人資訊後觸發處理
  useEffect(() => {
    // 如果沒有開課的聯絡人陣列
    if (classesMentor.length !== 0) {
      handleMentor()
    } else {
      const contactArray = []
      // 課程經理 + 教務承辦
      contactArray.push(mentor, maintainers)

      // 篩選掉名字相同的
      const filterName = contactArray.filter((v, i, a) => {
        return a.indexOf(v) === i
      })
      const setMentorArrays = []
      // 處理沒開班的課程, 抓課程經理資訊
      // eslint-disable-next-line no-unused-vars
      const handleMentor = filterName.map(async (v) => {
        const data = await Mentor(v)

        setMentorArrays.push(data)

        // 台中不要出現王先生的聯絡方式, 因為Toms還是掛王先生是課程經理
        const filterTaichungContact = setMentorArrays.filter((v) => {
          return v.name !== '王先生'
        })
        // console.log('排除王先生', filterTaichungContact)
        // console.log('聯絡人資料', setMentorArrays)
        // 不要把不該秀的資訊顯示出來, 所以先做篩選
        const listItems = filterTaichungContact.map((elem) => ({
          address: elem.address,
          name: elem.name,
          phone: elem.phone,
          email: elem.email,
          department: elem.department,
          cardTitle: elem.cardTitle,
        }))
        // console.log('listItems', listItems)

        // 還沒有帶完資料就顯示畫面, 因此先將其存在localStorage
        sessionStorage.setItem('mentorArray', JSON.stringify(listItems))

        const mentorArraySet = JSON.parse(sessionStorage.getItem('mentorArray'))
        if (mentorArraySet) {
          setMentorArray(mentorArraySet)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classesMentor, mentor])

  return (
    <>
      <ul className="seriesClass_contact_ul">
        {/* <li>
              <span> {item.advanceDate} </span>提前報名即享早鳥優惠,
              歡迎來電詢問 !!
            </li>*/}
        {(() => {
          switch (id) {
            case 'MDMS':
              return (
                <li className="onJob-info_exTex">
                  本課程團報優惠：三人團報可打95折、五人團報可打9折優惠
                </li>
              )

            default:
              return null
          }
        })()}
        <li>
          本課程採線上報名，報名課程之學員將於開課前7~10天內收到e-mail通知，確認課程是否開課，若確認開課才需繳費
        </li>
        <li>服務時間：平日週一至五 09:00 至 17:30</li>
        {(() => {
          switch (id) {
            default:
              return (
                <>
                  {mentorArray.map((v, i) => {
                    return (
                      <>
                        <li
                          key={i}
                          className={
                            v.address.substring(0, 2) === '台北' &&
                            v.department === '培訓服務部'
                              ? 'd-none'
                              : ''
                          }
                        >
                          {v.address.substring(0, 2)}班
                          {v.department === '桃園中心'
                            ? v.cardTitle === '教務經理'
                              ? '報名諮詢'
                              : '課程諮詢'
                            : '課程諮詢'}
                          ：
                          {(() => {
                            switch (v.phone.substring(2, 3)) {
                              case '4':
                                return (
                                  <a
                                    href={`tel:+886-4-${v.phone
                                      .substring(4, 13)
                                      .split('-')
                                      .join('')}`}
                                  >
                                    {v.phone}
                                  </a>
                                )
                              case '2':
                                return (
                                  <a
                                    href={`tel:+886-2-${v.phone
                                      .substring(4, 13)
                                      .split('-')
                                      .join('')}`}
                                  >
                                    {v.phone}
                                  </a>
                                )
                              case '7':
                                return (
                                  <a
                                    href={`tel:+886-7-${v.phone
                                      .substring(4, 13)
                                      .split('-')
                                      .join('')}`}
                                  >
                                    {v.phone}
                                  </a>
                                )
                              default:
                                return ''
                            }
                          })()}
                          &nbsp;
                          {v.name}
                          、E-mail：
                          <a href={`mailto:${v.email}`} className="mail-link_a">
                            {v.email}
                          </a>
                        </li>
                      </>
                    )
                  })}
                </>
              )
          }
        })()}
      </ul>
    </>
  )
}

export default TrainingContactInfoForSeries
