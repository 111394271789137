import React from 'react'
import UseApplyMeeting from './UseApplymeeting'

// 確認預約說明會視窗
function BookerMessage(props) {
  const {
    bookerCheck,
    setBookerCheck,
    sendBookerSubmit,
    setSendBookerSubmit,
    error,
  } = props

  const { source, setSource, sourceOption } = UseApplyMeeting()

  return (
    <>
      {/* 提示視窗 */}
      <div className="discountMessage-content">
        <div className="message-content">
          <button
            type="button"
            className="customClose ml-auto"
            aria-label="Close"
            onClick={() => setBookerCheck(!bookerCheck)}
          >
            <div className="close-border">
              <i className="fas fa-times message-close" aria-hidden="true"></i>
            </div>
          </button>
          <div className="discountMessage-inner_div">
            <h4>確定要送出預約嗎 ?</h4>
            <figure>
              <img src="/img/inquireAlert.png" alt="" />
            </figure>
            <h6 className="red">
              送出後即預約完成，您將會收到系統寄出的預約成功信件。
            </h6>
            <br />
            <p>
              您是如何得知本課程的 ?&nbsp;
              {error && <span className="red">{error}</span>}
            </p>

            <div>
              {sourceOption.map((item, i) => {
                return (
                  <>
                    <div key={i} className="checkboxC__div">
                      <input
                        type="checkbox"
                        value={item.value}
                        defaultChecked={false}
                        onChange={(e) => {
                          const key = e.target.value
                          setSource({ ...source, [key]: !source[key] })
                        }}
                        id={`${item.text}a`}
                      />
                      <h6 className="pl-3">
                        <label htmlFor={`${item.text}a`}>{item.text}</label>
                      </h6>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div className="swal2-actions">
            <button
              type="button"
              className="swal2-confirm"
              onClick={() => setSendBookerSubmit(!sendBookerSubmit)}
            >
              送出
            </button>
            <button
              type="button"
              className="swal2-cancel"
              onClick={() => setBookerCheck(!bookerCheck)}
            >
              取消
            </button>
          </div>
        </div>
      </div>
      {/* 提示視窗 */}
    </>
  )
}

export default BookerMessage
