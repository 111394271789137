import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// AI繪圖與3D美術人才養成班詳細內容, 20230901修改
function VR3DGameClass(props) {
  // const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      <div className="classContent__figure">
        <img src="/img/employment/3D_head.jpg?240326" alt="" />
      </div>

      <div className="txtC__div text-justify">
        <div className="employmentClassification-instruction_p">
          <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
            融合藝術與科技，探索創意無限的世界—生成式AI概念美術與3D遊戲美術設計
          </h4>
          <p>
            你是否曾對創造出令人驚嘆的視覺效果和引人入勝的遊戲世界充滿著無限憧憬？歡迎來到我們的AI繪圖與3D遊戲美術設計課程，開啟你的藝術之旅，探索數位藝術的無限可能性，讓你在視覺藝術的領域中展現你的獨特創意。
          </p>
          <br />
          <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
            擁抱AI技術，創造無限可能
          </h4>
          <p>
            在這個數位時代，人工智慧已經深入到藝術創作的各個領域。透過生成式AI概念美術的繪圖課程，你將學習如何利用AI技術，從繪畫基礎到專業技巧，協同Blender設計貼圖，融會貫通。透過AI工具的輔助，你將能夠以前所未有的速度和精度創作出令人驚歎的藝術作品。
          </p>
          <br />
          <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
            踏入3D遊戲美術的奇幻世界
          </h4>
          <p>
            3D遊戲美術是當今遊戲產業中不可或缺的一環，決定了遊戲的視覺風格和氛圍。在我們的課程中，你將以2D美術的Photoshop奠基，進入3D建模、材質設計，進而學習進階遊戲角色與遊戲場景製作，包括Substance
            3D
            Painter專業貼圖繪製，與導入ZBrush次世代遊戲技術，再銜接遊戲角色動作設定，更加入遊戲引擎Unity，設計遊戲特效、PBR材質。透過專業的指導和實踐機會，你將能夠打造出栩栩如生的遊戲場景和角色，讓玩家沉浸在你所創造的奇幻世界中。
          </p>
        </div>
      </div>
      {/* 學習重點 */}
      <div>
        <div className="content__title">
          <h3>課程亮點</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <ul className="listStyle__ul">
            <li>
              <h5>融合科技與藝術</h5>
              透過本課程，您將學會如何運用生成式人工智慧（AI）技術，將科技與藝術完美融合，創造出獨一無二的數位藝術作品。
            </li>
            <li>
              <h5>深入學習3D遊戲美術</h5>
              由資深業界的專業講師教授，深入了解3D遊戲美術的設計原理與技巧，掌握3D美術的關鍵要素與職能。多項軟體技術應用之外，特別安排基礎色彩學、藝用解剖學、美術風格設定與Motion
              Capture高階動畫設備實際體驗等課程。
            </li>
            <li>
              <h5>實踐與專業指導</h5>
              通過豐富多樣的課程整合，您有機會將所學技能付諸實踐，並獲得專業講師的專題指導，提升作品水準。
            </li>
            <li>
              <h5>團隊交流與合作</h5>
              在這個課程中，您將有機會與來自不同背景的同學一起學習、交流，激發創意的火花，參與團隊合作專題，拓展您的人脈與視野。
            </li>
          </ul>
        </div>
      </div>

      {/* 學習重點 */}
      <div>
        <div className="content__title">
          <h3>學習重點</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="employmentClassification-img_only">
          <div>
            <h5>遊戲美術理論與2D美術軟體基礎</h5>
            <h6 className="">
              基礎色彩學｜藝用解剖學｜遊戲世界觀與美術風格設定｜Photoshop
            </h6>
          </div>
          <div className="game-content_card">
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work01_01.png" alt="" />
              </div>
              <h6 className="blue">邱品甄</h6>
              <p className="game-content_txt">學員作品</p>
            </div>
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work01_02.png" alt="" />
              </div>
              <h6 className="blue">邱品甄</h6>
              <p className="game-content_txt">學員作品</p>
            </div>
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work01_03.png" alt="" />
              </div>
              <p className="game-content_txt">課程範例</p>
            </div>
          </div>
        </div>

        <div className="employmentClassification-img_only">
          <div>
            <h5>3D遊戲角色與場景製作</h5>
            <h6 className="">
              3ds MAX實務應用｜Low-Polygon模型製作技巧｜3D角色製作與Substance 3D
              Painter進階貼圖｜遊戲場景與景觀設計製作
            </h6>
          </div>
          <div className="game-content_card">
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work02_01.png" alt="" />
              </div>
              <h6 className="blue">尚雪兒</h6>
              <p className="game-content_txt">學員作品</p>
            </div>
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work02_02.png" alt="" />
              </div>
              <h6 className="blue">逄堡鈞</h6>
              <p className="game-content_txt">學員作品</p>
            </div>
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work02_03.png" alt="" />
              </div>
              <h6 className="blue">陳皇凱</h6>
              <p className="game-content_txt">課程範例</p>
            </div>
          </div>
        </div>

        <div className="employmentClassification-img_only">
          <div>
            <h5>生成式AI概念美術與3D貼圖</h5>
            <h6 className="">
              AI繪圖技術- Stable Diffusion概念美術｜Stable
              Diffusion協同Blender紋理貼圖製作
            </h6>
          </div>
          <div className="game-content_card">
            <div>
              <div className="game-content_img">
                <img src="/img/employment/scribble_sketch-thm.jpg" alt="" />
              </div>
              <p className="game-content_txt">課程範例</p>
            </div>
            <div>
              <div className="game-content_img">
                <img src="/img/employment/Dream-Textures.jpg" alt="" />
              </div>
              <p className="game-content_txt">課程範例</p>
            </div>
            <div>
              <div className="game-content_img">
                <img
                  src="/img/employment/Stable-Diffusion-ControlNet-1.jpg"
                  alt=""
                />
              </div>
              <p className="game-content_txt">課程範例</p>
            </div>
          </div>
        </div>

        <div className="employmentClassification-img_only">
          <div>
            <h5>ZBrush次世代 數位雕刻</h5>
            <h6 className="">ZBrush數位雕刻｜次世代遊戲技術</h6>
          </div>
          <div className="game-content_card">
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work03_01.png" alt="" />
              </div>
              <h6 className="blue">詹嘉豪</h6>
              <p className="game-content_txt">學員作品</p>
            </div>
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work03_02.png" alt="" />
              </div>
              <h6 className="blue">詹嘉豪</h6>
              <p className="game-content_txt">學員作品</p>
            </div>
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work03_03.png" alt="" />
              </div>
              <h6 className="blue">王子濤</h6>
              <p className="game-content_txt">課程範例</p>
            </div>
          </div>
        </div>

        <div className="employmentClassification-img_only">
          <div>
            <h5>遊戲角色動作設計</h5>
            <h6 className="">角色動作設計｜Motion Capture</h6>
          </div>
          <div className="game-content_card">
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work04_01.png" alt="" />
              </div>
              <h6 className="blue">江偉男</h6>
              <p className="game-content_txt">學員作品</p>
            </div>
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work04_02.png" alt="" />
              </div>
              <p className="game-content_txt">課程範例</p>
            </div>
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work04_03.png" alt="" />
              </div>
              <p className="game-content_txt">課程範例</p>
            </div>
          </div>
        </div>

        <div className="employmentClassification-img_only">
          <div>
            <h5>Unity遊戲引擎整合</h5>
            <h6 className="">
              Unity地形與氛圍｜遊戲特效製作｜遊戲互動設計｜PBR材質
            </h6>
          </div>
          <div className="game-content_card">
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work05_01.png" alt="" />
              </div>
              <h6 className="blue">劉穎諺</h6>
              <p className="game-content_txt">學員作品</p>
            </div>
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work05_02.png" alt="" />
              </div>
              <h6 className="blue">葉欣瑋</h6>
              <p className="game-content_txt">學員作品</p>
            </div>
            <div>
              <div className="game-content_img">
                <img src="/img/employment/3D_work05_03.png" alt="" />
              </div>
              <h6 className="blue">楊宗翰</h6>
              <p className="game-content_txt">課程範例</p>
            </div>
          </div>
        </div>
      </div>
      {/* 學習重點 */}

      {/* 學員作品 */}
      <div>
        <div className="content__title">
          <h3>學員作品</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="game-content_work">
          <div className="game-work_content">
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all01.png" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all02.jpg?240326" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all03.jpg" alt="" />
            </div>
          </div>
          <div className="game-work_content">
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all04.jpg" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all05.jpg" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all06.jpg" alt="" />
            </div>
          </div>
          <div className="game-work_content">
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all07.png" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all08.png" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all09.png" alt="" />
            </div>
          </div>
          <div className="game-work_content">
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all10.png" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all11.png" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all12.png" alt="" />
            </div>
          </div>
          <div className="game-work_content">
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all13.png" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all14.jpg" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all15.png" alt="" />
            </div>
          </div>
          <div className="game-work_content">
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all16.png" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all17.png" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all18.png" alt="" />
            </div>
          </div>
          <div className="game-work_content">
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all19.png" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all20.jpg" alt="" />
            </div>
            <div className="game-work_img">
              <img src="/img/employment/3D_work_all21.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* 學員作品 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure text-center">
          <img
            src="/img/employment/syllabus/3D_class.jpg"
            alt=""
            className="game-img"
          />
        </div>
        <p className="red pt-3">※課程內容以地區班級為準。</p>

        <div className="employmentClassification-list_content">
          <div className="row justify-content-center employmentClassification-list_inner">
            <div className="p-0 col-xxl-10 col-sm-12 d-flex flex-wrap employClassCard-justify">
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>3D遊戲概論</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>3D遊戲發展與遊戲業界介紹</li>
                  <li>遊戲流程製作與分工</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>3D角色製作與進階貼圖</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>Low Polygon Modeling技巧</li>
                  <li>Unwrap UVW Map</li>
                  <li>遊戲角色與武器製作</li>
                  <li>Normal Map概念</li>
                  <li>Photoshop, Substance 3D Painter貼圖與3ds MAX整合</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>Motion Capture</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>高階設備動態捕捉系統基本使用</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>2D美術軟體應用</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>Photoshop基本操作</li>
                  <li>Brush應用</li>
                  <li>無接縫影像處理</li>
                  <li>質感模擬與貼圖繪製技巧</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>ZBrush與3D角色進階整合</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>ZBrush基本操作</li>
                  <li>3D數位雕刻與細節繪製</li>
                  <li>3ds Max與Zbrush整合應用</li>
                  <li>Zbrush輸出Normal Map與AO map</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>生成式AI概念美術與3D貼圖</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>AI繪圖技術- Stable Diffusion操作應用</li>
                  <li>Stable Diffusion概念美術</li>
                  <li>Stable Diffusion協同Blender紋理貼圖製作</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>遊戲引擎</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>Unity遊戲引擎基本操作</li>
                  <li>Unity遊戲引擎特效應用</li>
                  <li>遊戲互動設計</li>
                  <li>PBR材質</li>
                  <li>Unity與3D美術的整合應用</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>3D美術軟體應用</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>3ds Max介面與指令面板操作</li>
                  <li>造型原理與建模基礎</li>
                  <li>遊戲模型製作實務</li>
                  <li>常用材質應用技巧</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>3D場景與建築製作</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>遊戲道具的細節處理</li>
                  <li>遊戲植物景觀的製作技巧</li>
                  <li>遊戲建築場景建模與貼圖</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>遊戲美術基礎理論</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>藝用解剖學－角色結構分析與佈線</li>
                  <li>遊戲世界觀與美術風格</li>
                  <li>遊戲角色與場景設定</li>
                  <li>創意發想與遊戲專案分工</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>3D角色動態設計</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>瞭解角色動作法則</li>
                  <li>動態調整基本原則</li>
                  <li>Bones運用</li>
                  <li>Character Studio</li>
                  <li>遊戲常用動作調整</li>
                </ul>
              </div>
              <div className="employmentClassification-instruction_list">
                <div className="employmentClassification-list_title">
                  <h5>專題</h5>
                </div>
                <ul className="employmentClassification-list_ul">
                  <li>專題指導/專題製作</li>
                  <li>Unity整合應用實做</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">招生對象</div>
          <ul className="classInfoList__styleNone">
            <li>熟悉電腦基本操作</li>
            <li>對3D遊戲美術設計有強烈學習意願及興趣</li>
            <li>美術或設計相關科系畢業者尤佳</li>
            <li>男女不拘，但男性需役畢或免役</li>
          </ul>
        </div> */}
        {/* <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>多元出口：SOHO接案、獨立製作、出國升學、職場就業</li>
            <li>
              發展領域：3D遊戲美術設計、3D角色製作、3D場景製作、3D角色動作、遊戲特效設計、3D動畫設計
            </li>
          </ul>
        </div> */}
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>AI繪圖與3D美術人才養成班適合對象</h4>
                <div className="longTermTabC__div">
                  {/* <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure> */}
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">熟悉電腦基本操作</h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對AI繪圖與3D美術有強烈學習意願及興趣
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        美術或設計相關科系畢業者尤佳
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        男女不拘，但男性需役畢或免役
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        解3D遊戲業界專案設計流程
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        學習3D遊戲美術實務與專業遊戲技術應用，以Unity整合美術與互動設計
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        具備遊戲專題製作能力
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">SOHO接案</li>
                  <li className="longTerm_tabContent_li">獨立製作</li>
                  <li className="longTerm_tabContent_li">出國升學</li>
                  <li className="longTerm_tabContent_li">職場就業</li>
                  <li className="longTerm_tabContent_li">3D遊戲美術設計</li>
                  <li className="longTerm_tabContent_li">3D角色製作</li>
                  <li className="longTerm_tabContent_li">3D場景製作</li>
                  <li className="longTerm_tabContent_li">3D角色動作</li>
                  <li className="longTerm_tabContent_li">遊戲特效設計</li>
                  <li className="longTerm_tabContent_li">3D動畫設計</li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default VR3DGameClass
