import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'
// import Collapse from 'react-bootstrap/Collapse'

// 網路與系統工程師就業養成班詳細內容, 20230901修改
function CloudSystemClass(props) {
  const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      <div className="txtC__div text-justify mt-0">
        <p className="employmentClassification-instruction_p">
          一個被喻為即將『改變全球10億電腦使用人口生活、顛覆全球網路版圖』的雲端運算，近來風起雲湧，各國政府和國際大廠紛紛投入龐大資源。國內行政院決議成立「雲端運算產業發展指導小組」，預計在5年內投入240億元，促成1000億台幣投資，創造5萬名就業機會
          ; 然而，想要搶食這塊大餅，人才技能仍舊是廠商的最大隱憂，但您知道嗎？
          雲端服務所需的技能，就在本班的訓練課程裡。
        </p>
        <p className="employmentClassification-instruction_p">
          本班專注以就業為導向，規劃最符合企業需求的課程，以學習IT產業之最主流產品、技術為目標，帶領您跨越雲端、網路、資安等相關硬體與軟體整合管理，使您成為網路與系統整合工程師，跨足雲端產業，開創個人生涯新契機。
        </p>
      </div>

      {/* 課程介紹 */}
      <div>
        <div className="pb-4">
          <h6>
            <strong>【課程目標】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              本班以就業為導向，精心規劃的課程內容，循序漸進的授課模式，以企業級的學習設備及優秀的師資帶領您成為資訊行業真正需要的電腦及網路技術人才，結訓後由本中心輔導就業，可立即從事網路及系統專業領域之維護及支援工作
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              本班以取得工作為導向，以學習IT產業之最新產品、技術為目標，帶領您跨越電腦、系統、網路等相關硬體與軟體設計開發，使您成為全方位系統整合工程師，跨足雲端產業，開創個人生涯新契機
            </li>
            <li>
              課程由淺入深、循序漸進，理論與實作並重，塑造群組學習與團隊合作，培育學員有效提升學習技巧與效率
            </li>
            <li>
              提供專業級網路及軟硬體設備(網路配線、Cisco Router & Switch,
              Windows Server 2019,
              Linux,虛擬化平台等)，強調做中學，培養動手實作能力
            </li>
            <li>
              雲端技術的導入，整合虛擬化平台、網路虛擬化、儲存裝置虛擬化、桌面虛擬化等，創造獨當一面之整合人才，展現軟硬兼具、與眾不同的才能
            </li>
            <li>
              輔導考取國際級專業認證 (Cisco CCNA、LPIC-1 )，展現最佳學習成效
            </li>
            <li>
              企業應用專題模擬，邀請徵才廠商參與學員專題簡報與實作演練，不但訓練簡報能力，更與廠商零距離接觸，展現實力，增加就業媒合的成功率
            </li>
            <li>
              履歷表撰寫與職場倫理講座，提供學員模擬面談指導，全方位提升學員就業職能
            </li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure pb-3">
          <img
            src={
              longTermClass && longTermClass.taxCode !== ''
                ? `/img/employment/syllabus/Cl_JNNWSET_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '高雄':
                        return 'kaohsiung'
                      default:
                        return ''
                    }
                  })()}_C2E2302.png?230901`
                : '/img/employment/syllabus/Cl_JNNWSET_kaohsiung.png?230901'
            }
            alt=""
          />
        </div>
        <p className="red mb-5">※課程內容以地區班級為準。</p>
      </div>

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">招生對象</div>
          <ul className="classInfoList__styleNone">
            <li>
              大專畢業，有志於從事網路及系統工作，可全心投入學習，積極希望建立就業所需之專業知識者
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>資訊系統或設備開發商(原廠)：維護工程師、支援工程師</li>
            <li>
              資訊系統整合公司(SI)：網路/系統整合工程師、網路 /
              系統業務工程師、資訊安全工程師
            </li>
            <li>
              網路服務商(ISP)、網路資料中心(IDC)：網路工程師、企業客服工程師、業務工程師
            </li>
            <li>各產業資訊部門(MIS)：網路/系統工程師、資訊安全工程師</li>
          </ul>
        </div>

        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>3D遊戲美術設計人才養成班適合對象</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">熟悉電腦基本操作</h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對3D遊戲美術設計有強烈學習意願及興趣
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        美術或設計相關科系畢業者尤佳
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        男女不拘，但男性需役畢或免役
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        解3D遊戲業界專案設計流程
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        學習3D遊戲美術實務與專業遊戲技術應用，以Unity整合美術與互動設計
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        具備遊戲專題製作能力
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">SOHO接案</li>
                  <li className="longTerm_tabContent_li">獨立製作</li>
                  <li className="longTerm_tabContent_li">出國升學</li>
                  <li className="longTerm_tabContent_li">職場就業</li>
                  <li className="longTerm_tabContent_li">3D遊戲美術設計</li>
                  <li className="longTerm_tabContent_li">3D角色製作</li>
                  <li className="longTerm_tabContent_li">3D場景製作</li>
                  <li className="longTerm_tabContent_li">3D角色動作</li>
                  <li className="longTerm_tabContent_li">遊戲特效設計</li>
                  <li className="longTerm_tabContent_li">3D動畫設計</li>
                </ul>
              </div>
            </Tab> */}
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default CloudSystemClass
