import React from 'react'
import { Link } from 'react-scroll'

// 下捲連結
function LinkScroll() {
  return (
    <>
      <div className="custom-container" id="applyInfo">
        <div className="linkScrollC__div px-0 pb-0">
          <ul>
            <li>
              <Link
                className="p-0"
                activeClass="active"
                to="applyInfo"
                spy={true}
                smooth={true}
              >
                <div className="linkScrollInnerC__div linkScrollTxt__div">
                  報名資訊
                </div>
              </Link>
            </li>

            <li>
              <Link
                className="p-0"
                activeClass="active"
                to="classIntroduce"
                spy={true}
                smooth={true}
              >
                <div className="linkScrollInnerC__div linkScrollTxt__div">
                  課程介紹
                </div>
              </Link>
            </li>

            <li>
              <Link
                className="p-0"
                activeClass="active"
                to="classList"
                spy={true}
                smooth={true}
              >
                <div className="linkScrollInnerC__div linkScrollTxt__div">
                  開課資訊
                </div>
              </Link>
            </li>

            <li>
              <Link
                className="p-0"
                activeClass="active"
                to="question"
                spy={true}
                smooth={true}
              >
                <div className="linkScrollInnerC__div linkScrollTxt__div">
                  常見問題
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
export default LinkScroll
