import React from 'react'
import { Link } from 'react-router-dom'

// 在職班 - 政府補助的細項分類頁面
function OjtSubsidyItems(props) {
  const { handleClick } = props
  // 政府補助選項預設是消失狀態, 點擊每個分類之後到達分頁呈現消失
  // const [click, setClick] = useState(false)

  return (
    <>
      <div className="dropdown_subClassification_div">
        <div className="dropdown_subClassificationContent_div">
          <div className="classificationTheme h0">
            <div className="dropdown_subClassificationTitle_div">
              <h3>政府補助</h3>
            </div>
          </div>
          <div className="classificationTheme classificationTheme_subContent">
            <ul>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/onjobsubsidy"
                  onClick={handleClick}
                >
                  小人提補助計畫
                </Link>
              </li>
              {/* <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/"
                  onClick={handleClick}
                >
                  職能發展學院補助
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default OjtSubsidyItems
