// 報名在職班表單檢查
function Validate(signupInput) {
  const errors = {}

  if (signupInput.invoiceTypeID === '3') {
    // 統編
    if (!signupInput.taxCode || signupInput.taxCode.trim() === '') {
      errors.taxCode = '需輸入統一編號'
    }

    // 發票抬頭
    if (!signupInput.invoiceTitle || signupInput.invoiceTitle.trim() === '') {
      errors.invoiceTitle = '需輸入發票抬頭'
    }
  }

  if (signupInput.discountType === '1' || signupInput.discountType === '2') {
    // 團報備註
    if (!signupInput.remark) {
      errors.teamsRemark = '需輸入和誰一起團報'
    }
  }

  if (signupInput.discountType === '0') {
    // 備註養成班結訓
    if (!signupInput.remark) {
      errors.remark = '需輸入於何時哪個班級結訓'
    }
  }

  if (signupInput.discountType === '5') {
    // 備註其他(課程經理給予的文字輸入)
    if (!signupInput.remark) {
      errors.otherRemark = '需輸入課程經理給予的特殊優惠敘述'
    }
  }

  return errors
}

export default Validate
