import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TestCenterItems from './TestCenterItems'
import SubsidyInformationItems from './SubsidyInformationItems'

// 學員服務Dropdown
function DropdownMateService(props) {
  const { setOptionTypeTab } = props

  // 學員服務選項預設是消失狀態, 點擊每個分類之後到達分頁呈現消失
  const [click, setClick] = useState(false)

  // 考試中心預設為不顯示, hover出現
  const [testCenterDisplay, setTestCenterDisplay] = useState(false)
  // 補助方案資訊預設為不顯示, hover出現
  const [subsidyDisplay, setSubsidyDisplay] = useState(false)

  const handleMouseLeave = () => {
    setTestCenterDisplay(false)
  }

  // hover考試中心
  const handleMouseLeaveTestCenter = () => {
    if (subsidyDisplay) {
      setSubsidyDisplay(false)
      setTestCenterDisplay(true)
    } else {
      setTestCenterDisplay(true)
    }
  }

  // hover補助方案資訊
  const handleMouseLeaveSubsidy = () => {
    if (testCenterDisplay) {
      setTestCenterDisplay(false)
      setSubsidyDisplay(true)
    } else {
      setSubsidyDisplay(true)
    }
  }

  // 切換狀態
  const handleClick = () => setClick(!click)

  return (
    <>
      <ul
        onClick={handleClick}
        className={
          click ? 'customDropdown-menu clicked' : 'customDropdown-menu'
        }
        onMouseLeave={handleMouseLeave}
      >
        <li className="classificationTheme">
          <div className="dropdown-content_inner">
            <h2 className="titleC__h3">
              <div className="dropdown-title">
                <span className="section-content_en">學員服務</span>
              </div>
              <span className="lesson-content_ch d-block mt-3">Service</span>
              {/* 要不要做一個總覽課程頁面? */}
            </h2>
          </div>
        </li>
        <li className="classificationTheme">
          <div className="dropdown_primaryClassification_div">
            <ul>
              {/* <li className="dropdown_primaryClassification_li">
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  線上平台
                </Link>
              </li> */}
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeave}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/studentnotice"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  學員須知
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeaveTestCenter}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/testcenter"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  考試中心
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeaveSubsidy}
              >
                <span
                  className="dropdown_primaryClassification_a"
                  // to="/"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  政府就業協助方案資訊
                </span>
              </li>
            </ul>
          </div>
          {testCenterDisplay && (
            <TestCenterItems
              handleClick={handleClick}
              setOptionTypeTab={setOptionTypeTab}
            />
          )}
          {subsidyDisplay && (
            <SubsidyInformationItems handleClick={handleClick} />
          )}
        </li>
      </ul>
    </>
  )
}

export default DropdownMateService
