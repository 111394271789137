// 就業養成班首頁, 時數與班別敘述
// 這頁應該不要從資料取得 ?

export const EmploymentIndexList = [
  {
    title: '3D遊戲美術設計',
    classTypeId: 'JAMTDCT',
    time: '',
    description:
      '以3D遊戲概論、遊戲美術理論與2D美術的Photoshop奠基，主軸工具軟體則是遊戲業界標準的3ds Max',
    marketing:
      '不需要任何經驗，只需要一顆對創意的熱情！我們的專業指導和最新的工具將助你在3D遊戲美術領域蓬勃發展。現在就加入我們，一起開創未來！',
  },
  {
    title: 'iOS App與Mac應用軟體開發',
    classTypeId: 'JEMMPDT',
    time: '',
    description:
      '培養行動應用軟體開發程式設計師，專長於iPhone平台、行動應用雲端服務人才，具備規劃與實作行動應用雲端服務的能力',
    marketing:
      '打造未來，成為行動開發專家！資展國際獨家提供Apple iOS APP開發課程，培養你成為企業級APP開發人才。',
  },
  {
    title: '嵌入式系統與韌體技術',
    classTypeId: 'JEMEMET',
    time: '',
    description:
      '嵌入式軟體區分為使用者端的應用軟體及伺服器端的整合軟體，伺服器端的軟體可能以Linux或是Windows為核心',
    marketing:
      '國內對於嵌入式系統人才的需求持續增長，而我們的課程將帶您走向這個前景光明的領域。無論您是尋求職業發展還是學術探索，我們的課程將助您成為業界不可或缺的專家。',
  },
  {
    title: 'AI 人工智慧創新應用',
    classTypeId: 'JEAIMLT',
    time: '600',
    description:
      'Python 程式設計、JavaScript語法入門開始，一路引領您實作到AI相關的應用',
    marketing:
      '啟動您的AI之旅！從Python、Java到機器學習和物聯網，本課程為您打造完美的AI技能，為未來就業市場做好準備。加入我們，開始您的AI之旅！',
  },
  {
    title: 'Big Data 巨量資料分析',
    classTypeId: 'JJBDSET',
    time: '600',
    description: 'Java 到R與Python的應用，探討與時俱進的Hadoop Ecosystem',
    marketing:
      '資料科學是未來的趨勢，而我們的課程將為您提供一個全面的學習體驗，從程式語言到資料視覺化，從關聯式資料庫到巨量資料處理。',
  },
  {
    title: 'AI跨界行銷資料科學專業班',
    classTypeId: 'JPDSMKT',
    time: '600',
    description:
      'MarTech的主要目標是通過運用各種技術工具和數據分析來實現更有針對性的行銷活動，並更好地理解和滿足目標客戶的需求。',
    marketing:
      '解鎖行銷科技的力量！加入我們的AI跨界行銷資料科學專業班，學到如何運用數據分析工具、客戶關係管理系統（CRM）、個性化行銷工具等各種技術，來創建精準、有針對性的行銷策略。',
  },
  {
    title: '跨域Java軟體工程師',
    classTypeId: 'JJEEITT',
    time: '576',
    description:
      '從Java/JSP/Servlet/Java EE 等程式設計，到 Hibernate、Spring、Spring MVC 應用程式框架以及 SQL Server 資料庫的設計與應用等，俾使學員橫跨程式設計、網頁開發、企業級資料庫運用等電腦主流技術',
    marketing:
      '成為Java跨平台應用程式設計師！由基礎到專業，這是你開啟電腦主流技術之旅的機會。立即參與，打造你的軟體未來！',
  },
  {
    title: '全端工程師',
    classTypeId: 'JMFUENT',
    time: '',
    description:
      '全端工程師需要對Web 架構的每一個層次都有足夠的了解：搭建和配置Linux 服務器，編寫服務器端API，利用客戶端Javascript 代碼驅動應用，將設計層面的東西轉化到實際的CSS 代碼',
    marketing:
      '我們的課程旨在培養初階程式開發工程師，從前端到後端，學習JavaScript、C#、ASP.NET MVC，擁有全方位的技能。',
  },
  {
    title: '智慧應用微軟C#工程師',
    classTypeId: 'JMMSITT',
    time: '600',
    description:
      '微軟是目前市佔率最高的軟體領導廠商，本班是職訓市場唯一最有經驗、且針對微軟開發技術所設計的就業養成課程',
    marketing:
      '開創多元技能之旅！課程涵蓋C#、ASP.NET、前端開發、雲端網站、機器學習等多方面，為您打造紮實的基礎。立即參加，成就您的技術未來！',
  },
  {
    title: '雲端網路與資安工程師',
    classTypeId: 'JNSESET',
    time: '600',
    description:
      '雲端技術的導入，整合虛擬化平台、網路虛擬化、儲存裝置虛擬化、桌面虛擬化等，創造獨當一面之整合人才',
    marketing:
      '成就IT職涯！資展國際為您提供尖端技術、就業輔導，成為多方位系統整合工程師。立即參與，開創新的職涯契機！',
  },
  {
    title: '前端工程師',
    classTypeId: 'JPMFEET',
    time: '588',
    description:
      '負責網頁與使用者互動的角色，根據設計稿實作出使用者介面、以及如何編排功能的流程、資訊的呈現，通常以切版（HTML+CSS）作為入門',
    marketing:
      '學會網頁排版、UX設計，還有JavaScript前端開發，讓你的學習旅程充滿活力，讓未來網站設計無可限量！',
  },
  {
    title: 'Unity互動程式開發工程師',
    classTypeId: 'JPUMVRT',
    time: '',
    description: 'Unity 3D遊戲設計各項功能與實務進階應用、AR, VR 互動程式開發',
    marketing:
      '課程以實戰為主，學生將參與團隊專題，實際開發遊戲作品。我們不僅教您工具，更重要的是培養開發者思維，讓您在元宇宙市場中脫穎而出。一起創造遊戲未來！',
  },
  // {
  //   title: 'Unity跨平台遊戲開發工程師',
  //   classTypeId: 'JPUMVRT',
  //   time: '',
  //   description: 'Unity 3D遊戲設計各項功能與實務進階應用、AR, VR 互動程式開發',
  //   marketing:
  //     '課程以實戰為主，學生將參與團隊專題，實際開發遊戲作品。我們不僅教您工具，更重要的是培養開發者思維，讓您在元宇宙市場中脫穎而出。一起創造遊戲未來！',
  // },
  {
    title: 'UI/UX網頁視覺設計師',
    classTypeId: 'JWUIUXT',
    time: '570',
    description:
      '初學者從無到有，從UIUX觀念到實際UI實作UX導入，工具使用到應用，實際建立自己的網站作品，訓練完整技術技能並進入職場。',
    marketing:
      '初學者從無到有，從UIUX觀念到實際UI實作UX導入，工具使用到應用，實際建立自己的網站作品，訓練完整技術技能並進入職場。',
  },
  {
    title: '網路與系統工程師',
    classTypeId: 'JNNWSET',
    time: '462',
    description:
      '雲端技術的導入，整合虛擬化平台、網路虛擬化、儲存裝置虛擬化、桌面虛擬化等，創造獨當一面之整合人才，展現軟硬兼具、與眾不同的才能。',
    marketing:
      '我們強調實戰，學生將參與專題模擬，獲得真正的工作體驗。我們也提供國際級認證，幫助學生提升就業競爭力。',
  },
  {
    title: '創新應用全端網站開發工程師',
    classTypeId: 'JPPHPDT',
    time: '462',
    description:
      '訓練紮實的Junior Programmer初階程式開發工程師為目標來設計課程，以前端主要的程式語言JavaScript為核心，學習相關熱門的函式庫jQuery及框架React，後端主要以微軟的PHP程式為主，學習PHP Laravel框架開發方式，當然資料庫運用是不能少的。',
    marketing:
      '成為全端開發工程師，精通前後端技術。從零基礎出發，培養初階程式開發師的技能，打造職涯新亮點！',
  },
  {
    title: '5G+AI跨域智慧天線設計工程師',
    classTypeId: 'JN5GAIT',
    time: '',
    description:
      'Python程式語言處理與5G天線相關之軟硬體整合，完成課堂上的練習與作業，於課程結束後、通過評量，取得結業證書',
    marketing:
      '加入我們的課程，快速掌握Python、5G技術與AI應用，獲得結業證書。長期規劃，打造職涯成功！',
  },
  {
    title: '全方位IT工程師',
    time: '',
    description:
      '以扎實的網頁技術打底,帶入RWD網頁開發方式,並讓學員能夠理解網站前後端如何整合,以及系統網管相關技術',
  },
  {
    title: '智慧製造軟體測試工程師養成班(工研院新尖兵計劃)',
    classTypeId: 'JMSMQAT',
    time: '',
    description:
      '課程核心涵蓋軟體開發的技術，進而學習軟體測試實作技能的相關技術，讓學員成為具有軟體測試思維與測試實作技能的軟體開發工程師，並將介紹智慧製造技術，並指導學員如何整合這些工具到軟體測試的各個階段',
    marketing:
      '學習軟體測試實作技能的相關技術，成為具有軟體測試思維與測試實作技能的軟體開發工程師！',
  },
  {
    title: 'PHP後端工程師養成訓練班',
    classTypeId: 'JPPHPST',
    time: '',
    description:
      '最新Php框架技術-Laravel,並結合瀏覽器必備互動程式語言JavaScript',
    marketing: '進一步具熱門網頁開發技術，增加自己在就業市場的競爭優勢！',
  },
  {
    title: '全方位JavaScript工程師',
    classTypeId: 'JWJETPT',
    time: '',
    description:
      '從零開始學習 JavaScript 並進一步發展職業生涯的初學者設計，也適合希望提升技能、轉職或進入高需求 IT 行業的現職開發人員',
    marketing:
      '前端框架 Vue.js 到後端開發 Node.js，再到 DevOps 部署、測試及版本控制的全方位技能。',
  },
  {
    title: '前端網頁開發工程師轉職精修班',
    classTypeId: 'PWOJTFT',
    time: '',
    description:
      '前端轉職精修班，專為熱愛網頁開發的新手和業界專業人士打造。涵蓋最新HTML、CSS、JavaScript技術，探索React、Angular、Vue等主流框架，深入理解跨平台設計和響應式網頁開發。',
    marketing:
      '獲得最新技能、掌握最熱門框架，成為前端界的翹楚，啟航你的職業轉型之旅！',
  },
  {
    title: 'Python AI 應用工程師轉職精修班',
    classTypeId: 'PWOJTPT',
    time: '',
    description:
      '培養學員Python程式能力，掌握網頁開發技能，並深入學習人工智慧相關知識，包括前後端整合、資料分析、影像辨識、深度學習等創新應用。',
    marketing: '掌握Python AI技能，開啟職涯新征程，即刻啟動智慧轉職之旅！',
  },
  {
    title: 'Java應用工程師轉職精修班',
    classTypeId: 'PWOJTJT',
    time: '',
    description:
      '習得動態網頁開發的相關知識、Java程式設計開發、關聯式資料庫相關知識、Spring框架等專業知識與技能。',
    marketing: '掌握Java開發、動態網頁與Spring框架！打造專業技能，開啟新職涯！',
  },
  {
    title: '全端工程師轉職精修班',
    classTypeId: 'PWOJTMT',
    time: '',
    description:
      '全端工程師需要對Web 架構的每一個層次都有足夠的了解：搭建和配置Linux 服務器，編寫服務器端API，利用客戶端Javascript 代碼驅動應用，將設計層面的東西轉化到實際的CSS 代碼。',
    marketing:
      '從前端到後端，學習JavaScript、C#、ASP.NET MVC，擁有全方位的技能！',
  },
]
