import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// AIoT就業養成班詳細內容 (20230901沒有開班了)
function AIotApplicationClass() {
  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      <div className="classContent__figure">
        <img src="/img/employment/AIoT_head.jpg" alt="" />
      </div>

      <div className="txtC__div text-justify">
        <h4 className="employmentClassification-instruction_h4">
          張忠謀：AI改變世界一如智慧手機改變生活
          <br />
          馬雲：我們認為IoT是未來
        </h4>
        <div className="employmentClassification-instruction_detial">
          <p>
            隨著處理資料的方式和位置的不斷變化，雲端運算受到了硬體和網路連線方面的限制，邊緣運算可以看做是無處不在的雲端運算和物聯網(IoT)的延伸概念。人工智慧(AI)技術逐漸成熟，以及物聯網(IoT)蓬勃發展，AI透過IoT滲透到社會生活和行業之中，AIoT驅動各式智慧裝置應用，裝置將變得機智靈巧。
          </p>
          <br />
          <p>
            從根本上來講，邊緣運算是AI機器學習從雲資料中心到IoT的移動。邊緣運算具備以下幾個優勢：
            能夠近乎即時地處理資料，消除了在頻寬有限的網路上傳送原始資料的負擔，
            消除計算量大的原始資料對雲資料中心的壓力，
            降低雲網路從資料中心獲得資訊的依賴性。
          </p>
          <br />
          <p>
            特斯拉把汽車變成邊緣運算裝置。自駕車必須能夠自行AI機器學習思考行動，而且不能仰賴雲端，必須即時處理感測器傳來的資訊。若要發揮最大效用，必須徹底整合物聯網(IoT)軟硬體，特斯拉所做的正是如此。
          </p>
          <br />
          <p>
            工具機大廠Sandvik
            Coromant將AI機器學習放到靠近資料源的IoT設備前端來分析，省下資料兩地往返的等待時間，讓異常事件預警可以更接近即時反應，甚至沒有網路也不怕。
          </p>
          <br />
          <p>
            因應邊緣運算的未來趨勢，Intel近日也推出了OpenVINO開發工具包，透過OpenVINO，使用者可以開發高效能OpenCV的視覺分析應用，可以與AI機器學習工具結合使用。結合Intel軟體、硬體產品，讓開發者可以更簡單將邊緣、物聯網裝置搜集的影像資料，轉換成有商業價值的資訊。
          </p>
          <br />
          <p>
            在AIoT (人工智慧＋物聯網)的時代中，如何成為一名的AIoT
            (人工智慧＋物聯網)
            智慧裝置軟硬整合應用開發工程師，應該要具備哪些技能？
          </p>
        </div>
      </div>

      {/* 課程介紹 */}
      <div>
        <div className="pb-4">
          <h6>
            <strong>【課程目標】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              培養學員能夠使用網站JavaScript+Node.js+Java
              Web開發技術，建構在Raspberry Pi
              IoT平台智慧裝置軟硬整合Web網頁應用程式
            </li>
            <li>
              培養學員能夠運用Python+ Java程式語言，進行在Raspberry Pi
              IoT平台AI機器學習以及OpenCV影像辨識應用
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              首重實作！使用Raspberry Pi IoT物聯網平台整合Node.js +Java Web
              到Python+Java AI機器學習專案開發
            </li>
            <li>
              從零開始！本班從JavaScript、Python Java
              Web語法入門開始，一路帶你實作到Raspberry Pi IoT Node.js +Java Web
              +OpenCV影像辨識應用
            </li>
            <li>
              專題導向！我們從使用Raspberry Pi
              IoT平台應用案例為目標，引導你完成智慧裝置AI機器學習+OpenCV影像辨識應用專題實作
            </li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure employmentClassification-img_only">
          <img src="/img/employment/AIoT_class.jpg" alt="" />
        </div>
      </div>

      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">招生對象</div>
          <ul className="classInfoList__styleNone">
            <li>
              對於JavaScript+Node.js +Java
              Web語言、IoT網站開發Web網頁應用程式有興趣者
            </li>
            <li>對於運用Python+ Java進行AI機器學習智慧裝置應用等有興趣者</li>
            <li>對於OpenCV影像辨識應用等有興趣者</li>
            <li>對於在Raspberry Pi IoT平台智慧裝置應用整合有興趣者</li>
            <li>對人工智慧AI+物聯網IoT應用有興趣，有志成為創客Maker一族者</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>物聯網IOT工程師</li>
            <li>Python+ Java AI機器學習智慧裝置應用工程師</li>
            <li>
              JavaScript+Node.js +Java Web網站IoT Web網頁應用程式開發工程師
            </li>
            <li>OpenCV影像辨識應用工程師</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>3D遊戲美術設計人才養成班適合對象</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">熟悉電腦基本操作</h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對3D遊戲美術設計有強烈學習意願及興趣
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        美術或設計相關科系畢業者尤佳
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        男女不拘，但男性需役畢或免役
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        解3D遊戲業界專案設計流程
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        學習3D遊戲美術實務與專業遊戲技術應用，以Unity整合美術與互動設計
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        具備遊戲專題製作能力
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">SOHO接案</li>
                  <li className="longTerm_tabContent_li">獨立製作</li>
                  <li className="longTerm_tabContent_li">出國升學</li>
                  <li className="longTerm_tabContent_li">職場就業</li>
                  <li className="longTerm_tabContent_li">3D遊戲美術設計</li>
                  <li className="longTerm_tabContent_li">3D角色製作</li>
                  <li className="longTerm_tabContent_li">3D場景製作</li>
                  <li className="longTerm_tabContent_li">3D角色動作</li>
                  <li className="longTerm_tabContent_li">遊戲特效設計</li>
                  <li className="longTerm_tabContent_li">3D動畫設計</li>
                </ul>
              </div>
            </Tab> */}
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default AIotApplicationClass
