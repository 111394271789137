import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// 大數據就業養成班詳細內容, 20230901修改
function BigDataAnalysisClass(props) {
  const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  // 課程內容資訊打開與隱藏狀態
  const [switchCss1, setSwitchCss1] = useState(false)
  const [switchCss2, setSwitchCss2] = useState(false)
  const [switchCss3, setSwitchCss3] = useState(false)
  const [switchCss4, setSwitchCss4] = useState(false)
  const [switchCss5, setSwitchCss5] = useState(false)
  const [switchCss6, setSwitchCss6] = useState(false)
  const [switchCss7, setSwitchCss7] = useState(false)
  const [switchCss8, setSwitchCss8] = useState(false)

  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [open5, setOpen5] = useState(false)
  const [open6, setOpen6] = useState(false)
  const [open7, setOpen7] = useState(false)
  const [open8, setOpen8] = useState(false)

  // 展開詳細內容狀態
  const handleOpen1 = () => {
    setSwitchCss1(!switchCss1)
    setOpen1(!open1)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  const handleOpen2 = () => {
    setSwitchCss2(!switchCss2)
    setOpen2(!open2)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  const handleOpen3 = () => {
    setSwitchCss3(!switchCss3)
    setOpen3(!open3)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  const handleOpen4 = () => {
    setSwitchCss4(!switchCss4)
    setOpen4(!open4)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  const handleOpen5 = () => {
    setSwitchCss5(!switchCss5)
    setOpen5(!open5)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  const handleOpen6 = () => {
    setSwitchCss6(!switchCss6)
    setOpen6(!open6)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  const handleOpen7 = () => {
    setSwitchCss7(!switchCss7)
    setOpen7(!open7)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  const handleOpen8 = () => {
    setSwitchCss8(!switchCss8)
    setOpen8(!open8)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
  }

  return (
    <>
      <div className="classContent__figure">
        <img src="/img/employment/Analysis Of Big Data_head.png" alt="" />
      </div>

      <div className="txtC__div text-justify">
        <p>
          過去數年以來，國內外大量產業引進資料科學、物聯網甚至人工智慧等概念來處理更大量的業務、更快速的需求、更多元的訂單。個人乃至於企業如何善用手邊的數據以增進工作效率、加速產線質量、改善專案管理也是不見退燒的議題。
        </p>
        <br />
        <p>
          但在茫茫的資料流中，如何將這些技術應用在各種不同的行業、情境，一直都是資料科學工作者所追求的，且不論是物聯網或是人工智慧的應用，其底層技術不外乎「資料」本身。本班乃是因應資料科學職場上的實際需求所設計出來並與大數據技術直接相關的就業養成班，在AI、IoT掛帥的產業聲浪中，為各位打下良好的「資料」基礎，站在巨量資料「巨人」的肩膀，觀看整個「資料世界」的演繹。
        </p>

        {/* 學生心得 */}
        <div className="knowledge_content knowledge-c">
          <h5>學生心得分享</h5>
          <div className="knowledge-content">
            <div className="knowledge-content_list">
              <ul className="classLink-content_ul knowledge-content_ul">
                <li>
                  <a href="/1501048" className="knowledge-content_a">
                    淡江大學數學系資統組 林Ｏ妤同學
                  </a>
                </li>
                <li>
                  <a href="/1501043" className="knowledge-content_a">
                    輔仁大學西班牙語文學系 石Ｏ同學
                  </a>
                </li>
                <li>
                  <a href="/1501047" className="knowledge-content_a">
                    淡江大學水資源及環境工程學研究所管理組 陳Ｏ威同學
                  </a>
                </li>
                <li>
                  <a href="/1501041" className="knowledge-content_a">
                    東吳大學微生物學研究所碩士 傅Ｏ瑩同學
                  </a>
                </li>
                <li>
                  <a href="/1501028" className="knowledge-content_a">
                    台灣大學機械工程系 廖Ｏ傑同學
                  </a>
                </li>
                <li>
                  <a href="/1501026" className="knowledge-content_a">
                    輔仁大學數學系 林Ｏ任同學
                  </a>
                </li>
                <li>
                  <a href="/1501027" className="knowledge-content_a">
                    實踐大學社會工作學系 謝Ｏ賢同學
                  </a>
                </li>
                <li>
                  <a href="/1501033" className="knowledge-content_a">
                    台灣大學經濟系 鍾Ｏ辰同學
                  </a>
                </li>
                <li>
                  <a href="/1700718" className="knowledge-content_a">
                    台灣大學電機工程研究所碩士 柯Ｏ彬同學
                  </a>
                </li>
                <li>
                  <a href="/1601315" className="knowledge-content_a">
                    美國德州大學奧斯汀分校會計研究所碩士 蔣Ｏ庭同學
                  </a>
                </li>
              </ul>
            </div>
            <div className="knowledge-content_list">
              <ul className="classLink-content_ul knowledge-content_ul">
                <li>
                  <a href="/1501040" className="knowledge-content_a">
                    淡江大學統計系 朱Ｏ宏同學
                  </a>
                </li>
                <li>
                  <a href="/1600366" className="knowledge-content_a">
                    台灣大學工商管理系科技管理組 羅Ｏ同學
                  </a>
                </li>
                <li>
                  <a href="/1600381" className="knowledge-content_a">
                    台灣科技大學應用外語系 曾Ｏ毅同學
                  </a>
                </li>
                <li>
                  <a href="/1600369" className="knowledge-content_a">
                    政治大學社工所(大學資管跨領域) 施Ｏ芸同學
                  </a>
                </li>
                <li>
                  <a href="/1600382" className="knowledge-content_a">
                    台北科技大學光電工程學 莊Ｏ逸同學
                  </a>
                </li>
                <li>
                  <a href="/1501035" className="knowledge-content_a">
                    台灣大學物理系 徐Ｏ谷同學
                  </a>
                </li>
                <li>
                  <a href="/1501044" className="knowledge-content_a">
                    交通大學運輸科技與管理學系 鍾Ｏ修同學
                  </a>
                </li>
                <li>
                  <a href="/1601337" className="knowledge-content_a">
                    台灣大學經濟研究所碩士 林Ｏ瑩同學
                  </a>
                </li>
                <li>
                  <a href="/1601331" className="knowledge-content_a">
                    中央大學物理學研究所碩士 林Ｏ余同學
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* 學生心得 */}
      </div>

      {/* 課程介紹 */}
      <div>
        <div className="content__title">
          <h3>課程特色</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="content__div--mb">
          <p className="text-justify">
            本班課程以Python、Java等程式語言為出發點，沿途搭配RDBMS(關聯式資料庫管理系統)，佐以Data
            Mining(數據挖掘)及各式各樣的工具套件，進而應用到機器學習、深度學習等進階技術與知識；系統環境建置部分，以Linux作業系統作為基底，建構出業界常用的Hadoop、Spark巨量資料處理平台，逐步帶領大家走入資料科學的美麗新世界。
          </p>
          <br />
          <p>
            透過我們的課程，您可以… <br />
            1. 學習程式語言如何應用在資料科學的領域 <br />
            2. 了解巨量資料處理與分析的生命週期 <br />
            3. 熟悉一般資料庫的建置維運，乃至於處理巨量資料的雲端平台 <br />
            4. 認識資料演算法的基本原理及其應用 <br />
            5.
            運用互動式的網頁開發到資料視覺化套件，讓您得以最豐富的方式呈現資料的意涵{' '}
            <br />
            6.
            經歷以小組團隊分工合作方式來進行的資料科學專題，以期磨練學員上課所學習到的專業技能並強化產業應用的能力
          </p>
        </div>
      </div>

      <div>
        <div className="content__title">
          <h3>成果展現</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>

        <div className="content__div--mb">
          <h4 className="blue pb-3">賀BDSE08班同學參與AI人才解題競賽獲獎</h4>
          <div className="imgC__div--flex">
            <figure className="imgC__figure">
              <img
                src="/img/employment/Analysis Of Big Data_work03.jpg"
                alt=""
              />
            </figure>
          </div>
          <h4 className="blue pb-3">
            賀BDSE13班同學與中華電信學院合作企業專題成果卓越
          </h4>
          <div className="imgC__div--flex">
            <figure className="imgC__figure">
              <img
                src="/img/employment/Analysis Of Big Data_work01.jpg"
                alt=""
              />
            </figure>
            <figure className="imgC__figure">
              <img
                src="/img/employment/Analysis Of Big Data_work02.jpg"
                alt=""
              />
            </figure>
          </div>
          <h4 className="blue pb-3">
            賀BDSE22班同學期末專題發表暨企業媒合圓滿落幕
          </h4>
          <div className="imgC__div--flex">
            <figure className="imgC__figure mb-0">
              <img
                src="/img/employment/Analysis Of Big Data_work04.jpg"
                alt=""
              />
            </figure>
          </div>
        </div>
      </div>

      <div>
        <div className="content__title">
          <h3>專題應用</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>

        <div className="content__div--mb">
          <ul className="listStyle__ul">
            <li>
              應用情境1
              <br />
              經由爬取並分析台北市新成屋、中古屋、租屋等情況下的行情資料，讓議價空間一目瞭然，精準價格落點，捍衛居住正義，避免買高賣低遭坑殺。建立地圖查詢快搜，揭露歷史成交物件資訊，審視周圍環境及嫌惡設施，創造優質住宅的多元價值，協助消費者做正確判斷。掌握房價漲跌趨勢預測，一手掌握買屋賣屋的好時機及大趨勢。
            </li>
          </ul>
          <figure className="imgC__figure mt-3">
            <img
              src="/img/employment/Analysis Of Big Data_class01.jpg"
              alt=""
            />
          </figure>
          <ul className="listStyle__ul">
            <li>
              應用情境2
              <br />
              台灣自2006年啟用的國道電子收費ETC，至目前為止eTag普及率高達94%，足以代表國道車流量，每日產生的站點偵測紀錄更多達300萬筆。看準eTag資料量龐大、資料取得容易及其運用範圍廣泛，希望能將此作為專題目標，分析國道路況及預測塞車問題。
            </li>
          </ul>
          <figure className="imgC__figure mt-3">
            <img
              src="/img/employment/Analysis Of Big Data_class02.jpg"
              alt=""
            />
          </figure>
          <ul className="listStyle__ul">
            <li>
              應用情境3
              <br />
              妨害名譽罪的案件量近年有逐漸增長的情形，我們針對已成立的妨害名譽罪判決書進行分類和分析，並透過機器學習，預測在滿足哪些犯案情境時，所對應的罰金金額，進而達成提升效率，減少濫用國家訴訟資源的目的，也能讓一般民眾了解觸犯妨害名譽罪不同條件的罰金差別。
            </li>
          </ul>
          <figure className="imgC__figure mt-3">
            <img
              src="/img/employment/Analysis Of Big Data_class03.jpg"
              alt=""
            />
          </figure>
          <ul className="listStyle__ul">
            <li>
              應用情境4
              <br />
              面相學說的都是真的嗎？透過大量蒐集的照片進行面部辨識，並匯入面相學提及之臉部特徵與職業做相關性分析，藉以驗證面相學的真實性。這些關聯性的分析內容將可運用在商場之精準廣告投放、醫美診所服務內容或面相改運之使用。
            </li>
          </ul>
          <figure className="imgC__figure mt-3 mb-0">
            <img
              src="/img/employment/Analysis Of Big Data_class04.jpg"
              alt=""
            />
          </figure>
        </div>
      </div>

      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure">
          <img
            src={
              longTermClass
                ? `/img/employment/syllabus/BD_JJBDSET_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '台北':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Taipei_C2E2302'
                            : 'Taipei'
                        }`
                      case '桃園':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Zhongli_C2E2302'
                            : 'Zhongli'
                        }`
                      case '台中':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Taichung_C2E2302'
                            : 'Taichung'
                        }`
                      case '台南':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'Tainan_C2E2302'
                            : 'Tainan'
                        }`
                      case '高雄':
                        return `${
                          longTermClass.taxCode !== ''
                            ? 'kaohsiung_C2E2302'
                            : 'kaohsiung'
                        }`
                      default:
                        return ''
                    }
                  })()}.png?230901`
                : '/img/employment/syllabus/BD_JJBDSET_Taipei.png?230901'
            }
            alt=""
          />
        </div>
        <p className="red pt-3 mb-3">※課程內容以地區班級為準。</p>

        {/* 展開詳細介紹 ↓↓↓　*/}
        <div className="mt-4 pb-5">
          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen1()}
              aria-controls="example-collapse-text"
              aria-expanded={open1}
              className={
                switchCss1
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              資料視覺化
            </button>
          </div>
          <Collapse in={open1}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  1. 從互動式的網頁語法(HTML,
                  JavaScript)或儀表板到資訊圖表的應用(Tableau)，將複雜的資料處理流程或演算法推導用最親民的方式呈現。
                  <br />
                  2.
                  學習在資料科學業中將前端網頁與後端資料庫做整合，我們會需要像是應用框架(Flask)這類型的技術。
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen2()}
              aria-controls="example-collapse-text"
              aria-expanded={open2}
              className={
                switchCss2
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              Java程式系列
            </button>
          </div>
          <Collapse in={open2}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  1.
                  Java是理解程式撰寫邏輯的最佳嚴師，也是目前資訊產業中的主流程式語言之一。
                  <br />
                  2.
                  由奢入儉難，由儉入奢易，學過Java後再回過頭來看看Python，會有不同的風貌。
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen3()}
              aria-controls="example-collapse-text"
              aria-expanded={open3}
              className={
                switchCss3
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              Linux作業系統
            </button>
          </div>
          <Collapse in={open3}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  1.
                  除了Windows和Mac以外，其實還有第三種作業系統喔！這也是資料科學業的必備能力。
                  <br />
                  2.
                  Linux做為系統基底，加上虛擬環境的好工具Docker，是打造Hadoop與Spark
                  叢集一定要會的馬步功夫。
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen4()}
              aria-controls="example-collapse-text"
              aria-expanded={open4}
              className={
                switchCss4
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              資料分析
            </button>
          </div>
          <Collapse in={open4}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  1.
                  使用Python來做資料處理與分析，始於程式語言的學習，透過爬蟲技術蒐集資料並清理整併，找出有價值的部分，藉此獲得敘述型統計的結論或做進一步的應用。
                  <br />
                  2.
                  數據有著千變萬化的型態、欄位、筆數、缺失、雜質，更重要的是這些數據究竟是應用在哪種產業？哪個場合？做數據分析的目的又是什麼？如果能透過數據挖掘(data
                  mining)的案例分析這些研究方法，是否會讓我們事半功倍？
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen5()}
              aria-controls="example-collapse-text"
              aria-expanded={open5}
              className={
                switchCss5
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              人工智慧應用
            </button>
          </div>
          <Collapse in={open5}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  1.
                  在越來越重視效益的現代職場，用AI去取代一些簡易或重複性高的工作已成為趨勢，經由資料科學來作為入門AI的敲門磚，是再適合不過的了。
                  <br />
                  2.
                  無論是天上飛的無人機、大門的人臉辨識、工廠的機械手臂，所有的AI應用都必須將數據作為模型的輸入才能運作，因此演算法也是這個領域一定會認識的新知識。
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen6()}
              aria-controls="example-collapse-text"
              aria-expanded={setOpen6}
              className={
                switchCss6
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              資料庫系統
            </button>
          </div>
          <Collapse in={open6}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  1.
                  作為資訊業的通識科目，資料庫(SQL)是一定要學會的基礎，不論是什麼產業都會用得上。
                  <br />
                  2. 資料工程師的必備技能，未來在職場上會再銜接到資料流(data
                  flow)、資料管線(data pipeline)、資料倉儲(data
                  warehouse)等觀念。
                  <br />
                  3.
                  任何單一的資料庫系統都會有其容量上限，當手上的資料量超過這個上限時，該怎麼辦？
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen7()}
              aria-controls="example-collapse-text"
              aria-expanded={open7}
              className={
                switchCss7
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              Hadoop生態系
            </button>
          </div>
          <Collapse in={open7}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  1.
                  擺脫單機的思維，嘗試用「叢集」的角度去看待「巨量資料」的存放跟處理，有些產業的資料不是只用單一系統就能處理好的。
                  <br />
                  2.
                  與雲端技術連接，善用各種平台工具，將龐大的數據掌握在手中，縮放自如。
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header lesson-header-last">
            <button
              onClick={() => handleOpen8()}
              aria-controls="example-collapse-text"
              aria-expanded={open8}
              className={
                switchCss8
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              專題研究與實作
            </button>
          </div>
          <Collapse in={open8}>
            <div id="example-collapse-text" className="lesson-body-last">
              <div className="lesson-content">
                <h6>
                  1.
                  在資料科學的世界，你可以一個人走得很快，但是你走不遠；要走的遠，還是得依賴團隊，一群人才走得遠。藉由團隊分工合作，用專案管理的方式來模擬資料科學業中的工作內容，以利日後銜接職場。
                  <br />
                  2.
                  求職與升遷中，最重要的就是擁有自己的作品，訓期內將學到的技術妥善整理，以備未來不時之需。
                </h6>
              </div>
            </div>
          </Collapse>
        </div>
        {/* 展開詳細介紹 ↑↑↑　*/}
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>

        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天為主，另因配合企業講師之時間安排，部份課程會於週末假日或平日晚上上課。
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>Big Data 巨量資料分析就業養成班適合對象</h4>
                <div className="longTermTabC__div">
                  {/* <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure> */}
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">學歷建議</h5>
                      <h6>大專(以上)畢業青年</h6>
                    </div>

                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">就讀科系</h5>
                      <h6>不限，歡迎各領域的有志者加入</h6>
                    </div>

                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">基礎條件</h5>
                      <h6>
                        對「玩轉資料」這件事情感興趣，願意投入時間心力，且具備高度學習意願
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備轉換跑道，進入資料科學的工作領域
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備資料擷取、管理與處理的重要業界核心技能
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備Java程式開發、資料庫設計等能力，快速加入資訊產業
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員具備使用Python進行資料分析、資料視覺化、機器學習，甚至深度學習等能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        實作Hadoop巨量資料處理平台解決方案，並使用Spark處理巨量資料，大幅提升運算速度
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">資料工程師</li>
                  <li className="longTerm_tabContent_li">資料庫工程師</li>
                  <li className="longTerm_tabContent_li">
                    Python、Java程式設計師
                  </li>
                  <li className="longTerm_tabContent_li">資料分析師</li>
                  <li className="longTerm_tabContent_li">BI商業智慧分析師</li>
                  <li className="longTerm_tabContent_li">資料科學專案經理</li>
                  <li className="longTerm_tabContent_li">資料科學家</li>
                  <li className="longTerm_tabContent_li">AI工程師</li>
                  <li className="longTerm_tabContent_li">
                    巨量資料平台Hadoop系統架構師
                  </li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default BigDataAnalysisClass
