import React, { useState } from 'react'
import OjtItems from './OjtItems'
import { Link } from 'react-router-dom'
import OjtSubsidyItems from './OjtSubsidyItems'

// 在職進修班Dropdown
function DropdownOjt() {
  // 在職進修班選項預設是消失狀態, 點擊每個分類之後到達分頁呈現消失
  const [click, setClick] = useState(false)

  // 進修班預設為不顯示, hover出現
  const [ojtDisplay, setOjtDisplay] = useState(false)

  // 政府補助預設為不顯示, hover出現
  const [subsidyDisplay, setSubsidyDisplay] = useState(false)

  const handleMouseLeave = () => {
    setOjtDisplay(false)
    setSubsidyDisplay(false)
  }

  // hover課程探索
  const handleMouseHoverOjt = () => {
    if (subsidyDisplay) {
      setSubsidyDisplay(false)
      setOjtDisplay(true)
    } else {
      setOjtDisplay(true)
    }
  }

  // hover政府補助
  const handleMouseLeaveSubsidy = () => {
    if (ojtDisplay) {
      setOjtDisplay(false)
      setSubsidyDisplay(true)
    } else {
      setSubsidyDisplay(true)
    }
  }

  // 切換狀態
  const handleClick = () => setClick(!click)

  return (
    <>
      <ul
        onClick={handleClick}
        className={
          click ? 'customDropdown-menu clicked' : 'customDropdown-menu'
        }
        onMouseLeave={handleMouseLeave}
      >
        <li className="classificationTheme">
          <div className="dropdown-content_inner">
            <h2 className="titleC__h3">
              <div className="dropdown-title">
                <span className="section-content_en">在職培訓</span>
                {/* <span className="section-content_en">On Job Training</span> 
                <span className="lesson-content_ch d-block">就業養成班</span>*/}
              </div>
              <span className="lesson-content_ch d-block mt-3">
                OnJob Training
              </span>
              {/* 要不要做一個總覽課程頁面? */}
            </h2>
          </div>
        </li>
        <li className="classificationTheme">
          <div className="dropdown_primaryClassification_div">
            <ul>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeave}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/OJT/All"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  課程總覽
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseHoverOjt}
              >
                <Link
                  className={
                    ojtDisplay
                      ? 'dropdown_primaryClassification_a select_bcc'
                      : 'dropdown_primaryClassification_a'
                  }
                  to="/OJT"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  分類探索
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeave}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/corporate-training"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  企業包班
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeave}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/DT"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  數位人才培訓
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeaveSubsidy}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/onjobsubsidy"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  政府補助
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeave}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/careers"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  轉職進修
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeave}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/vocationaltraining"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  職業訓練機構專班
                </Link>
              </li>
            </ul>
          </div>

          {ojtDisplay && <OjtItems handleClick={handleClick} />}
          {subsidyDisplay && <OjtSubsidyItems handleClick={handleClick} />}
        </li>
      </ul>
    </>
  )
}

export default DropdownOjt
