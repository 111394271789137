import React, { useEffect, useState } from 'react'
import MultiLevelBreadCrumb from '../../components/MultiLevelBreadCrumb'
import UseLongTerm from '../../components/LongTerm/UseLongTerm'
import Question from '../../components/LongTerm/Qusetion'
import moment from 'moment' // 格式化日期

function LongTerm(props) {
  const {
    breadCrumbBool,
    setBreadCrumbBool,
    signinBool,
    dynamicBreadcrumbName,
    setDynamicBreadcrumbName,
  } = props

  console.log(dynamicBreadcrumbName)
  const {
    applyInfoHtml,
    description,
    ClassInfo,
    ContactStaff,
    SubsidyBlock,
    isLoading,
    setIsLoading,
    VR3DGameClass,
    AppDeveloperClass,
    EmbeddedSystemClass,
    AIoTSeriesClass,
    BigDataAnalysisClass,
    JavaSoftwareClass,
    FullStackDeveloperClass,
    MicrosoftDeveloperClass,
    CloudTechnologyClass,
    FrontEndClass,
    UnityDeveloperClass,
    UIuxInteractiveClass,
    CloudSystemClass,
    PhpdClass,
    AntennaClass,
    AnalyseInformationClass,
    JMSMQATClass,
    JPPHPSTClass,
    JWJETPTClass,
  } = UseLongTerm()

  // 展開詳細內容狀態
  const [closed, setClosed] = useState(true)

  const [longTermClass, setLongTermClass] = useState([])

  const handleLongTermClass = (newClass) => {
    if (newClass.length !== 0) {
      console.log(newClass)
      // 在這裡處理接收到的數據
      setLongTermClass(newClass)
    }
  }
  // console.log(longTermClass)

  useEffect(() => {
    // 設置 isLoading 為 true，表示加载中
    setIsLoading(true)

    // 使用 setTimeout 在 2 秒後將 isLoading 設置為 false，表示加载完成
    const timeoutId = setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    // 在组件卸載時清除定時器，以防止内存泄漏
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 掛載時改動麵包屑位置
  useEffect(() => {
    setBreadCrumbBool(false)
  })

  // 卸載時還原麵包屑位置
  useEffect(() => {
    return () => {
      setBreadCrumbBool(true)
    }
  })

  useEffect(() => {
    if (longTermClass.length !== 0 && setDynamicBreadcrumbName) {
      setDynamicBreadcrumbName(longTermClass[0].v.aClass.className)
    }
  }, [longTermClass, setDynamicBreadcrumbName])

  return (
    <>
      <div className={isLoading ? 'spinner-wrapper_content' : 'd-none'}>
        <div className="spinner-wrapper mt-0">
          <span className="loading" data-name="Loading">
            Loading
          </span>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="col p-0 position-relative">
          <div className="banner__container">
            <figure className="main-picture_img">
              <img src="/img/employment/longTermBanner.jpg" alt="" />
            </figure>
            <div className="banner__titleC">
              <div className="custom-container">
                <div className="bannerTitle__white text-left">
                  <h1>
                    {longTermClass.length !== 0 &&
                      longTermClass[0].v.aClass.className.replace(
                        /(就業養成班|養成班)/g,
                        ''
                      )}
                  </h1>
                  <h6>
                    就業養成班 &nbsp;|&nbsp;
                    {description}
                  </h6>
                  <p>
                    開課時間 :&nbsp;
                    {(() => {
                      switch (
                        longTermClass.length !== 0 &&
                        longTermClass[0].v.aClass.classID
                      ) {
                        case 'JNSESET2403':
                          return '113年10月中旬開課'
                        case 'JPUMVRT2402':
                          return '113年11月上旬開課'
                        case 'JEMEMET2402':
                          return '113年12月下旬開課'
                        default:
                          return (
                            <>
                              {moment(
                                longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass.startDate
                              ).format('YYYY/MM/DD') +
                                '-' +
                                moment(
                                  longTermClass.length !== 0 &&
                                    longTermClass[0].v.aClass.endDate
                                ).format('YYYY/MM/DD')}
                            </>
                          )
                      }
                    })()}
                    {/* {moment(
                      longTermClass.length !== 0 &&
                        longTermClass[0].v.aClass.startDate
                    ).format('YYYY/MM/DD') +
                      '-' +
                      moment(
                        longTermClass.length !== 0 &&
                          longTermClass[0].v.aClass.endDate
                      ).format('YYYY/MM/DD')} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 麵包屑 ↓↓↓　*/}
      <div className="container-fluid p-0">
        <div className="breadcrumbs-content greyBcc">
          {!breadCrumbBool && dynamicBreadcrumbName !== '' && (
            <MultiLevelBreadCrumb
              dynamicBreadcrumbName={
                dynamicBreadcrumbName !== '' && dynamicBreadcrumbName
              }
            />
          )}
        </div>
      </div>
      {/* 麵包屑 ↑↑↑　*/}

      <SubsidyBlock
        longTermClassID={
          longTermClass.length !== 0 && longTermClass[0].v.aClass.classID
        }
      />

      <div className="bc__div--light">
        <div className="custom-container">
          <div>
            <div className="bcBlockInner__div">
              <h3 className="titleC__h3">
                <span className="black">報名課程資訊</span>
              </h3>
              <div className="bc__div--white">
                <div>
                  <div
                    className="json-content"
                    dangerouslySetInnerHTML={{ __html: [applyInfoHtml] }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="bcBlockInner__div pt-0">
              <h3 className="titleC__h3">
                <span className="black">課程介紹</span>
              </h3>
              <div className="lesson-ditailed_inner">
                <div
                  className={
                    closed
                      ? 'lesson-ditailed_header is-opened'
                      : 'lesson-ditailed_header'
                  }
                >
                  <div
                    className="lesson-header_content"
                    onClick={() => setClosed(!closed)}
                  >
                    <div className="lesson-header_a">
                      <div className="lesson-header_title">
                        {longTermClass.length !== 0 &&
                          longTermClass[0].v.aClass.className.replace(
                            /(就業養成班|養成班)/g,
                            ''
                          )}
                      </div>
                      <div className="expandIcon__div--circle">
                        <span className="expandIcon__span--allow"></span>
                        <span className="expandIcon__span--line"></span>
                        <span className="expandIcon__span--line"></span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      closed
                        ? 'classContent__container'
                        : 'classContent__container__off'
                    }
                  >
                    <div className="classContent__padding">
                      {(() => {
                        switch (
                          longTermClass.length !== 0 &&
                          longTermClass[0].v.aClass.classTypeID
                        ) {
                          case 'JAMTDCT':
                            return (
                              <VR3DGameClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JEMMPDT':
                            return (
                              <AppDeveloperClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JEMEMET':
                            return (
                              <EmbeddedSystemClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JEAIMLT':
                            return (
                              <AIoTSeriesClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JJBDSET':
                            return (
                              <BigDataAnalysisClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JPDSMKT':
                            return (
                              <AnalyseInformationClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JJEEITT':
                            return (
                              <JavaSoftwareClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JMFUENT':
                            return (
                              <FullStackDeveloperClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JMMSITT':
                            return (
                              <MicrosoftDeveloperClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JNSESET':
                            return (
                              <CloudTechnologyClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JPMFEET':
                            return (
                              <FrontEndClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JPUMVRT':
                            return (
                              <UnityDeveloperClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JWUIUXT':
                            return (
                              <UIuxInteractiveClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JNNWSET':
                            return (
                              <CloudSystemClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JPPHPDT':
                            return (
                              <PhpdClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JN5GAIT':
                            return (
                              <AntennaClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JMSMQAT':
                            return (
                              <JMSMQATClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JPPHPST':
                            return (
                              <JPPHPSTClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          case 'JWJETPT':
                            return (
                              <JWJETPTClass
                                longTermClass={
                                  longTermClass.length !== 0 &&
                                  longTermClass[0].v.aClass
                                }
                              />
                            )
                          default:
                            return null
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <ClassInfo
              signinBool={signinBool}
              LongTermClassInfo={handleLongTermClass}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="custom-container" id="contactCourseWide">
          {(() => {
            switch (
              longTermClass.length !== 0 &&
              longTermClass[0].v.aClass.classID
            ) {
              case 'JNSESET2401':
                return (
                  <div className="bcBlockInner__div" id="question">
                    <h3 className="black">洽詢資訊</h3>
                    <div className="bc__div--light mt-4">
                      <div className="region-contact_inner">
                        <h5>『台灣區電機電子工業同業公會』 課程洽詢專線</h5>
                        <div className="line__div--dark"></div>
                        <p>
                          【電話】 ：(02) 8792-6666 分機236 &nbsp;黃興邦先生
                        </p>
                      </div>
                    </div>
                  </div>
                )
              case 'JEAIMLT2401':
                return (
                  <div className="bcBlockInner__div" id="question">
                    <h3 className="black">洽詢資訊</h3>
                    <div className="bc__div--light mt-4">
                      <div className="region-contact_inner">
                        <h5>『台灣區電機電子工業同業公會』 課程洽詢專線</h5>
                        <div className="line__div--dark"></div>
                        <p>
                          【電話】 ：(02) 8792-6666 分機236 &nbsp;黃興邦先生
                        </p>
                      </div>
                    </div>
                  </div>
                )
              case 'JEMEMET2401':
                return (
                  <div className="bcBlockInner__div" id="question">
                    <h3 className="black">洽詢資訊</h3>
                    <div className="bc__div--light mt-4">
                      <div className="region-contact_inner">
                        <h5>『台灣區電機電子工業同業公會』 課程洽詢專線</h5>
                        <div className="line__div--dark"></div>
                        <p>
                          【電話】 ：(02) 8792-6666 分機236 &nbsp;黃興邦先生
                        </p>
                      </div>
                    </div>
                  </div>
                )
              case 'JMSMQAT2401':
                return (
                  <div className="bcBlockInner__div" id="question">
                    <h3 className="black">洽詢資訊</h3>
                    <div className="bc__div--light mt-4">
                      <div className="region-contact_inner">
                        <h5>『財團法人工業技術研究院』 課程洽詢專線</h5>
                        <div className="line__div--dark"></div>
                        <p>【電話】 ：(06) 363-6695&nbsp;邱小姐</p>
                      </div>
                    </div>
                  </div>
                )
              default:
                return (
                  <ContactStaff
                    maintainer={
                      longTermClass.length !== 0 &&
                      longTermClass[0].v.aClass.maintainer
                    }
                  />
                )
            }
          })()}
          {/* <ContactStaff
            maintainer={
              longTermClass.length !== 0 && longTermClass[0].v.aClass.maintainer
            }
          /> */}
        </div>
      </div>

      <div>
        <Question />
      </div>
    </>
  )
}

export default LongTerm
