import React, { useEffect, useState } from 'react'
import { Mentor } from '../../services/Employee'

function ContactStaff(props) {
  const { maintainer } = props
  const [maintainerInfo, setMaintainerInfo] = useState()

  const handleGetMaintainerInfo = async () => {
    try {
      const datas = await Mentor(maintainer)
      setMaintainerInfo(datas)
    } catch (error) {
      console.error('Error fetching maintainer info:', error)
    }
  }
  // 處理取得教務資訊
  useEffect(() => {
    if (maintainer.length !== 0) {
      handleGetMaintainerInfo()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintainer])

  return (
    <>
      {maintainerInfo && (
        <>
          <div className="bcBlockInner__div" id="question">
            <h3 className="black">洽詢資訊</h3>
            <div className="bc__div--light mt-4">
              <div className="region-contact_inner">
                <h5>{maintainerInfo.address.substring(0, 2)}開課課務人員</h5>
                <div className="line__div--dark"></div>
                <p>
                  【電話】
                  {(() => {
                    switch (maintainerInfo.phone.substring(2, 3)) {
                      case '4':
                        return (
                          <a
                            href={`tel:+886-4-${maintainerInfo.phone
                              .substring(4, 13)
                              .split('-')
                              .join('')}`}
                          >
                            {maintainerInfo.phone}
                          </a>
                        )
                      case '2':
                        return (
                          <a
                            href={`tel:+886-2-${maintainerInfo.phone
                              .substring(4, 13)
                              .split('-')
                              .join('')}`}
                          >
                            {maintainerInfo.phone}
                          </a>
                        )
                      case '7':
                        return (
                          <a
                            href={`tel:+886-7-${maintainerInfo.phone
                              .substring(4, 13)
                              .split('-')
                              .join('')}`}
                          >
                            {maintainerInfo.phone}
                          </a>
                        )
                      case '3':
                        return (
                          <a
                            href={`tel:+886-3-${maintainerInfo.phone
                              .substring(4, 13)
                              .split('-')
                              .join('')}`}
                          >
                            {maintainerInfo.phone}
                          </a>
                        )
                      case '6':
                        return (
                          <a
                            href={`tel:+886-6-${maintainerInfo.phone
                              .substring(4, 13)
                              .split('-')
                              .join('')}`}
                          >
                            {maintainerInfo.phone}
                          </a>
                        )
                      default:
                        return ''
                    }
                  })()}
                  &nbsp;
                  {maintainerInfo.name}
                </p>
                <p>
                  【信箱】
                  <a href={`mailto:${maintainerInfo.email}`}>
                    {maintainerInfo.email}
                  </a>
                  &nbsp;{maintainerInfo.name}
                </p>
                <p>
                  【地址】
                  {maintainerInfo.address}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ContactStaff
