import { useState, useEffect } from 'react'
import { Base64 } from 'js-base64'
import Swal from 'sweetalert2'
import { Attender, Booker } from '../../services/Attender' // 處理報名或預約的api
import { GetInfoSourceOption } from '../../services/Briefing'

// 定義SweetAlert2的按鈕　↓↓↓
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    title: 'inquiry-title',
    denyButton: 'denyButton',
  },
  buttonsStyling: false,
})

// 報名說明會處理
const UseApplymeeting = () => {
  // 有說明會報名結果預設狀態 (用在轉址)
  const [submitApplyResult, setSubmitApplyResult] = useState(false)

  // 沒有說明會預約結果預設狀態 (用在轉址)
  const [submitReservationResult, setSubmitReservationResult] = useState(false)

  // 送出中
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)

  // 確認送出嗎視窗, 預設關閉 (報名)
  const [attenderCheck, setAttenderCheck] = useState(false)

  // 點"報名"的送出用的判斷
  const [sendAttenderSubmit, setSendAttenderSubmit] = useState(false)
  // console.log(sendAttenderSubmit)

  // 確認送出嗎視窗, 預設關閉 (預約)
  const [bookerCheck, setBookerCheck] = useState(false)

  // 點"預約"的送出用的判斷
  const [sendBookerSubmit, setSendBookerSubmit] = useState(false)

  // 裝取得消息來源的資料
  const [sourceOption, setSourceOption] = useState([])
  // console.log(sourceOption)

  // 裝要送出的json用的容器
  const [packageData, setPackageData] = useState({})
  // console.log('packageData', packageData)

  // 裝勾選序號成陣列
  // eslint-disable-next-line no-unused-vars
  const [infoSourceId, setInfoSourceId] = useState([])

  // checkbox篩選
  const [source, setSource] = useState({
    1: false, //Facebook
    2: false, //Google
    3: false, //iii
    4: false, //news
    5: false, //blog
    6: false, //employment
  })

  const [error, setError] = useState('請至少勾選一個選項')

  // 處理拿資料
  const handleGetInfoSourceOption = async () => {
    // 取的課程開課資訊
    const datas = await GetInfoSourceOption()
    setSourceOption(datas)
  }

  // 處理勾選消息來源
  const handleSetInfoSourceId = () => {
    const checked = Object.entries(source).filter((v) => {
      return v[1] === true
    })

    // setInfoSourceId(e.target.value)

    const checkedId = checked.map((v) => {
      return v[0]
    })

    localStorage.setItem('checkedId', JSON.stringify({ InfoSource: checkedId }))
  }

  // 點擊報名送出
  const handleSubmitApply = () => {
    const checkBoxId = JSON.parse(localStorage.getItem('checkedId'))
    let jsonData = {
      ...packageData,
      InfoSource: checkBoxId.InfoSource,
    }

    // 點擊送出就關閉視窗
    setAttenderCheck(false)

    // 狀態更新(true:傳送中, false:傳送完成)
    setIsLoading(true)

    // 送後端
    const datas = Attender(JSON.stringify(jsonData))
    datas.then((response) => {
      setIsLoading(false)

      localStorage.removeItem('checkedId')

      if (response.flag === 0) {
        // console.log('報名成功')
        setSubmitApplyResult(true)
      } else if (response.message === '已報名') {
        setSendAttenderSubmit(false)
        // console.log('你已經報名過了')
        // setIsLoading(true) 測試樣式使用的
        swalWithBootstrapButtons
          .fire({
            title: '提示訊息',
            html: '<figure><img src="/img/noticeAlert.png" alt="" /></figure>您已經報名過此說明會 !',
            padding: '60px',
            showConfirmButton: true,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            denyButtonText: '',
            backdrop: `rgba(0,0,0,.6)`,
          })
          .then((result) => {
            if (result.isConfirmed) {
              // 已報名過此課程
              // history.push('/longterm')
              return
            }
          })
      }
    })
  }

  // 點擊預約送出
  const handleSubmitBooker = () => {
    // 點擊送出就關閉視窗
    setBookerCheck(false)

    const checkBoxId = JSON.parse(localStorage.getItem('checkedId'))
    let jsonData = {
      ...packageData,
      InfoSource: checkBoxId.InfoSource,
    }
    // console.log('要送出的內容', jsonData)

    // 狀態更新(true:傳送中, false:傳送完成)
    setIsLoading(true)

    // 送後端
    const datas = Booker(JSON.stringify(jsonData))
    datas.then((response) => {
      setIsLoading(false)

      localStorage.removeItem('checkedId')

      if (response.message === undefined) {
        // console.log('報名成功')
        setSubmitReservationResult(true)
      } else if (response.message === '已報名') {
        setSendBookerSubmit(false)
        // console.log('你已經報名過了')
        // setIsLoading(true) 測試樣式使用的
        swalWithBootstrapButtons
          .fire({
            title: '提示訊息',
            html: '<figure><img src="/img/noticeAlert.png" alt="" /></figure>您已經預約過此說明會 !',
            padding: '60px',
            showConfirmButton: true,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            denyButtonText: '',
            backdrop: `rgba(0,0,0,.6)`,
          })
          .then((result) => {
            if (result.isConfirmed) {
              return
            }
          })
      }
    })
  }

  // 取得會員資料
  const handGetInfoData = () => {
    // 取得localStorage的結果
    const data = JSON.parse(localStorage.getItem('SigninMessage'))

    // 如果有會員資料data
    if (data && data.code === 200) {
      // console.log('是登入的狀態')
      // console.log(data)
      const account = Object.values(data)[2]
      const payload = account.split('.')[1]
      const jsonPayload = JSON.parse(Base64.decode(payload))
      // console.log(jsonPayload)

      // 將data資料設定成新的localStorage => Account ({username: jsonPayload.username / email: jsonPayload.email})
      const setUser = (data) =>
        window.localStorage.setItem('applyMessage', JSON.stringify(data))
      return setUser({
        customerId: jsonPayload.id,
      })
    }
  }

  // 從 localStorage 中取得使用者資訊，有的話就 Parse 這筆資訊
  const getUser = () =>
    window.localStorage.getItem('applyMessage')
      ? JSON.parse(window.localStorage.getItem('applyMessage'))
      : {}

  // console.log(getUser().customerId)

  // 送出報名, 會將登入會員資料放入, 一進到養成班課程詳細資訊頁面或地區專頁就會存入localStorage
  useEffect(() => {
    handGetInfoData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 觸發視窗開啟的時候拿資料
  useEffect(() => {
    handleGetInfoSourceOption()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attenderCheck, bookerCheck])

  // checkbox有變化才要處理
  useEffect(() => {
    handleSetInfoSourceId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source])

  return {
    swalWithBootstrapButtons,
    submitApplyResult,
    setSubmitApplyResult,
    submitReservationResult,
    setSubmitReservationResult,
    getUser,
    attenderCheck,
    setAttenderCheck,
    bookerCheck,
    setBookerCheck,
    setInfoSourceId,
    handleSetInfoSourceId,
    source,
    setSource,
    handleSubmitApply,
    handleSubmitBooker,
    setPackageData,
    setIsLoading,
    isLoading,
    sourceOption,
    sendAttenderSubmit,
    setSendAttenderSubmit,
    sendBookerSubmit,
    setSendBookerSubmit,
    error,
    setError,
  }
}

export default UseApplymeeting
