// import React, { useState, useEffect } from 'react'
import React, { useState, useEffect } from 'react'
// 高階元件樣式(HOC)，增強元件用的
// import { withRouter, Link, useLocation } from 'react-router-dom'
import { withRouter, Link, useLocation } from 'react-router-dom'
// 中文路徑對照陣列，移出到config/index.js中設定
import { pathnameList, pathnameTextList } from '../config/path'

// import { OnJobSingleClassesInfo } from '../services/OnJob'

function MultiLevelBreadCrumb(props) {
  const { location, breadCrumbBool, dynamicBreadcrumbName } = props

  // 第一次載入時不會更新pathnameList, 因此寫了這段去取代第一次載入
  const locationNow = useLocation()
  const [currentUrl, setCurrentUrl] = useState('')
  useEffect(() => {
    // 當 location.pathname 變化時更新 currentUrl
    setCurrentUrl(locationNow.pathname)
  }, [locationNow.pathname])

  // 在這裡將 currentUrl 添加到 pathnameList
  useEffect(() => {
    if (currentUrl) {
      // 判断 currentUrl 是否已存在於 pathnameList 中
      if (!pathnameList.includes(currentUrl)) {
        // 如果不存在，將其添加到 pathnameList
        pathnameList.push(currentUrl)
        // console.log('pathnameList', pathnameList)
        // 更新 pathnameTextList
        pathnameTextList.push('/就業養成班/')
        // console.log('pathnameTextList', pathnameTextList)
      }
    }
  }, [currentUrl])

  // find index
  const findPathnameIndex = (pathname) =>
    pathnameList.findIndex((v, i) => v === pathname)

  // 有一個項目和二個項目的情況
  const formatText = (index, dynamicBreadcrumbName) => {
    if (index === -1) return ''

    // '/產品/嬰兒/初生兒' -> ['','產品','嬰兒', '初生兒']
    const textArray = pathnameTextList[index].split('/')
    // console.log(textArray)

    // '/product/baby/birth' -> ['','product','baby', 'birth']
    const pathArray = pathnameList[index].split('/')
    // console.log(pathArray)

    const listArray = textArray.map((v, i, array) => {
      if (i === 0) return ''

      if (i === array.length - 1) {
        // 在最後一个元素上添加連結
        return (
          <li key={i} className="breadcrumb-item active" aria-current="page">
            {i === array.length - 1 ? (
              <>
                {v}
                {dynamicBreadcrumbName}
              </>
            ) : (
              <Link to={pathArray.slice(0, i + 1).join('/')}>{v}</Link>
            )}
          </li>
        )
      }

      return (
        <li key={i} className="breadcrumb-item">
          <Link to={pathArray.slice(0, i + 1).join('/')}>{v}</Link>
        </li>
      )
    })

    return listArray
  }

  return (
    <>
      {/* 一般的麵包屑 */}
      {/* {breadCrumbBool && (
        <div className="container">
          <div className="row">
            <div className="col d-none d-md-block">
              <nav aria-label="breadcrumb">
                <ol
                  className="breadcrumb"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <li className="breadcrumb-item">
                    <Link to="/">首頁</Link>
                  </li>
                  {formatText(findPathnameIndex(location.pathname))}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      )} */}

      {!breadCrumbBool && (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb" style={{ backgroundColor: 'transparent' }}>
            <li className="breadcrumb-item">
              <Link to="/">首頁</Link>
            </li>
            {formatText(
              findPathnameIndex(location.pathname),
              dynamicBreadcrumbName
            )}
          </ol>
        </nav>
      )}
    </>
  )
}

export default withRouter(MultiLevelBreadCrumb)
