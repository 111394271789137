import React, { useEffect, useState } from 'react'
import MultiLevelBreadCrumb from '../../components/MultiLevelBreadCrumb'
import LinkScroll from '../../components/EmploymentClass/LinkScroll'
import CloudTechnologyClass from '../../components/EmploymentClassification/CloudTechnologyClass' // 雲端班詳細內容
import ClassInfoList from '../../components/EmploymentClass/ClassInfoList' // 課程資訊列表
// import CloudTechnologyType from '../RegionForLongTerm/CloudTechnologyType'
// import ClassmateNeedKnow from '../../components/EmploymentClass/ClassmateNeedKnow' // 學員須知
import MetaDecorator from '../../components/Util/MetaDecorator' // SEO meta

import { LongTermClassTypeInfo } from '../../services/LongTerm' // 處理接收報名課程資訊, API

// 就業養成班 > 雲端網路與資安工程師就業養成班內頁說明課程
function CloudTechnology(props) {
  // 麵包屑
  const { breadCrumbBool, setBreadCrumbBool, signinBool } = props

  // 雲端養成班meta
  const JNSESETMeta = require('../../data/longTerm/JNSESET.json')

  // 展開詳細內容狀態
  const [closed, setClosed] = useState(true)

  // 裝載報名課程資訊
  const [classInfo, setClassInfo] = useState([])

  //
  // eslint-disable-next-line no-unused-vars
  const [region, setRegion] = useState()

  // 取得報名課程資訊
  const handleGetClassInfo = async () => {
    const datas = await LongTermClassTypeInfo()
    // console.log(datas)
    setClassInfo(datas)
  }

  // 掛載時改動麵包屑位置
  useEffect(() => {
    setBreadCrumbBool(false)
  })

  // 卸載時還原麵包屑位置
  useEffect(() => {
    return () => {
      setBreadCrumbBool(true)
    }
  })

  // 取得資料
  useEffect(() => {
    handleGetClassInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 取得地區來源
  useEffect(() => {
    const judgmentArea = JSON.parse(
      window.localStorage.getItem('longTermFromRegion')
    )
    // console.log(judgmentArea)
    setRegion(judgmentArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <MetaDecorator
        title={JNSESETMeta.pageTitle}
        keywords={JNSESETMeta.pageKeywords}
        description={JNSESETMeta.pageDescription}
      />
      {/* {region ? (
        <>
          <CloudTechnologyType
            signinBool={signinBool}
            breadCrumbBool={breadCrumbBool}
            setBreadCrumbBool={setBreadCrumbBool}
            region={region}
            classInfo={classInfo}
          />
        </>
      ) : ( */}
      <>
        {/* 雲端網路就業養成班主圖 ↓↓↓　*/}
        <div className="container-fluid p-0">
          <div className="col p-0 position-relative">
            <div className="breadcrumbs-content_classification">
              {!breadCrumbBool && <MultiLevelBreadCrumb />}
            </div>
            <div className="banner__container">
              <div className="banner__titleC">
                <div className="bannerTitle__black">
                  <h1>雲端網路與資安工程師就業養成班</h1>
                  {/* <h6 className="main-picture_ch">{longTermClasses.length === 0 ? '' : longTermClasses[0].className}</h6> */}
                  {/* <div className="classification-picture_line"></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 雲端網路就業養成班主圖 ↑↑↑　*/}

        <div className="container-fluid p-0 ojt-page">
          <div id="ojt-page">
            <LinkScroll />
            {/* 報名課程資訊 ↓↓↓　*/}
            <div className="custom-container">
              <div className="content__div--mt">
                <div className="line__div--dark"></div>
                <div className="content__title">
                  <h3>報名課程資訊</h3>
                  <div className="line__div--light">
                    <div className="lease-content_line"></div>
                  </div>
                </div>

                {classInfo.map((v, i) => (
                  <div key={i}>
                    {v.classTypeID.substring(0, 7) === 'JNSESET' ? (
                      <div
                        className="json-content"
                        dangerouslySetInnerHTML={{ __html: v['applyInfo'] }}
                      ></div>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>
            </div>
            {/* 報名課程資訊 ↑↑↑　*/}

            {/* 詳細課程介紹 ↓↓↓　*/}
            <div className="content__div--mt" id="classIntroduce">
              <div className="bc__div--light">
                <div className="custom-container">
                  <div className="bcBlockInner__div">
                    <h3 className="titleC__h3">
                      <span className="black">課程介紹</span>
                      {/* <span className="lesson-content_ch">Lesson Instruction</span> */}
                    </h3>
                    <div className="lesson-ditailed_inner">
                      <div
                        className={
                          closed
                            ? 'lesson-ditailed_header is-opened'
                            : 'lesson-ditailed_header'
                        }
                      >
                        <div
                          className="lesson-header_content"
                          onClick={() => setClosed(!closed)}
                        >
                          <div className="lesson-header_a">
                            <div className="lesson-header_title">
                              雲端網路與資安工程師
                            </div>
                            <div className="expandIcon__div--circle">
                              <span className="expandIcon__span--allow"></span>
                              <span className="expandIcon__span--line"></span>
                              <span className="expandIcon__span--line"></span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            closed
                              ? 'classContent__container'
                              : 'classContent__container__off'
                          }
                        >
                          <div className="classContent__padding">
                            <CloudTechnologyClass />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 詳細課程介紹 ↑↑↑　*/}

            {/* 開課資訊 ↓↓↓　*/}
            <ClassInfoList signinBool={signinBool} />
            {/* 開課資訊 ↑↑↑　*/}

            {/* <ClassmateNeedKnow /> */}
          </div>
        </div>
      </>
      {/* )} */}
    </>
  )
}

export default CloudTechnology
