import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Mentor } from '../../services/Employee' // 傳入課程經理名稱的api

// 在職班報名課程資訊
function TrainingContactInfo(props) {
  const { classesMentor, classMaintainers, mentor, maintainers } = props
  // console.log(classesMentor)
  // 裝有開班的聯絡人
  const [mentorArray, setMentorArray] = useState([])
  console.log(mentorArray)
  // 處理用課程經理名字帶出課程經理聯繫資訊, 且將其裝成陣列
  const handleMentors = () => {
    // 課程經理 + 教務承辦資展國際股份有限公司附設台北市私立資展國際電腦技藝短期補習班
    const margeClassMentor = classesMentor.concat(classMaintainers)
    // console.log('合併開課陣列的經理和教務', margeClassMentor)

    // 篩選掉名字相同的
    const filterName = margeClassMentor.filter((v, i, a) => {
      return a.indexOf(v) === i
    })
    // console.log('filterName', filterName)
    const classesMentorArray = []
    // eslint-disable-next-line no-unused-vars
    const mentorInfo = filterName.map(async (v) => {
      const item = await Mentor(v)

      // console.log('不重複的聯絡人資料', item)
      // 開課的課程經理和教務資料裝成陣列
      classesMentorArray.push(item)
      // 台中不要出現王先生的聯絡方式, 因為Toms還是掛王先生是課程經理
      const filterTaichungContact = classesMentorArray.filter((v) => {
        return v.name !== '王先生'
      })
      // console.log('排除王先生', filterTaichungContact)
      // console.log('聯絡人資料', classesMentorArray)
      // 不要把不該秀的資訊顯示出來, 所以先做篩選
      const listItems = filterTaichungContact.map((elem) => ({
        address: elem.address,
        name: elem.name,
        phone: elem.phone,
        email: elem.email,
        department: elem.department,
        cardTitle: elem.cardTitle,
      }))
      // console.log('listItems', listItems)
      // 還沒有帶完資料就顯示畫面, 因此先將其存在sessionStorage

      sessionStorage.setItem('mentorArray', JSON.stringify(listItems))

      const mentorArraySet = JSON.parse(sessionStorage.getItem('mentorArray'))
      if (mentorArraySet) {
        setMentorArray(mentorArraySet)
      }
    })
  }

  let { id } = useParams()

  // 拿到課程聯絡人資訊後觸發處理
  useEffect(() => {
    // 如果沒有開課的聯絡人陣列
    if (classesMentor.length !== 0) {
      handleMentors()
    } else {
      const contactArray = []
      // 課程經理 + 教務承辦
      contactArray.push(mentor, maintainers)
      // console.log('沒開課的聯絡人資訊', contactArray)
      // 篩選掉名字相同的
      const filterName = contactArray.filter((v, i, a) => {
        return a.indexOf(v) === i
      })

      const setMentorArrays = []
      // 處理沒開班的課程, 抓課程經理資訊
      // eslint-disable-next-line no-unused-vars
      const handleMentor = filterName.map(async (v) => {
        const data = await Mentor(v)

        setMentorArrays.push(data)
        // 台中不要出現王先生的聯絡方式, 因為Toms還是掛王先生是課程經理
        const filterTaichungContact = setMentorArrays.filter((v) => {
          return v.name !== '王先生'
        })
        // console.log('排除王先生', filterTaichungContact)
        // console.log('聯絡人資料', setMentorArrays)
        // 不要把不該秀的資訊顯示出來, 所以先做篩選
        const listItems = filterTaichungContact.map((elem) => ({
          address: elem.address,
          name: elem.name,
          phone: elem.phone,
          email: elem.email,
          department: elem.department,
          cardTitle: elem.cardTitle,
        }))
        // console.log('listItems', listItems)

        // 還沒有帶完資料就顯示畫面, 因此先將其存在localStorage
        sessionStorage.setItem('mentorArray', JSON.stringify(listItems))

        const mentorArraySet = JSON.parse(sessionStorage.getItem('mentorArray'))
        if (mentorArraySet) {
          setMentorArray(mentorArraySet)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classesMentor, mentor])

  return (
    <>
      <div className="custom-container">
        <div className="content__div--mt">
          <div className="line__div--dark"></div>
          <div className="content__title">
            <h3>報名資訊</h3>
            <div className="line__div--light">
              <div className="lease-content_line"></div>
            </div>
          </div>
          <ul className="listStyle__ul">
            {/* <li>
              <span> {item.advanceDate} </span>提前報名即享早鳥優惠,
              歡迎來電詢問 !!
            </li>*/}
            {(() => {
              switch (id) {
                case 'MDMS':
                  return (
                    <li className="onJob-info_exTex">
                      本課程團報優惠：三人團報可打95折、五人團報可打9折優惠
                    </li>
                  )
                case 'SV':
                  return (
                    <li className="onJob-info_exTex">
                      本課程團報優惠：三人團報可打95折、五人團報可打9折優惠
                    </li>
                  )
                case 'iso14971':
                  return (
                    <li className="onJob-info_exTex">
                      本課程團報優惠：三人團報可打95折、五人團報可打9折優惠
                    </li>
                  )
                case 'MDR':
                  return (
                    <li className="onJob-info_exTex">
                      本課程團報優惠：三人團報可打95折、五人團報可打9折優惠
                    </li>
                  )
                case 'LEEDAP':
                  return (
                    <>
                      <li className="onJob-info_exTex">
                        至綠矩粉絲專頁按讚並分享課程貼文(需公開)，附上個人臉書連結和截圖，可立即享500元優惠
                        &nbsp;
                        <span className="onJob-info_mark">
                          ※每人限用一次，不得重複使用於其他課程
                        </span>
                        <span>
                          綠矩整合粉絲團：
                          <a href="https://www.facebook.com/greenmatrixes">
                            https://www.facebook.com/greenmatrixes
                          </a>
                        </span>
                        <span>
                          課程貼文：
                          <a href="https://reurl.cc/kambdd">
                            https://reurl.cc/kambdd
                          </a>
                        </span>
                      </li>
                      <li className="onJob-info_exTex">
                        報名本課程並完成繳費，可享同系列課程：LEED
                        GA＋95折優惠&nbsp;
                        <span className="onJob-info_mark">
                          ※此折扣與團報優惠擇一使用
                        </span>
                      </li>
                    </>
                  )
                case 'LEEDGA':
                  return (
                    <>
                      <li className="onJob-info_exTex">
                        至綠矩粉絲專頁按讚並分享課程貼文(需公開)，附上個人臉書連結和截圖，可立即享500元優惠
                        &nbsp;
                        <span className="onJob-info_mark">
                          ※每人限用一次，不得重複使用於其他課程
                        </span>
                        <span>
                          綠矩整合粉絲團：
                          <a href="https://www.facebook.com/greenmatrixes">
                            https://www.facebook.com/greenmatrixes
                          </a>
                        </span>
                        <span>
                          課程貼文：
                          <a href="https://reurl.cc/kambdd">
                            https://reurl.cc/kambdd
                          </a>
                        </span>
                      </li>
                      <li className="onJob-info_exTex">
                        報名本課程並完成繳費，可享同系列課程：LEED GA / LEED
                        AP早鳥＋95折優惠&nbsp;
                        <span className="onJob-info_mark">
                          ※此折扣與團報優惠擇一使用
                        </span>
                      </li>
                    </>
                  )
                case 'WELLAP':
                  return (
                    <>
                      <li className="onJob-info_exTex">
                        至綠矩粉絲專頁按讚並分享課程貼文(需公開)，附上個人臉書連結和截圖，可立即享500元優惠
                        &nbsp;
                        <span className="onJob-info_mark">
                          ※每人限用一次，不得重複使用於其他課程
                        </span>
                        <span>
                          綠矩整合粉絲團：
                          <a href="https://www.facebook.com/greenmatrixes">
                            https://www.facebook.com/greenmatrixes
                          </a>
                        </span>
                        <span>
                          課程貼文：
                          <a href="https://pse.is/4qqmd9">
                            https://pse.is/4qqmd9
                          </a>
                        </span>
                      </li>
                      <li className="onJob-info_exTex">
                        報名本課程並完成繳費，可享同系列課程：LEED GA / LEED
                        AP早鳥＋95折優惠&nbsp;
                        <span className="onJob-info_mark">
                          ※此折扣與團報優惠擇一使用
                        </span>
                        <span>
                          如當年度完成LEED / WELL兩堂課程，加贈WELL AP
                          模擬試題乙份(250題)
                        </span>
                      </li>
                    </>
                  )
                case 'ESGH':
                  return (
                    <div>
                      <li className="onJob-info_exTex">
                        至綠矩粉絲專頁按讚並分享課程貼文(需公開)，附上個人臉書連結和截圖，可立即享500元優惠
                        &nbsp;
                        <span className="onJob-info_mark">
                          ※每人限用一次，不得重複使用於其他課程
                        </span>
                        <br />
                        <span>
                          綠矩整合粉絲團：
                          <a href="https://www.facebook.com/greenmatrixes">
                            https://www.facebook.com/greenmatrixes
                          </a>
                        </span>
                        <br />
                        <span>
                          課程貼文：
                          <a href="https://reurl.cc/qO1p0g">
                            https://reurl.cc/qO1p0g
                          </a>
                        </span>
                      </li>
                      <li className="onJob-info_exTex">
                        本課程團報優惠：三人團報可打95折、五人團報可打9折優惠
                      </li>
                    </div>
                  )
                case 'ESGZ':
                  return (
                    <div>
                      <li className="onJob-info_exTex">
                        至綠矩粉絲專頁按讚並分享課程貼文(需公開)，附上個人臉書連結和截圖，可立即享500元優惠
                        &nbsp;
                        <span className="onJob-info_mark">
                          ※每人限用一次，不得重複使用於其他課程
                        </span>
                        <br />
                        <span>
                          綠矩整合粉絲團：
                          <a href="https://www.facebook.com/greenmatrixes">
                            https://www.facebook.com/greenmatrixes
                          </a>
                        </span>
                        <br />
                        <span>
                          課程貼文：
                          <a href="https://reurl.cc/qO1p0g">
                            https://reurl.cc/qO1p0g
                          </a>
                        </span>
                      </li>
                      <li className="onJob-info_exTex">
                        本課程團報優惠：三人團報可打95折、五人團報可打9折優惠
                      </li>
                    </div>
                  )
                case 'ESGR':
                  return (
                    <div>
                      <li className="onJob-info_exTex">
                        至綠矩粉絲專頁按讚並分享課程貼文(需公開)，附上個人臉書連結和截圖，可立即享500元優惠
                        &nbsp;
                        <span className="onJob-info_mark">
                          ※每人限用一次，不得重複使用於其他課程
                        </span>
                        <br />
                        <span>
                          綠矩整合粉絲團：
                          <a href="https://www.facebook.com/greenmatrixes">
                            https://www.facebook.com/greenmatrixes
                          </a>
                        </span>
                        <br />
                        <span>
                          課程貼文：
                          <a href="https://reurl.cc/qO1p0g">
                            https://reurl.cc/qO1p0g
                          </a>
                        </span>
                      </li>
                      <li className="onJob-info_exTex">
                        本課程團報優惠：三人團報可打95折、五人團報可打9折優惠
                      </li>
                    </div>
                  )
                case 'ESGW':
                  return (
                    <div>
                      <li className="onJob-info_exTex">
                        至綠矩粉絲專頁按讚並分享課程貼文(需公開)，附上個人臉書連結和截圖，可立即享500元優惠
                        &nbsp;
                        <span className="onJob-info_mark">
                          ※每人限用一次，不得重複使用於其他課程
                        </span>
                        <br />
                        <span>
                          綠矩整合粉絲團：
                          <a href="https://www.facebook.com/greenmatrixes">
                            https://www.facebook.com/greenmatrixes
                          </a>
                        </span>
                        <br />
                        <span>
                          課程貼文：
                          <a href="https://reurl.cc/qO1p0g">
                            https://reurl.cc/qO1p0g
                          </a>
                        </span>
                      </li>
                      <li className="onJob-info_exTex">
                        本課程團報優惠：三人團報可打95折、五人團報可打9折優惠
                      </li>
                    </div>
                  )
                case 'ESGD':
                  return (
                    <div>
                      <li className="onJob-info_exTex">
                        至綠矩粉絲專頁按讚並分享課程貼文(需公開)，附上個人臉書連結和截圖，可立即享500元優惠
                        &nbsp;
                        <span className="onJob-info_mark">
                          ※每人限用一次，不得重複使用於其他課程
                        </span>
                        <br />
                        <span>
                          綠矩整合粉絲團：
                          <a href="https://www.facebook.com/greenmatrixes">
                            https://www.facebook.com/greenmatrixes
                          </a>
                        </span>
                        <br />
                        <span>
                          課程貼文：
                          <a href="https://reurl.cc/qO1p0g">
                            https://reurl.cc/qO1p0g
                          </a>
                        </span>
                      </li>
                      <li className="onJob-info_exTex">
                        本課程團報優惠：三人團報可打95折、五人團報可打9折優惠
                      </li>
                    </div>
                  )

                default:
                  return null
              }
            })()}
            <li>
              本課程採線上報名，報名課程之學員將於開課前7~10天內收到e-mail通知，確認課程是否開課，若確認開課才需繳費
            </li>
            <li>服務時間：上班日09:00 至 17:30 </li>
            {(() => {
              switch (id) {
                case 'PMP':
                  return (
                    <>
                      <li>
                        課程諮詢：
                        <a href="tel:+886-2-66316571">(02) 6631-6571 </a>
                        張先生、E-mail：
                        <a
                          href="mailto:alanchang@ispan.com.tw"
                          className="mail-link_a"
                        >
                          alanchang@ispan.com.tw
                        </a>
                      </li>
                      {/* <li>
                        台北班報名確認：
                        <a href="tel:+886-2-66316587">(02) 6631-6587 </a>
                        劉小姐、E-mail：
                        <a
                          href="mailto:hoan@ispan.com.tw"
                          className="mail-link_a"
                        >
                          hoan@ispan.com.tw
                        </a>
                      </li> */}
                      <li>
                        台中班課程諮詢：
                        <a href="tel:+886-4-23265860">(04) 2326-5860 #6541 </a>
                        林小姐、E-mail：
                        <a
                          href="mailto:maylin@ispan.com.tw"
                          className="mail-link_a"
                        >
                          maylin@ispan.com.tw
                        </a>
                      </li>
                      <li>
                        高雄班課程諮詢：
                        <a href="tel:+886-7-9699885">(07) 9699-885 #6511 </a>
                        陳小姐、E-mail：
                        <a
                          href="mailto:irene@ispan.com.tw"
                          className="mail-link_a"
                        >
                          irene@ispan.com.tw
                        </a>
                      </li>
                    </>
                  )
                case 'CCNA':
                  return (
                    <>
                      <li>
                        課程諮詢：
                        <a href="tel:+886-2-66316568">(02) 6631-6568 </a>
                        陳先生、E-mail：
                        <a
                          href="mailto:kc@ispan.com.tw"
                          className="mail-link_a"
                        >
                          kc@ispan.com.tw
                        </a>
                      </li>
                      {/* <li>
                        台北班報名確認：
                        <a href="tel:+886-2-66316587">(02) 6631-6587 </a>
                        劉小姐、E-mail：
                        <a
                          href="mailto:hoan@ispan.com.tw"
                          className="mail-link_a"
                        >
                          hoan@ispan.com.tw
                        </a>
                      </li> */}
                      <li>
                        台中班課程諮詢：
                        <a href="tel:+886-4-23265860">(04) 2326-5860 #6541 </a>
                        林小姐、E-mail：
                        <a
                          href="mailto:maylin@ispan.com.tw"
                          className="mail-link_a"
                        >
                          maylin@ispan.com.tw
                        </a>
                      </li>
                      <li>
                        高雄班課程諮詢：
                        <a href="tel:+886-7-9699885">(07) 9699-885 #6511 </a>
                        陳小姐、E-mail：
                        <a
                          href="mailto:irene@ispan.com.tw"
                          className="mail-link_a"
                        >
                          irene@ispan.com.tw
                        </a>
                      </li>
                    </>
                  )
                case 'GCP':
                  return (
                    <>
                      <li>
                        台北班課程諮詢：
                        <a href="tel:+886-2-66316568">(02) 6631-6568 </a>
                        陳先生、E-mail：
                        <a
                          href="mailto:kc@ispan.com.tw"
                          className="mail-link_a"
                        >
                          kc@ispan.com.tw
                        </a>
                      </li>
                      {/* <li>
                        台北班報名確認：
                        <a href="tel:+886-2-66316587">(02) 6631-6587 </a>
                        劉小姐、E-mail：
                        <a
                          href="mailto:hoan@ispan.com.tw"
                          className="mail-link_a"
                        >
                          hoan@ispan.com.tw
                        </a>
                      </li> */}
                    </>
                  )
                case 'OGSM':
                  return (
                    <>
                      <li>
                        課程諮詢：
                        <a href="tel:+886-2-66316568">(02) 6631-6568 </a>
                        林小姐、E-mail：
                        <a
                          href="mailto:kiki@ispan.com.tw"
                          className="mail-link_a"
                        >
                          kiki@ispan.com.tw
                        </a>
                      </li>
                      {/* <li>
                        報名確認：
                        <a href="tel:+886-2-66316587">(02) 6631-6587 </a>
                        劉小姐、E-mail：
                        <a
                          href="mailto:hoan@ispan.com.tw"
                          className="mail-link_a"
                        >
                          hoan@ispan.com.tw
                        </a>
                      </li> */}
                    </>
                  )

                default:
                  return (
                    <>
                      {mentorArray.map((v, i) => {
                        return (
                          <>
                            <li
                              key={i}
                              className={
                                v.address.substring(0, 2) === '台北' &&
                                v.department === '培訓服務部'
                                  ? 'd-none'
                                  : ''
                              }
                            >
                              {v.address.substring(0, 2)}班
                              {v.department === '桃園中心'
                                ? v.cardTitle === '教務經理'
                                  ? '報名諮詢'
                                  : '課程諮詢'
                                : '課程諮詢'}
                              ：
                              {(() => {
                                switch (v.phone.substring(2, 3)) {
                                  case '4':
                                    return (
                                      <a
                                        href={`tel:+886-4-${v.phone
                                          .substring(4, 13)
                                          .split('-')
                                          .join('')}`}
                                      >
                                        {v.phone}
                                      </a>
                                    )
                                  case '2':
                                    return (
                                      <a
                                        href={`tel:+886-2-${v.phone
                                          .substring(4, 13)
                                          .split('-')
                                          .join('')}`}
                                      >
                                        {v.phone}
                                      </a>
                                    )
                                  case '7':
                                    return (
                                      <a
                                        href={`tel:+886-7-${v.phone
                                          .substring(4, 13)
                                          .split('-')
                                          .join('')}`}
                                      >
                                        {v.phone}
                                      </a>
                                    )
                                  case '3':
                                    return (
                                      <a
                                        href={`tel:+886-3-${v.phone
                                          .substring(4, 13)
                                          .split('-')
                                          .join('')}`}
                                      >
                                        {v.phone}
                                      </a>
                                    )
                                  case '6':
                                    return (
                                      <a
                                        href={`tel:+886-6-${v.phone
                                          .substring(4, 13)
                                          .split('-')
                                          .join('')}`}
                                      >
                                        {v.phone}
                                      </a>
                                    )
                                  default:
                                    return `${v.phone}`
                                }
                              })()}
                              &nbsp;
                              {v.name}
                              、E-mail：
                              <a
                                href={`mailto:${v.email}`}
                                className="mail-link_a"
                              >
                                {v.email}
                              </a>
                            </li>
                          </>
                        )
                      })}
                    </>
                  )
              }
            })()}

            {/* 某些在職班獨有需要的文字說明 */}
            {(() => {
              switch (id) {
                case 'PMP':
                  return (
                    <>
                      <li>
                        PMP考試相關資訊
                        <a
                          href=" https://www.ispan.com.tw/PMP/pmpexam"
                          className="mail-link_a"
                        >
                          https://www.ispan.com.tw/PMP/pmpexam
                        </a>
                      </li>
                      <li>
                        PMP線上教材相關資訊
                        <a
                          href="https://www.ispan.com.tw/PMP/PMPipass"
                          className="mail-link_a"
                        >
                          https://www.ispan.com.tw/PMP/PMPipass
                        </a>
                      </li>
                    </>
                  )
                case 'CCNA':
                  return ''

                default:
                  return ''
              }
            })()}
          </ul>
        </div>
      </div>
    </>
  )
}

export default TrainingContactInfo
