import React from 'react'
import { Link } from 'react-router-dom'

// 免費活動
function EventItems(props) {
  const { handleClick } = props

  return (
    <>
      <div className="dropdown_subClassification_div">
        <div className="dropdown_subClassificationContent_div">
          <div className="classificationTheme h0">
            <div className="dropdown_subClassificationTitle_div">
              <h3>免費活動</h3>
            </div>
          </div>
          <div className="classificationTheme classificationTheme_subContent">
            <ul>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/GoogleCloud"
                  onClick={() => {
                    handleClick()
                  }}
                >
                  Google 雲端 Google Kubernetes Engine 操作
                </Link>
              </li>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/GoogleCloud"
                  onClick={() => {
                    handleClick()
                  }}
                >
                  Google 雲端基礎: Big Data & Machine Learning
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default EventItems
