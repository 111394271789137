import React from 'react'
import { Link } from 'react-router-dom'

// 補助方案資訊 - 資訊
function SubsidyInformationItems(props) {
  const { handleClick } = props

  return (
    <>
      <div className="dropdown_subClassification_div">
        <div className="dropdown_subClassificationContent_div">
          <div className="classificationTheme h0">
            <div className="dropdown_subClassificationTitle_div">
              <h3>政府就業協助方案資訊</h3>
            </div>
          </div>
          <div className="classificationTheme classificationTheme_subContent">
            <ul>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/eventpage/job_allowance"
                  onClick={() => {
                    handleClick()
                  }}
                >
                  初次尋職青年穩定就業計畫
                </Link>
              </li>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/eventpage/employment_expansion"
                  onClick={() => {
                    handleClick()
                  }}
                >
                  勞動部疫後改善缺工擴大就業方案
                </Link>
              </li>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/eventpage/job_opportunity"
                  onClick={() => {
                    handleClick()
                  }}
                >
                  青年就業旗艦計畫
                </Link>
              </li>
              <li className="longTerm-content_li">
                <Link
                  className="longTerm-content_link"
                  to="/eventpage/job_investment"
                  onClick={() => {
                    handleClick()
                  }}
                >
                  投資青年就業方案 第2期
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubsidyInformationItems
