// 報名說明會表單處理報名POST
import { apiUrl } from '../config/path'

// const url = apiUrl + '/api/Attender' // 有說明會
const url2 = apiUrl + '/api/Attender/AddOrUpdateAttenders/v2' // 230202版
// const urlBook = apiUrl + '/api/Booker' // 沒有說明會(預約說明會)
const urlBook = apiUrl + '/api/Booker/v2' // 230214版
const urlCancelReserve = apiUrl + '/api/Booker/Cancel' // 取消預約的說明會

// 有說明會, 報名
export const Attender = async (data) => {
  // fetch(url, {
  //   body: JSON.stringify(data),
  //   headers: {
  //     'content-type': 'application/json',
  //   },
  //   method: 'POST',
  //   mode: 'cors', // no-cors, cors, *same-origin
  // }).then((response) => response.json()) // 輸出成 json

  return await fetch(url2, {
    body: data,
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  }).then((data) => data.json())
}

// 沒有說明會, 預約
export const Booker = async (data) => {
  return await fetch(urlBook, {
    body: data,
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  }).then((data) => data.json())
}

// 取消預約的說明會
export const cancelReserve = async (data) => {
  return await fetch(urlCancelReserve, {
    body: data,
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  }).then((data) => data.json())
}
