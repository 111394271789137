import React, { useState } from 'react'
import { Link } from 'react-scroll'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

// 嵌入式智慧就業養成班詳細內容, 20230901修改
function EmbeddedSystemClass(props) {
  const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      <div className="txtC__div text-justify mt-0">
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          5G軟韌體整合硬體的時代來
        </h4>
        <div className="employmentClassification-instruction_detial">
          <p>
            運用嵌入式系統技術「開發」諸如車聯網、遠端醫療、監控系統、生活照顧、居家安全、交通管理、智慧建築、機器人、無人飛機...等等的應用產品，已是目前產業發展的大趨勢，而車商特斯拉成為電動車的領航者、工研院研發的MOCVD人工智慧故障預測診斷雛形系統、觸控筆有近三十個軟體搭配，就可銷出百萬支的商業模式...，都在驗證「軟韌體加值」的成功。
          </p>
          <br />
          <p>
            嵌入式系統的開發成為目前國家產業發展的主要政策之一，加上後PC時代種種需要複雜運算功能的數位應用系統，其核心肯定是低成本高效率的即時性嵌入式系統。而國內在未來幾年內這一方面的人才依然相當缺乏，一般應用產品設計公司依然會有其技術瓶頸，對於目前有志於從事資訊工業相關的人員或是學生來說，這是一項非常具有前景與挑戰的技術，為此，資展國際特別規劃了詳細的課程，帶您成為產業界要求而且無可取代的人才。
          </p>
        </div>
      </div>
      {/* 課程介紹 */}
      <div>
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              符合政府軟體核心關鍵技術人才培訓計劃之精神，培訓Linux系統程式設計技術，讓您成為真正的系統核心人才
            </li>
            <li>
              培養結訓學員從完全不具程式設計基礎，讓學員成為完整軟體、硬體、韌體兼具的程式設計基本功夫
            </li>
            <li>課程結訓後推薦就業機會</li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure">
          <img
            src={
              longTermClass && longTermClass.taxCode !== ''
                ? `/img/employment/syllabus/Eb_JEMEMET_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '台北':
                        return 'Taipei'
                      default:
                        return ''
                    }
                  })()}_C2E2302.png?231213`
                : '/img/employment/syllabus/Eb_JEMEMET_Taipei.png?231213'
            }
            alt=""
          />
        </div>
        <p className="red pt-3 mb-3">※課程內容以地區班級為準。</p>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">招生簡章</div>
          <ul className="classInfoList__styleNone">
            <li>
              <a
                href="/pdf/資展國際_嵌入式智慧系統創新應用就業養成班_招訓簡章.pdf"
                className="orange"
                target="_blank"
                download
              >
                簡章下載<i className="fas fa-download"></i>
              </a>
            </li>
          </ul>
        </div> */}
        <div className="pb-4">
          <div className="subT__div">招生對象</div>
          <ul className="classInfoList__styleNone">
            <li>對程式設計有興趣並具備基本(大專畢業程度)之英文閱讀能力</li>
            <li>願接受本班受訓期間之耐力與毅力考驗者</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>嵌入式設計師</li>
            <li>韌體工程師</li>
            <li>資訊家電設計師</li>
            <li>網路應用程式設計</li>
            <li>Linux平台驅動程式等</li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">Q&A</div>
          <ul className="classInfoList__styleNone">
            <li>1）嵌入式學習門檻高嗎？</li>
            <li>
              總聽說學習嵌入式系統(軟韌體)設計很難、學習門檻高，除了要懂軟、硬體外，還要弄懂那些相關的原理、協定和演算法等，但實際上業界目前普遍的狀況是，因為網路資源的普及，已足以讓現在有興趣的學習者，以更少的難度和門檻，學習到以前前輩們不斷踩坑和彎路才能獲得的東西，也就是說踩在巨人的肩膀上(各式網路開放原始碼)，學習這些過去被視為較為困難的知識和技術，不僅相對簡單而且實用。
              <br />
              本班級課程係以業界迫切需求(人力銀行相關職缺全年度任何時間皆超過3000筆以上)的嵌入式軟韌體解決方案為訓練主軸，以就業為導向，訓練學員具備紮實的程式開發能力，以學科、術科同步、強調由淺入深、大量上機實作及使用開發實驗板實習演練，期末以企業專題實作等學習方式，培養具動手實作，軟、硬兼具，且具積極主動、創新、問題解決能力與團隊合作能力的全方位人才。
              <br />
              報名本班級的學員並不需要會電子學電路學，也不需要電子，機械及相關理工科學背景，而且以我們多年來的培訓經驗，非理工科系學得不錯且職涯發展得很好的學員，也大有人在。我們的課程會教會學員看懂周邊設備電路圖，學會查晶片規格書，以及動手撰寫、修改程式(ODM修改原廠開放原始碼)，以軟體來控制硬體，因此本班上課不僅有提供實驗開發版，更多的是軟體控制硬體的實作，課程強調從基礎教起，有興趣的學員，歡迎報名這個班級。
            </li>
          </ul>
        </div>
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>嵌入式系統與韌體技術就業養成班適合對象</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對程式設計有興趣並具備基本(大專畢業程度)之英文閱讀能力
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        願接受本班受訓期間之耐力與毅力考驗者
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab> */}
            {/* <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        只要學員吸收良好，就能達到就業所需職能養成
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        能獨立完成設計個人網站以及與小組協同完成電商網站
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        能夠掌握網站從UX規劃到網站製作，以及前端與後端技術開發流程
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab> */}
            {/* <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">嵌入式設計師</li>
                  <li className="longTerm_tabContent_li">韌體工程師</li>
                  <li className="longTerm_tabContent_li">資訊家電設計師</li>
                  <li className="longTerm_tabContent_li">網路應用程式設計</li>
                  <li className="longTerm_tabContent_li">
                    Linux平台驅動程式等
                  </li>
                </ul>
              </div>
            </Tab> */}
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default EmbeddedSystemClass
