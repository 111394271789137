import React from 'react'

function SubsidyBlock(props) {
  const { longTermClassID } = props
  return (
    <>
      {longTermClassID === 'JJEEITT2411' ||
      longTermClassID === 'JPMFEET2406' ||
      // longTermClassID === 'JEMEMET2401' ||
      // longTermClassID === 'JEAIMLT2401' ||
      // longTermClassID === 'JNSESET2401' ||
      // longTermClassID === 'JMMSITT2304' ||
      // longTermClassID === 'JJEEITT2404' ||
      longTermClassID === 'JPMFEET2312' ||
      longTermClassID === 'JMFUENT2306' ? (
        <div className="custom-container">
          <div className="bc__position">
            <div className="bc__position01">
              <div className="spinner-box">
                <div className="configure-border-1">
                  <div className="configure-core"></div>
                </div>
                <div className="configure-border-2">
                  <div className="configure-core"></div>
                </div>
              </div>
            </div>
            <div className="bc__position02">
              <div className="spinner-box">
                <div className="configure-border-1">
                  <div className="configure-core"></div>
                </div>
                <div className="configure-border-2">
                  <div className="configure-core"></div>
                </div>
              </div>
            </div>
            <div className="bc__position03">
              <div className="spinner-box">
                <div className="configure-border-1">
                  <div className="configure-core"></div>
                </div>
                <div className="configure-border-2">
                  <div className="configure-core"></div>
                </div>
              </div>
            </div>
            <div className="titleInner__container">
              {/* <h2>本班級通過「產業新尖兵計畫」學費補助</h2> */}
              {longTermClassID === 'JPMFEET2312' ? (
                <>
                  <h2>本班級通過「產業新尖兵計畫」學費補助</h2>
                  <h6>
                    訓練單位：台中市電腦商業同業公會 /
                    上課地點：南臺科技大學資展訓練中心
                  </h6>
                </>
              ) : (
                ''
              )}
              {longTermClassID === 'JNSESET2401' ||
              longTermClassID === 'JEAIMLT2401' ? (
                <>
                  <h2>『台灣區電機電子工業同業公會』計畫班</h2>
                  <h6>
                    訓練單位：台灣區電機電子工業同業公會 /
                    上課地點：台北市大安區復興南路一段390號2樓
                  </h6>
                </>
              ) : (
                ''
              )}
              {longTermClassID === 'JEMEMET2401' ? (
                <>
                  <h2>『台灣區電機電子工業同業公會』計畫班</h2>
                  <h6>
                    訓練單位：台灣區電機電子工業同業公會 /
                    上課地點：台北市大安區復興南路一段390號3樓
                  </h6>
                </>
              ) : (
                ''
              )}
              {longTermClassID === 'JMMSITT2304' ? (
                <>
                  <h2>『中華民國全國工業總會』計畫班</h2>
                  <h6>
                    訓練單位：中華民國全國工業總會 /
                    上課地點：台北市大安區復興南路一段390號2樓
                  </h6>
                </>
              ) : (
                ''
              )}
              {longTermClassID === 'JJEEITT2404' ? (
                <>
                  <h2>『中華民國全國工業總會』計畫班</h2>
                  <h6>
                    訓練單位：中華民國全國工業總會 /
                    上課地點：台北市大安區信義路三段153號3樓
                  </h6>
                </>
              ) : (
                ''
              )}
              {longTermClassID === 'JMFUENT2306' ? (
                <>
                  <h2>本班級通過「產業新尖兵計畫」學費補助</h2>
                  <h6>
                    訓練單位：台中市電腦商業同業公會 /
                    上課地點：資展國際高雄中心
                  </h6>
                </>
              ) : (
                ''
              )}
              {longTermClassID === 'JJEEITT2411' ||
              longTermClassID === 'JPMFEET2406' ? (
                <>
                  <h2>本班級通過「產業新尖兵計畫」學費補助</h2>
                  <h6>
                    訓練單位：台中市電腦商業同業公會 /
                    上課地點：資展國際桃園中心 (桃園市中壢區新生路二段421號)
                  </h6>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default SubsidyBlock
