import React, { useState, useEffect } from 'react'
import { Redirect, useParams, useLocation } from 'react-router-dom'

import moment from 'moment' // 格式化日期
import currency from 'currency.js' // 貨幣轉換

import { OnJobSingleClassesInfo } from '../../services/OnJob'
import UseTrainingSignup from '../TrainingSignup/UseTrainingSignup'

// 在職班開班課程List
function TrainingClassInfoList() {
  const { swalWithBootstrapButtons, getUser } = UseTrainingSignup()

  // 沒登入轉址
  // eslint-disable-next-line no-unused-vars
  const [redirectTo, setRedirectTo] = useState('')

  // 裝得到的開課班級資料
  const [classes, setClasses] = useState([])

  // 貨幣轉換
  const NTD = (value) => currency(value, { precision: 0, symbol: 'NT$' })

  let { id } = useParams() // destructure id  PMP

  // 當前路由
  const ifo = useLocation()

  // 點擊立即報名
  const handleSignupClass = (classID, startDate, endDate, tuitionDiscount) => {
    // e.preventDefault()
    const data = JSON.parse(localStorage.getItem('SigninMessage'))

    // 有登入就進入報名流程
    if (data && data.code === 200) {
      setRedirectTo('trainingClassSignup')
      localStorage.setItem(
        'TrainingSignup',
        JSON.stringify({
          classID: classID,
          customerID: getUser().customerId,
          startDate: startDate,
          endDate: endDate,
          tuitionDiscount: tuitionDiscount,
          fromUrl: ifo.pathname,
        })
      )
      // 從官網在職班, 點擊立即報名, 若有 iii, 就移除 iii (TrainingSignup 優先)
      if (localStorage.getItem('iii')) {
        localStorage.removeItem('iii')
      }
      return
    } else {
      swalWithBootstrapButtons
        .fire({
          title: '尚未登入',
          html: '<figure><img src="/img/questionAlert.png" alt="" /></figure>請先登入會員，才能報名在職班課程。',
          padding: '60px',
          showConfirmButton: true,
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: '登入',
          cancelButtonText: '取消',
          denyButtonText: '',
          backdrop: `rgba(0,0,0,.6)`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            setRedirectTo('signin')
          }
          // 暫存課程代號
          localStorage.setItem(
            'TrainingTemplate',
            JSON.stringify({
              class: id,
            })
          )
        })
    }
  }

  // 拿開課資料
  useEffect(() => {
    const data = OnJobSingleClassesInfo(id)
    data.then((classes) => {
      setClasses(classes)
    })
  }, [id])

  // 卸載時還原載入狀態
  useEffect(() => {
    return () => {
      setClasses()
    }
  }, [setClasses])

  return (
    <>
      {/* 不同的情況下改變轉址 */}
      {redirectTo === 'signin' && <Redirect to="/signin" />}
      {redirectTo === 'trainingClassSignup' && (
        <Redirect to="/trainingClassSignup" />
      )}

      <div id="classList">
        <div className="bc__div--light">
          <div className="custom-container">
            <div className="bcBlockInner__div">
              <h3 className="black">開課資訊</h3>
              <div>
                {classes.length === 0 ? (
                  <div className="classListC__div text-center blue">
                    尚無課程
                  </div>
                ) : (
                  <div>
                    {classes.map((v, i) => {
                      return (
                        <div key={i} className="classListC__div">
                          <div
                            className={
                              v.mark === '' ? '' : 'classListInner__div--left'
                            }
                          >
                            <div className="onJob-list_txt">
                              <div className="tagC__div">
                                {(() => {
                                  switch (v.classID) {
                                    case 'NCN920T2403':
                                      return (
                                        <h6 className="tagInner__h6">
                                          <span>確定開班！！！</span>
                                        </h6>
                                      )
                                    default:
                                      return null
                                  }
                                })()}
                              </div>
                              {/* <div className="tagC__div">
                                {v.address.includes('線上') ? (
                                  <h6 className="tagInner__h6">
                                    <span>線上直播</span>
                                  </h6>
                                ) : (
                                  <h6 className="tagInner__h6 darkBcc darkBorder">
                                    <span>實體課程</span>
                                  </h6>
                                )}
                              </div> */}

                              <h6>
                                <span className="onJob-txt_add orange">
                                  {v.className}
                                </span>
                              </h6>

                              <h6>
                                <span>上課地點</span>
                                <span className="pl-3 onJob-txt_add">
                                  {v.address}
                                </span>
                                {v.address.includes('線上') ? (
                                  <span className="onJob-txt_hr">
                                    <span>線上直播</span>
                                  </span>
                                ) : (
                                  <span className="onJob-txt_hr">
                                    <span>實體課程</span>
                                  </span>
                                )}
                              </h6>

                              <h6 className="d-flex">
                                <div>上課期間</div>
                                <div className="pl-3">
                                  <span>
                                    {v.date} ({v.timesType})
                                  </span>
                                  <br />
                                  <span className="onJob-txt_date">
                                    {moment(v.startDate).format('YYYY/MM/DD') +
                                      '-' +
                                      moment(v.endDate).format('YYYY/MM/DD')}
                                  </span>
                                  {/* <span
                                    className={
                                      v.classID === 'MPM100T2406'
                                        ? 'lesson-info_mark'
                                        : 'd-none'
                                    }
                                  >
                                    {v.classID === 'MPM100T2406'
                                      ? '確定開班'
                                      : ''}
                                  </span> */}
                                  <span className="onJob-txt_hr">
                                    <span>總時數 {v.length} hr</span>
                                  </span>
                                  <br />
                                  <span
                                    className={
                                      v.classID === 'MPM100T2406'
                                        ? 'tagC__div white pt-1'
                                        : 'd-none'
                                    }
                                  >
                                    <span className="tagInner__h6 px-2">
                                      {v.classID === 'MPM100T2406'
                                        ? '本班級確定開班！！'
                                        : ''}
                                    </span>
                                  </span>
                                </div>
                              </h6>
                              {/* <h6
                                className={
                                  v.classID === 'MPM100T2204'
                                    ? 'onJob-txt_mark green'
                                    : 'd-none'
                                }
                              >
                                <span>
                                  備註
                                  <span className="pl-3">
                                    {v.classID === 'MPM100T2204'
                                      ? '本班級確定開班'
                                      : ''}
                                  </span>
                                </span>
                              </h6> */}
                            </div>
                          </div>

                          <div className="classListInner__div--right">
                            {(() => {
                              switch (v.classID) {
                                case 'NOS311T2301':
                                  return (
                                    <div>
                                      <h6 className="priceTxt--orange">
                                        專案價 {NTD(v.tuitionDiscount).format()}
                                      </h6>
                                    </div>
                                  )
                                default:
                                  return (
                                    <div>
                                      <h6 className="priceTxt--orange">
                                        優惠價 {NTD(v.tuitionDiscount).format()}
                                      </h6>
                                      <h6>原價 {NTD(v.tuition).format()}</h6>
                                    </div>
                                  )
                              }
                            })()}
                            <div className="pt-4">
                              {(() => {
                                switch (v.flag) {
                                  case '1':
                                    return (
                                      <button
                                        type="submit"
                                        className="onJob-list_button"
                                        onClick={() =>
                                          handleSignupClass(
                                            v.classID,
                                            v.startDate,
                                            v.endDate,
                                            v.tuitionDiscount
                                          )
                                        }
                                      >
                                        立即報名
                                      </button>
                                    )
                                  case '12':
                                    return (
                                      <button
                                        type="text"
                                        className="employmentClassification-content_button_disable"
                                      >
                                        報名額滿
                                      </button>
                                    )
                                  case '13':
                                    return (
                                      <button
                                        type="text"
                                        className="employmentClassification-content_button_candidate"
                                      >
                                        額滿電洽
                                      </button>
                                    )
                                  default:
                                    return null
                                }
                              })()}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TrainingClassInfoList
