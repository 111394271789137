import React from 'react'

// 內容包在裡面
function MainContent(props) {
  // 顯示最上方學生須知區塊
  const { display } = props

  return (
    <>
      <main
        role="main"
        className={
          display
            ? 'flex-shrink-0 normal-classmateNotice_margin'
            : 'flex-shrink-0 none-classmateNotice_margin'
        }
      >
        {props.children}
      </main>
    </>
  )
}

export default MainContent
