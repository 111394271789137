import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// 關於我們Dropdown
function DropdownAboutUs() {
  // 關於我們選項預設是消失狀態, 點擊每個分類之後到達分頁呈現消失
  const [click, setClick] = useState(false)

  // 切換狀態
  const handleClick = () => setClick(!click)

  return (
    <>
      <ul
        onClick={handleClick}
        className={
          click ? 'customDropdown-menu clicked' : 'customDropdown-menu'
        }
      >
        <li className="classificationTheme">
          <div className="dropdown-content_inner">
            <h2 className="titleC__h3">
              <div className="dropdown-title">
                <span className="section-content_en">關於我們</span>
                {/* <span className="section-content_en">On Job Training</span> 
                <span className="lesson-content_ch d-block">就業養成班</span>*/}
              </div>
              <span className="lesson-content_ch d-block mt-3">About Us</span>
              {/* 要不要做一個總覽課程頁面? */}
            </h2>
          </div>
        </li>
        <li className="classificationTheme">
          <div className="dropdown_primaryClassification_div">
            <ul>
              <li className="dropdown_primaryClassification_li">
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/aboutus"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  品牌背景
                </Link>
              </li>
              <li className="dropdown_primaryClassification_li">
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/serviceplace"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  服務據點
                </Link>
              </li>
              <li className="dropdown_primaryClassification_li">
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/news"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  消息公告
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </>
  )
}

export default DropdownAboutUs
