import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// 企業服務Dropdown
function DropdownEnterprise() {
  // 企業服務選項預設是消失狀態, 點擊每個分類之後到達分頁呈現消失
  const [click, setClick] = useState(false)

  // 切換狀態
  const handleClick = () => setClick(!click)

  return (
    <>
      <ul
        onClick={handleClick}
        className={
          click ? 'customDropdown-menu clicked' : 'customDropdown-menu'
        }
      >
        <li className="classificationTheme">
          <div className="dropdown-content_inner">
            <h2 className="titleC__h3">
              <div className="dropdown-title">
                <span className="section-content_en">企業服務</span>
                {/* <span className="section-content_en">On Job Training</span> 
                <span className="lesson-content_ch d-block">就業養成班</span>*/}
              </div>
              <span className="lesson-content_ch d-block mt-3">Enterprise</span>
              {/* 要不要做一個總覽課程頁面? */}
            </h2>
            {/* <a href="/employmentclassindex">
              <div className="dropdown-content_button">More</div>
            </a> */}
          </div>
        </li>
        <li className="classificationTheme">
          <div className="dropdown_primaryClassification_div">
            <ul>
              {/* <li className="dropdown_primaryClassification_li">
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  企業服務
                </Link>
              </li> */}
              <li className="dropdown_primaryClassification_li">
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/enterprise"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  會員專案
                </Link>
              </li>
              <li className="dropdown_primaryClassification_li">
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/corporate-training"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  企業包班
                </Link>
              </li>
              <li className="dropdown_primaryClassification_li">
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/lease"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  場地租借
                </Link>
              </li>
              <li className="dropdown_primaryClassification_li">
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/jobRecruitment"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  企業徵才
                </Link>
              </li>
              {/* <li className="dropdown_primaryClassification_li">
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/DT"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  數位人才培訓
                </Link>
              </li> */}
            </ul>
          </div>
        </li>
      </ul>
    </>
  )
}

export default DropdownEnterprise
