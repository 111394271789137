import React, { useState, useEffect } from 'react'
import { useParams, Redirect, useLocation } from 'react-router-dom'
import LinkScroll from '../../components/EmploymentClass/LinkScroll'
import MetaDecorator from '../../components/Util/MetaDecorator'

// import MultiLevelBreadCrumb from '../../components/MultiLevelBreadCrumb'
import { OnJobSingleClassesInfo, OnJobTypeClass } from '../../services/OnJob' // 縮寫對應的班級代號 api

import TrainingContactInfo from '../../components/TrainingClass/TrainingContactInfo' // 報名聯絡人資訊
import TrainingClassInfoList from '../../components/TrainingClass/TrainingClassInfoList' // 課程開班List
import ClassDetailInstruction from '../../components/TrainingClass/ClassDetailInstruction' // 課程介紹EMD內容
import ClassmateQuestion from '../../components/TrainingClass/ClassmateQuestion' // 常見問題

// 在職進修班分類 > 課程頁面
function TrainingClasses() {
  // 會員
  // const { signinBool, breadCrumbBool, setBreadCrumbBool } = props

  // eslint-disable-next-line no-unused-vars
  const [classes, setClasses] = useState([])
  // console.log(classes)

  // eslint-disable-next-line no-unused-vars
  const [classType, setClassType] = useState({})
  // console.log(classType)

  // 裝課程經理陣列
  const [classMentor, setClassMentor] = useState([])

  // 裝教務承辦陣列
  const [classMaintainer, setClassMaintainer] = useState([])

  // 裝課程經理陣列 (classType) (沒開課)
  const [mentor, setMentor] = useState('')

  // 裝教務承辦陣列 (classType) (沒開課)
  const [maintainer, setMaintainer] = useState('')

  // 轉址
  // eslint-disable-next-line no-unused-vars
  const [redirectTo, setRedirectTo] = useState('')

  let { id } = useParams() // destructure id  ML2

  const url = 'https://www.ispan.com.tw' + useLocation().pathname
  // console.log(url)

  // 處理拿到開課班級, 取得出班級的課程經理
  const handleMentor = () => {
    if (classes.length !== 0) {
      // 開課陣列不=0, 抓到課程經理陣列
      const newMentor = classes.map((v) => {
        return v.classMentor
      })
      setClassMentor(newMentor)
      // console.log('', newMentor)
    } else {
      // 抓最新那筆課程經理
      const mentor = classType.classMentor
      setMentor(mentor)
    }
  }

  // 處理拿到開課班級, 取得出班級的教務承辦
  const handleMaintainer = () => {
    if (classes.length !== 0) {
      // 開課陣列不=0, 抓到教務承辦陣列
      const maintainer = classes.map((v) => {
        return v.maintainer
      })
      setClassMaintainer(maintainer)
      // console.log('', maintainer)
    } else {
      // 抓最新那筆教務承辦
      const maintainer = classType.maintainer
      setMaintainer(maintainer)
    }
  }

  // 沒有開課也要能夠看到課程介紹, 捲課程代號帶出的資料
  // api/ClassType/v1/single/ML2
  const handleClassType = async () => {
    const datas = await OnJobTypeClass(id)

    setClassType(datas)
  }

  // 有開課就捲開課的第一門資料
  useEffect(() => {
    const data = OnJobSingleClassesInfo(id)

    data.then((courses) => {
      if (courses.length !== 0) {
        setClasses(courses)
      }
    })
  }, [id])

  useEffect(() => {
    handleClassType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    handleMentor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes, classType])

  useEffect(() => {
    handleMaintainer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes, classType])

  // // 掛載時改動麵包屑位置
  // useEffect(() => {
  //   setBreadCrumbBool(false)
  // })

  // // 卸載時還原麵包屑位置
  // useEffect(() => {
  //   return () => {
  //     setBreadCrumbBool(true)
  //   }
  // })

  return (
    <>
      <MetaDecorator
        title={`${
          classes.length !== 0 ? classes[0].className : classType.classTypeName
        } | 
        iSpan資展國際`}
        keywords={(() => {
          switch (id) {
            case 'PMP':
              return 'PMP，iPASS，專案管理師認證'
            case 'CCNA':
              return 'CCNA，CCNA認證，Cisco，思科'
            case 'HREX':
              return '人資，數據分析，EXCEL 資料分析'
            case 'BCIC':
              return 'solidity，智能合約，區塊鏈課程，Web3'
            case 'CW':
              return '小編，品牌行銷，文案設計'
            case 'SGSISO14064L':
              return 'ISO14064-1:2018，溫室氣體盤查主導查證員'
            case 'GA4':
              return 'GA4，網站數據分析，Google Analytics'
            case 'OGSM':
              return 'OGSM，一頁計畫表，目標管理'
            case 'CDCP':
              return 'CDCP認證，EPI，資安證照，CDCP課程'
            case 'MLC':
              return '機器學習，Machine Learning，Python演算法，ChatGPT'
            case 'NLC':
              return '深度學習，Deep Learning，ChatGPT'
            case 'TSQL':
              return 'SQL server，T-SQL，DML，關聯式資料庫'
            case 'CS':
              return 'CompTIA Security+，資安證照，Cryptography，ISC2'
            case 'MMP':
              return '心智圖，視覺思考，筆記整理，專注力訓練'
            case 'FBA':
              return 'FB廣告投放，廣告投放策略，廣告投放優化，廣告投放預算規劃'
            case 'PY':
              return 'Python數據分析、Anaconda'
            case 'IG':
              return 'IG，IG經營，IG廣告投放'
            case 'EBIA':
              return 'Power BI Desktop，大數據分析，儀表板'
            case 'BP':
              return '商業簡報，PowerPoint，韓明文'
            case 'CKA':
              return 'CKA，k8s，Container技術'
            case 'Kuber':
              return 'Kubernetes，k8s，Docker，Container'
            case 'LEEDGA':
              return '綠建築，leed ga認證，leee ga考試，美國綠建築'
            case 'LEEDAP':
              return '綠建築，leed ap認證，leed ap考試，美國綠建築'
            case 'WELLAP':
              return 'well ap課程，健康建築，well ap認證'
            case 'VM':
              return 'VMWARE，伺服器虛擬化，vSphere'
            case 'webadmin':
              return '網路架構，VPN，網路安全，防火牆'
            case 'IM':
              return '網路行銷策略，網路廣告投放，社群平台行銷'
            case 'PB':
              return 'PowerBI，數據處理，視覺化應用'
            case 'EBA':
              return 'Excel數據分析，商業數據分析'
            case 'SGSISO27001':
              return 'ISO27001，主導稽核員，資安證照，Lead auditor'
            case 'SGSISO27001T':
              return 'ISO27001，主導稽核員，資安證照，轉版'
            case 'SSCP':
              return 'SSCP，ISC2，CISSP，資安證照課程'
            case 'CISSP':
              return '資安認證，資安工程師，Cryptography'
            case 'CN':
              return 'CompTIA Network+ ，國際證照，OSI,TCP/IP'
            case 'PEN':
              return 'CompTIA PenTest+，滲透測試，資安認證，弱點掃描'
            case 'PT':
              return '滲透測試'
            case 'WW':
              return '網路弱點，弱點掃瞄，Nessus，OpenVAS'
            case 'Wshark':
              return 'Wireshark，封包分析，惡意程式，流量分析'
            case 'UED':
              return '使用者經驗，UIUX，互動設計'
            case 'VLE':
              return '調色技巧，調色環境，調色收費'
            case 'VLH':
              return '調色師，調色創意，調光調色'
            case 'VLW':
              return 'Color Grading，影視後製，調色'
            case 'ICT':
              return 'ISTQB，軟體測試工程師，認證'
            case 'TPM':
              return '軟體測試管理，軟體測試，測試'
            case 'APM':
              return '敏捷專案，agile，專案管理'
            case 'ANGU':
              return 'Angular，前端開發框架，前端工程師'
            case 'EXDA':
              return 'Excel，樞紐分析，函數應用'
            case 'IPRO':
              return '高質感企劃書，PowerPoint，韓明文'
            case 'HRMP':
              return 'SPHRi，人資國際證照，HRCI'
            case 'DMS':
              return '新手主管，基層主管，管理技巧'
            case 'BDA':
              return '物聯網，區塊鏈，大數據，資料分析'
            case 'PWC':
              return 'Python網路爬蟲，CSS Selector，Selenium'
            case 'AITECH':
              return 'tensorflow，生成對抗，深度強化學習，KERAS'
            case 'CDL':
              return 'CDL，Iaas，Paas，google cloud'
            case 'FMEA':
              return 'FMEA，DFMEA，PFMEA，SFMEA'
            case 'Iteacher':
              return '企業內部講師，教學技巧，講師技巧'
            case 'VBA':
              return 'Excel VBA，雲端數據分析'
            case 'HBP':
              return '十倍速簡報，PowerPoint，韓明文'
            case 'BPT':
              return '商業簡報圖表，PowerPoint，韓明文'
            case 'GPHR':
              return 'GPHR，人資國際證照，HRCI'
            case 'STT':
              return '軟體測試技術，黑箱測試，白箱測試'
            case 'SEE':
              return '軟體工程，DevOps，Jenkins'
            case 'SRA':
              return '系統分析，系統分析課程，需求訪談'
            case 'HyperV':
              return 'Hyper-V，伺服器虛擬化，Microsoft Hyper-V'
            case 'MUI':
              return 'APP設計，UI設計，Figma'
            case 'SGM':
              return '社群行銷，社群經營，社群內容規劃'
            case 'YBC':
              return 'Youtuber拍片，手機後製，剪映'
            case 'MYSQL':
              return 'MySQL，關聯式資料庫，DML，SQL'
            case 'PYTHONB':
              return 'Python，Numpy，Pandas，資料探勘'
            case 'ISAT':
              return '資訊安全、網路釣魚、社交工程、密碼安全'
            case 'NPDP':
              return 'NPDP，新產品開發管理師，國際管理證照'
            case 'GB':
              return '六標準差，ISO 13053，Minitab，Six Sigma'
            case 'BLC':
              return '簡報技巧，口語表達，簡報設計'
            case 'BLD':
              return '表達溝通，溝通技巧，換位思考'
            case 'GRPT':
              return '政府補助，政府計畫，政府補助計劃書'
            case 'EXA':
              return 'Excel，樞紐分析，統計分析'
            case 'RBP':
              return '沉浸式簡報、PowerPoint、韓明文'
            case 'FBM':
              return 'FB企業管理，廣告帳戶創建與管理，社群管理與經營'
            case 'LS':
              return '網路直播，直播行銷，直播準備'
            case 'YTM':
              return 'YouTube行銷，微電影製作，影音行銷'
            case 'KW':
              return '關鍵字行銷，SEO優化，廣告投放'
            case 'LINEBOT':
              return 'LINEBOT，AZURE，Python Flask'
            case 'AIOPENCV':
              return 'openCV，邊緣檢測，形態學，電腦視覺'
            case 'SPARK':
              return 'SPARK,RESTful，RDD'
            case 'DEVS':
              return 'DevSecOps，Gitlab，CI/CD'
            case 'AGILE':
              return '敏捷創新，agile，triz'
            case 'JKS':
              return 'Gitlab，CI/CD，DevOps，Docker，k8s'
            case 'SRS':
              return '軟體需求，規格文件，文件撰寫'
            case 'SAU':
              return '軟體架構師，軟體工程，分層結構'
            case 'BLK':
              return '業務談判，行銷技巧，談判技巧'
            case 'STM':
              return '小編文案，品牌行銷，腳本企劃'
            case 'EXF':
              return 'Excel，函數應用，表格彙整'
            case 'ABP':
              return '商業簡報進階，PowerPoint，韓明文'
            case 'BPI':
              return '商業簡報整合，PowerPoint，韓明文'
            case 'GAC':
              return 'Google Ads，Google Ads 認證，關鍵字廣告'
            case 'GAK':
              return 'Google Ads，關鍵字廣告，廣告投放效果分析'
            case 'WP':
              return 'WordPress網站，WordPress SEO，WordPress整合行銷'
            case 'MPR':
              return '媒體經營，公關策略規劃，社群媒體公關'
            case 'SEO':
              return 'SEO優化，關鍵字研究與分析，網站架構優化'
            case 'AINLP':
              return 'NLP，自然語言，RNN，LSTM'
            case 'BDAA':
              return '大數據，Python，資料建模'
            case 'PYTHON':
              return 'Python，資料探勘，機器學習'
            case 'PYTHONV':
              return 'matplotlib，資料視覺化，missingno，ggplot'
            case 'DTM':
              return '主管課程，主管培訓，數位轉型'
            case 'SRAR':
              return '軟體需求，系統分析，密集班，規格文件'
            case 'TCD':
              return '軟體測試個案設計，軟體測試，黑箱測試，白箱測試'
            case 'TUT':
              return '單元測試理論，白箱測試，黑箱測試，單元測試'
            case 'BLB':
              return '創意思考，問題分析，整合思維'
            case 'BLA':
              return '業務銷售，顧問式銷售，行銷技巧'
            case 'Presale':
              return '售前規劃，Pre-Sales，報價議價'
            case 'DBR':
              return '品牌經營，品牌管理，數位行銷'
            case 'PE':
              return '商業簡報圖解，PowerPoint，韓明文'
            case 'ASEO':
              return 'SEO優化，關鍵字研究與分析，網站架構優化'
            case 'EN':
              return 'Evernote，九宮格，超效率思考'
            case 'YTP':
              return 'Youtube企劃，行銷優化'
            case 'Notion':
              return 'Notion，ChatGPT，管理工具'
            case 'AIFIN':
              return 'AI金融，AI行銷'
            case 'SQL':
              return 'SQL server，T-SQL，DML，關聯式資料庫'
            case 'PMS':
              return '產品經理，專案管理，Trello'
            case 'BF':
              return '突破框架，SCAMPER，635腦力激盪法，心智圖'
            case 'RDPM':
              return '產品研發，專案管理'
            case 'STS':
              return '業務行銷，銷售技巧，顧問式銷售'
            case 'STW':
              return '小編文案，故事行銷，腳本企劃'
            case 'OCS':
              return '說話高手，口語表達技巧'
            case 'GCP':
              return 'Google Cloud，Google Cloud Platform'
            case 'AIRC':
              return '推薦系統，Spark MLlib，協同過濾，Apache Spark'
            case 'NBS':
              return '網路爬蟲，SPARK，Hortonworks'
            case 'ISRT':
              return '數位韌性，SecOps，SIEM，ISO27014'
            case 'MSPM':
              return '專案管理，OCOSA，周哈里窗'
            case 'LC':
              return '創新領導'
            case 'TDDJ':
              return 'Java，單元測試，TDD，JUnit 5，mockito，Mock'
            case 'TDDN':
              return 'C#.NET，單元測試，MSTest，TDD'
            case 'JSS':
              return '5 Framework，Java Spring，安全程式開發'
            case 'DFC':
              return '表達溝通，高難度對話，溝通技巧'
            case 'OCB':
              return '職場溝通，口語溝通，職場經驗'
            case 'aPHRi':
              return 'aPHRi，人資國際證照，HRCI'
            case 'MMS':
              return '中階主管，人資管理，管理技能'
            case 'AME':
              return '亞馬遜電商，跨境電商，Amazon'
            case 'AMM':
              return '亞馬遜平台，亞馬遜開店，亞馬遜課程'
            case 'AMH':
              return '亞馬遜廣告，亞馬遜操作，Amazon課程'
            case 'SBP':
              return '創業，建立品牌，多元收入'
            case 'canva':
              return 'Canva，圖片編輯，排版設計'
            case 'PYTHONC':
              return 'Python，特徵工程'
            case 'PES':
              return '網站入侵，資安防護'
            case 'SGSISO27701':
              return 'ISO27701:2019，主導稽核員，隱私資安，資安證照'
            case 'SGSISO22301':
              return 'ISO22301:2019，BCMS，營運持續管理，SGS證照'
            case 'PMTD':
              return '跨部門溝通，產品經理，專案管理'
            case 'UML2':
              return 'UML，合成結構，物件導向'
            case 'UML':
              return '需求分析師，系統分析師，UML'
            case 'BCFS':
              return '區塊鏈，去中心化，Solidity'
            case 'KMA':
              return '知識萃取，價值傳承，職人經驗'
            case 'NFT':
              return 'NFT商業策略，產業應用，NFT技術'
            case 'NFTB':
              return 'NFT投資，NFT交易，NFT實戰策略'
            case 'NOT':
              return 'Notion，李燕秋，筆記軟體'
            case 'PAI':
              return 'Playground AI，AI繪圖，繪圖教學'
            case 'GPTAI':
              return 'ChatGPT，AI影音，AI行銷'
            case 'LAI':
              return 'Leonardo AI，AI創作，智能繪圖'
            case 'AOGSM':
              return 'OGSM，SWOT，OKR，KPI'
            case 'PTIME':
              return '專案時程規劃，專案管控'
            case 'SGSISO14067L':
              return 'ISO14067:2018，碳足跡主導查證員'
            case 'ESGB':
              return 'ESG，循環經濟'
            case 'VisualC':
              return 'C#，程式語言，Visual Studio，.NET'
            case 'C':
              return 'C++，程式語言，物件導向技術，Ｃ'
            case 'CC':
              return 'C++，程式語言，物件導向技術，Ｃ'
            case 'VLOG':
              return '旅行錄影，旅行拍攝，攝影'
            case 'GTM':
              return 'GTM，動態載入追蹤碼，事件追蹤與轉換追蹤'
            case 'LINE':
              return 'LINE@，LINE2.0，LINE行銷'
            case 'google':
              return 'Google商家，Google在地，Google店家'
            case 'DMB':
              return '行銷預算，預算規劃，行銷成效'
            case 'RB':
              return 'R軟體，大數據，建模'
            case 'RC':
              return 'R軟體，機器學習'
            case 'PMART':
              return '專案管理，專案經理，領導統御，主管課程'
            case 'SGSISO14064':
              return 'ISO14064-1:2018，溫室氣體查證員'
            case 'SGSISO14067':
              return 'ISO14067:2018，碳足跡計算，CF-PCR'
            case 'webs':
              return 'Linux、雲端伺服器、滲透測試'
            case '5GA':
              return '5G，行動通訊，通訊架構'
            case 'startup':
              return '餐飲創業，創業開店'
            case 'PL':
              return '專案領導，團隊激勵'
            case 'RDTPM':
              return '績效管理，研發管理'
            case 'ESGT':
              return 'ESG，SDG，碳盤查，循環經濟'
            case 'VLS':
              return 'DaVinci Resolve，調色，調光軟體'
            case 'FLM':
              return 'IP創建，流量變現，人設IP'
            case 'AZOS':
              return 'ChatGPT，Azure，OpenAI'
            case 'AZLS':
              return 'Azure，聊天機器人，自然語言，AI language'
            case 'ESGSDG':
              return 'ESG，SDG，CDP，碳盤查'
            case 'MTV':
              return '元宇宙'
            case 'LINUXC':
              return 'Linux，嵌入式課程'
            case 'FPGA':
              return 'vivado verilog，FPGA，SOPC，嵌入式課程'
            case 'GPTPPT':
              return 'ChatGPT簡報，AI簡報，ChatGPT外掛，AI繪圖，Clipdrop'
            case 'AIDESIGN':
              return 'AI繪圖，AI創作，AI藝術，人工智慧藝術'
            case 'BLENDER':
              return 'Blender，3D軟體，動畫，電影，遊戲'
            case 'TRIZ':
              return 'TRIZ，萃智，萃思'
            case 'BMD':
              return '商業模式，九宮格，ECRS'
            case 'GPTDA':
              return 'ChatGPT資料分析，AI聊天，ChatGPT外掛，Prompt技巧，ChatGPT問卷，ChatGPT表格'
            case 'GPTExpert':
              return 'GPT備課，GPT簡報，GPT教案'
            case 'GPTExcel':
              return 'ChatGPT應用，ChatGPT數據，ChatGPT excel'
            case 'AIMedia':
              return 'AI自媒體，ChatGPT，自媒體'
            case 'CFS':
              return '財務報表，現金流，非財務背景'
            case 'AIMoney':
              return '短影音變現，短視頻變現，流量變現'
            case 'BMA':
              return '品牌行銷，行銷廣告，品牌價值'
            case 'GPTM':
              return '品牌設計，優化電商，提升運營'
            case 'PHPSQL':
              return 'PHP，MySQL，網站開發'
            case 'DTAI':
              return 'ChatGPT，生成式AI'
            case 'WGPT':
              return 'ChatGPT，工作效率'
            case 'ISO50001':
              return 'ISO50001，內部稽核員'
            case 'ISO50001L':
              return 'ISO50001，主導稽核員'
            case 'ESGM':
              return 'ESG系統，商業模式'
            case 'ESGS':
              return 'ESG永續供應鏈，SSCM'
            case 'ESGP':
              return 'ESG永續報告書'
            case 'GPTEXCEL':
              return 'ChatGPT應用，ChatGPT數據，ChatGPT excel'
            case 'CCB':
              return 'CC，ISC2，Certified in Cybersecurity'
            case 'AITL':
              return '邊緣運算，Edge AI，TinyML,，Mobile AI，ChatGPT，TENSORFLOW Lite'
            case 'GAI':
              return '生成式AI，GAN，VAE'
            case 'SIAMF':
              return 'EXIN，SIAM，ITIL'
            case 'SIAMP':
              return 'EXIN，SIAM'
            case 'LLMC':
              return 'LLM，ChatBOT，Llama，RAG，LangChain，Prompt'
            case 'AITOOLS':
              return 'Claude，Gemini，Copilot'
            default:
              return ''
          }
        })()}
        description={(() => {
          switch (id) {
            case 'PMP':
              return '本課程目標為協助獲取PMP®認證，洞悉PMBOK® Guide第七版8個績效領域之精髓, 具備國際級專案管理技能。'
            case 'CCNA':
              return '思科認證網路工程師的完整課程，涵蓋網路基礎架構，LAN和WAN、路由器和交換機設置、IPv4和IPv6協議、VLAN、STP、OSPF、ACL、VPN、NAT和WLAN、網路安全等技術。獲得CCNA證書以證明具備網路工程師的技能和知識的最佳選擇。'
            case 'HREX':
              return '本課程將由具備產業HR高階主管經歷、現任頂大人資系所教授親自授課，以HR真實案例為基礎，手把手傳授學員如何應用EXCEL資料分析，輕鬆學會各式統計工具和簡單的AI機器學習預測法，結合HR專業知識，來解決HR業務的問題。'
            case 'BCIC':
              return '學習區塊鏈的發展歷史，針對Web3.0的起手式有初步的認識，了解錢包背後的邏輯運作。'
            case 'CW':
              return '數位文案就是比傳統文案白話！但我們還要進一步告訴你，如何讓白話變成誘惑力！會寫中文不見得會寫文案，因為你還不懂TA的心！我們將帶領各位，分析TA消費的終極慾望！小編不只是文案，他們更像是公關！只是文案剛好是最常用的溝通工具而已！所以，參加本課程將將帶領各位建立話題性！'
            case 'SGSISO14064L':
              return '本課程將透過溫室氣體管理趨勢的發展探討企業之碳管理風險與機會，並深入解析新版標準之要求，引導學員掌握報告邊界之界定方法、溫室氣體盤查量化/報告/查證之技巧與能力，使企業之溫室氣體清冊內容與品質符合預期使用者之期待。'
            case 'GA4':
              return 'Google Analytics升級至GA4，舊版GA分析轉換到GA4？課程從商業視角切入，完整說明數據與商業之間的紐帶關係，並全程以實作，帶大家熟悉 GA 4 與商業應用如何連結。'
            case 'OGSM':
              return '參加本課程將了解OGSM工具，使用OGSM工具，掌握OGSM邏輯，以OGSM施展管理效能。'
            case 'CDCP':
              return '由EPI (Enterprise Products Integration)開發的Data Center系列認證，是國際上極具權威性的資訊機房領域專業認證，提供從基礎到高階、技術到管理，完整的資訊機房人員認證架構，也被許多國際性的大型企業視作資料機房專業人才之訓練發展與能力評估的標竿。'
            case 'MLC':
              return '本課程之學員將可學到機器學習的原理及應用，使用Python程式實作機器學習各演算法(迴歸、維度縮減、分類、分群)於實際案，並學習運用當紅的ChatGPT提升職場競爭力。'
            case 'NLC':
              return '使學員瞭解深度學習的理論背景、評估如何使用適當的深度學習方法於實際例子。包括卷積神經模型用於圖像資料辨識(MNIST/Cifar 10)、遷移式學習、Word2vec、RNN用於垃圾郵件辨識、LSTM於實作上下文預測，以及GAN於影像合成。透過案例說明深度學習中的方法對圖像與文字資料處理，進而增進學員了解深度學習的相關知識與提升在資訊處理時代之競爭力。'
            case 'TSQL':
              return '從關聯式資料庫的基本概念介紹起，透過循序的教學與實作歷程，讓您逐步瞭解並熟悉微軟的 SQL Server 各式各樣的基礎語法撰寫實務。'
            case 'CS':
              return 'CompTIA Security+ 考試已於2021年全面改版為最新的「SY0-601」版本，本課程亦已針對新版的考試內容做調整，將考試內容彙整至上課教材中，目標是協助學員考取最新版的CompTIA Security+ 國際認證。'
            case 'MMP':
              return '心智圖法以圖像為基礎的思考方法，可以幫助更有效地組織思路，提高記憶力和創造力。從構圖、視覺思考、記憶強化、筆記整理等多角度，探索心智圖法具體應用，優化自己的工作和學習方式，提高效率和創造力，進行思維創新、問題解決等工作。'
            case 'FBA':
              return 'FB廣告投放秘訣將學習選擇合適的廣告形式、設定廣告投放目標、編寫廣告內容、進行廣告優化等技巧，解析數據讓你把錢花在刀口上，實現更有效的廣告效果。'
            case 'PY':
              return '透過Anaconda提供簡單易用的開發環境，輕鬆學習Python基礎與應用。使用Python與Jupyter Notebook進行編程，介紹數據處理和可視化。'
            case 'IG':
              return '課程學習在Instagram上成功地經營社群，創建有效的社交媒體策略、增加追隨者、提高社交媒體參與度，並學習如何在Instagram上進行廣告投放，以提高品牌知名度和銷售額。'
            case 'EBIA':
              return '本課程旨在介紹Power BI Desktop於大數據資料的應用，從建立資料模型、數據下載與表格正規化、數據分析與圖象表達，讓不具程式背景的人也可以針對大數據資料進行分析。'
            case 'BP':
              return '雜亂無章的架構往往是導致商業簡報失敗的關鍵因素，參加本課程將破除簡報中圖片與動畫越多越好的迷思，透過講述商業簡報「製作原則」的完整呈現，及解說知名人士或國際大廠的簡報範例（例如：蘋果電腦、Cisco等等），讓您了解簡報之間規則與關連性的重要，重新調整安排簡報展現的架構與流程。'
            case 'CKA':
              return '不管你是否意識到，在這幾年時間裡，資訊技術類招聘重點關注的是 Kubernetes（簡稱: K8S)，Kubernetes 是目前雲端計算領域最流行的技術之一。 根據 Indeed 最近的一項調查，Kubernetes 的求職人數增長最快，比去年同期增長了 173%。'
            case 'Kuber':
              return '介紹使用Kubernetes優點、架構與部屬，最後並透過在公有雲Google Cloud Platform 使用Kubernetes的Container服務，更加了解如何使用與管理Kubernetes。'
            case 'LEEDGA':
              return '本課程將學習LEED評估系統架構、基本概念與案例分享，除了讓學員對於LEED評估系統有更詳盡的認知，也將考試內容彙整至上課教材中，帶領學員理解考試流程，並提供模擬試題練習，目標是協助學員取得LEED® Green Associate™證照。'
            case 'LEEDAP':
              return '本課程以新建築版本 (Building Design and Construction, BD+C) 為主軸，進行得分點規範解析、整合式永續設計概念、綜效得分策略、LEED案例分享等，讓學員對於LEED評估系統有更詳盡的認知。'
            case 'WELLAP':
              return '本課程配合講師所規劃的準備計畫，考取WELL專業顧問師(Accredited Professional, AP)證照。'
            case 'VM':
              return '伺服器虛擬化是企業不可少的重要環節。提升IT競爭力！掌握虛擬化技術基本概念、虛擬機的建立與配置、伺服器集群的構建、虛擬機性能的優化、虛擬化存儲等技能。'
            case 'webadmin':
              return '網路基本概念、原理和技術：包括網路拓撲、協議、IP位址、子網劃分、路由、交換、VLAN、TCP/IP、DNS、防火牆、VPN，涵蓋網路服務的知識和實作技巧，如郵件伺服器、FTP、DNS、DHCP等。深入理解網路基礎架構和網路服務的運作原理，具備網路配置、維護和故障排除等能力。'
            case 'IM':
              return '網路行銷規劃師分三部曲，培養網路整合行銷專業人才，涵蓋網路市場分析與數位行銷規劃，分析透徹精準預判網路市場及商圈，掌握數據訂定有效經營策略。網路品牌行銷曝光，讓客戶找到你，熟悉各項曝光途徑讓網站增加點閱率及網站知名度。網站經營整合行銷，掌握正確行銷策略方向，把錢花在刀口上！'
            case 'PB':
              return '商業人員掌握分析數據，建立分析，匯入多樣化數據、處理資料庫(Data Model)、靈活產出報表(Power Pivot)，視覺化工具 Power BI提供深度處理數據，繪製互動圖、表，協作共用，與Azure無縫配合。'
            case 'EBA':
              return '利用Excel 執行初階商業數據分析，讓Excel成為強大數據分析工具。內化「資料(Data)」選單ETL、無需寫程式建模、外行也可管理關聯資料庫結構、輕易處理百萬列(Mega Row)數據規模分析。'
            case 'SGSISO27001':
              return 'ISO27001主導評審員訓練之主要目的在培訓國際資訊安全標準ISO27001輔導及驗證人員 (Lead Auditor)，同時將經由此課程授與主導評審員資格，得以註冊登記於IRCA，發予的證書註冊於IRCA中。'
            case 'SGSISO27001T':
              return '本課程係針對已取得ISO/IEC 27001:2013年版主導稽核員資格的學員，協助您學習如何依據新版要求稽核組織管理系統與程序，以確認符合新版標準要求，並強化資訊安全管理系統持續改善的流程。'
            case 'SSCP':
              return '同樣是ISC2(國際資訊安全認證委員會)推出資安國際證照，SSCP顯然更適合國內為數最多且需要實際動手操作的資安技術工程師，目標為培養學員具通過SSCP 認證考試之實力。'
            case 'CISSP':
              return '培養學員具通過CISSP認證考試之實力，培養學員具備基本通訊、網路安全技術（Firewall, VPN, NAT…等）及系統存取控制等相關概念。'
            case 'CN':
              return '資展國際特開辦CompTIA Network+ 國際網路認證課程，目前版本已經從N10-007更新為新版的N10-008，目標是協助學員考取最新版的CompTIA Network+ 國際認證。'
            case 'PEN':
              return '您將了解如何進行滲透測試以確定如何進行網路抵禦攻擊的彈性所需的漏洞評估、勘查與掃描技術。目標是協助學員考取最新版的CompTIA PenTest+國際認證。'
            case 'PT':
              return '此門課程為滲透測試實務操作課程，須具備網路與資安基礎知識以及Linux操作指令。'
            case 'WW':
              return '培養學員瞭解弱點生命週期、弱點法則、特性分析與因應原則，並有弱點掃瞄實務課程-Nessus / OpenVAS。'
            case 'Wshark':
              return '網路封包分析工具Wireshark安裝和設定、封包捕捉和過濾技巧、封包分析和協議解析、流量分析和延遲分析、數據統計和封包組裝等。有效分析和解決網路故障、優化網路和加強網路安全。適合網路管理、網路安全、系統運維等相關人員。'
            case 'UED':
              return '提高在UX設計、人機互動、設計思考、用戶研究、互動設計、UI設計、資訊架構、使用者測試、產品設計等多個方面，創造優秀的UX體驗，以及如何使用人機互動設計和UI設計來提高產品的可用性和易用性。'
            case 'VLE':
              return '對影片後期製作中的調色師角色感到好奇？正在考慮調色師作為您職業選擇？，我們的課程都將帶您深入了解調色師工作領域上所需具備的技巧。了解調色師工作各方面，包括創意、技術和商業層面，如何建立自己調光調色室、有效管理業務，以及提升創意和技術技能。'
            case 'VLH':
              return '調光調色是後期製作關鍵環節，營造故事情感和打造獨特影視風格。掌握這些技巧使您在調光調色領域脫穎而出，課程涵蓋觀察、顏色風格設計、常用工具和調色創意技巧。學習如何分析電影和電視作品的調色，掌握典型調色流程，了解建立風格和使用工具來創造視覺效果。深入了解電影、電視影集和廣告中使用的多種調色技術。'
            case 'VLW':
              return '免費研討會，旨在詳細介紹調色師的職業和他們在後期製作中的角色。我們將涵蓋調色師工作的各個方面，包括創意、技術和商業層面。這個課程提供實用的建議，包括設備需求和建立調光調色室，有效管理業務，以及提升創意和技術技能的方法。此研討會適合對視覺藝術、影片製作感興趣的人，特別是那些考慮在影片後期製作領域發展職業的人。無論您是否有調色經驗，我們都歡迎您的參加。'
            case 'ICT':
              return '「軟體測試」是確保與改善軟體品質的必要手段之一，儘管如此，「軟體測試工程師」供需落差缺口的持續擴大卻大大地阻礙國內資通訊產業的發展與競爭力。'
            case 'TPM':
              return '軟體測試是產品開發重要的一環，所需花費時間大約是佔整個專案的40%，因此有效的測試管理，會對專案進行有很大的幫助。'
            case 'APM':
              return '敏捷式開發強調的是如何應對快速變化需求的軟體開發能力，並非單指一種特定制式的開發方法，重點是軟體開發的精神 (spirit)，任何方法只要符合敏捷宣言(Agile Manifesto)，都可以算是敏捷式開發的方法。'
            case 'ANGU':
              return '本課程透過深入淺出的方式，帶著學員了解 Angular 基本觀念且透過課堂練習從無到有逐步搭建起一個完整的 Angular 專案，並透過 JSON server 練習介接 Restful API。'
            case 'EXDA':
              return '本課程將透過Excel工具的函數與樞紐分析，並藉以案例演練與帶領學員逐步操作，進行表格彙整與與資料分析，以大幅提升工作效率。'
            case 'IPRO':
              return '參加本課程可增加完成企劃書的效率性與專業度，學習掃瞄期的企劃書寫作要領，經由高效說服力的企劃書提升過案率，創造個人績效，讓每個學員均可製作出高品質的文件內容。'
            case 'HRMP':
              return '本課程全程中文解說，透過實戰模擬考試進行練習，提供獨家的解題與說明，使學員快速掌握通過SPHRi考試的要領，並透過有系統的方式學會國際級人力資源管理的知識體系和標準。'
            case 'DMS':
              return '本課程將啟發新手主管之角色自覺，以因應被賦予的職責重任，培養主管人員應有的態度與氣質，強化組織互動與人際關係，贏得團隊成員信任，培養主管人員目標管理技巧、有效部屬培育、提升團隊溝通、問題分析與解決能力。'
            case 'BDA':
              return '「資料科學與大數據分析實務」課程，介紹基礎資料分析方法，在大數據的技術興起之下，透過多種常見工具例如：開源程式碼(R, Python)、NodeXL、Hadoop等進行資料分析實作。'
            case 'PWC':
              return '本課程將帶領學員從網路爬蟲所需的Python基礎入門；同樣被稱為網路爬蟲（Web Crawler）的網頁資料擷取是一種從網頁上取得頁面內容的技術，與常見搜尋引擎使用之爬蟲技術不同的是其更側重於轉換網路上非結構化資料為便於儲存和分析的結構化資料。'
            case 'AITECH':
              return '本課程的目標在帶領經理人快速的掌握人工智慧技術的開發趨勢，配合親自上機安裝體驗人工智慧技術的開發流程，使經理主管掌握正確方向，取得先機。'
            case 'CDL':
              return '本課程概述Google Cloud平臺的產品和服務。本領域還考慮可幫助公司企業更好地開發和管理的 Google Cloud 解決方案，例如 Compute Engine、App Engine 和 Apigee。'
            case 'FMEA':
              return '資展國際開辦「FMEA失效模式及效應分析實務班」，，以產品設計的開發過程為主軸，並會講授System FMEA(SFMEA)&Design FMEA(DFMEA)。'
            case 'Iteacher':
              return '本課程將瞭解企業內部講師角色、定位與職責，瞭解企業內部講師需具備的形象與特色、基本條件與作為， 瞭解企業內部講師的基本動作與演練示範， 瞭解企業內部講師必要之心理準備與掌握，學會遠離上台緊張恐懼的有效方法。'
            case 'VBA':
              return '本課程旨在介紹Excel VBA的巨集設計，讓Excel自動完成重複操作，並結合樞紐與函數進行資料統計製作報表與統計圖表，最後介紹如何進行資料加密與傳送電子郵件，大幅提升工作效率。'
            case 'HBP':
              return '參加本課程將徹底改變簡報者的軟體使用習慣，以每年節省100小時為課程學習目標，拆解簡報製作中150個核心動作，找出各種製作方式的最佳效率解答，讓學習者達成速度連發的高效率製作。'
            case 'BPT':
              return '本課程將協助學員從「圖表快閃族」晉升為「專業圖表應用者」，由淺入深之方式，經由大量實戰演練，帶領學員一窺商業圖表豐富而多元的世界。'
            case 'GPHR':
              return '本課程全程中文解說，透過實戰模擬考試進行練習，提供獨家的解題與說明，使學員快速掌握通過GPHR考試的要領，並透過有系統的方式學會國際級人力資源管理的知識體系和標準。'
            case 'STT':
              return '資訊服務產業要能長久經營，除了有良好的開發能力之外，還要有良好的軟體品質，及較低的軟體維護成本以維持公司的競爭優勢，其中高品質的軟體以及較低的軟體維護成本，這兩者的共同要素即是軟體測試。對企業來說，軟體測試往往是軟體開發過程中，最繁瑣也是最重要的工作之一。'
            case 'SEE':
              return '本課程透過Open Source軟體工具的實作練習，讓您熟悉一個應用系統開發過程中關於「自動化軟體持續整合建置」「軟體型態版本控管」、「網頁應用系統效能測試」、「問題追蹤管理」，與等軟體工程中的重要議題，讓您得以知行合一。'
            case 'SRA':
              return '多數專案失敗的原因在於需求無法掌握，古今中外的老生常談，知道這回事但不知如何做的大有人在。身為系統分析人員，若在初步階段沒有一套可依循的方法，後續的設計開發會遇到的困境將接踵而來，心力也大多耗費在補洞救火，致使延宕開發時程。'
            case 'HyperV':
              return '學習Microsoft Hyper-V建立虛擬環境，包含設置和管理虛擬機器、優化網路和存儲資源，保護虛擬環境安全。詳細的實戰練習，讓您快速掌握實用技能。'
            case 'MUI':
              return '透過Figma軟體針對行動裝置上的APP規劃與UI設計，了解行動裝置上UI設計的核心概念和技能，進而利用視覺設計和互動設計提高APP產品的易用性和品質。'
            case 'SGM':
              return '許多公司的市場及行銷部門，將社群網路經營成為營運的重要一環，來因應社群反應做銷售、產品、服務策略修改。透過實務案例，社群行銷市場詳細介紹，撰寫企業社群行銷規劃書藍圖、行銷目標與KPI設定，快速建立網路社群行銷觀念技巧。'
            case 'YBC':
              return '想要快速用手機完成拍攝高品質的影片、並用後製軟體快速剪輯和編輯、增加音效和字幕等等，本課程將讓您具備這些成為成功YouTuber必學技巧。'
            case 'MYSQL':
              return '透過循序漸進的教學與實作，讓學員逐步瞭解並熟悉MySQL的運作方式以及SQL指令的撰寫方式與實務應用，以提昇學員在職場的競爭力。'
            case 'PYTHONB':
              return '「Python資料處理與分析」，引導學員從認識Python，到瞭解資料 處理與建模前的基礎⼯作，內容包括：資料結構(含原⽣資料結構與 NumPy, pandas等衍⽣資料結構)、資料匯入匯出、資料前處理、統計 分析、以及探索式資料分析等。'
            case 'ISAT':
              return '認識網路釣魚、社交工程、數據洩露、密碼安全、安全瀏覽、個人設備安全等安全風險及預防或減緩方案 '
            case 'NPDP':
              return '由國際創新產品發展管理協會(PDMA) 所發展的NPDP方法論，內容都是來自於業界的最佳範例，而PDMA所頒發的新產品開發專家（New Product Development Professional，NPDP）證照，更為新產品開發領域中唯一的國際專業資格認證。'
            case 'GB':
              return '本課程設計符合國際標準ISO 13053六標準差管理系統綠帶要求，提升學員運用Minitab統計軟體分析數據，進而具備大數據分析能力，並協助考取六標準差證照。'
            case 'BLC':
              return '本課程將善用認知行為背後的學習心理因素，學會設計有邏輯架構又精彩的簡報，提升簡報設計美感以創造視覺饗宴，讓說話層次清晰條理分明易於吸收，增進口語表達技巧及肢體語言魅力，上台者講師將給予個人化指導回饋。'
            case 'BLD':
              return '本課程將認知到溝通時應有的正確觀念，學會多種溝通表達的實戰技巧，能夠換位思考展現同理心傾聽，懂得運用各種提問法深度探詢。'
            case 'GRPT':
              return '參加本課程將快速掌握此類計畫書撰寫的要領以提高計畫申請成功的機率，瞭解如何利用政府專案補助計畫以提高研發效率及減輕研發負擔。'
            case 'EXA':
              return '本課程將介紹Excel工具裡面的樞紐分析，藉以案例演練與帶領學員逐步操作，進行表格彙整與資料分析，搭配樞紐分析圖的視覺化表達，讓您大幅提升工作效率與輕鬆解讀分析結果。'
            case 'FBM':
              return '如果您是企業主或行銷人員，且需要在Facebook上進行有效的網路行銷，課程中將深入瞭解FB企業管理平台的各種功能和操作，例如粉絲專頁管理、廣告管理、資訊統計分析、客戶服務等等。'
            case 'LS':
              return '學習如何打造一個成功的網路直播行銷計畫，包含選擇合適的直播平台、制定行銷策略、製作直播內容、提高觀眾參與度等技巧，讓更多人透過網路平台觀看和參與互動整體規劃。'
            case 'YTM':
              return '近年來商業模式明顯進入社群媒體行銷，善用YouTube各項功能建立品牌。然而並非單純將影片上傳就可以有效果，影片的分鏡腳本，可節省拍攝預算及範圍、規劃更具娛樂效果與能動人心的影片。透過實務案例行銷面著手完成影音行銷您的品牌或產品。'
            case 'KW':
              return '花錢買關鍵字卻不一定有成效，要如何將錢花在刀口上？分析企業找到目標族群，運用網路行銷工具及網路活動，透過網站顧客轉換率來引導網站營運者提升網站與顧客互動力。最終透過網路行銷經營及提升網路銷售轉換率技巧，讓關鍵字廣告成功轉換購買率。'
            case 'LINEBOT':
              return '本課程除了會帶領學生深入的探討Line bot之應用外，也會使用目前最夯的Python程式語言及微軟AI Azure服務來帶領學生實做智能客服，也會做Linebot與Python Flask介紹。'
            case 'AIOPENCV':
              return 'OpenCV是一個由英特爾所開發的視覺函數庫，其功能包含許多與影像處理有關之功能。隨著人工智慧近年來在視覺上的突破，OpenCV也與深度學習有著密不可分的關係，另外課程也會介紹卷積運算，形態學，邊緣檢測等技術。'
            case 'SPARK':
              return '課程注重於巨量資料實務開發，從Spark核心SDK介紹、Spark SQL結構化資料處理、Mllib機器學習到如何包裝成RESTful API之系統整合，給予完整的介紹。'
            case 'DEVS':
              return '軟體安全防護的最佳時機就在開發階段，包括:正式上線後的除錯階段。從需求分析階段的安全需求分析開始，到安全系統架構設計，到資料流程的安全性分析設計，安全程式的開發，以及安全性測試等，建立一套自動化上版，持續整合(CI)、持續交付(CD)安全程式的DevSecOps Pipeline是目前最佳的「軟體持續安全保證」的解決方案。'
            case 'AGILE':
              return '敏捷創新是國內創新課程市場獨樹一幟的實操實練型工作坊課程，將系統解析如何打造企業的核心創新力。'
            case 'JKS':
              return 'DevOps文化就在於破除傳統的Developer團隊Operator團隊兩邊獨立作業的文化，讓Developer開發時考慮到後續維護的難處，維護團隊也能夠在開發時就對後續維護可能發生的問題提供意見。而這一切核心就在於持續整合、持續發佈、持續部署（CI/CD），把相關開發、建置、測試、部署、維護所有流程串連，確保在每次Commit後，所有流程皆自動化，軟體版本交付週期從數月變為數天，降低錯誤率並同時加快軟體開發速度，大幅提高軟體穩定性。而這些在微服務Container開發流程中特別適合導入。'
            case 'SRS':
              return '多數軟體開發專案“需求無法收歛”或“需求變更頻繁”是導致專案失敗的重要原因，身為公司最為重視的棟樑之材，並肩負起專案成敗以及系統開發決定性影響的“系統分析師”。這麼專業的角色，相信必須要做的更好、更出色，所以您不能不知道以下這些事：『系統分析階段的關鍵成功要素是什麼？』、『如何掌握系統分析階段的作業重點？』、『什麼是系統分析過程必要的產出？』'
            case 'SAU':
              return '本課程旨在教授有志於擔任軟體架構師 (software architect)一職者，所應具備的相關技能。從對系統全貌有著概括的瞭解，再進而往精細面逐一實現。'
            case 'BLK':
              return '本課程將理解到共創雙贏結果的重要性，能從對方背後的核心需求出發，學會運用談判策略的各項原則，看透談判對手背後隱藏的布局，掌握即學即用的實戰攻防技巧。'
            case 'STM':
              return '參加本課程將幫助學員理解故事行銷原理，引介操作的時機與策略，引導故事創作的方法。'
            case 'EXF':
              return '本課程將介紹Excel裡面的重要函數與設定規則，藉以案例演練與帶領學員逐步操作，進行表格彙整與資料分析，完成客製化報表。'
            case 'ABP':
              return '本課程不談華麗酷炫、閃亮吸睛，但是卻無法接軌職場實務的視覺特效，以應用性、本質性、專業性為三大主軸，發展出進階簡報製作必須具備的六大特徵，說明進階版排、圖解、圖表及圖片的專業製作方式，讓製作者學習課程之後，成為更進階、更善於視覺化表達的溝通者。'
            case 'BPI':
              return '參加本課程可提高簡報製作效率、快速修正錯誤格式，確保簡報風格不混亂/熟悉簡報軟體運作規則，說明簡報六大元素整合技巧，讓公司簡報具有國際級公司的一致性風格/讓全公司的簡報資料具有共享性，輕鬆整合個人簡報、團隊簡報及Office簡報資料。'
            case 'GAC':
              return '針對Google Ads認證，掌握Google Ads廣告基本投放技巧。課程中將學習設置和優化Google Ads帳戶，選擇關鍵字等，針對認證考試準備與基本操作技巧。'
            case 'GAK':
              return 'Google Ads課程將為您介紹如何選擇和使用關鍵字、設定競價策略、分析廣告效果等實戰技巧，把錢花在刀口上，取得更好的廣告投放效果。'
            case 'WP':
              return 'WordPress是目前最受歡迎的網站建置平台之一，課程中將學習使用WordPress外掛或是額外插件進行網站的行銷、提高網站流量、SEO技巧來優化網站內容等等。'
            case 'MPR':
              return '如何設計出讓消費者認同的主題，再藉由媒體公關管道，讓資訊大量曝光。社群公關的基礎規劃，使用網路社群公關媒體行銷產品或服務，讓您只利用小成本就能創造大業績。'
            case 'SEO':
              return '想要在網站搜尋引擎上提高曝光率並增加網站流量？課程學習如何優化網站內容、選擇關鍵字、建立連結和進行社交媒體行銷等技巧。分享實用SEO策略和行銷技巧，讓網站在搜尋引擎上脫穎而出。'
            case 'AINLP':
              return '本課程會介紹自然語言處理的基礎觀念，了解機器學習該如何應用在文字分析上，Word2vec詞向量，以及RNN/LSTM神經網路介紹。'
            case 'BDAA':
              return '本課程將聚焦在以個案研討方式進行建模實作，讓學員了解在實務建模過程中可能會遇到的問題並如何解決，讓數據分析結果可以與企業的策略結合。'
            case 'PYTHON':
              return '資展國際特規劃「Python資料探勘與機器學習實戰班」課程，運用numpy、pandas、scikit-learn、TensorFlow及其他相關模組，以通用的資料分析流程進行(資料理解、準備、建模、評估與釋義)語音、文字、影像等資料探勘案例實作，課程內容涵蓋各式機器學習類型，方法包括頻繁型態探勘、集群、迴歸與分類等，結合運用以達成機器學習預測建模的目標。並以最適合資料科學家的Python整合式開發環境Jupyter Notebook與Spyder進行實機操作，幫助學員輕鬆上手資料探勘/機器學習的工作。'
            case 'DTM':
              return '本課程非以技術角度切入，避免過於艱深之理論，以企業應用為主，協助學員了解新科技應用的發展可能性，透過多元案例，拓展學員在不同領域應用之視野。'
            case 'SRAR':
              return '軟體開發專案失敗的原因大多在於⸢需求⸥無法掌握清楚。身為公司最為重視的棟樑之材，肩負起專案成敗與系統開發決定性影響的“系統分析師”，這麼專業又出色的您不能不知道以下這些事：『如何掌握系統分析階段的作業重點？』、『如何進行需求蒐集Requirement Gathering)規劃、需求訪談』、『如何建立分析客戶需求之能力』、『系統分析過程必要的產出(瞭解系統分析的產出文件撰寫重點與表達目的)』。'
            case 'TCD':
              return '資訊服務產業要能長久經營，除要有良好的開發能力外，還需要有良好的軟體品質及較低的維護成本，用以維持公司競爭力。其中高品質以及低維護成本，這兩者的共同要素即軟體測試。'
            case 'TUT':
              return '單元測試（Unit Testing）又稱為模組測試，是針對程式模組（軟體設計的最小單位）來進行正確性檢驗的測試工作。程式單元是應用的最小可測試部件。'
            case 'BLB':
              return '本課程將打破舊有慣性思維跳脫思考框架，延伸拓展看待事情的多角度面向，學會運用多種熱門創意思考工具，善用問題解決流程有系統地分析，培養跨領域的整合思維提升格局。'
            case 'BLA':
              return '本課程將運用顧問式銷售提問法探詢客戶需求，學會引發客戶興趣進而介紹產品特色，善用締結成交技巧成功獲得客戶訂單。'
            case 'Presale':
              return '本課程將學習如何問對問題，並創造客戶對產品的信任感、價值感及急迫性，學習如何Cook客戶預算，學習如何規劃解決方案，並撰寫建議書，學習如何撰寫解決方案簡報及一對多的簡報技巧，學習如何報價，如何第一高價得標。'
            case 'DBR':
              return '本課程將協助品牌經理人掌握數位時代下消費者動態與需求，挖掘品牌機會點，透過全方面規劃品牌，利用數位行銷強化產品體驗，短期達成銷售目標，長期提升品牌效益，設計符合潮流的品牌敘事，吸引消費者自然地與品牌互動，並建立與消費者更緊密的關係。'
            case 'PE':
              return '本課程以「高效率圖解製作」、「想法精準傳遞」、「專業圖解呈現」為三大課程目標，從認識圖解的基本原理開始，再經由大量的初階、進階等實作演練逐步提升圖解功力，歷經整個學習歷程後，將不再依賴Office所提供的SmartArt功能，並只要使用以往1/2的製作時間，就能製作出商業溝通等級的專業圖解。'
            case 'ASEO':
              return '幫助您了解最新的SEO趨勢，並學習使用新型態搜尋引擎優化您網站和內容。使包含關鍵字研究、內容優化、建立鏈接等策略，以提高您的網站排名和曝光度。'
            case 'EN':
              return '課程將學習使用Evernote來組織和管理您的工作和個人生活，並且結合九宮格思考術來激發您的創造力和思考能力。幫助您提高工作效率，提高生產力，並將您的工作和生活更好地組織起來。'
            case 'YTP':
              return '課程學習創建有吸引力的內容、制定戰略和執行計劃完成高品質視頻、編輯和上傳，利用Youtube的各種工具和功能來提高觀眾互動和收益。'
            case 'Notion':
              return '學習使用Notion創建專案管理系統、製作行銷計劃、設計流程、管理銷售，並整合ChatGPT工具，提高生產力、更有效管理時間、以及掌握現代行銷技巧和ChatGPT實戰應用。'
            case 'AIFIN':
              return '本課程除了會帶領學生深入的探討AI人工智慧的技術外，也會使用行銷及金融數據集作為本課程的範例並加以分析，課程的最後，講師也會分享行銷/金融領域在業界是如何導入AI人工智慧並將實際會發生的問題分享給學員。'
            case 'SQL':
              return '從本課程有別於微軟英文官方教材的授課形式，以業界常用的開發實務為導向，讓您熟習SQL Server 上各類的資料庫物件、功能與實作方式。例如：預存程序、觸發、函數、交易與鎖定的管理、XML 資料處理、全文檢索等等，培養您具備資料庫應用程式開發的進階實作能力。'
            case 'PMS':
              return '課程中將邀請業界具備多年大型企業產品經理、專案經理、流程經理資歷之資深經理人現身說法，解析身為產品經理應扮演的角色、應負擔的關鍵職責，以及建議需具備的核心能力與技巧，包括產品之趨勢預測、市場區隔、競爭情報分析、品牌與價格策略、新產品開發、專案管理與產品組合管理、產品行銷計畫等，以培養參訓學員成為優秀且成功的產品經理人。'
            case 'BF':
              return '本課程從「慣性思維」角度切入，透過「喚醒學員創新意識」為起點，再切入工具面的講授，且能夠運用635腦力激盪法、SCAMPER等工具。'
            case 'RDPM':
              return '使研發或技術人員能具備專案管理的正確觀念，並能善用研發專案管理的技術及工具應用，進而提升新產品研發專案的專案管理能力。'
            case 'STS':
              return '本課程將點燃學員對銷售的熱情，有效掌握關鍵銷售環節，準確運用顧問式銷售技巧，強化學員對顧客需求的探詢能力，有效處理銷售障礙並經營良好顧客關係，導入行銷實戰的策略思考，提升學員於目標市場之業務行銷力與目標達成力。'
            case 'STW':
              return '本課程旨在故事創意發想訓練，影像腳本格式撰寫。'
            case 'OCS':
              return '參加本課程將瞭解並實際運用口語溝通的秘訣及技巧，建立個人魅力，自信表達，善用口語及肢體語言技巧，達到高效溝通。'
            case 'GCP':
              return '越來越多企業選擇雲端技術管理數據和應用程式。學習使用Google Cloud Platform建立、管理和維護雲端基礎設施和應用程式。'
            case 'AIRC':
              return '本課程除了會深入的介紹各種推薦系統演算法，如CBR，CF，也會學習如何用Python，Numpy，Pandas等來實作推薦系統，增加實戰經驗。'
            case 'NBS':
              return '課程會以Spark核心技術，與串流處理技術Spark Streaming 結合第三方串流平台(Kafka、Socket等)為課程主軸，學員可以學習到如何即時處理大量串流資料，並透過Spark相關核心工具與機器學習技術加以分析出結果。'
            case 'ISRT':
              return '數位韌性的核心關鍵在「資訊安全治理」(ISG)。其 必備知識包括： ● 資安治理框架與風險管理 ● 資訊安全控制、合規管理及審計管理 ● 安全計劃管理和運營(SecOps + SIEM) ● 資訊安全核心能力(IT / OT Security + DevSecOps) 。然後依P-D-C-Improve循環，進行安全計劃管理和運營，持 續改善(ISO27014)'
            case 'MSPM':
              return '本課程從專案人員的角度講授專案管理知識，包含專案啟動、專案規劃、專案管控、專案結束等，透過專案的案例說明與實作，讓學員更能掌握專案管理的精髓，以提升專案成功機率與管理績效。'
            case 'LC':
              return '強化基層主管引導部屬激發創意的能力，並知道如何塑造部門創新文化，能夠察覺與突破自已的慣性思維。'
            case 'TDDJ':
              return '軟體開發人員要能為自己所撰寫以類別為單位的應用程式確保其正確性，那麼同時撰寫單元測試可說是必然要在日常的開發養成習慣與修鍊了。'
            case 'TDDN':
              return '軟體開發人員要能為自己所撰寫以類別為單位的應用程式確保其正確性，那麼同時撰寫單元測試可說是必然要在日常的開發養成習慣與修鍊了。'
            case 'JSS':
              return '安全性 (security) 是屬於系統的非功能性需求議題。隨着應用系統的網際網路普及化，除了網管硬體等的資安防護外，應用程式的安全性設計，包括如何發現、修正與防護程式碼的漏洞，那必然是專事安全性開發人員所應具備的技能。'
            case 'DFC':
              return '本課程將拆解、分析高難度對話的癥結問題點，提升在對話時的即時反思與利益共創，雙向強化對話中的文字與話語設計力，提升引導提問時的敏感度與應用深度，具備能促進雙向對話延續的引導思維。'
            case 'OCB':
              return '參加本課程將瞭解並實際運用口語溝通的秘訣及技巧，建立個人台風魅力，自信表達，善用口語及肢體語言技巧，達到高效影響力。'
            case 'aPHRi':
              return '本課程全程中文解說，透過實戰模擬考試進行練習，提供獨家的解題與說明，使學員快速掌握通過aPHRi考試的要領，並透過有系統的方式學會國際級人力資源管理的知識體系和標準。'
            case 'MMS':
              return '本課程將協助學員了解中階主管必須具備的管理才能藍圖，進而自我檢核與掌握努力的方向，滿足學員進階管理課程的需求，能夠無縫接軌基層主管培訓的課程內容， 提升學員的管理能力，跳脫基層主管凡事事必躬親、衝鋒陷陣的管理模式，邁向更高層次的管理境界，協助學員掌握成功授權與培育部屬的管理技巧，使自己能夠成為一位名副其實的中階主管，協助學員能夠承上啟下，有效帶領團隊達成部門目標。'
            case 'AME':
              return '課程對亞馬遜電商人才培養提供從零開始建立基本概念培訓。亞馬遜作為一個成熟的跨境電商平台，要求團隊具備完整的能力要求。課程涵蓋平台的各項功能，並理解相關領域的基本常識和資源尋求方法，從而成為具有亞馬遜基本概念的得力助手。'
            case 'AMM':
              return '課程針對台灣亞馬遜圈中的實際狀況，企業指派行銷人員、外銷人員或國貿助理進行亞馬遜業務。課程目的是幫助快速縮短與亞馬遜專業操作人員的能力差異，並正確分配學習資源。同時學習應用經驗，加速團隊業績成長。從熟悉工作內容出發，讓一線操作人員以最小學習成本擁抱亞馬遜的創新導入，以促進產業轉型的成功。'
            case 'AMH':
              return '課程旨在針對高階主管提供：從賣家視角切入市場大小、投入資金、平均收益、廣告演算法以及成功經驗分享等多個維度探討亞馬遜的運作邏輯。課程目標以實際案例和數據說明如何正確理解亞馬遜，計算投資報酬率和成功率，並避免在資源配置上受制於行銷術語的錯誤決策。'
            case 'SBP':
              return '課程旨在幫助創業者解決創業過程中的困難，包括找到商機、建立品牌形象、吸引客戶、確保收益等。內容涵蓋市場調查、品牌故事創建、顧客聯繫、客戶忠誠計劃、拓展事業版圖、多元化收入來源等。學員將學習到一系列創業技巧和策略，擴大創業視野，實現事業的成功。'
            case 'canva':
              return 'Canva是目前免費強大的流行圖像設計工具，也是一款基礎易上手的工具軟體。可快速進行圖像創作、版面設計、圖片裁剪、照片編輯等多方面創作，也可利用圖庫設計出專業圖片、達到設計個人風格等素材需求。'
            case 'PYTHONC':
              return '本課程運用numpy、pandas、scikit-learn、matplotlib、seaborn及其他相關模組，以通用的資料分析流程進行資料理解、整合、清理、轉換等介紹。課程涵蓋特徵工程簡介、特徵建構、特徵選取、特徵轉換與特徵學習，以達成學習特徵工程的目標，並做為邁向機器學習與深度學習的重要技能。課程結合多元應用案例與講師實戰經驗分享，以最適合資料科學家的Python整合式開發環境Jupyter Notebook與Spyder進行實機操作，幫助學員輕鬆上手特徵工程的工作。'
            case 'PES':
              return '本課程透過以OWASP Top 10為基礎架構的實戰練習平台，在實戰範例中了解駭客攻擊的思考邏輯和技術與手法，同時將介紹駭客是如何繞越 WAF防火牆以達攻擊成功之目的。此外，本課程也將介紹如何制定和執行Web伺服器和網頁應用程式的滲透測試方法。'
            case 'SGSISO27701':
              return 'ISO 27701:2019是植基於ISO 27001資訊安全管理標準之上的個人資訊管理標準，歐盟亦於2018年開始GDPR個資保護法令。修完此課程並通過筆試將發予SGS認可之證書。'
            case 'SGSISO22301':
              return 'SGS ISO 22301條款解析課程，藉由BCMS 系統簡介、系統導入與運作-RA, BIA, BCP的解說讓學員建立概念，結訓核發Project Management Institute (國際專案管理學會)認可之7個PDU專業學分。'
            case 'PMTD':
              return '特邀請業界實務經驗豐富的資深產品經理，讓學員瞭解在產品發想、產品開發、產品行銷等各個階段，產品經理應如何做好跨部門的溝通協調（包含會議管理、衝突管理、進度/規格管理等）。'
            case 'UML2':
              return 'UML (Unified Modeling Language)，統一塑模語言，是國際標準化且最適切表達軟體開發人員在「系統架構規劃與系統分析設計實作」的設計思維。'
            case 'UML':
              return '本課程涵蓋的需求分析範疇分為兩個層次：一為企業層級的業務流程；另一為資訊系統的系統功能。兩個層級均採以標準的 UML (Unified Modeling Language)塑模圖示語法來呈現表達。'
            case 'BCFS':
              return '學習時下最常見的區塊鏈解決方案-以太坊及HyperLedger的運作方式及架設方法。'
            case 'KMA':
              return '本課程將繁雜資訊透過「濃縮、轉換、設計」，創造出符合任務的價值產出，了解知識萃取的應用場景、目的價值、結構與設計流程、操作手，提升對資訊的敏感度，建立「蒐集、拆解、重組、濃縮」的能力。'
            case 'NFT':
              return '參加本課程將了解目前NFT技術的所有應用，瞭解及實際操作發行人生第一枚NFT，認識NFT在不同產業的應用。'
            case 'NFTB':
              return '參加本課程將瞭解NFT的投資市場，學習各種NFT投資策略，從交易、發行到獲利的投資實戰攻略。'
            case 'NOT':
              return '參加本課程將學習Notion基礎功能，製作Notion筆記，建置專屬的資料庫管理系統。'
            case 'PAI':
              return '這門課程深入探索AI繪圖創作，教授Prompt結構、指令解析和處理Negative Prompt，提升生成結果的質量。學員將使用Playground AI進行多元創作，並結合ChatGPT生成Prompt，豐富作品創意。適合視覺藝術愛好者、AI技術探索者和數位創意工作者。無論你是高中生還是專業人士，這個課程將提升你的藝術技巧和創作能力，探索AI繪圖的無限可能性。'
            case 'GPTAI':
              return '本課程透過善用ChatGPT在影音行銷中的應用，學習ChatGPT基礎、角色扮演庫建立和插件應用。實作廣告文案和影片製作，並探索剪映廣告文案AI自動生成影片。進一步瞭解剪映功能、影片編輯和微軟文字轉語音技術。適合對影音行銷和AI技術有興趣的學員，提供實用的知識和技巧，實踐創新的影音行銷策略。'
            case 'LAI':
              return '探索Leonardo AI繪圖創作班，解析其功能和特點，提升繪圖技巧和創意表達能力。實作使用Leonardo AI的繪圖工具，培養藝術創作技巧，體驗創作樂趣和無限可能性。同時，學習ChatGPT生成個性化LINE貼圖，提高情感和幽默表達，創造有趣的貼圖作品。進一步了解Leonardo AI的訓練模組，自定義AI模型，並探索Canva AI在設計領域的應用。培養設計效率和創作品質，製作專業水準的作品和視覺內容。'
            case 'AOGSM':
              return 'OGSM是一頁式計畫表，起源於1950年代的本田等汽車製造裝配線公司，可以幫助公司緊密連結願景、策略和具體目標及行動方案，成為有效的溝通工具。'
            case 'PTIME':
              return '本課程將和學員一起探討造成專案時程延誤的原因為開始，從這些原因當中歸納出主要因素，然後再針對這些主要因素逐一破解。在有限時數之內，將重點鎖定在WBS、甘特圖與專案風險管理的探討。'
            case 'SGSISO14067L':
              return '掌握國內外碳足跡發展現況；學習生命週期概念及相關碳足跡國際標準發展；ISO 14067標準解析應用目的及範疇界定、產品類別規則應用、碳足跡盤查量化。'
            case 'ESGB':
              return '本課程將學習循環經濟商業模式的知識，專注於瞭解循環經濟的商機，學員將可在此課程中了解循環經濟的整體概念！'
            case 'VisualC':
              return '在科技的驅動下，各行各業都在從根本上發展技術，而這些技術的核心部分就是程式語言，加強程式技術乃至跨語言、跨工具多元進修，增加多元環境的競爭力。從全球最通用流行的程式語言來分析，根據TIOBE整合世界各地的使用資料，釋出最新趨勢的程式語言排行榜，最熱門的 20 種程式語言，Python、C/C++、C#直穩居前五的位置，市占率高，Python 近年也受到廣泛關注，市占率也較前幾年有了快速的提升。'
            case 'C':
              return '在科技的驅動下，各行各業都在從根本上發展技術，而這些技術的核心部分就是程式語言，加強程式技術乃至跨語言、跨工具多元進修，增加多元環境的競爭力。從全球最通用流行的程式語言來分析，根據TIOBE整合世界各地的使用資料，釋出最新趨勢的程式語言排行榜，最熱門的 20 種程式語言，Python、C/C++、C#直穩居前五的位置，市占率高，Python 近年也受到廣泛關注，市占率也較前幾年有了快速的提升。'
            case 'CC':
              return '在科技的驅動下，各行各業都在從根本上發展技術，而這些技術的核心部分就是程式語言，加強程式技術乃至跨語言、跨工具多元進修，增加多元環境的競爭力。從全球最通用流行的程式語言來分析，根據TIOBE整合世界各地的使用資料，釋出最新趨勢的程式語言排行榜，最熱門的 20 種程式語言，Python、C/C++、C#直穩居前五的位置，市占率高，Python 近年也受到廣泛關注，市占率也較前幾年有了快速的提升。'
            case 'VLOG':
              return '課程將提供全面的教學。學習選擇適當的器材和相機設定，分析社交平台的風格和需求。了解主題設定、拍攝要點，涵蓋運鏡技巧、光影虛實控制，以及實戰，激發學生的潛能。課程也涵蓋製作流程，素材整理、音樂挑選、調光調色和剪輯軟體使用。最後將製作具有高流量和瘋傳潛力的影片。'
            case 'GTM':
              return '課程中將學習如何有效使用Google Tag Manager (GTM) By Website來進階管理網站數據收集和分析，讓網站數據能更有效的管理和分析。'
            case 'LINE':
              return '課程學習創建一個有效的LINE@帳戶，並學習用LINE@來促進銷售、提高顧客忠誠度和建立品牌知名度。包括LINE@的集點功能來促進參與度。'
            case 'google':
              return '課程將學習運用Google在地商家平台，透過Google Maps、Google My Business等工具，將您的商家資訊展示在Google搜尋結果頁面上，並透過行銷手段，提升商家知名度、增加客戶流量。'
            case 'DMB':
              return '想要讓數位行銷更有效率地運作，預算規劃與成效追蹤是必不可少的。課程將帶深入學習預算規劃流程，透過成效追蹤了解廣告運作狀況，並學習如何根據數據調整投放策略，提高廣告效益。'
            case 'RB':
              return '引導學員認識R語言，本課程會從R語言開發環境相關知識開始，下載相關軟體並安裝屬於自己的R環境。課程中講述R語言的一些基本函數、資料結構、迴圈之外，同時著重課堂上的實務訓練，引導學員逐步完成實作練習。'
            case 'RC':
              return '引導學員認識R語言，本課程會從R語言開發環境相關知識開始，下載相關軟體並安裝屬於自己的R環境。課程中講述R語言的一些基本函數、資料結構、迴圈之外，同時著重課堂上的實務訓練，引導學員逐步完成實作練習。'
            case 'PMART':
              return '本課程取材當今管理和領導的基本思維和理論為基礎，更融合了舉世知名史蒂芬.柯維『與成功有約』的原則架構和卡內基人際溝通的精髓。'
            case 'SGSISO14064':
              return '因應巴黎氣候協定生效及IPCC公布之SR15報告，可預期各個國家及組織將採取更積極的措施以減緩氣候變遷之衝擊。在此契機下，ISO 組織亦於2018年底將ISO 14064-1標準大幅改版，期能引導企業全面性的鑑別其溫室氣體衝擊並報告其減碳績效。本課程目的將協助學員瞭解國內外溫室氣體管理趨勢，並依據新版標準教導學員溫室氣體盤查技巧，協助企業培育其內部溫室氣體查證人員。'
            case 'SGSISO14067':
              return '碳足跡 (Carbon Footprint, CFP) 是溫室氣體管理之重要工具。ISO 14067 已於2018年8月正式從技術規範轉為國際標準，本標準除了與其他ISO標準調合外，亦強化碳足跡量化細節、數據品質要求及碳足跡研究報告之透明度。本課程將協助學員了解 ISO 14067標準核心概念與碳足跡盤查之實務技能。'
            case 'webs':
              return '課程旨在讓你從基本認識Linux系統開始，進入系統安裝和指令操作，再深入至企業網路中的重要網路服務伺服器安裝、維護管理，包括NTP、SSH、NFS、DHCP、DNS、Database、FTP、Web Server、Mail Server等。你還會學習如何強化Linux伺服器的安全監控，並模擬駭客思維進行滲透測試，找出潛在漏洞並確保符合公司的安全要求。最後，課程還包括將企業網路中的Linux伺服器遷移到Azure雲端平台，讓你了解伺服器和網路服務的雲端化優勢。這個充實的課程結合實作練習，讓你快速成為一個合格的Linux系統、網路和資安專業人員。'
            case '5GA':
              return '介紹5G行動通訊的架構和相關標準，包括5G行動網絡的核心架構、無線接入網絡、物聯網等。同時探討5G技術的關鍵特點和挑戰，如高頻譜效率、低延遲、高速率和安全性等，以及5G相關的標準組織和規範。'
            case 'startup':
              return '透過本課程，學到開店前準備，包括選點、研究市場定位、設計菜單、掌握成本控制、營銷策略、顧客服務、店面設計等相關技能。'
            case 'PL':
              return '因此本課程的設計不單強調專案管理的技術，而是探討專案領導的能力。'
            case 'RDTPM':
              return '本課程目標為學習設計研發人員的職能基準，並設計與財務指標連動的績效指標，並透過績效指標設定與管理，提升研發團隊的生產力、產值，並降低學習曲線；並進而可與薪酬管理制度結合，激勵研發人員提升產能與產值，發揮績效管理的綜效。'
            case 'ESGT':
              return '本課程將學習碳中和的知識，用CDP及TCFD找出風險跟機會，學員將可在此課程中找出公司的ESG綠色轉型商業模式。'
            case 'VLS':
              return '學習達芬奇軟體：調色、剪輯、媒體櫃、導出等核心功能，專注於基礎剪輯、調色技巧。6小時的實用課程，包括素材整理、工作流程規劃、輸出格式設定等。不含特效、音效混音。免費版本已足夠應付一般HD視頻工作流。'
            case 'FLM':
              return '課程致力於解決社交媒體內容設計的挑戰，幫助你在短時間內吸引流量並實現變現。你可能遇到影片無法吸引關注、劇本難以持續創作、宣傳產品流失率高等問題。課程將教你在短視頻中實現3秒吸睛、15秒反轉、15秒拉目的，以及快速創作內容。你將學習如何從陌生流量中展示自己，設計精準人設，植入產品，並建立變現模型。無需社交媒體經驗，適合業務、行銷、企業人員，致力於在社交媒體上吸引並轉化流量。'
            case 'AZOS':
              return '本課程使用Azure OpenAI Service，帶領學員使用企業級生成式AI服務，安全、穩定，又能客製化，並透過Azure AI Studio最新功能Prompt Flow，快速打造專屬於公司的完美回覆！'
            case 'AZLS':
              return '本課程結合Line Messaging API與Azure AI Language Service，讓你的聊天機器人升級！具備更多智慧功能，如文字情緒分析、語意判斷、多國語言翻譯、語音合成等等。'
            case 'ESGSDG':
              return '了解2030 SDGs與ESG的本質與議題的意義；從2030 SDGs game桌遊，認識世界狀態的變化與對ESG的察覺。'
            case 'MTV':
              return '本課程的目標在探討元宇宙真實世界與虛擬世界完美互動的關鍵技術，探討的結論即成為學員及公司未來技術研發的重點方針，進一步為迎接元宇宙的到來超前部署，加速元宇宙相關核心技術及產品的開發。'
            case 'LINUXC':
              return '本課程將深入探討Linux平台驅動程式開發技術。課程安排採用Raspberry Pi硬體實習環境，兼具理論與實務經驗，使學員在開發Linux系統產品時能夠掌握技術重點，成功達到嵌入式軟硬體系統整合的開發目標。'
            case 'FPGA':
              return '本課程的特色在於由淺而深、循序漸近的探討Verilog HDL FPGA及晶片開發的設計理念，並搭配精彩而簡易的設計範例，實際的在Vivado電路模擬與合成軟體及Xilinx FPGA硬體板上徹底的實習數位電路系統設計，完成訓練之後可加入開發FPGA電路及晶片等相關設計行列。'
            case 'GPTPPT':
              return '這門AI簡報設計課程將教您使用ChatGPT和AI創意工具，快速製作令人印象深刻的簡報。課程內容包括文字編輯、多媒體運用、色彩搭配、版面設計等。您將學會生成內容大綱、整理文字、運用色彩原則、並取得高品質圖片。此外，我們將介紹多種AI工具，加速簡報製作，讓您的簡報更具創意和效果。最終，您將能自信地運用AI工具，製作出極具專業水準的簡報。克服簡報困難，立即報名參加課程，展現簡報設計的新境界！'
            case 'AIDESIGN':
              return '這課程探討人工智慧（AI）在藝術和設計中的應用，強調AI技術和創意的結合。學員將學習基本AI理論和實際技能，並探討不同設計領域的潛力，包括產品設計、網頁UI/UX、室內設計等。課程結合案例研究和實際操作，培養跨領域思維，鼓勵創新設計。'
            case 'BLENDER':
              return '這門課程教授Blender 3D軟體的基本應用，適合初學者，涵蓋介面操作、物件建模、材質應用、以及最終輸出作品。學員將透過實際練習，快速掌握Blender的技巧，能夠創建自己的3D模型和場景。無論你是想進入3D設計領域，或者是遊戲開發者、動畫師、影片編輯者，這門課程都適合你。'
            case 'TRIZ':
              return '特別舉辦TRIZ(萃智)培訓課程，希望透過TRIZ系統性創新思考方式及TRIZ創新工具，快速提升學員突破性創新解題能力。'
            case 'BMD':
              return '探討如何應用商業模式創新的工具與手法來幫助現行企業，就其商業模式重新規劃設計進，以突破目前營運的框架與困境。'
            case 'GPTDA':
              return '這門ChatGPT入門課程適合初學者、職場尋求提升的人士，以及對人工智慧感興趣者。我們將深入探討ChatGPT的運作原理、版本區別，並教授正確使用指令和Prompt技巧。課程包括多樣化的實際應用案例，從虛擬面試伙伴到資料分析，並介紹各種外掛應用。這個課程將幫助您充分利用ChatGPT，提升溝通能力，改善職場表現，並在人工智慧領域獲得寶貴的知識。無論您的經驗水平如何，都能受益匪淺。'
            case 'GPTExpert':
              return '協助教育與知識工作者運用 ChatGPT。目標為掌握 AI 對話、人機協作，優化教案備課。適合各級教師及對知識工作有興趣者，無特定先備知識要求，但授課經驗者更佳。內容涵蓋講師備課流程、使用 ChatGPT 協助備課，以及提問詠唱技巧。課程包括文本、講述和模擬三大方向，涵蓋課程需求分析、教材設計、時事案例融入等，並提供實際演練，生成教案、教材，模擬師生問答。'
            case 'GPTExcel':
              return '課程將全面介紹AI技術 ChatGPT在Excel中的各種應用。學員將首先了解AI和Prompt Engineering的基本概念,並複習Excel的基礎知識。接著將通過大量實際操作案例,學習使用ChatGPT自動生成Excel公式、函數、圖表等內容。課程將分階段講解ChatGPT的各項功能運用,從基礎到進階,最終達到綜合運用ChatGPT提升Excel工作效率。學員將在短時間內大幅提升Excel技能,並學會使用AI輔助完成Excel相關工作。'
            case 'AIMedia':
              return '自媒體競爭激烈的環境下,正確經營自媒體以獲得流量為關鍵。課程將從診斷自媒體經營痛點入手,利用ChatGPT精準定位內容主題,並依據不同視角大量自動生成優質內容。我們還將運用Canva AI設計精美的活動主視覺,全方位提升自媒體的品牌影響力。最後,課程將分享建立自動化經營流程的方法,讓你的自媒體在AI工具的助力下,實現由手工到智能化升級,輕鬆獲得更多曝光量。本課程系列讓你掌握AI時代自媒體成功經營之道。'
            case 'CFS':
              return '企業獲利的關鍵在於掌握財務三表背後的運作規律。課程將從淺入深講解財務三表的關聯性,讓你快速理解企業的獲利方程式。我們將解析資產負債表的流動性與財務結構特點,並講解如何利用成本控制和市場分析實現財務目標。你還將學會通過損益表進行經營分析,以及如何提高現金流量的利用率。本系列課程將提升你的財務思維,讓你能從數字分析企業經營狀況,為企業經營決策提供有力支撐。'
            case 'AIMoney':
              return '在短影音盛行的時代,如何利用這一新媒體為個人或品牌增值,是重要的行銷技能。本課程系列將全面介紹短影音的經營策略,包括品牌推廣、產品銷售、內容變現等。我們將分享利用短影音提升知名度和商業價值的技巧,講授製作引人注目的短影音廣告的方法。你還可以學到如何將內容變現,實現商業價值;以及建立個人影響力的短影音營銷手段。我們更提供主題搜集和管理的技能培訓。本課程助你掌握短影音經營的關鍵,在新媒體時代脫穎而出!'
            case 'BMA':
              return '面對品牌細分和消費者個性化,品牌需突破傳統框架應對新世代挑戰。成功轉型需從品牌行銷全貌入手,在定位、傳播、渠道、用戶體驗等方面優化。正確定位差異化品牌個性與價值主張,利用各類廣告傳播提升知名度與美譽度。隨著電商時代,利用電商平台強化品牌影響力也很重要。需要不斷增強品牌價值,使其在長期發展中積累吸引力。只有整體把握品牌行銷之道,才能在複雜變化的市場中持續成長。'
            case 'GPTM':
              return '隨著ChatGPT等新型AI技術的興起,利用AI為商業品牌增值已成為重要課題。課程將探討ChatGPT在品牌建設中的多種應用,包括利用ChatGPT自動產生品牌營銷內容、優化品牌形象策略、智能設計廣告創意、提供電商運營建議等。學員將全面了解ChatGPT的原理、功能與限制,通過大量實戰案例,學會使用ChatGPT提升品牌營銷效果、優化用戶體驗、創新增長點。本課程助你在AI時代掌握品牌運營新工具,使品牌更智慧、更有影響力。'
            case 'PHPSQL':
              return '透過完整含蓋前端、後端與資料庫技術內容，讓學員逐步瞭解並熟悉網站的運作與開發方式。透過循序漸進的教學與實作，協助學員建置自己的開發環境，並完成一個簡易的商業網站製作。'
            case 'DTAI':
              return '本課程通過課程學習，參與者不僅能夠全面理解設計思考，還能夠掌握利用ChatGPT等工具進行創新的實際操作技巧。'
            case 'WGPT':
              return '學員能夠運用ChatGPT提昇自已的生產力與工作效率。'
            case 'ISO50001':
              return '本課程是以ISO 50001國際標準版為基礎，課程中不僅介紹標準條文細部要求及案例，並介紹組織導入此系統時可參考的建置流程與既有管理系統(ISO 9001&ISO 14001)的整合。'
            case 'ISO50001L':
              return ''
            case 'ESGM':
              return '了解2030 SDGs與ESG系統觀與國際趨勢'
            case 'ESGS':
              return 'ESG影響力商業模式/永續策略價值體系建立'
            case 'ESGP':
              return '了解如何不花大錢可以製作一份簡易永續報告書'
            case 'GPTEXCEL':
              return '課程專注於結合下指令處理技術與數據分析能力。您將學會運用ChatGPT的強大功能，探討Excel的數據處理技巧，從基本到高階應用，讓您能夠靈活處理各種數據情境。教授如何整合ChatGPT與Excel，實現自動化流程和程式碼整合。無論您是想提升工作效率，還是探索人工智慧應用，這個課程都將為您打開新的可能性，讓您輕鬆掌握這兩個強大工具的精髓。'
            case 'CCB':
              return '(ISC)² Certified in Cybersecurity（CC）是(ISC)²推出的入門級網路安全認證，目的在為非技術背景的人員提供進入網路安全領域的途徑。'
            case 'AITL':
              return '本課程以Edge AI、TinyML、及Mobile AI為主題，依序說明邊緣裝置的縮裝技巧及調用ChatGPT API的方法。'
            case 'GAI':
              return '本課程的目標在奠定學員學習當代生成式AI技術的理論基礎與應用。'
            case 'SIAMF':
              return '探討 SIAM 與其他管理實踐之間的關係，強調 SIAM 如何與 ITIL 等框架保持一致，並提升整體服務交付能力。'
            case 'SIAMP':
              return 'EXIN SIAM Professional 課程將圍繞著 SIAM 的各個階段來帶領學員熟練 SIAM 的各項核心原則。'
            case 'LLMC':
              return '本課程另有提及Meta，發表了Llama 2及Llama 3可下載至本機執行，再加上LangChain提供將LLM model、RAG、Prompt等技巧。'
            case 'AITOOLS':
              return '人工智能浪潮來臨,運用AI提升工作效率勢在必行。本課程引領學員掌握ChatGPT、Claude、Google Gemini和Copilot等主流AI應用,prompt技巧,透過角色扮演策略發揮AI潛能。全面實戰演練,運用AI文字創作、資料處理和內容生成,大幅提升工作效率和創造力。'
            default:
              return ''
          }
        })()}
        url={url}
      ></MetaDecorator>
      {/* 不同的情況下改變轉址 */}
      {redirectTo === 'OJT' && <Redirect to="/OJT" />}

      {/* 在職班課程主圖 ↓↓↓　*/}
      <div className="container-fluid p-0">
        <div className="col p-0 position-relative">
          {/* <div className="breadcrumbs-content_classification">
            {!breadCrumbBool && <MultiLevelBreadCrumb />}
          </div> */}
          <div className="banner__container">
            <div className="banner__titleC">
              <div className="bannerTitle__black">
                <h1>
                  {classes.length !== 0
                    ? classes[0].classTypeID === 'MTI310T'
                      ? '網路行銷規劃師三部曲'
                      : classes[0].className === 'PMP專案管理師認證(線上課程)'
                      ? 'PMP專案管理師認證'
                      : classes[0].classTypeID === 'MSA502T'
                      ? 'GCP (Google Cloud Platform) 系列課程'
                      : classes[0].classTypeID === 'MSA503T'
                      ? 'GCP (Google Cloud Platform) 系列課程'
                      : classes[0].classTypeID === 'MSA504T'
                      ? 'GCP (Google Cloud Platform) 系列課程'
                      : classes[0].classTypeID === 'MSA488T'
                      ? 'GCP (Google Cloud Platform) 系列課程'
                      : classes[0].classTypeID === 'MSA489T'
                      ? 'GCP (Google Cloud Platform) 系列課程'
                      : classes[0].classTypeID === 'MSA490T'
                      ? 'GCP (Google Cloud Platform) 系列課程'
                      : classes[0].classTypeID === 'ZST302T'
                      ? 'Color Grading 調色師系列課程'
                      : classes[0].classTypeID === 'CMA205T'
                      ? 'Color Grading 調色師系列課程'
                      : classes[0].className
                    : classType.classTypeID === 'MTI310T'
                    ? '網路行銷規劃師三部曲'
                    : classType.classTypeID === 'MSA502'
                    ? 'GCP (Google Cloud Platform) 系列課程'
                    : classType.classTypeID === 'MSA503'
                    ? 'GCP (Google Cloud Platform) 系列課程'
                    : classType.classTypeID === 'MSA504'
                    ? 'GCP (Google Cloud Platform) 系列課程'
                    : classType.classTypeID === 'MSA488'
                    ? 'GCP (Google Cloud Platform) 系列課程'
                    : classType.classTypeID === 'MSA489'
                    ? 'GCP (Google Cloud Platform) 系列課程'
                    : classType.classTypeID === 'MSA490'
                    ? 'GCP (Google Cloud Platform) 系列課程'
                    : classType.classTypeID === 'ZST302'
                    ? 'Color Grading 調色師系列課程'
                    : classType.classTypeID === 'CMA205'
                    ? 'Color Grading 調色師系列課程'
                    : classType.classTypeName}
                  {/* {classes.length !== 0
                    ? classes[0].className
                    : classType.classTypeName} */}
                  {/* {classes.classTypeName} */}
                </h1>
                {/* <h6 className="main-picture_ch">{longTermClasses.length === 0 ? '' : longTermClasses[0].className}</h6> */}
                {/* <div className="classification-picture_line"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 在職班課程主圖 ↑↑↑　*/}

      {/* 報名說明文字, 課程資訊表格 ↓↓↓　*/}
      <div className="container-fluid p-0 ojt-page">
        <div id="ojt-page">
          <LinkScroll />

          {/* 報名課程資訊 ↓↓↓　*/}
          <TrainingContactInfo
            classesMentor={classMentor}
            mentor={classMentor.length === 0 && mentor}
            classMaintainers={classMaintainer}
            maintainers={classMaintainer.length === 0 && maintainer}
          />
          {/* 報名課程資訊 ↑↑↑　*/}

          {/* edm內容課程介紹 */}
          <ClassDetailInstruction
            classesName={classes.length !== 0 && classes[0].className}
            classTypeName={classType.classTypeName}
            classInfo={classes.length !== 0 && classes[0]}
            classTypeContent={classType}
          />
          {/* 課程說明 ↑↑↑　*/}

          {/* 開課資訊 ↓↓↓　*/}
          <TrainingClassInfoList />
          {/* 開課資訊 ↑↑↑　*/}

          <ClassmateQuestion classTypeName={classType.classTypeName} />
        </div>
        {/* 報名說明文字, 課程資訊表格 ↑↑↑　*/}
      </div>
    </>
  )
}
export default TrainingClasses
