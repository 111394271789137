import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// Java轉職班詳細內容
function PWOJTJT() {
  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  return (
    <>
      {/* <div className="classContent__figure">
        <img src="/img/employment/AI_head.png" alt="" />
      </div> */}
      <div className="txtC__div text-justify mt-0">
        <div className="employmentClassification-instruction_p">
          <p>
            您是否畢業後尋尋覓覓，一直沒找到合適的工作？在萎靡不振的就業市場中，究竟何去何從？
          </p>
          <br />
          <p>
            不是您能力不足，只是沒找到正確的管道幫助自己而已。請您來參加我們的課程說明會，
            讓業界的前輩們幫您開創自己的一片蔚藍大海，遨遊到一個您從未想過的新天地！
          </p>
          <br />
          <h4>不怕您學，就怕您不學</h4>
          <br />
          <p>
            我們會從最基礎的Java語法開始教起，一直到業界目前最常使用Vue、Spring等應用程式框架，學員只要透過本班精心規劃的課程按部就班地學習，再經由期末專題的實作加以整合，以此來證明自己的技術能力，如果能夠再結合原有的專長，一定能夠創造個人獨特的專業價值，打造夢寐以求的未來。
          </p>
          <br />
          <h4>符合用人需求，增加就業籌碼</h4>
          <br />
          <p>
            以現在的職場工作而言，有哪一個程式設計師可以完全不碰資料庫？有哪一個程式設計師可以完全不碰網頁製作？所以囉，整個課程的設計，雖然是以Java為主軸，但是我們的終極目標，是要培養一個能夠參與業界專案開發的優秀程式設計師！所以，不管是基本的作業系統管理、網頁開發、資料庫設計、軟體工程等，這一切的一切，都是一個程式設計師該會的基本馬步功夫！
          </p>
          <br />
          <h4>提供就業輔導，工作免煩惱</h4>
          <br />
          <p>
            在您認真學習，取得優秀成績，做出夠水準的專題之後，空有一身好本領，可是找不到知人善任的伯樂老闆，怎麼辦？沒關係，資策會有專門的人員幫您進行就業輔導。您可以專心於學習之中，由我們為您打點相關的就業輔導事宜。
          </p>
        </div>
        {/* <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          由淺入深、技術扎根
        </h4>
        <p className="employmentClassification-instruction_p">
          根據 TIOBE
          程式語言的排行榜，自從人工智慧的應用興起，Python已經盤據最熱門的程式語言一段時間了，對於初學者來說它有簡單易學的特性，想要進入軟體開發相關的領域從
          Python
          入門比較不會有挫折感，Python的應用也非常的全面，從測試自動化、遊戲開發、網頁開發、資料分析、區塊鍊到人工智慧等，都可以透過Python找到解決方案。因此本課程主要就是以
          Python 為核心，學習 Python 的相關知識與應用，從基礎的 Python
          語言教起，學習網頁開發的相關應用技術，再循序漸進的學習資料分析、機器學習、深度學習等引導學員進入人工智慧的奇幻旅程。專題的部分會結合網頁及AI應用，開發出能夠自我學習、推理、解決問題的應用系統，並透過網頁讓使用者可以使用。
          <br />
          學員只要透過本班精心規劃的課程按部就班地學習，再經由期末專題的實作加以整合，以此來證明自己的技術能力，如果能夠再結合原有的專長，一定能夠創造個人獨特的專業價值，打造夢寐以求的未來。
        </p> */}
      </div>

      {/* 課程介紹 */}
      <div>
        <div className="pb-4">
          <h6>
            <strong>【課程目標】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              本訓練目標為使學員能習得動態網頁開發的相關知識、Java程式設計開發、關聯式資料庫相關知識、Spring框架、Vue前端框架等專業知識與技能。
            </li>
            <li>
              1）培養學員能夠運用網站前端HTML、CSS、JavaScript技術以及Vue框架，結合後端Java技術以及Spring框架，製作出動態網頁應用程式
            </li>
            <li>
              2）培養學員能夠進行Java程式開發、關聯式資料庫設計、前後分離應用程式架構等創新應用
            </li>
            <li>
              3）培養學員能夠運用docker技術進行應用程式建置與部署，並搭配jenkins工具將建置與部署流程自動化
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              從零開始！本班從Java程式設計、JavaScript語法入門開始，一路引領您實作到動態網頁應用程式開發、建置與部署。
            </li>
            <li>
              專題導向！
              透過專題小組的團隊合作以及大、小專題的歷練，引導您完成期末專題並上台進行成果發表。
            </li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure pb-3">
          <img src="/img/careers/Java_PWOJTJT_Taipei.png?240319" alt="" />
        </div>
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              周間晚上
              (線上或實體)，六日一天上課(實體7小時)，實際情況將會因配合講師安排為主
            </li>
          </ul>
        </div>
      </div>
      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>適合對象</h4>
                <div className="longTermTabC__div">
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對於使用Java程式語言與網站開發有興趣者
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對於使用Vue框架的網站前端開發有興趣者
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對於網站前後端整合開發應用以及DevOps軟體開發實務有興趣者
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員能夠運用網站前端HTML、CSS、JavaScript技術以及Vue框架，結合後端Java技術以及Spring框架，製作出動態網頁應用程式
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員能夠進行Java程式開發、關聯式資料庫設計、前後分離應用程式架構等創新應用
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        培養學員能夠運用docker技術進行應用程式建置與部署，並搭配jenkins工具將建置與部署流程自動化
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">
                    Java跨平台應用程式設計師
                  </li>
                  <li className="longTerm_tabContent_li">
                    Java動態網頁程式設計師
                  </li>
                  <li className="longTerm_tabContent_li">ISP網站程式設計師</li>
                  <li className="longTerm_tabContent_li">
                    企業內部應用系統開發人員
                  </li>
                  <li className="longTerm_tabContent_li">
                    Outsourcing專案委外程式設計師
                  </li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </nav>
      </div>
      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default PWOJTJT
