import React, { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-scroll'

// 雲端就業養成班詳細內容, 20230901修改
function CloudTechnologyClass(props) {
  const { longTermClass } = props

  // 課程說明active預設狀態
  const [key, setKey] = useState('content')

  // 課程內容資訊打開與隱藏狀態
  const [switchCss1, setSwitchCss1] = useState(false)
  const [switchCss2, setSwitchCss2] = useState(false)
  const [switchCss3, setSwitchCss3] = useState(false)
  const [switchCss4, setSwitchCss4] = useState(false)
  const [switchCss5, setSwitchCss5] = useState(false)
  const [switchCss6, setSwitchCss6] = useState(false)
  const [switchCss7, setSwitchCss7] = useState(false)
  const [switchCss8, setSwitchCss8] = useState(false)
  const [switchCss9, setSwitchCss9] = useState(false)

  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [open5, setOpen5] = useState(false)
  const [open6, setOpen6] = useState(false)
  const [open7, setOpen7] = useState(false)
  const [open8, setOpen8] = useState(false)
  const [open9, setOpen9] = useState(false)

  // 展開詳細內容狀態
  const handleOpen1 = () => {
    setSwitchCss1(!switchCss1)
    setOpen1(!open1)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen2 = () => {
    setSwitchCss2(!switchCss2)
    setOpen2(!open2)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen3 = () => {
    setSwitchCss3(!switchCss3)
    setOpen3(!open3)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen4 = () => {
    setSwitchCss4(!switchCss4)
    setOpen4(!open4)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen5 = () => {
    setSwitchCss5(!switchCss5)
    setOpen5(!open5)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen6 = () => {
    setSwitchCss6(!switchCss6)
    setOpen6(!open6)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen7 = () => {
    setSwitchCss7(!switchCss7)
    setOpen7(!open7)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss8(false)
    setOpen8(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen8 = () => {
    setSwitchCss8(!switchCss8)
    setOpen8(!open8)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss9(false)
    setOpen9(false)
  }

  const handleOpen9 = () => {
    setSwitchCss9(!switchCss9)
    setOpen9(!open9)
    setSwitchCss1(false)
    setOpen1(false)
    setSwitchCss2(false)
    setOpen2(false)
    setSwitchCss3(false)
    setOpen3(false)
    setSwitchCss4(false)
    setOpen4(false)
    setSwitchCss5(false)
    setOpen5(false)
    setSwitchCss6(false)
    setOpen6(false)
    setSwitchCss7(false)
    setOpen7(false)
    setSwitchCss8(false)
    setOpen8(false)
  }

  return (
    <>
      <div className="classContent__figure">
        <img src="/img/employment/Cloud Network_head.png" alt="" />
      </div>

      <div className="txtC__div text-justify">
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          雲端產業人才何處尋？
        </h4>
        <p className="employmentClassification-instruction_p">
          一個被喻為即將『改變全球10億電腦使用人口生活、顛覆全球網路版圖』的雲端運算，近來風起雲湧，各國政府和國際大廠紛紛投入龐大資源。國內行政院決議成立「雲端運算產業發展指導小組」，預計在5年內投入240億元，促成1000億台幣投資，創造5萬名就業機會
          ; 然而，想要搶食這塊大餅，人才技能仍舊是廠商的最大隱憂，但您知道嗎？
          雲端服務所需的技能，其實在本班的訓練課程裡。
        </p>
        <h4 className="employmentClassification-instruction_h4 employmentClassification-instruction_mb">
          賀 廠商大徵才，網工班結訓發表會，感謝眾多廠商蒞臨~搶人才
        </h4>
        <p className="employmentClassification-instruction_p">
          奇毅資訊、凌群電腦股份有限公司、LANcom、中國人壽、泰偉電子、敦新科技
          (聚碩科技)、
          (法商)大潤發、明門實業股份有限公司、睿訊有限公司、蘇羅士科技股份有限公司、冠新資訊、
          達凱科技有限公司、伯仲國際股份有限公司、聯易科技、技成科技、顯榮國際、軍崴科技、
          中華電信企業客戶分公司、群環科技股份有限公司、富邦金控
          富邦人壽保險(股)公司、
          中華系統整合股份有限公司、飛泓科技、擎昊科技股份有限公司、中華數位、台灣固網、北祥
        </p>
      </div>

      {/* 課程介紹 */}
      <div>
        <div className="pb-4">
          <h6>
            <strong>【課程目標】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              本班以就業為導向，資展國際以精良的學習設備及優秀的師資帶領您成為資訊行業真正需要的電腦及網路技術人才，結訓後由本中心輔導就業，可立即從事網路及系統專業領域之維護及支援工作。
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <h6>
            <strong>【班級特色】</strong>
          </h6>
          <ul className="listStyle__ul">
            <li>
              本班以取得工作為導向，以學習IT產業之最新產品、技術為目標，帶領您跨越電腦、系統、網路等相關硬體與軟體設計開發，使您成為全方位系統整合工程師，跨足雲端產業，開創個人生涯新契機
            </li>
            <li>
              課程由淺入深、循序漸進，理論與實作並重，塑造群組學習與團隊合作，培育學員有效提升學習技巧與效率
            </li>
            <li>
              提供專業級網路及軟硬體設備(網路配線、Cisco Router & Switch,
              Windows Server 2019,
              Linux,虛擬化平台等)，強調做中學，培養動手實作能力
            </li>
            <li>
              雲端技術的導入，整合虛擬化平台、網路虛擬化、儲存裝置虛擬化、桌面虛擬化等，創造獨當一面之整合人才，展現軟硬兼具、與眾不同的才能
            </li>
            <li>
              全國首創融入AI智慧、網路管理系統與資訊安全整合課程，讓您掌握最新IT技術，成為熱門資訊人才
            </li>
            <li>
              輔導考取國際級專業認證 (Cisco CCNA、LPIC-1 )，展現最佳學習成效
              (往年參訓學員平均每人取得二張以上國際證照)
            </li>
            <li>
              企業應用專題模擬，邀請徵才廠商參與學員專題簡報與實作演練，不但訓練簡報能力，更與廠商零距離接觸，展現實力，增加就業媒合的成功率
            </li>
            <li>
              履歷表撰寫與職場倫理講座，提供學員模擬面談指導，全方位提升學員就業職能
            </li>
          </ul>
        </div>
      </div>
      {/* 課程介紹 */}

      {/* 課程內容 */}
      <div>
        <div className="content__title">
          <h3>課程內容</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        <div className="classContent__figure">
          <img
            src={
              longTermClass && longTermClass.taxCode !== ''
                ? `/img/employment/syllabus/CI_JNSESET_${(() => {
                    switch (longTermClass.address.substring(0, 2)) {
                      case '台北':
                        return 'Taipei'
                      default:
                        return ''
                    }
                  })()}_C2E2302.png?230901`
                : '/img/employment/syllabus/CI_JNSESET_Taipei.png?230901'
            }
            alt=""
          />
        </div>
        <p className="red pt-3 mb-3">※課程內容以地區班級為準。</p>

        {/* 展開詳細介紹 ↓↓↓　*/}
        <div className="mt-4 pb-5">
          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen1()}
              aria-controls="example-collapse-text"
              aria-expanded={open1}
              className={
                switchCss1
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              電腦概論與硬體基礎維護
            </button>
          </div>
          <Collapse in={open1}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  各項電腦元件規格介紹，
                  由淺入深讓您更有系統的了解，以及作業系統安裝和大量佈署，有基礎的同學也能體會，企業環境的建置情形
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen2()}
              aria-controls="example-collapse-text"
              aria-expanded={open2}
              className={
                switchCss2
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              區域網路運作與
              <br className="brNoneXxs" />
              故障排除實務
            </button>
          </div>
          <Collapse in={open2}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  由實務入門，讓您動手自己壓製網路線開始,
                  體驗網路工程師的生活，進而帶您進入網路世界，各種通訊協定介紹
                  (TCP/IP，Ethernet，DHCP，DNS，HTTP等)，封包擷取實作分析，更能體會網路運作的原理，打下穩固的基礎
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen3()}
              aria-controls="example-collapse-text"
              aria-expanded={open3}
              className={
                switchCss3
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              網路規劃建置與管理
            </button>
          </div>
          <Collapse in={open3}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  這是進入網路領域最重要的一步，完整的課程及詳細的教學，更充分的時數及練習實作，引導您了解在中小企業網路架構中，一步一步將網路系統建置起來，交換器及路由器各項整合技術，讓您順利取得
                  CCNA 證照，更有真正的能力建置中小企業網路環境
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen4()}
              aria-controls="example-collapse-text"
              aria-expanded={open4}
              className={
                switchCss4
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              高可用網路服務建置
            </button>
          </div>
          <Collapse in={open4}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  面對網路時代，企業運作將是無國界與24小時永不停頓的服務，透過本班的訓練讓您具備企業需求的專業技術，擁有建構企業級的高可用系統的能力
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen5()}
              aria-controls="example-collapse-text"
              aria-expanded={open5}
              className={
                switchCss5
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              雲端運算介紹與虛擬化
              <br className="brNoneXxs" />
              技術建置與管理
            </button>
          </div>
          <Collapse in={open5}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  雲端運算與虛擬化的基本概念，常見的虛擬化技術分析介紹，採用虛擬化元件創建、部署、啟動虛擬化解決方案的方法，以及業界幾家重要的虛擬化廠商的產品和特性
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen6()}
              aria-controls="example-collapse-text"
              aria-expanded={setOpen6}
              className={
                switchCss6
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              作業平台與網路應用服務
            </button>
          </div>
          <Collapse in={open6}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  1.
                  Windows系統是目前市場上最普及的作業系統，本課程採微軟MCITP的部分官方標準課程，除了有系統的課程內容以外，更輔導同學取得微軟認證，從實務的角度切入，完整詳盡的一步一步讓同學吸收
                  <br />
                  2.
                  Linux是目前除了微軟系統外，使用率最高，也最是企業尋求成本下降的解決方案，而且在企業建置資訊系統時，通常採用的架構，都是混合式環境(Windows、Linux、Unix)，所以本班在Linux訓練強調的是架構觀念與指令的練習
                  <br />
                  3. 網際網路的應用伺服器，Mail Server及Web
                  Server幾乎是每家公司都會建置的網路服務，本班針對重量級網路服務，皆有詳細的課程介紹，讓同學作更深入的了解與實作練習，更貼近企業的實際使用需求
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen7()}
              aria-controls="example-collapse-text"
              aria-expanded={open7}
              className={
                switchCss7
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              資訊安全
            </button>
          </div>
          <Collapse in={open7}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  由資訊安全的理論入門，網際網路會面臨的網路攻擊和威脅，及各種驗證加密技術的介紹說明，透過實作建立公司的防火牆，讓同學一步一步建立起公司最重要的安全防護機制，由基礎到進階全面性的了解資訊安全的架構
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header">
            <button
              onClick={() => handleOpen8()}
              aria-controls="example-collapse-text"
              aria-expanded={setOpen8}
              className={
                switchCss8
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              就業輔導與專案管理概論
            </button>
          </div>
          <Collapse in={open8}>
            <div id="example-collapse-text" className="lesson-body">
              <div className="lesson-content">
                <h6>
                  指導同學履歷自傳的寫作，更提醒大家面試的重點跟技巧，讓同學能充分了解職場現狀，掌握更多優勢
                </h6>
              </div>
            </div>
          </Collapse>

          <div className="employmentClassification-instruction_header lesson-header-last">
            <button
              onClick={() => handleOpen9()}
              aria-controls="example-collapse-text"
              aria-expanded={setOpen9}
              className={
                switchCss9
                  ? 'Mblesson-instruction_button opened'
                  : 'Mblesson-instruction_button closed'
              }
            >
              結訓專題製作
            </button>
          </div>
          <Collapse in={open9}>
            <div id="example-collapse-text" className="lesson-body-last">
              <div className="lesson-content">
                <h6>
                  由專案管理的角度，將所有的課程模擬成一實際案例，同學將扮演不同角色，將所學完全整合，做一完整實現，也訓練同學進行簡報的技巧，讓課程的成果有同學親自展示給與會嘉賓聆聽
                </h6>
              </div>
            </div>
          </Collapse>
        </div>
        {/* 展開詳細介紹 ↑↑↑　*/}
      </div>
      {/* 課程內容 */}

      {/* 相關資訊 */}
      <div>
        <div className="content__title">
          <h3>相關資訊</h3>
          <div className="line__div--light">
            <div className="lease-content_line"></div>
          </div>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">招生簡章</div>
          <ul className="classInfoList__styleNone">
            <li>
              <a
                href="/pdf/資展國際_雲端網路與資安工程師就業養成班_招訓簡章.pdf"
                className="orange"
                target="_blank"
                download
              >
                簡章下載<i className="fas fa-download"></i>
              </a>
            </li>
          </ul>
        </div> */}
        <div className="pb-4">
          <div className="subT__div">招生對象</div>
          <ul className="classInfoList__styleNone">
            <li>
              有志於從事網路、伺服器管理、雲端服務及資訊安全相關工作，可全心投入學習，積極希望建立就業所需之專業知識者。
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <div className="subT__div">就業方向</div>
          <ul className="classInfoList__styleNone">
            <li>1）資訊系統或設備開發商(原廠)：維護工程師、支援工程師</li>
            <li>
              2）資訊系統整合公司(SI)：網路/系統整合工程師、資訊安全工程師
            </li>
            <li>
              3）網路服務商(ISP)、網路資料中心(IDC)：網路工程師、企業客服工程師
            </li>
            <li>4）各產業資訊部門(MIS)：網路/系統工程師、資訊安全工程師</li>
          </ul>
        </div>

        <div className="pb-4">
          <div className="subT__div">上課時段</div>
          <ul className="classInfoList__styleNone">
            <li>
              星期一到五白天、晚上皆會排課，因配合講師安排，部份課程會於週末假日上課
            </li>
          </ul>
        </div>
        {/* <div className="pb-4">
          <div className="subT__div">Q&A</div>
        </div> */}
      </div>

      <div className="content__div--mb">
        <nav
          id="controlled-tab-example"
          activeey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tabs defaultActiveKey="apply" className="longTerm_tabs">
            <Tab eventKey="apply" title="報名說明" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>報名資訊</h4>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    正式報名課程之前需先聽過說明會
                  </h5>
                  <h6>
                    說明會皆採線上報名(免費報名)，再參訓課程之前必須先聽過該課程之說明會，才能夠報名課程。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">
                    說明會之後確定想報名課程
                  </h5>
                  <h6>
                    參加過說明會之後，若確定想參訓課程，與地區承辦人員再次確認報名後，始可繳費。
                  </h6>
                </div>
                <div className="longTerm_tabInner_div">
                  <h5 className="longTerm_tab_span">各地區課務人員</h5>
                  <h6>
                    進一步了解更多資訊，請洽
                    <Link
                      className="p-0"
                      activeClass="active"
                      to="contactCourseWide"
                      spy={true}
                      smooth={true}
                    >
                      各區課務人員
                    </Link>
                    。
                  </h6>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="object" title="招生對象" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>3D遊戲美術設計人才養成班適合對象</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img src="/img/employment/Java_illustrator.png" alt="" />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">熟悉電腦基本操作</h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        對3D遊戲美術設計有強烈學習意願及興趣
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        美術或設計相關科系畢業者尤佳
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        男女不拘，但男性需役畢或免役
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="target" title="課程目標" className="longTerm_tab">
              <div className="longTerm_tab_div">
                <h4>課程目標</h4>
                <div className="longTermTabC__div">
                  <figure>
                    <img
                      src="/img/employment/JavaTarget_illustrator.png"
                      alt=""
                    />
                  </figure>
                  <div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        解3D遊戲業界專案設計流程
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        學習3D遊戲美術實務與專業遊戲技術應用，以Unity整合美術與互動設計
                      </h5>
                    </div>
                    <div className="longTerm_tabInner_div">
                      <h5 className="longTerm_tab_span">
                        具備遊戲專題製作能力
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="job" title="就業方向" className="longTerm_tab">
              <div className="longTerm_tab_div w-100">
                <h4>未來就業方向</h4>
                <ul className="online-card_content">
                  <li className="longTerm_tabContent_li ">SOHO接案</li>
                  <li className="longTerm_tabContent_li">獨立製作</li>
                  <li className="longTerm_tabContent_li">出國升學</li>
                  <li className="longTerm_tabContent_li">職場就業</li>
                  <li className="longTerm_tabContent_li">3D遊戲美術設計</li>
                  <li className="longTerm_tabContent_li">3D角色製作</li>
                  <li className="longTerm_tabContent_li">3D場景製作</li>
                  <li className="longTerm_tabContent_li">3D角色動作</li>
                  <li className="longTerm_tabContent_li">遊戲特效設計</li>
                  <li className="longTerm_tabContent_li">3D動畫設計</li>
                </ul>
              </div>
            </Tab> */}
          </Tabs>
        </nav>
      </div>

      {/* 相關資訊 */}
      <div className="red customMarginBottom50">
        <h6>
          ※主辦單位保有開課與否及課程內容調整之權利。所有訊息以官網公布為準
        </h6>
      </div>
    </>
  )
}

export default CloudTechnologyClass
