import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ClassInfo from './ClassInfo'
import ContactStaff from './ContactStaff'
import SubsidyBlock from './SubsidyBlock'
import { EmploymentIndexList } from '../EmploymentClass/EmploymentIndexList'
import { LongTermClassTypeInfo } from '../../services/LongTerm'
import FrontEndClass from '../EmploymentClassification/FrontEndClass'
import AIoTSeriesClass from '../EmploymentClassification/AIoTSeriesClass'
import AntennaClass from '../EmploymentClassification/AntennaClass'
import AppDeveloperClass from '../EmploymentClassification/AppDeveloperClass'
import BigDataAnalysisClass from '../EmploymentClassification/BigDataAnalysisClass'
import CloudSystemClass from '../EmploymentClassification/CloudSystemClass'
import CloudTechnologyClass from '../EmploymentClassification/CloudTechnologyClass'
import EmbeddedSystemClass from '../EmploymentClassification/EmbeddedSystemClass'
import FullStackDeveloperClass from '../EmploymentClassification/FullStackDeveloperClass'
import JavaSoftwareClass from '../EmploymentClassification/JavaSoftwareClass'
import MicrosoftDeveloperClass from '../EmploymentClassification/MicrosoftDeveloperClass'
import PhpdClass from '../EmploymentClassification/PhpdClass'
import UIuxInteractiveClass from '../EmploymentClassification/UIuxInteractiveClass'
import VR3DGameClass from '../EmploymentClassification/VR3DGameClass'
import UnityDeveloperClass from '../EmploymentClassification/UnityDeveloperClass'
import AnalyseInformationClass from '../EmploymentClassification/AnalyseInformationClass'
import PWOJTFT from '../EmploymentClassification/PWOJTFT'
import PWOJTPT from '../EmploymentClassification/PWOJTPT'
import PWOJTJT from '../EmploymentClassification/PWOJTJT'
import PWOJTMT from '../EmploymentClassification/PWOJTMT'
import JMSMQATClass from '../EmploymentClassification/JMSMQATClass'
import JPPHPSTClass from '../EmploymentClassification/JPPHPSTClass'
import JWJETPTClass from '../EmploymentClassification/JWJETPTClass'

const UseLongTerm = () => {
  const location = useLocation()

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)

  // 裝載報名課程資訊
  const [classInfo, setClassInfo] = useState([])

  const [applyInfoHtml, setApplyInfoHtml] = useState('')

  // console.log(EmploymentIndexList)
  const [description, setDescription] = useState('')
  // console.log(description)

  // 取得報名課程資訊
  const handleGetClassInfo = async () => {
    const datas = await LongTermClassTypeInfo()
    setClassInfo(datas)
  }

  const handleFilterApplyInfo = () => {
    if (location.pathname.substr(1, 3) === 'job') {
      // 網址前7碼對應顯示的班別報名課程資訊
      const filterClassType = classInfo.filter(
        (item) => item.classTypeID === location.pathname.substr(5, 7)
      )

      // 檢查篩選後的數組是否有元素
      if (filterClassType.length > 0) {
        // 第一个匹配項的 applyInfo 属性
        const parsedHtml = filterClassType[0]['applyInfo']

        setApplyInfoHtml(parsedHtml)
      } else {
        // console.log('No matching items found')
      }
    } else {
      // 網址前7碼對應顯示的班別報名課程資訊
      const filterClassType = classInfo.filter(
        (item) => item.classTypeID === location.pathname.substr(10, 7)
      )

      // 檢查篩選後的數組是否有元素
      if (filterClassType.length > 0) {
        // 第一个匹配項的 applyInfo 属性
        const parsedHtml = filterClassType[0]['applyInfo']

        if (parsedHtml) {
          const filteredApplyInfo = parsedHtml.replace(
            /<li><span style="color:hsl\(0, 0%, 0%\);">本班洽詢專員 .*?<\/li>|<li>本班洽詢專員.*?<\/li>|<li>詳洽各區承辦人.*?<\/li>/g,
            ''
          )
          setApplyInfoHtml(filteredApplyInfo)
        } else {
          setApplyInfoHtml('')
        }
      } else {
        // console.log('No matching items found')
      }
    }
  }

  const handleBannerDescription = () => {
    // 區分轉職班或養成班
    if (location.pathname.substr(1, 3) === 'job') {
      const matchingItem = EmploymentIndexList.find(
        (item) => item.classTypeId === location.pathname.substr(5, 7)
      )

      if (matchingItem) {
        setDescription(matchingItem.marketing)
      } else {
        // setDescription('没有可用的描述')
      }
    } else {
      const matchingItem = EmploymentIndexList.find(
        (item) => item.classTypeId === location.pathname.substr(10, 7)
      )

      if (matchingItem) {
        setDescription(matchingItem.marketing)
      } else {
        // setDescription('没有可用的描述')
      }
    }
  }

  // 取得資料
  useEffect(() => {
    handleGetClassInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 處理報名課程資訊的篩選
  useEffect(() => {
    if (classInfo.length !== 0) {
      handleFilterApplyInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classInfo])

  // 處理報名課程資訊的篩選
  useEffect(() => {
    handleBannerDescription()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classInfo])

  return {
    applyInfoHtml,
    description,
    ClassInfo,
    ContactStaff,
    SubsidyBlock,
    isLoading,
    setIsLoading,
    VR3DGameClass,
    AppDeveloperClass,
    EmbeddedSystemClass,
    AIoTSeriesClass,
    BigDataAnalysisClass,
    JavaSoftwareClass,
    FullStackDeveloperClass,
    MicrosoftDeveloperClass,
    CloudTechnologyClass,
    FrontEndClass,
    UnityDeveloperClass,
    UIuxInteractiveClass,
    CloudSystemClass,
    PhpdClass,
    AntennaClass,
    AnalyseInformationClass,
    PWOJTFT,
    PWOJTPT,
    PWOJTJT,
    PWOJTMT,
    JMSMQATClass,
    JPPHPSTClass,
    JWJETPTClass,
  }
}

export default UseLongTerm
